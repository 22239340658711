﻿var SPPurchaseStatus =
    {
        Accepted: 1,
        Declined: 2,
        Waiting: 3,
        Delayed: 4,
        NewOffer: 5,
        ClientAccepted: 6,
        NotAuthorized: 33,
        RegulatorTimeOut: 9999
    };

SPPurchaseStatus.IsWaiting = function (status) {
    return (status == SPPurchaseStatus.Waiting || status == SPPurchaseStatus.NewOffer || status == SPPurchaseStatus.ClientAccepted || status == SPPurchaseStatus.NotAuthorized);
}

window.SPPurchaseStatus = SPPurchaseStatus;