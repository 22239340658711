﻿var VolleyballScore = (function ()
{
    function VolleyballScore(game)
    {

        this.scores = {
            Team1Set1Points: '-',
            Team2Set1Points: '-',
            Team1Set2Points: '-',
            Team2Set2Points: '-',
            Team1Set3Points: '-',
            Team2Set3Points: '-',
            Team1Set4Points: '-',
            Team2Set4Points: '-',
            Team1Set5Points: '-',
            Team2Set5Points: '-',
            Team1Sets: 0,
            Team2Sets: 0,
            ServingTeam: 0,
            Set: 0,
            IsOver: false
        };

        var mapping = [];
        mapping[130] = 'Team1Set1Points';
        mapping[131] = 'Team2Set1Points';
        mapping[132] = 'Team1Set2Points';
        mapping[133] = 'Team2Set2Points';
        mapping[134] = 'Team1Set3Points';
        mapping[135] = 'Team2Set3Points';
        mapping[136] = 'Team1Set4Points';
        mapping[137] = 'Team2Set4Points';
        mapping[138] = 'Team1Set5Points';
        mapping[139] = 'Team2Set5Points';
        mapping[140] = 'Team1Sets';
        mapping[141] = 'Team2Sets';
        mapping[164] = 'ServingTeam';

        var arr = game.AdditionalData;
        if (arr && arr.length > 0)
        {
            for (var i = 0; i < arr.length; i += 1)
            {
                this.scores[mapping[arr[i][0]]] = 1 * arr[i][1];
            }
        }

        var setsToWin = game.NumberOfParts ? Math.ceil(game.NumberOfParts / 2) : 3;
        this.scores.IsOver = this.scores.Team1Sets === setsToWin || this.scores.Team2Sets === setsToWin;
        this.scores.Set = (this.scores.Team1Sets + this.scores.Team2Sets + (this.scores.IsOver ? 0 : 1)) || 1;

        if (game.IsTeamSwapEnabled)
        {
            this.swap(this.scores, 'Team1Set1Points', 'Team2Set1Points');
            this.swap(this.scores, 'Team1Set2Points', 'Team2Set2Points');
            this.swap(this.scores, 'Team1Set3Points', 'Team2Set3Points');
            this.swap(this.scores, 'Team1Set4Points', 'Team2Set4Points');
            this.swap(this.scores, 'Team1Set5Points', 'Team2Set5Points');
            this.swap(this.scores, 'Team1Sets', 'Team2Sets');
            this.scores.ServingTeam = this.scores.ServingTeam ? 0 : 1;
        }
    }

    VolleyballScore.prototype.getScore = function ()
    {
        return this.scores;
    };

    VolleyballScore.prototype.swap = function (obj, prop1, prop2)
    {
        var temp = obj[prop1];
        obj[prop1] = obj[prop2];
        obj[prop2] = temp;
    };

    return VolleyballScore;
}());

window.VolleyballScore = VolleyballScore;