/* globals Application */

/* exported EPSCashier */
var EPSCashier = (function ()
{
    var EPSCashier = {};

    EPSCashier.HandleFrameHeight = function(element, frameHeight) {
        if (element && frameHeight) {
            element.style.height = frameHeight;
        }
    }
    
    EPSCashier.HandleOpenFullCashier = function(url) {
        Application.OpenFullCashier(url);
    }
    
    EPSCashier.HandleOpenBalance = function() {
        var BalanceLinkType = CONSTANTS.AccountMenuLinkTypes.Balance;
    
        if (typeof (MyAccountResponsiveBlock) === 'undefined') {
            return;
        }
    
        var panelToNavigate = Array.find(Application.Panels, function (panel) {
            return panel.Position === PanelPosition.POPUP && panel.Blocks.contains(function (block) {
                return block instanceof MyAccountResponsiveBlock;
            });
        });
    
        var params = [BalanceLinkType];
        panelToNavigate && Application.navigateTo(panelToNavigate.ID, false, params);
    }    

    return EPSCashier;
}());

window.EPSCashier = EPSCashier;
