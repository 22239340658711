﻿/* globals CrossEvent */
/* exported customSelect */
var customSelect = (function ()
{
    'use strict';

    var api = {
            activeItem: null,
        },
        customChange = CrossEvent('change', true, true),
        CLASSES = {
            wrapper: 'filter-customselect-wrapper',
            onFocus: 'onfocus',
            isOpen: 'filter-htmldropdown-open',
            isSelected: 'filter-htmldropdown-option-selected'
        },
        handlers = {};

    function getPropOrEmpty(prop) {
        return prop || '';
    }

    function getFilter(target)
    {
        return document.getElementById('filter_' + target.getAttribute('data-select'));
    }

    function removeClass(parent, cl)
    {
        var items = parent.getElementsByClassName(cl),
            len = items.length;

        while (len)
        {
            len -= 1;
            items[len].classList.remove(cl);
        }
    }

    function getDropdownItem(parent, value)
    {
        return parent.querySelector('[data-value="' + value + '"]');
    }

    api.init = function ()
    {
        document.addEventListener('click', this, false);
        document.addEventListener('focus', this, true);
        document.addEventListener('blur', this, true);
        document.addEventListener('change', this, false);
    };

    api.destroy = function ()
    {
        document.removeEventListener('click', this, false);
        document.removeEventListener('focus', this, true);
        document.removeEventListener('blur', this, true);
        document.removeEventListener('change', this, false);
    };

    api.handleEvent = function (e)
    {
        var handler = handlers['on' + e.type];
        
        if (typeof handler === 'function' &&
            e.target.nodeType === 1 && // this excludes the `document`
            e.target.hasAttribute('data-select'))
        {
            handler.call(this, e);
        }
        else if (this.activeItem && e.type !== 'focus' && (e.type !== 'blur' || e.target === this.activeItem))
        {
            this.hide(e);
        }
    };

    api.show = function (target)
    {
        this.activeItem = document.getElementById(target.getAttribute('data-select'));
        var wrapper = target.closest('.' + CLASSES.wrapper);
        wrapper && wrapper.classList.add(CLASSES.isOpen);
    };

    api.hide = function ()
    {
        if (!this.activeItem)
        {
            return;
        }
        var wrapper = this.activeItem.closest('.' + CLASSES.wrapper);
        wrapper && wrapper.classList.remove(CLASSES.isOpen);
        this.activeItem = null;
    };

    api.setValue = function (select, val, data)
    {
        var dropdownParent,
            dropdownItem,
            selectOldValue = select.value;

        if (!select)
        {
            return;
        }

        select.value = val;
        if (select.options.selectedIndex === -1)
        {
            select.value = selectOldValue;
        }

        if (customChange.eventPhase === 0)
        {
            select.dispatchEvent(customChange);
        }
        else
        {
            var event = CrossEvent('change', true, true);
            select.dispatchEvent(event);
        }

        dropdownParent = document.getElementById('scrollable_' + select.id);

        if (dropdownParent)
        {
            removeClass(dropdownParent, CLASSES.isSelected);
            dropdownItem = getDropdownItem(dropdownParent, val);
            dropdownItem && dropdownItem.classList.add(CLASSES.isSelected);
        }

        if (data && data.Name && data.Id) 
        {
            var displayedInputValue = document.getElementById("filter_value_" + select.id);
            displayedInputValue && (displayedInputValue.value = data.Name);
        }
    };

    api.config = function (params)
    {
        var config = {
            id: params.id,
            name: params.name || params.id,
            disabled: params.disabled || false,
            cssClasses: getPropOrEmpty(params.cssClasses),
            selectClass: getPropOrEmpty(params.selectClass),
            placeholder: getPropOrEmpty(params.placeholder),
            title: getPropOrEmpty(params.title),
            inlineHandler: getPropOrEmpty(params.inlineHandler),
            uat: getPropOrEmpty(params.uat),
            data: []
        };

        if (params.firstOption)
        {
            config.data.push(params.firstOption);
        }

        if (typeof params.setData === 'function')
        {
            params.setData(config.data);
        }
        else if (Array.isArray(params.setData))
        {
            config.data = params.setData;
        }

        return config;
    };

    handlers.onclick = function (e)
    {
        var target = e.target,
            actionType = target.getAttribute('data-select-action'),
            callback = handlers[actionType + 'click'];

        if (typeof callback === 'function')
        {
            callback.call(api, e);
        }
        else
        {
            api.hide();
        }
    };

    handlers.oninputclick = function (e)
    {
        var cond = api.activeItem && e.target.getAttribute('data-select') === api.activeItem.id;
        api.activeItem && api.hide();
        !cond && api.show(e.target);
    };

    handlers.onoptionclick = function (e)
    {
        var target = e.target;

        if (!target.classList.contains(CLASSES.isSelected))
        {
            api.setValue(api.activeItem, target.getAttribute('data-value'));
        }
        api.hide();
    };

    handlers.onfocus = function (e)
    {
        var filter = getFilter(e.target);

        filter && filter.classList.add(CLASSES.onFocus);
    };

    handlers.onblur = function (e)
    {
        var filter = getFilter(e.target);

        filter && filter.classList.remove(CLASSES.onFocus);
    };

    handlers.onchange = function (e)
    {
        var target = e.target,
            input = document.getElementById('filter_value_' + target.id),
            label = '',
            selected = null;

        if (input)
        {
            var selectedIndex = e.target.selectedIndex;
            selected = e.target.children[selectedIndex];
            label = input.getAttribute('data-label') || '';
            input.value = selected.textContent !== input.placeholder ? (label + selected.textContent) : '';
        }
    };

    return api;
}());

window.customSelect = customSelect;
