﻿/* exported StorageUtils */
/*globals DOMException */
var StorageUtils = (function ()
{
    "use strict";
    var api = {};

    api.sessionStorageAvailable = function ()
    {
        if (typeof (sessionStorage) == "undefined") { return false; }

        try
        {
            sessionStorage.setItem("sessionStorageAvailable_test", "sessionStorageAvailable");
            var result = sessionStorage.length > 0;
            sessionStorage.removeItem("sessionStorageAvailable_test");
            return result;
        }
        catch (e)
        {
            return false;
        }
    };

    api.saveToStorage = function (key, value, storage)
    {
        try
        {
            if (!storage)
            {
                if (typeof (sessionStorage) == "undefined")
                {
                    Cookies.set(key, value);
                }
                else
                {
                    sessionStorage.setItem(key, value);
                }
            }
            else
            {
                storage.setItem(key, value);
            }
        }
        catch (e)
        {
            if (e.code == DOMException.QUOTA_EXCEEDED_ERR && sessionStorage.length === 0 && localStorage.length === 0)
            {
                Cookies.set(key, value);
            }
            else
            {
                throw e;
            }
        }
    };

    api.getFromStorage = function (key, storage)
    {
        var result; 
        if (storage)
        {
            result = storage.getItem(key);
        }
        if (!result)
        {
            var sessionStorageItem = sessionStorage.getItem(key);
            var cookiesItem = Cookies.get(key);
            result = sessionStorageItem ? sessionStorageItem : cookiesItem;
        }

        return (typeof result != "undefined") ? result : null;
    };

    api.removeFromStorage = function (key, storage)
    {
        Cookies.remove(key);
        storage ? storage.removeItem(key) : sessionStorage.removeItem(key);
    };

    api.keyExsists = function (key, storage)
    {
        return (storage && storage.getItem(key)) || sessionStorage.getItem(key) || document.cookie.indexOf(key) >= 0;
    };

    return api;
}());

window.StorageUtils = StorageUtils;