﻿/* globals executeEvents */
/* exported UITooltip */
var UITooltip = (function ()
{
    'use strict';

    var DEFAULTS = {
        ID: 'global-tootlip',
        classes: 'global-tooltip',
        visibleClass: 'global-tooltip-visible',
        offsetX: 10,
        offsetY: 20,
        dataAttribute: 'data-tooltip',
        eventTarget: document
    };

    function Tooltip(options)
    {
        this.tooltip = null;
        this.coords = {
            x: 0,
            y: 0
        };
        this.visibleState = false;
        this.onBeforeShow = {};
        this.onAfterShow = {};
        this.onBeforeHide = {};
        this.onAfterHide = {};

        this.opt = Object.assign({}, DEFAULTS, options);

        this.init();
    }

    Tooltip.prototype.init = function ()
    {
        this.tooltip = document.getElementById(this.opt.ID);
        if (!this.tooltip)
        {
            this.tooltip = this.createTooltip();
            document.body.appendChild(this.tooltip);
        }

        this.opt.eventTarget.addEventListener('mouseenter', this, true);
        this.opt.eventTarget.addEventListener('mouseleave', this, true);
    };

    Tooltip.prototype.destroy = function ()
    {
        this.opt.eventTarget.removeEventListener('mouseenter', this, true);
        this.opt.eventTarget.removeEventListener('mouseleave', this, true);

        this.tooltip.parentNode.removeChild(this.tooltip);
        this.tooltip = null;
        this.visibleState = false;
    };

    Tooltip.prototype.createTooltip = function ()
    {
        var el = document.createElement('div');
        el.id = this.opt.ID;
        el.className = this.opt.classes;
        el.role = 'tooltip';

        return el;
    };

    Tooltip.prototype.handleEvent = function (e)
    {
        typeof this[e.type] === 'function' && this[e.type](e);
    };

    Tooltip.prototype.mouseenter = function (e)
    {
        var target = e.target;

        if (target instanceof HTMLElement && target.hasAttribute(this.opt.dataAttribute))
        {
            this.prepare(e, target);
        }
    };

    Tooltip.prototype.mouseleave = function (e)
    {
        if (this.visibleState)
        {
            this.hide();
        }
    };

    Tooltip.prototype.setCoords = function (e)
    {
        this.coords.x = e.pageX + this.opt.offsetX;
        this.coords.y = e.pageY + this.opt.offsetY;
    };

    Tooltip.prototype.prepare = function (e, target)
    {
        var data = target.getAttribute(this.opt.dataAttribute) || target.innerHTML;
        this.setCoords(e);
        this.show(data);
    };

    Tooltip.prototype.show = function (data)
    {
        if (!this.tooltip) { return; }
        executeEvents(this.onBeforeShow);
        this.tooltip.innerHTML = data;
        this.position(this.coords.x, this.coords.y);
        this.tooltip.classList.add(this.opt.visibleClass);
        this.visibleState = true;
        executeEvents(this.onAfterShow);
    };

    Tooltip.prototype.hide = function ()
    {
        if (!this.tooltip) { return; }
        executeEvents(this.onBeforeHide);
        this.tooltip.removeAttribute('style');
        this.tooltip.classList.remove(this.opt.visibleClass);
        this.visibleState = false;
        executeEvents(this.onAfterHide);
    };

    Tooltip.prototype.position = function (x, y)
    {
        if (!this.tooltip) { return; }

        var root = document.documentElement,
            tooltipW = this.tooltip.offsetWidth,
            tooltipH = this.tooltip.offsetHeight,
            limitW = root.clientWidth + window.pageXOffset,
            limitH = root.clientHeight + window.pageYOffset;

        if (x + tooltipW > limitW)
        {
            x -= tooltipW + this.opt.offsetX;
        }

        if (y + tooltipH > limitH)
        {
            y -= tooltipH + this.opt.offsetY;
        }

        this.tooltip.style.left = x + 'px';
        this.tooltip.style.top = y + 'px';
    };

    return Tooltip;

}());

window.UITooltip = UITooltip;