﻿/**
 * ui.countdown.js
 * @namespace
 */
var UI = window.UI || {};

/**
 * Count down for upcoming events
 * @returns Countdown constructor
 */
UI.Countdown = (function ()
{
    'use strict';
    
    /**
     * Initialize an instance of CountDown with configuration object
     * @constructor
     * @param {Object} conf
     */
    function Countdown(conf)
    {        
        if (!conf.td || !conf.id)
        {
            return false;
        }

        this.config = {
            targetDate: new Date(conf.td).getTime(),
            el: document.getElementById(conf.id),
            currentDate: undefined,
            timeLeft: undefined,
            interval: undefined,
            d: undefined,
            h: undefined,
            m: undefined,
            s: undefined
        };

        if (!this.config.el){ return; }

        this.init();
    }

    /**
     * Initialize
     * @param {Object} td - Object for the new target date
     */
    Countdown.prototype.init = function (td)
    {
        if (td)
        {
            this.config.targetDate = new Date(td).getTime();
        }

        this.config.currentDate = new Date().getTime();

        this.start();
    };

    /**
     * Event for countDown timer expiring
     * GameBlock is subscribed for it, when timer expire PreLive Event view jumps into Live Event View
     */
    Countdown.prototype.onTimeExpire = {};

    /**
     * Apend zero before the single numbers
     * @param {Integer} val
     */
    Countdown.prototype.isSingle = function (val)
    {
        var tmp = val.toString();
        return tmp.length < 2 ? 0 + tmp : tmp;
    };

    /**
     * Start count down
     */
    Countdown.prototype.start = function ()
    {
        var days = this.config.el.querySelector('.countDays'),
            hours = this.config.el.querySelector('.countHours'),
            mins = this.config.el.querySelector('.countMins'),
            secs = this.config.el.querySelector('.countSecs');

        this.config.interval = setInterval((function ()
        {
            return function ()
            {
                var localT = new Date();
                this.config.currentDate = localT.getTime() + (localT.getTimezoneOffset() * 60000);
                this.config.timeLeft = (this.config.targetDate - this.config.currentDate) / 1000;

                this.config.d = parseInt(this.config.timeLeft / 86400);
                this.config.timeLeft = this.config.timeLeft % 86400;

                this.config.h = parseInt(this.config.timeLeft / 3600);
                this.config.timeLeft = this.config.timeLeft % 3600;

                this.config.m = parseInt(this.config.timeLeft / 60);
                this.config.s = parseInt(this.config.timeLeft % 60);

                //if we get negative value for some reason || the couner has finished completely -> stop it
                if (this.config.s < 0 || (this.config.d + this.config.m === 0 && this.config.s <= 0))
                {                  
                    days.innerHTML = '00';
                    hours.innerHTML = '00';
                    mins.innerHTML = '00';
                    secs.innerHTML = '00';

                    this.stop();
                    executeEvents(this.onTimeExpire);
                }
                else
                {
                    days.innerHTML = this.isSingle(this.config.d);
                    hours.innerHTML = this.isSingle(this.config.h);
                    mins.innerHTML = this.isSingle(this.config.m);
                    secs.innerHTML = this.isSingle(this.config.s);
                }
            };
        })().bind(this), 1000);
    };   

    /**
     * Stop count down
     */
    Countdown.prototype.stop = function ()
    {
        clearInterval(this.config.interval);
        this.config.interval = undefined;      
    };

    return Countdown;

}());

window.UI = UI;

