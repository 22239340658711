/*********************************************************************************************************************************************\
/*
/* Event
/*
/**/

function LiveSpecialEventInfo(initArray)
{
  BaseEventInfo.call(this, initArray);
  this.Special = true;
  this.Live = true; 
  
  this.Visible = { ML : true, Spread : true, OU : true };
} 
$.extend(LiveSpecialEventInfo.prototype, BaseEventInfo.prototype);

$.extend(LiveSpecialEventInfo.prototype,
{ 
  setVisible : function()
  {
    this.Visible = { ML : true, Spread : true, OU : true };
  },
  
  getVisible : function()
  {
    var has = { ML:false, Spread:false, OU:false};
    for(var key in this.Lines)
    {
      var ln = this.Lines[key];
      has.ML = has.ML || ln.ML;
      has.OU = has.OU || ln.OU;
      has.Spread = has.Spread || ln.Spread;
    }
    
    return (has.ML && this.Visible.ML) || (has.OU && this.Visible.OU) || (has.Spread && this.Visible.Spread);
  },

  updateFromServer : function(initArray)
  {
    this.HasCombo = false;
    this.Updated = true;
    this.LastUpdate = new Date();
    
    if((initArray[2]) && (initArray[2] != -1))
    {
      this.updateLines(HashtableOf(initArray[2], LineGroup));

      this.MainLine = null;
      this.Options = [];
      
      for(var key in this.Lines)
      {
        var line = this.Lines[key];
      
        if(line.IsOptional)          
          this.Options.push(line);
        else
          this.MainLine = line;
      } 
    }
    else if(initArray[2] == -1)
    {
      this.Deleted = true;
      return;
    }
    else
    { 
      this.__resetUpdated();
    }
    
    if(initArray[3] == undefined) return;
    
    if(initArray[3] == 1)
    {
      this.Score1 = initArray[4];
      this.Score2 = initArray[5];
      return;
    }
    
    this.Team1 = initArray[4];
    this.Team2 = initArray[5];
    this.TypeName = initArray[6];

    spt = specialsID[this.Type];
    this.SplitType = (spt != undefined) ? spt : initArray[7];
   
    this.OrderID = initArray[8];
    
    if(initArray[9])
    {
      this.Score1 = initArray[10];
      this.Score2 = initArray[11];
    }

    if (initArray[12])
    {
        this.LiveGroupID = initArray[12];
    }
  },
    
  getLinesCount : function(opt)
  {
    var res = 0;
    
    for(var key in this.Lines)
    {
      var ll = this.Lines[key];
      if(opt && ll.IsOptional)
      {
        res +=  ll.hasOdds() ? 2 : 0;
        continue;
      }
      
      if(!opt && !ll.IsOptional)
      {
        return ll.hasDraw() ? 3 : 2;
      }
    }
    
    return res;
  },

  getLineTypeID: function (line)
  {
      return line.ML && line.ML.BetType || line.OU && line.OU.BetType || line.Spread && line.Spread.BetType;
  }
});

window.LiveSpecialEventInfo = LiveSpecialEventInfo;