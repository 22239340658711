﻿/* jshint ignore:start */
var UI = window.UI || {};

UI.scrollUtil = (function ()
{
    'use strict';
    var page = document,
        onAfterBottomScroll = {};

    var scrollHandler = debounce(function () {

        if (Math.round(window.innerHeight + window.scrollY) >= document.body.offsetHeight)
        {
            executeEvents(onAfterBottomScroll);
        }

    }, 50);

    // Attach scroll listeners to the current panel
    function attachScroll() {
                
        detachScroll();       
        
        if (page) {
            new uiElement({
                name: 'mainContentScroll',
                eventHtmlElement: page,
                handler: scrollHandler,
                eventType: 'scroll'
            });
        }
        
    }
    
    // Detach scroll listeners from current panel
    function detachScroll() {
        
        if (page) {
            page.detach(page);
        }
    }

    return {
        attachScroll: attachScroll,                   // AttachScroll
        detachScroll: detachScroll,                    // DetachScroll
        onAfterBottomScroll: onAfterBottomScroll
    };

}());

window.UI = UI;

/* jshint ignore:end */