﻿import { TypeExtends } from "sb-js-extends"
TypeExtends();

String.prototype.toCurrencyFormat = function (number, currencySign) {
    return this.toCurrencyLocalizedFormat(number, currencySign);
}

String.prototype.toCurrencyLocalizedFormat = function (number, currencySign) {
    var amount = parseFloat(number) > CONSTANTS.ZeroBalanceLimit.Negative && 
    parseFloat(number) < CONSTANTS.ZeroBalanceLimit.Positive ? 0 : number;
    var roundedNumber = parseFloat(amount).roundForCurrencyFormat();
    var value = MoneyFormat.format(roundedNumber);
    return this.format(value, currencySign);
}

Number.prototype.roundForCurrencyFormat = function() {
    return (Math.floor((this.toFixed(5) * 1000).toPrecision(16)) / 1000).toFixed(2);
}

/*****************************************************************************************************************************************/
/*
/*  Server constants routines
/*
/**/

/// Internal hashtables for loaded constants
var __sectionsHash = new Object();

/// Adds translations for specified section into cache
/// string section - section name
/// JSON values - key/value pairs
function $initStrings(section, values)
{
    for (var propertyName in values)
    {
        if (values.hasOwnProperty(propertyName))
        {
            values[propertyName] = $unescapeHTML(values[propertyName]);
        }
    }
    __sectionsHash[section] = values;
}

/// Returns section of translated string constants
///    string section - section name
function $string(section) {
    if (arguments.length == 1) {
        var scs = __sectionsHash[section];
        if (scs) return scs;

        scs = __loadStringSection(section);
        if (scs) return __sectionsHash[section] = scs;

        return null;
    }
    else {
        // if more than one argument is supplied, the function doesn't return anything,
        // but loads the requested sections into the __sectionsHash

        var nonCachedSections = Array.filter(arguments,
                                            function (name) { return !__sectionsHash[name] })
                                     .join(',');
        if (nonCachedSections)
        {
            __loadStringSectionList(nonCachedSections);
        }
    }
}

/// Returns section of non translated numeric constants
///    string section - section name
function $int(section) {
    scs = $string(section);

    if (!scs.$int) {
        __setNumbers(scs);
    }

    return scs;
}

//--------------------------------  SERVICE FUNCTIONS --------------------------------------------------------------

/// Object clonning
function __cloneObject(obj) {
    res = {};
    for (key in obj) {
        res[key] = obj[key];
    }
    return res;
}

/// Trying to convert each massive element to number
function __setNumbers(obj) {
    for (key in obj) {
        var zz = new Number(obj[key]);
        if (!isNaN(zz)) obj[key] = zz;
    }

    obj.$int = true;
}

/// Loading the section from server
///   string sectionName - name of section in xml file
function __loadStringSection(sectionName) {
    var data = __blockingAJAXCall("/Constants.ashx?section={0}&lock={1}".format(sectionName, (new Date()).getTime()));
    if (data.status != 200) return null;

    var lines = data.text.split("\r\n");
    if (lines.length == 0) return null;

    var res = new Object;                         // resulting object
    var splitter = /^(\w+?)=(.*)/;              // precompiled regex for parsing data if format key=value

    for (var i = 0; i < lines.length; i++) {
        var line = lines[i];
        var splitIdx = line.indexOf("=");

        if (splitIdx < 0) continue;

        var key = line.substr(0, splitIdx);
        var val = line.substr(splitIdx + 1, line.length - splitIdx - 1);

        res[key] = val;
    }

    res.$int = false;
    return res;
}

/// Loading the section from server
///   string sectionName - name of section in xml file
function __loadStringSectionList(sections) {
    if (typeof isLngDomainChanged != "undefined") {
        if (isLngDomainChanged == 1 && window.location.href.indexOf("www") != -1 && Cookies.get("lng") != 4) {
            Cookies.remove("lng");
        }
    }

    var data = __blockingAJAXCall("/Constants.ashx?section={0}&lock={1}".format(sections, (new Date()).getTime()));
    if (data.status != 200) return;

    var lines = data.text.split("\r\n");
    if (lines.length == 0) return;

    var current_section = null;

    for (var i = 0; i < lines.length; i++) {
        var line = lines[i];

        if (line.indexOf("@@@@@") == 0) {
            current_section = {};
            var name = line.substr(5, line.length - 5);
            __sectionsHash[name] = current_section;
            continue;
        }

        if (!current_section) continue;

        var line = lines[i];
        var splitIdx = line.indexOf("=");

        if (splitIdx < 0) continue;

        var key = line.substr(0, splitIdx);
        var val = line.substr(splitIdx + 1, line.length - splitIdx - 1);

        current_section[key] = val;
    }
}

/// Performs blocking ajax call to the url. Returns object like { bool status, string statusText,  string text }
///    URL      - URL of script to call
///    isPost   - [optional] is this post request
///    postbody - [optional] body for post request (XmlDocument or string)
function __blockingAJAXCall(URL, isPost, postbody) {
    var xmlHttp = __createXmlHttp();

    xmlHttp.open((isPost) ? "POST" : "GET", application_root + URL, false);
    xmlHttp.setRequestHeader("RequestTarget", "XmlService");

    if ((isPost) && (postbody))
        xmlHttp.send(postbody);
    else
        xmlHttp.send(null);

    var Status = xmlHttp.status;
    if (Status != 200) {
        return { status: Status, statusText: xmlHttp.statusText, text: xmlHttp.responseText };
    }

    return { status: 200, statusText: 'OK', text: xmlHttp.responseText };
}

/****************************************************************************************************************************************
/*
/*  Date formating functions
/*
/**/

/// Decoding time from ISO string representation
Date.fromISO = function(ms) {
    //2008-05-12T15:28:45.6642211+03:00 - example of server answer
    if (ms == null) return;
    const arr = ms.match(/(\d+)-(\d+)-(\d+)T(\d+):(\d+):(\d+)\.?(\d*)/i);
    if (!arr) return undefined;

    return new Date(
    parseInt(arr[1], 10),
    parseInt(arr[2], 10) - 1,
    parseInt(arr[3], 10),
    parseInt(arr[4], 10),
    parseInt(arr[5], 10),
    parseInt(arr[6], 10),
    arr[7] ? parseFloat("0." + arr[7]) * 1000 : 0
  );
}

/// Decoding time from ISO string representation and applying timezone shift
Date.UTCFromISO = function(ms) {
    //2008-05-12T15:28:45.6642211+03:00 - example of server answer
    if (ms.match(/Z\s*$/i)) return Date.fromISO(ms);

    var pre = Date.fromISO(ms);

    const arr = ms.match(/([+-])(\d+):(\d+)\s*$/i);
    if (!arr) return undefined;

    var delta = (parseInt(arr[2]) * 60 + parseInt(arr[3])) * (arr[1] == "+" ? -1 : +1);
    pre.setMinutes(pre.getMinutes() + delta);
    return pre;
}

function $dict(constant)
{
    return (PageConstants && (constant in PageConstants))
             ? PageConstants[constant]
             : constant;
}

function $createBlockDictById(id)
{
    var dict = Configuration.Dictionary[id];

    return $createBlockDict(dict);
}

function $createBlockDict(dict)
{
    var constants = dict;

    return function(constant)
    {
        return (constants && (constant in constants))
            ? constants[constant]
            : constant;
    };
}

/* exported $escapeHTML */
var $escapeHTML = (function ()
{
    var cache = { 'null': '', 'undefined': '', '': '' },
        map = { '&': '&amp;', '<': '&lt;', '>': '&gt;', '"': '&quot;', '\'': '&#39;' },
        mapFunc = function(c) { return map[c]; };

    /*jshint maxcomplexity: 6*/
    return function(str) {
        if (str === 0 || (str && !str.replace)) {
            return str;
        }
        if (cache.hasOwnProperty(str)) {
            return cache[str] !== null ? cache[str] : str;
        }

        var result = str.replace(/[&<>'"]/g, mapFunc);
        cache[str] = result !== str ? result : null;

        return result;
    };
}());

/* exported $escapeHTML */
var $unescapeHTML = (function ()
{
    var map = { '&amp;': '&', '&lt;': '<', '&gt;': '>', '&quot;': '"' },
        mapFunc = function (c) { return map[c]; };

    /*jshint maxcomplexity: 6*/
    return function (str)
    {
        if (str === 0 || (str && !str.replace))
        {
            return str;
        }

        var result = str.replace(/&amp;|&lt;|&gt;|&quot;/g, mapFunc);

        return result;
    };
}());

String.prototype.trimText = function(charCount)
{
	var endingSymbols = '...';

	if (typeof charCount !== 'number' || this.length <= charCount + endingSymbols.length)
	{
		return this.toString();
	}

	return this.substring(0,charCount - endingSymbols.length) + endingSymbols;
}

window.__sectionsHash = __sectionsHash;
window.$initStrings = $initStrings;
window.$string = $string;
window.$int = $int;
window.__cloneObject = __cloneObject;
window.__setNumbers = __setNumbers;
window.__loadStringSection = __loadStringSection;
window.__loadStringSectionList = __loadStringSectionList;
window.__blockingAJAXCall = __blockingAJAXCall;
window.$dict = $dict;
window.$createBlockDict = $createBlockDict;
window.$createBlockDictById = $createBlockDictById;
window.$escapeHTML = $escapeHTML;
window.$unescapeHTML = $unescapeHTML;