﻿// TODO: replace uiElement with new UIElement
/* exported InitialEventListeners */
/* globals uiElement, getParent */
/* jshint strict: false */

var InitialEventListeners = (function (undefined)
{
    var globalClickEvents;

    function init()
    {
        headerEventListeners();
        addGlobalClickEvents();
    }

    function headerEventListeners()
    {
        var scrollToTop = function ()
        {
            window.scroll(0, 0);
        };

        var elements = document.getElementsByClassName('header_logo');
        for (var i = 0; i < elements.length; i = i + 1)
        {
            elements[i].addEventListener('click', scrollToTop);
        }
    }

    function addGlobalClickEvents()
    {
        // TODO: replace uiElement with new UIElement
        globalClickEvents = uiElement({
            name: 'globalClickEvents',
            eventHtmlElement: document.body,
            handler: globalClickHandler,
            eventType: 'click'
        });
    }

    function parseFunctionParams(event, currentElement)
    {
        /* jshint maxcomplexity: 15 */
        var dataParams = currentElement.dataset ? currentElement.dataset.params : currentElement.getAttribute('data-params');
        var paramsArr = [];
        var dataJSON = currentElement.dataset ? currentElement.dataset.json : currentElement.getAttribute('data-json');
        var parsedJSON = JSON.parse(dataJSON);

        if (!Number.MAX_SAFE_INTEGER)
        {
            Number.MAX_SAFE_INTEGER = 9007199254740991;
        }

        if (dataParams)
        {
            paramsArr = dataParams.split(',');

            for (var i = 0; i < paramsArr.length; i += 1)
            {
                paramsArr[i] = paramsArr[i].trim();

                //If params contains fractional odd param '/',convert it
                if (paramsArr[i].indexOf('/') > 0)
                {
                    var split = paramsArr[i].split('/');
                    paramsArr[i] = parseInt(split[0], 10) / parseInt(split[1], 10);
                }
                // If params contains number type as string, convert it into number
                if (paramsArr[i] !== '' && !isNaN(paramsArr[i]) && paramsArr[i] * 1 < Number.MAX_SAFE_INTEGER)
                {
                    paramsArr[i] = paramsArr[i] * 1;
                }
                // If params is undefined, convert it to value
                if (paramsArr[i] === 'undefined')
                {
                    paramsArr[i] = undefined;
                }
                // If params is null, convert it to value
                if (paramsArr[i] === 'null')
                {
                    paramsArr[i] = null;
                }
                // If params contains boolean type as string, convert it into boolean
                if (paramsArr[i] === 'false')
                {
                    paramsArr[i] = !paramsArr[i];
                }
                // If params contains boolean type as string, convert it into boolean
                if (paramsArr[i] === 'true')
                {
                    paramsArr[i] = !!paramsArr[i];
                }
            }
            /*jshint maxcomplexity:15 */
            if (paramsArr.indexOf('this') !== -1)
            {
                paramsArr[paramsArr.indexOf('this')] = currentElement;
            }

            if (paramsArr.indexOf('event') !== -1)
            {
                paramsArr[paramsArr.indexOf('event')] = event;
            }
        }

        if (parsedJSON)
        {
            paramsArr.push(parsedJSON);
            paramsArr.push(event);
        }
       
        return paramsArr;
    }
  
    function globalClickHandler(event)
    {
        var currentElement = event.target;
        if (!currentElement.getAttribute('data-action'))
        {
            currentElement = getParent(event.target, 'attr', 'data-action');
        }

        if (!currentElement)
        {
            return;
        }

        var clickHandler = currentElement.dataset ? currentElement.dataset.action : currentElement.getAttribute('data-action');
        var context = getAppropriateContext(clickHandler);

        if (context && typeof context === 'function')
        {
            var params = parseFunctionParams(event, currentElement);

            context.apply(null, params);
        }

        // Fixes SB-134161 (and maybe other problems as well)
        // We still have navigation links which have both data action and a hash - for ex. old mobile header is still used on some operators
        // although they are released with responsive, and the login link there is such a link. Hash navigation issues a popstate event with
        // event.state equal to null which causes problem when navigating back or using Application.goBack(). See also AppUrlHistory.js
        if (context === Application.navigateTo && currentElement.hash)
        {
            event.preventDefault && event.preventDefault();
        }
    }

    function getAppropriateContext(path)
    {
        var context = window;
        if (!path)
        {
            return context;
        }
        var pathParts = path.split('.');

        for (var i = 0; i < pathParts.length; i += 1)
        {
            if (context[pathParts[i]])
            {
                context = context[pathParts[i]];
            }
        }
        return context;
    }

    return {
        init: init,
        globalClickEvents: globalClickEvents
    };
}());

var ElementsToClick = new Object();

function BindElementToClickFunction(ArrayToBeClick, buttonToClick)
{
    for (var tempNum in ArrayToBeClick)
    {
        ElementsToClick[ArrayToBeClick[tempNum]] = buttonToClick;
    }
}


window.InitialEventListeners = InitialEventListeners;
window.ElementsToClick = ElementsToClick;
window.BindElementToClickFunction = BindElementToClickFunction;