﻿/* exported MobileBlocksPerf */
var MobileBlocksPerf = (function () {
    var data = [];
    var firstInit = undefined;
    var firstActivate = undefined;
    var activationOrder = 0;

    function MobileBlocksPerf() {}

    MobileBlocksPerf.prototype.init = function (block) {
        var d = new Date();
        data.push({
            id: block.BlockUniqueID,
            addedToLogStamp: d.getTime(),
            addedToLogTime: typeof d.formatDate === 'function' ? d.formatDate('hh:mm:ss') : d,
            meta: {
                beforeInit: 0,
                afterInit: 0,
                beforeActivate: 0,
                afterActivate: 0,
                beforeDeactivate: 0,
                afterDeactivate: 0
            },
            type: 'ms',
            init: 0,
            activate: 0,
            deactivate: 0,
            sincePrevInit: 0,
            sinceFirstInit: 0,
            activationOrder: null,
            sinceFirstActivate: 0,
        });
    };

    MobileBlocksPerf.prototype.log = function (timer, block) {
        if (timer === 'beforeInit' && typeof firstInit === 'undefined') {
            firstInit = performance.now();
        }
        if (timer === 'beforeActivate' && typeof firstActivate === 'undefined') {
            firstActivate = performance.now();
        }

        var el = data.filter(function (x) {
            return x.id === block.BlockUniqueID
        })[0];

        if (el) {
            var stamp = performance.now();
            el.meta[timer] = stamp;

            if (timer === 'afterInit') {
                el.sinceFirstInit = stamp - firstInit;
            }
            if (timer === 'afterActivate') {
                el.sinceFirstActivate = stamp - firstActivate;
                el.activationOrder = ++activationOrder;
            }
        }
    };

    MobileBlocksPerf.prototype.data = function () {
        return data
            .map(function (x, i) {
                x.init = x.meta.afterInit - x.meta.beforeInit;
                x.activate = x.meta.afterActivate - x.meta.beforeActivate;
                x.deactivate = x.meta.afterDeactivate - x.meta.beforeDeactivate;
                return x;
            })
    };

    MobileBlocksPerf.prototype.plotActive = function () {
        this
            .plot(
                this
                .data()
                .filter(function (x) {
                    return x.activationOrder !== null
                })
                .sort(function (a, b) {
                    return a.activationOrder - b.activationOrder
                })
            );
    };

    MobileBlocksPerf.prototype.plotInactive = function () {
        this.plot(this.data().filter(function (x) {
            return x.activationOrder === null
        }));
    };

    MobileBlocksPerf.prototype.plotSlowerThan = function (ms) {
        var time = ms || 10;
        this.plot(
            this
            .data()
            .filter(function (x) {
                return (Math.max(x.init, x.activate, x.deactivate) > time)
            }),
            time
        );
    };

    MobileBlocksPerf.prototype.plotDelayedBy = function (ms) {
        var time = ms || 10;
        this.plot(
            this
            .data()
            .filter(function (x) {
                return x.sinceFirstInit > time
            }),
            time
        );
    };

    MobileBlocksPerf.prototype.pushToES = function () {
        data.forEach(function (x) {
            var o = new XMLHttpRequest();
            o.open("POST", "http://localhost:9200/index3/type");
            o.setRequestHeader("Content-Type", "application/json");
            o.addEventListener("onreadystatechange", function (e) {
                if (this.readyState == 4 && this.status == 200) {
                    console.log("sent: ", x);
                }
            });
            o.send(JSON.stringify(x));
        })
    };

    MobileBlocksPerf.prototype.plot = function (data, ms) {
        var d = data || this.data();

        d
            .forEach(function (x) {
                var dT = ms || 10; // 10 ms
                var max = Math.max(x.init, x.activate, x.deactivate);

                console.log('\n\n----', x.id, '----');
                if (max > dT) {
                    console.log(
                        '%cSLOW BLOCK!!! Exceeding max treshold of ' + dT + 'ms',
                        'color: #a80000;font-weight:bold;'
                    );
                }
                console.log(
                    'Registered at: ', x.addedToLogTime, "(" + x.addedToLogStamp + ")",
                    '\nActivation order :', x.activationOrder,
                    '\ninit :', x.init + ' ' + x.type,
                    '\nactivate :', x.activate + ' ' + x.type,
                    '\ndeactivate :', x.deactivate + ' ' + x.type,
                    '\nsinceFirstInit :', x.sinceFirstInit + ' ' + x.type,
                    '\nsinceFirstActivate :', x.sinceFirstActivate + ' ' + x.type
                );

            });

    };

    return new MobileBlocksPerf();
}());

window.MobileBlocksPerf = MobileBlocksPerf;