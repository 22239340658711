﻿/* exported MobileTopPanel */
/* globals MobilePeripheralPanel */
function MobileTopPanel(config) {
    'use strict';
    MobilePeripheralPanel.call(this, config);
    this.Position = PanelPosition.TOP;
}

$.extend(MobileTopPanel.prototype, MobilePeripheralPanel.prototype);

MobileTopPanel.EmptyPanel = new MobileTopPanel({ id: 'panel-top-empty-panel' });
window.MobileTopPanel = MobileTopPanel;
