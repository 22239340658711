(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("mobx"), require("ReactDOM"), require("sbInternalMsgBus"), require("mobxReact"), require("sbJsExtends"));
	else if(typeof define === 'function' && define.amd)
		define("sbUiMessage", ["React", "mobx", "ReactDOM", "sbInternalMsgBus", "mobxReact", "sbJsExtends"], factory);
	else if(typeof exports === 'object')
		exports["sbUiMessage"] = factory(require("React"), require("mobx"), require("ReactDOM"), require("sbInternalMsgBus"), require("mobxReact"), require("sbJsExtends"));
	else
		root["sbUiMessage"] = factory(root["React"], root["mobx"], root["ReactDOM"], root["sbInternalMsgBus"], root["mobxReact"], root["sbJsExtends"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__5__, __WEBPACK_EXTERNAL_MODULE__22__, __WEBPACK_EXTERNAL_MODULE__24__) {
return 