function UserInfo(result) {
    this.EventsManager = new EventsManager();
    if (result[0] === undefined) {
        this.updateSeamlessData(result);
    }
    else {
        this.updateData(result);
    }
}

UserInfo.current = null;
UserInfo.onJWTUpdated = {};
UserInfo.onLogin = {};
UserInfo.onLogout = {};
UserInfo.onLogoutReceived = {};
UserInfo.onNotLoggedIn = {};
UserInfo.onLoginFailed = {};
UserInfo.onLoginSucceed = {}
UserInfo.onDataUpdated = {};
UserInfo.onUpdateBalance = {};
UserInfo.onGetBalance = {};
UserInfo.onLimitationsDataUpdated = {};
UserInfo.onCaptcha = {};
UserInfo.onTokenLogin = {};
UserInfo.onTokenLoginReceived = {};
UserInfo.onOneTimeTokenReceived = {};
UserInfo.onOneTimeTokenLoginFailed = {};
UserInfo.onSessionKeepAlive = {};
UserInfo.onJwtLoginReceived = {};
UserInfo.onSecondStepLoginFailed = {};
UserInfo.onTwoStepLogin = {};
UserInfo.onTwoStepLoginContinue = {};
UserInfo.OnTwoStepNativeLogin = {};
UserInfo.onCommand = {};
UserInfo.onMessage = {};
UserInfo.onAfterLogout = {};

UserInfo.TaxProvider = EmptyTaxProvider;
UserInfo.onAcceptOddsChanged = {};

//dummy declaration
UserInfo.prototype.updateSeamlessData = function(data) {}
UserInfo.prototype.updateData = function(data) {}

window.UserInfo = UserInfo;
