﻿if (UseUrlNav) {
    var ApplicationHistory = ((function (history) {
        'use strict';

		var scrollIOS = function()
		{
			if (UI.detects.mobileDetect.version('iOS')) {
                setTimeout(function () {
                    window.scrollBy(0, -20);
                    setTimeout(function () { window.scrollBy(0, -60); }, 50);
                }, 80);
            }
		};
		
        var goBack = function (forceReload) {
            history.back();
            scrollIOS();
        };

        var addRemovePanelFromPath = function (fullPath, panelUrl, hidePanelIdUrl) {
            let panelPath = panelUrl.toLowerCase() + '/';
            let panelPathFormatted = '/' + panelPath;
            let fullPathLowerCase = fullPath.toLowerCase();
            let isPathContainsPanel = fullPathLowerCase.indexOf(panelPathFormatted) >= 0;
            let needToRemovePanelFromPath = isPathContainsPanel && hidePanelIdUrl;
            let needToAddPanelToPath = !hidePanelIdUrl && !isPathContainsPanel;
            if (needToRemovePanelFromPath) {
                fullPath = fullPathLowerCase.replace(panelPathFormatted, '/');
                if (Application.Navigation.viewState.PanelParams) {
                    let panelParams = Application.Navigation.viewState.PanelParams.split('#');
                    for (let i = 0; i < panelParams.length; i += 1) {
                        let paramFormatted = '/' + panelParams[i].toLowerCase() + '/';
                        fullPath = fullPath.replace(paramFormatted, '/');
                    }
                }
            }
            else if (needToAddPanelToPath) {
                fullPath += panelPath;
            }

            return fullPath;
        };

        var adjustPath = function (path, panelUrl, config) {
            var searchAndHash;
            if (!path || path.startsWith('javascript:')) {
                path = window.location.origin + window.location.pathname;
                searchAndHash = window.location.search + window.location.hash;
            }
            else {
                var hashArray = path.split('#');
                path = hashArray[0];
                searchAndHash = hashArray[1];
                if (searchAndHash) {
                    searchAndHash = '#' + searchAndHash;
                }
            }

            var fullPath = path.indexOf('?') >= 0 || path.endsWith('/') ? path : path + '/';
            if (panelUrl !== config.LiveBettingNavigationCode) {
                fullPath = addRemovePanelFromPath(fullPath, panelUrl, config.HidePanelIdInUrl);
            }

            if (searchAndHash) {
                fullPath += searchAndHash;
            }

            return fullPath;
        };

        var shouldPreserveTitleOnDeepLink = function (goingToPath){
            return goingToPath === "" || goingToPath === window.location.href;
        };

        var handleSEOTitleAndDescription = function (panel, pageNavigationConfig, goingToPath) {
            if (Application.Navigation.isSportInitializing() || shouldPreserveTitleOnDeepLink(goingToPath)) {
                return;
            };

            var title = panel.SEOTitle || pageNavigationConfig.PageSEOTitle;
            var metaDescription = panel.SEOMetaDescription || pageNavigationConfig.PageSEOMetaDescription;

            document.title = title;
            var metaDescriptionElement = document.querySelector('meta[name=description]');
            if (metaDescriptionElement) {
                metaDescriptionElement.setAttribute('content', metaDescription);
            }
        };

        Application.AfterNavigation['PushToUrlHistory'] = function (e) {
            if (e.isBackCall) {
                handleSEOTitleAndDescription(e.panel, e.pageNavigationConfig, e.path);
                return;
            }

            var state = { panel: e.panel.ID, params: e.params };
            var panelCode = e.path && !stringIncludes(e.path, e.panel.ID) && stringIncludes(e.path, e.panel.defaultCode) ? e.panel.defaultCode : e.panel.ID;
            var path = adjustPath(e.path, panelCode, e.pageNavigationConfig);
            path = ensureLiveBettingEvent(e, path);

            handleSEOTitleAndDescription(e.panel, e.pageNavigationConfig, e.path);
            push(path, state);
        };

        function ensureLiveBettingEvent(e, path)
        {
            if (e.panel.ID == e.pageNavigationConfig.LiveBettingNavigationCode &&
                Array.isArray(e.params) && e.params.length > 0 && e.params[0] &&
                path.indexOf('#' + e.params[0]) < 0)
            {
                return path + '#' + e.params[0];
            }
            return path;
        }

        function stringIncludes(str, search) {
            return str.toLowerCase().indexOf(search.toLowerCase()) !== -1;
        }

        function push(path, state) {
            var setState = history.state ? history.pushState : history.replaceState;
            setState.call(history, state ? state : {}, document.title, path);
        }

        window.onpopstate = function (event) {
            var previous = event.state;

            if (previous) {
                Application.navigateTo(previous.panel, true, previous.params);
				scrollIOS();
            }
        };

        return {
            goBack: goBack,
            push: push,
            adjustPath: adjustPath
        };

    })(window.history));

    window.ApplicationHistory = ApplicationHistory;

} else {
    var ApplicationHistory = ((function () {
        'use strict';

        var history = [];
        var goingBackwards = false;

        var top = function () {
            if (!history || history.length < 1) {
                return null;
            }

            return history[history.length - 1];
        };

        var goBack = function (forceReload) {
            go(-1, forceReload);
        };

        /* jshint maxcomplexity: 10*/
        var go = function (count, forceReload) {
            if (count === 0) {
                return;
            }

            if (count < 0) {
                goingBackwards = true;
                var numberOfStepsBack = 0;

                while (window.location.href.replace(window.location.search, '') === history[history.length - 1]) {
                    history.pop();
                    numberOfStepsBack += numberOfStepsBack + 1;
                }

                if (window.location.href && window.location.href.indexOf('comeon') > -1 && (forceReload || (window.self !== window.top))) {
                    var historyUrl = history[history.length - 1] ? history[history.length - 1] : '/';
                    window.location.replace(historyUrl);
                    return false;
                }

                if (forceReload) {
                    var url = history[history.length - 1] ? history[history.length - 1] : '/';
                    window.location.replace(url);
                }
                else {
                    numberOfStepsBack = numberOfStepsBack === 0 ? 1 : numberOfStepsBack;
                    window.history.go(-numberOfStepsBack);
                }
            }

        };

        /* jshint maxcomplexity: 5 */

        //Adds item at the end of the stack
        var push = function (path) {
            if (goingBackwards) {
                goingBackwards = false;
                return;
            }

            var item = top();

            if (path && (!item || (path != item))) {
                history.push(path);
                return history.length - 1;
            }
            else {
                return -1;
            }
        };

        function pop() {
            return history && history.length > 0 ? history.pop() : null;
        }

        Application.AfterNavigation['PushToHashHistory'] = function () {
            push(window.location.href.replace(window.location.search, ''));
        };

        if (StorageUtils.getFromStorage('AppHistory')) {
            history = JSON.parse(StorageUtils.getFromStorage('AppHistory'));
            if (!Array.isArray(history)) {
                history = [];
            }

            StorageUtils.removeFromStorage('AppHistory');
        }

        if (StorageUtils.getFromStorage('goingBackwards')) {
            goingBackwards = (StorageUtils.getFromStorage('goingBackwards') == '1') || (StorageUtils.getFromStorage('goingBackwards') == 'true');
        }

        window.addEventListener('pagehide',
            function () {
                StorageUtils.saveToStorage('AppHistory', JSON.stringify(history), sessionStorage);
                StorageUtils.saveToStorage('goingBackwards', goingBackwards, sessionStorage);
            },
            false);

        return {
            go: go,
            goBack: goBack,
            pop: pop,
            push: push
        };
    })());

    window.ApplicationHistory = ApplicationHistory;
}
