﻿/* exported MobileCenterPanel */
/* globals Application, MobilePanel, MobileLeftPanel, MobileRightPanel, MobileTopPanel, MobileBottomPanel, PanelPosition */
function MobileCenterPanel(config)
{
    'use strict';
    MobilePanel.call(this, config);

    this.Position = PanelPosition.CENTER;
    this.BreadcrumbTitle = config.BreadcrumbTitle;
    this.SEOTitle = config.SEOTitle;
    this.SEOMetaDescription = config.SEOMetaDescription;
    
    this.LeftPanelID = config.leftPanelID;
    this.RightPanelID = config.rightPanelID;
    this.TopPanelID = config.topPanelID;
    this.BottomPanelID = config.bottomPanelID;

    // To be initialized in init method
    this.LeftPanel = null;
    this.RightPanel = null;
    this.TopPanel = null;
    this.BottomPanel = null;
}

$.extend(MobileCenterPanel.prototype, MobilePanel.prototype,
{
    init : function () 
    {
        MobilePanel.prototype.init.call(this);

        this.initPanels();
    },
    initPanels: function() 
    {
        this.LeftPanel = this.LeftPanelID ? Application.Panels[this.LeftPanelID] : MobileLeftPanel.EmptyPanel;
        this.RightPanel = this.RightPanelID ? Application.Panels[this.RightPanelID] : MobileRightPanel.EmptyPanel;
        this.TopPanel = this.TopPanelID ? Application.Panels[this.TopPanelID] : MobileTopPanel.EmptyPanel;
        this.BottomPanel = this.BottomPanelID ? Application.Panels[this.BottomPanelID] : MobileBottomPanel.EmptyPanel;
    },

    switchToPanel: function (toPanel, transitionSettings)
    {
        'use strict';
        if (this.ID == toPanel.ID) { return; }

        // Do not leave if we are opening popup. Popups do not have left/right panels
        toPanel.Position != PanelPosition.POPUP && this.leave(transitionSettings);
        toPanel.enter(transitionSettings, this);

        if (toPanel.Position === PanelPosition.POPUP) 
        {
            return;
        }

        if (!this.LeftPanel || !this.RightPanel || !this.TopPanel || !this.BottomPanel) 
        {
            this.initPanels();
        }

        this.LeftPanel.switchToPanel(toPanel.LeftPanel, transitionSettings);
        this.RightPanel.switchToPanel(toPanel.RightPanel, transitionSettings);
        this.TopPanel.switchToPanel(toPanel.TopPanel, transitionSettings);
        this.BottomPanel.switchToPanel(toPanel.BottomPanel, transitionSettings);
    },

    hideSidePanels: function ()
    {
        this.LeftPanel && this.LeftPanel.leave();
        this.RightPanel && this.RightPanel.leave();
    }
});

MobileCenterPanel.EmptyPanel = new MobileCenterPanel({id: 'panel-center-empty-panel'});
window.MobileCenterPanel = MobileCenterPanel;