function isFastMarket(splitType)
{
    if (splitType) {
        var arr = splitType.toString();
        // soccer fm 
        return arr.length == 4 & arr[0] > 0;
    }
    return false;
}

function isTennisFastMarket(splitType) {
    if (splitType) {
        var arr = splitType.toString();
        // tennis fm
        return arr.length == 6 & arr[0] > 0;
    }
    return false;
}

window.isFastMarket = isFastMarket;
window.isTennisFastMarket = isTennisFastMarket;