Object.defineProperties(

    Array.prototype,
    {
        "sum":
        {
            enumerable: false,
            value: function (flt)
            {
                var res = 0, i;

                if (flt instanceof Function)
                {
                    for (i in this) res += flt(this[i]);
                    return res;
                } else
                {
                    for (i in this) res += this[i][flt];
                    return res;
                }
            }
        },

        "select":
        {
            enumerable: false,
            value: function (flt)
            {
                var res = [];
                for (var i in this) res.push(flt(this[i]));
                return res;
            }
        },

        "where":
        {
            enumerable: false,
            value: function (flt)
            {
                var res = [];

                for (var i in this)
                    if (flt(this[i]))
                        res.push(this[i]);

                return res;
            }
        },


        "firstOrDefault":
        {
            enumerable: false,
            value: function (flt)
            {
                for (var i in this)
                    if (flt(this[i]))
                        return this[i];

                return null;
            }
        },

        "any":
        {
            enumerable: false,
            value: function (flt)
            {
                for (var i in this)
                    if (flt(this[i]))
                        return true;

                return false;
            }
        },

        "all":
        {
            enumerable: false,
            value: function (flt)
            {
                for (var i in this)
                    if (!flt(this[i]))
                        return false;

                return true;
            }
        },

        "removeAll":
        {
            enumerable: false,
            value: function (flt)
            {
                for (var i in this)
                    if (flt(this[i]))
                        delete this[i];
            }
        },

        "remove":
        {
            enumerable: false,
            writable: true,
            value: function (flt)
            {
                var i;

                if (flt instanceof Function)
                {
                    for (i in this)
                        if (flt(this[i]))
                            delete this[i];
                } else
                {
                    for (i in this)
                        if (this[i] == flt)
                            delete this[i];
                }

                
            }
        },

        "empty":
        {
            enumerable: false,
            get: function ()
            {
                return !this.length && Object.keys(this).length == 0;
            }
        },

        "contains":
        {
            enumerable: false,
            value: function (flt)
            {
                var i;

                if (flt instanceof Function)
                {
                    for (i in this)
                        if (flt(this[i]))
                            return true;
                } else
                {
                    for (i in this)
                        if (this[i] == flt)
                            return true;
                }

                return false;
            }
        },
        
        "each":
        {
            enumerable: false,
            value: function (flt)
            {
                for (var i in this) flt(this[i]);
            }
        },

        "eachInReverse":
        {
            enumerable: false,
            value: function (flt)
            {
                for (var i = this.length - 1; i >= 0; i--) flt(this[i]);
            }
        },

        "equals":
        {
            enumerable: false,
            value: function (arr, comparer)
            {
                if (this.length !== arr.length)
                    return false;
                for (var i in this)
                    if (!comparer(this[i], arr[i]))
                        return false;
                return true;
            }
        },

        "average":
        {
            enumerable: false,
            value: function ()
            {
                return this.sum(function(value){return value}) / this.length;
            }
        },

        "sortBy":
        {
            enumerable: false,
            value: function (prop)
            {
                return this.sort(function (a, b)
                {
                    if (a[prop] > b[prop])
                    {
                        return 1;
                    }
                    else if (a[prop] < b[prop])
                    {
                        return -1;
                    }
                    else
                    {
                        return 0;
                    }
                });
            }
        },

        "groupBy":
        {
            enumerable: false,
            value: function (prop, defaultVal)
            {
                var res = [], key;
                for (var i in this) 
                {
                    key = this[i][prop] || defaultVal;
                    if (!res[key]) res[key] = [];
                    res[key].push(this[i])
                }
                return res;
            }
        },

        "distinct":
        {
            enumerable: false,
            value: function ()
            {
                // works in O(n^2)
                return this.filter(function (value, index, self) {
                    return self.indexOf(value) === index;
                });
            }
        }
    }
);