﻿function SPMyBetsSelection(initData, purchase)
{
    SPOpenBetsSelection.call(this, initData, purchase);

    this.LiveEventsTimeHelper = null;

}

$.extend(SPMyBetsSelection.prototype, SPOpenBetsSelection.prototype,
{
    getIsCurrentlyLive: function ()
    {
        return !(this.LiveEventsTimeHelper == null);
    },

    getLiveClockUIElementId: function (betID)
    {
        return "mybets-live-time-" + betID + "_" + this.SelectionID;
    },

    getLiveScoreUIElementId: function (betID)
    {
        return "mybets-live-score-" + betID + "_" + this.SelectionID;
    },

    getTimeText: function ()
    {
        if (!this.getIsCurrentlyLive())
            return "";

        return this.LiveEventsTimeHelper.getShortTime();
    },

    getLiveScoreText: function ()
    {
        if (!this.getIsCurrentlyLive())
            return "";

        var score1Pos = !this.IsTeamSwapEnabled ? this.LiveScore1 : this.LiveScore2;
        var score2Pos = !this.IsTeamSwapEnabled ? this.LiveScore2 : this.LiveScore1;

        return "{0}:{1}".format(score1Pos, score2Pos);
    },

    updateLiveGameData: function (data, masterEventDate, score1, score2, masterEventAdditionalData)
    {
        var isLive = this.getIsCurrentlyLive();

        if (data == null)
        {
            this.LiveEventsTimeHelper = null;
            return isLive;
        }

        var gameData = {
            LastTimeUpdate: Date.fromISO(data[2]),
            GameTime: data[1],
            Status: data[0],
            RedCards: data[4],
            IsET: data[3]
            //TennisMatchType: data[5],
            //LastSetIsAdvantageSet: data[6]
        };

        this.LiveEventsTimeHelper = new LiveEventsHelper(gameData, masterEventDate, this.BranchID, masterEventAdditionalData);

        if (this.BranchID == 6)
        {
            var score = new TennisScore(score1.toString(), score2.toString());
            this.LiveScore1 = score.ScoreSetsPlayer1;
            this.LiveScore2 = score.ScoreSetsPlayer2;
        }
        else
        {
            this.LiveScore1 = score1;
            this.LiveScore2 = score2;
        }

        return !isLive
    },

    updateLiveTimeAndScoreUI: function (betID)
    {
        var timeEl = document.getElementById(this.getLiveClockUIElementId(betID));
        if (timeEl)
        {
            timeEl.textContent = this.getTimeText();
        }

        var scoreEl = document.getElementById(this.getLiveScoreUIElementId(betID));
        if (scoreEl)
        {
            scoreEl.textContent = this.getLiveScoreText();
        }
    },

    getEventNameFormatted: function ()
    {
        if (this.isAggregatedEvent())
            return this.getAggregatedEventName();
        else if (this.IsRegular)
            return this.getRegularEventNameFormatted();
        else
            return this.getQAEventNameFormatted();

    },

    getRegularEventNameFormatted: function ()
    {
        var formatStr = "<span class=\"cut\">{0}</span> <strong>{1}</strong> <span class=\"cut\">{2}</span>";

        if (this.IsTeamSwapEnabled)
        {
            return formatStr.format(this.Team2Name, $dict.bs("swapAt"), this.Team1Name);
        }

        return formatStr.format(this.Team1Name, $string("General").VS, this.Team2Name);
    },

    getQAEventNameFormatted: function ()
    {
        var formatStr = "<span class=\"full\">{0}</span>";

        if (this.Team1Name && this.Team2Name)
            return this.getRegularEventNameFormatted();

        if ((this.BranchID == constHorseRacingBranchID || this.BranchID == constGreyHoundRacingBranchID) && (this.LineTypeID != 18 && this.LineTypeID != 19))
        {
            var date = this.EventDate;
            date = (this.BranchID == constHorseRacingBranchID && UseAustralianHorseRacingView) ? date : Data.getDateForHR(date);
            return formatStr.format(this.LeagueName + ": " + date.toStringEx("HH:mm"));
        }

        if (this.EventName)
            return formatStr.format(this.EventName);

        return formatStr.format(this.EventTypeName);
    },

    getSameEventComboKey: function() 
    {
        var arr = this.ComboGroupKey.split('_');
        if (arr.length < 3)
        {
            return -1;
        }
        return 1 * arr[2];
    },

    getComboGroupKey: function ()
    {
        return this.ComboGroupKey;
    }
});

function SPMyBetsBet(initData, purchase)
{
    SPOpenBetsBet.call(this, initData, purchase);

    this.IsHiddenFromMyBets = false;
    this.Error = "";
    this.RowNumber = null;

    this.PurchaseID = purchase.PurchaseID;
}

$.extend(SPMyBetsBet.prototype, SPOpenBetsBet.prototype,
{
    getFormattedCurrencyStr: function (amount, compositeFormatString)
    {
        if (compositeFormatString && compositeFormatString !== "")
        {
            return compositeFormatString.format(MoneyFormat.format(amount), this.Purchase.CurrencyCode);
        }
        return this.Purchase.CurrencyCode + MoneyFormat.format(amount);
    },

    getCashOutOnClickMethod: function ()
    {
        return "BetSlip.cashOutBet('{0}', '{1}')".format(this.Purchase.PurchaseID, this.BetID);
    }
});

function SPMyBetsPurchase(initData)
{
    SPOpenBetsPurchase.call(this, initData);

    this.IsOpenBet = true;
}

$.extend(SPMyBetsPurchase.prototype, SPOpenBetsPurchase.prototype,
{
    createSelections: function (selections)
    {
        this.Selections = [];

        for (var sIdx in selections)
        {
            var selection = selections[sIdx];
            this.Selections[selection.SelectionID] = new SPMyBetsSelection(selection, this);
        }
    },

    createBets: function (bets)
    {
        this.Bets = [];

        for (var bIdx in bets)
        {
            var bet = bets[bIdx];
            this.Bets[bet.BetID] = new SPMyBetsBet(bet, this);
        }
    },

    getMultiLineBetName: function (bet)
    {
        if (bet.isComboBet())
        {
            var comboBetName = bet.EWBetRef ? bet.EWBetRef.getTicketHeader() : bet.getTicketHeader();

            if (bet.BetTypeID == BetTypes.System ||
                bet.BetTypeID == BetTypes.VirtualSystem)
            {
                return "{0} - {1}".format($dict.bs("System"), comboBetName);
            }
            if (bet.BetTypeID == BetTypes.Teaser)
            {
                return "{0} - {1}".format($dict.bs("Teaser"), comboBetName);
            }
            if (bet.BetTypeID == BetTypes.YourBet)
            {
                return $dict.bs("YourBetTypeName");
            }

            return comboBetName;
        }

        if (bet.IsMultiLine)
        {
            return bet.getEventTypeName();
        }

        return "";
    },

    getSelectionBetType: function (bet, sel)
    {
        var bettype = "";
        if (sel.BranchID == constHorseRacingBranchID)
        {

            bettype = sel.EventTypeName;
            if (sel.EventTypeID === RacingEventTypes.RaceCard)
            {
                bettype = "";

                if (sel.LineTypeID == 19 || sel.LineTypeID == 18)
                {
                    bettype = $string("General").Antepost + " ";
                }

                bettype += $string("BettingHistory").Win;
            }

            if (sel.EventTypeID == RacingEventTypes.BettingWithoutFav || sel.EventTypeID == RacingEventTypes.BettingWithoutTwoFav ||
                sel.EventTypeID == RacingEventTypes.PlaceOnly || sel.EventTypeID == RacingEventTypes.MatchBetting)
            {
                bettype = "{0} - {1}".format(sel.EventTypeName, sel.LineTypeName);
            }
            if (sel.EventTypeID === RacingEventTypes.AUHRTote)
            {
                bettype = "Win";
            }
            if (sel.EventTypeID === RacingEventTypes.AUHRTotePlaceOnly)
            {
                bettype = "Place";
            }
        }
        else
        {
            bettype = sel.getEventTypeName(bet.BetTypeID);
            if (sel.IsLive && sel.EventTypeID != 8) // if live and not outright
            {
                bettype = "{0} [{1}]".format(bettype, sel.getScore());
            }
        }

        if (bet.EWBetRef)
        {
            var ewSel = bet.EWBetRef.Selections.find(function (s) { return s.RelatedToID == sel.LineID && s.EventID == sel.EventID && s.IsEachWaySelection; });
            if (ewSel)
            {
                return "{0} ({1})".format(bettype, Bets.getEachWayString(ewSel.PlaceTermsID, ewSel.OddsTermsID, false));
            }
        }

        return bettype;
    },

    updateLiveGameData: function (masterEventId, data, masterEventDate, score1, score2, masterEventAdditionalData)
    {
        var hasChanges = false;

        for (var i in this.Selections)
        {
            var sel = this.Selections[i];
            if (data == null)
            {
                var changed = sel.updateLiveGameData(null);
                hasChanges = hasChanges || changed;
            }
            else if (sel.MasterEventID == masterEventId)
            {
                var changed = sel.updateLiveGameData(data, masterEventDate, score1, score2, masterEventAdditionalData);
                hasChanges = hasChanges || changed;
            }
        }

        return hasChanges;
    },

    updateBetsStatuses: function (data)
    {
        var hasUpdates = false;

        for (var i in data)
        {
            var betData = data[i];
            var bet = this.Bets[betData.BetID];

            // if cash out request was accepted,
            // don't update its open status just yet,
            // because this way it will be immediately hidden from MyBets,
            // and we want to wait 10 sec before hiding it.
            if (bet &&
                bet.StatusID != betData.StatusID &&
                bet.CashOutStatus != SPPurchaseStatus.Accepted)
            {
                bet.StatusID = betData.StatusID;
                hasUpdates = true;
            }
        }

        return hasUpdates;
    },

    updateLiveTimeAndScoreUI: function ()
    {
        for (var bid in this.Bets)
        {
            var bet = this.Bets[bid];
            for (var sid in bet.Selections)
            {
                var selection = bet.Selections[sid];
                //if (!selection.IsLive) continue;

                selection.updateLiveTimeAndScoreUI(bid);
            }
        }
    },

    // marks bet as visible or not visible in my bets
    changeBetVisibility: function (betId, isVisible)
    {
        var bet = this.Bets[betId];
        if (bet)
        {
            bet.IsHiddenFromMyBets = !isVisible;
        }
    },

    canRemovePurchase: function ()
    {
        for (var bid in this.Bets)
        {
            if (this.Bets[bid].CashOutStatus == SPPurchaseStatus.Accepted)
            {
                return false;
            }
        }

        return true;
    },

    getVisibleBetsCount: function ()
    {
        var count = 0;
        for (var bid in this.Bets)
        {
            var bet = this.Bets[bid];
            if (this.shouldBuildUIForBet(bet))
            {
                count++;
            }
        }

        return count;
    },

    hasLiveSelections: function ()
    {
        for (var i in this.Selections)
        {
            if (this.Selections[i].getIsCurrentlyLive())
            {
                return true;
            }
        }
    },

    buildCashOutDanger: function () { console.warn("SPMyBetsPurchase.buildCashOutDanger not implemented"); }

});

SPMyBetsPurchase.bankMyStakePanel =
{
    init: function (_cashoutSlider)
    {
        _cashoutSlider.bankMyStakeBtn = _cashoutSlider.sliderContainer.getElementsByClassName('bankMyStakeBtn')[0];
        _cashoutSlider.bankMyStakeArrow = _cashoutSlider.sliderContainer.getElementsByClassName('bankMyStakeArrow')[0];
        _cashoutSlider.bankMyStakeMarker = _cashoutSlider.sliderContainer.getElementsByClassName('bankMyStakeMarker')[0];
    },
    show: function (_cashoutSlider) {
        if (typeof _cashoutSlider.bankMyStakeBtn === 'object')
        {
            _cashoutSlider.bankMyStakeBtn.classList.remove('isHidden');
            _cashoutSlider.bankMyStakeArrow.classList.remove('isHidden');
            _cashoutSlider.bankMyStakeMarker.classList.remove('isHidden');
        }
    },
    hide: function (_cashoutSlider)
    {
        if (typeof _cashoutSlider.bankMyStakeBtn === 'object')
        {
            _cashoutSlider.bankMyStakeBtn.classList.add('isHidden');
            _cashoutSlider.bankMyStakeArrow.classList.add('isHidden');
            _cashoutSlider.bankMyStakeMarker.classList.add('isHidden');
        }
    },
    refresh: function (_cashoutSlider, _spMyBetsBet, _betId, _purchaseId)
    {
        //show
        if (_spMyBetsBet.CashOutData.Amount > _spMyBetsBet.Deposit + _spMyBetsBet.CashOutData.MinBet)
        {
            _cashoutSlider.bankMyStakeBtn.setAttribute('onclick', 'SPMyBetsPurchase.bankMyStake("' + _betId + '", "' + _purchaseId + '")');
            _cashoutSlider.bankMyStakeBtn.innerHTML = $dict.bs('BankMyStake') + '&nbsp;' + _spMyBetsBet.getFormattedCurrencyStr(_spMyBetsBet.Deposit);
            var perc = ((_spMyBetsBet.Deposit - _spMyBetsBet.CashOutData.MinimalPartialCashoutAmount) / (_spMyBetsBet.CashOutData.Amount - _spMyBetsBet.CashOutData.MinimalPartialCashoutAmount)) * 100;
            perc = _cashoutSlider.round(perc);
            _cashoutSlider.bankMyStakeArrow.style.left = perc + '%';
            _cashoutSlider.bankMyStakeMarker.style.left = perc + '%';
            _cashoutSlider.bankMyStakeIsEnabled = true;
            this.show(_cashoutSlider);
        }
        //hide (default)
        else
        {
            _cashoutSlider.bankMyStakeIsEnabled = false;
            this.hide(_cashoutSlider);
        }
    }
};

SPMyBetsPurchase.bankMyStake = function (betId, purchaseId)
{
    var spMyBetsPurchase = SPMyBetsCache.getInstance().getPurchaseById(purchaseId)
    if (!spMyBetsPurchase) return;

    var spMyBetsBet = spMyBetsPurchase.Bets[betId];
    if (!spMyBetsBet) return;

    if (spMyBetsBet.isPartialCashoutAllowed() && spMyBetsBet.CashOutStatus != SPPurchaseStatus.NewOffer)
    {
        if (spMyBetsBet.CashoutSlider && spMyBetsBet.CashOutData.Amount >= spMyBetsBet.Deposit + spMyBetsBet.CashOutData.MinBet)
        {
            spMyBetsBet.CashoutSlider.setValue(spMyBetsBet.Deposit);
            spMyBetsBet.LastPartialCashoutAmount = spMyBetsBet.Deposit;
            spMyBetsBet.updateCashOutData(spMyBetsBet.CashOutData);
            if (spMyBetsBet.CashoutSlider.bankMyStakeBtn)
            {
                spMyBetsBet.CashoutSlider.bankMyStakeBtn.classList.add('disabled');
            }

            if (BetSlip.EnableCashOutConfirmation)
            {
                setTimeout(function () { SPMyBetsCache.getInstance().manageCashout(spMyBetsBet.BetID) }, 50);
            }
        }
    }
}

function LiveEventsHelper(gamedata, masterEventDate, branchId, additionalData)
{
    this.GameData = gamedata;
    this.BranchID = branchId;
    this.MasterEventDate = masterEventDate;
    this.AdditionalData = additionalData;
    this.GameStatus = gameStatus.getGameStatus();
}

LiveEventsHelper.prototype.isStarted = function ()
{
    return LiveMasterEvent.prototype.isStarted.apply(this, arguments);
},

LiveEventsHelper.prototype.isEnabledForLive = function ()
{
    return LiveMasterEvent.prototype.isEnabledForLive.apply(this, arguments);
},

LiveEventsHelper.prototype.getCurrentGameTimeMinAndSec = function ()
{
    return LiveMasterEvent.prototype.getCurrentGameTimeMinAndSec.apply(this, arguments);
}

LiveEventsHelper.prototype.processCurrentGameTime = function ()
{
    return LiveMasterEvent.prototype.processCurrentGameTime.apply(this, arguments);
}

LiveEventsHelper.prototype.getTimeText = function ()
{
    return LiveMasterEvent.prototype.getTimeText.call(this);
}

LiveEventsHelper.prototype.getShortTime = function ()
{
    return LiveMasterEvent.prototype.getShortTime.call(this);
}

window.SPMyBetsSelection = SPMyBetsSelection;
window.SPMyBetsBet = SPMyBetsBet;
window.SPMyBetsPurchase = SPMyBetsPurchase;
window.LiveEventsHelper = LiveEventsHelper;


includeExtension('/JSComponents/Data/UniSlip/SPMyBetsPurchase.ext.js');