//what it does:
// GameStatus calculates in which half/quater/set of the game is currently playing
//sportsIds were got from [10betdb].[dbo].[tblBranches]
//[10betdb].[dbo].[tblLiveGameStatuses] get the statusId

//how to do it:
// gameStatus.getGameStatus().getStatusController(BranchID, GameData) is the controller function that divide the game by sportId==branchId
var gameStatus = (function ()
{
    function GameStatus()
    {
        this.jsonStatusCodes = {
            'firstSet': 11,
            'secondSet': 12,
            'thirdSet': 13,
            'fourthSet': 14,
            'fifthSet': 15,
            'firstHalf': 1,
            'halfTime': 3,
            'secondHalf': 2,
            'firstQuarter': 11,
            'secondQuarter': 12,
            'thirdQuarter': 13,
            'fourthQuarter': 14,
            'breakTime': 31,
            'firstBreak': 31,
            'secondBreak': 32,
            'thirthBreak': 33,
            'firstInn': 11,
            'secondInn': 12,
            'thirdInn': 13,
            'fourthInn': 14,
            'fifthInn': 16,
            'sixthInn': 17,
            'seventhInn': 18,
            'eighthInn': 19,
            'ninethInn': 20,
            'firstHalf': 1,
            'firstPer': 11,
            'secondPer': 12,
            'thirdPer': 13,
            'firstFra': 11,
            'secondFra': 12,
            'thirdFra': 13,
            'fourthFra': 14,
            'fifthFra': 15,
            'sixthFra': 16,
            'seventhFra': 17,
            'eightFra': 18,
            'ninethFra': 19,
            'tenthFra': 20,
            'eleventhFra': 21,
            'overtime': 60,
            'sets':
                 {
                     'first': $string("LiveBettingGameStatus").S1,
                     'second': $string("LiveBettingGameStatus").S2,
                     'third': $string("LiveBettingGameStatus").S3,
                     'fourth': $string("LiveBettingGameStatus").S4,
                     'fifth': $string("LiveBettingGameStatus").S5
                 },
            'frames':
                {
                    'first': $string("LiveBettingGameStatus").F1,
                    'second': $string("LiveBettingGameStatus").F2,
                    'third': $string("LiveBettingGameStatus").F3,
                    'fourth': $string("LiveBettingGameStatus").F4,
                    'fifth': $string("LiveBettingGameStatus").F5,
                    'sixth': $string("LiveBettingGameStatus").F6,
                    'seventh': $string("LiveBettingGameStatus").F7,
                    'eighth': $string("LiveBettingGameStatus").F8,
                    'nineth': $string("LiveBettingGameStatus").F9,
                    'tenth': $string("LiveBettingGameStatus").F10,
                    'eleventh': $string("LiveBettingGameStatus").F11
                },
            'pers':
                {
                    'first': $string("LiveBettingGameStatus").P1,
                    'second': $string("LiveBettingGameStatus").P2,
                    'third': $string("LiveBettingGameStatus").P3
                },
            'Inns':
                {
                    'first': $string("LiveBettingGameStatus").I1,
                    'second': $string("LiveBettingGameStatus").I2,
                    'third': $string("LiveBettingGameStatus").I3,
                    'fourth': $string("LiveBettingGameStatus").I4,
                    'fifth': $string("LiveBettingGameStatus").I5,
                    'sixth': $string("LiveBettingGameStatus").I6,
                    'seven': $string("LiveBettingGameStatus").I7,
                    'eight': $string("LiveBettingGameStatus").I8,
                    'nine': $string("LiveBettingGameStatus").I9
                },
            'halves':
                  {
                      'first': $string("LiveBettingGameStatus").Half1,
                      'second': $string("LiveBettingGameStatus").Half2,
                      'halfTime': $string("LiveBettingGameStatus").HT,

                  },
            'quarters':
                   {
                       'first': $string("LiveBettingGameStatus").Q1,
                       'second': $string("LiveBettingGameStatus").Q2,
                       'third': $string("LiveBettingGameStatus").Q3,
                       'fourth': $string("LiveBettingGameStatus").Q4,
                       'halfTime': $string("LiveBettingGameStatus").HT,
                       'overtime': $string("GameStatus").Overtime
                   },
            'parts':
                {
                    'first': $string("LiveScoreboard").First,
                    'second': $string("LiveScoreboard").Second,
                    'third': $string("LiveScoreboard").Third,
                    'fourth': $string("LiveScoreboard").Fourth,
                    'fifth': $string("LiveScoreboard").Fifth,
                    'half': $string("LiveScoreboard").Half,
                    'points': $string("LiveScoreboard").Points,
                    'sets': $string("LiveScoreboard").Sets,
                    'total': $string("LiveScoreboard").Total,
                    'overtime': $string("LiveScoreboard").OverTime
                }
        }

        this.jsonShortStatusCodes = {
            'pers':
                {
                    'first': $string("GameStatusShort").P1,
                    'second': $string("GameStatusShort").P2,
                    'third': $string("GameStatusShort").P3
                },

            'quarters':
                {
                    'first': $string("GameStatusShort").Q1,
                    'second': $string("GameStatusShort").Q2,
                    'third': $string("GameStatusShort").Q3,
                    'fourth': $string("GameStatusShort").Q4,
                    'firstQ': $string("GameStatusShort").FirstQ,
                    'secondQ': $string("GameStatusShort").SecondQ,
                    'thirdQ': $string("GameStatusShort").ThirdQ,
                    'fourthQ': $string("GameStatusShort").FourthQ,
                },

            'breaks':
                {
                    'first': $string("GameStatusShort")[31],
                    'second': $string("GameStatusShort")[32],
                    'third': $string("GameStatusShort")[33]
                },

            'halves':
                {
                    'first': $string("GameStatusShort")[1],
                    'second': $string("GameStatusShort")[2],
                    'firstH': $string("GameStatusShort").FirstH,
                    'secondH': $string("GameStatusShort").SecondH
                },

            'parts':
                {
                    'overtime': $string("GameStatusShort").OT
                }
        }
    }

    GameStatus.prototype = {
        getStatusController: function (branchId, status, tennis, config)
        {
            switch (branchId) {
                case 1: return this.getSoccerStatusCodeByStatusID(status, config);
                case 2: return this.getBasketStatusCodeByStatusID(status, config);
                case 3: return this.getAmFootballStatusCodeByStatusID(status, config);
                case 6: return this.getTennisStatusCodeByStatusID(status, tennis);
                case 7: return this.getBaseBallStatusCodeByStatusID(status);
                case 8: return this.getIceHockeyStatusCodeByStatusID(status, config);
                case 10: return this.getHandballStatusCodeByStatusID(status);
                case 11: return this.getRugbyLeagueStatusCodeByStatusID(status);
                case 13: return this.getSnookerAndPoolStatusCodeByStatusID(status);
                case 15: return this.getDartsStatusCodeByStatusID(status);
                case 19: return this.getVolleyballStatusCodeByStatusID(status);
                case 22: return this.getCricketStatusCodeByStatusID(status);
                case 34: return this.getBadmintonStatusCodeByStatusID(status);
                case 35: return this.getRugbyUnionStatusCodeByStatusID(status);
                case 25: return this.getFutsalStatusCodeByStatusID(status);
                case 26: return this.getTableTennisStatusCodeByStatusID(status, tennis);
                case 31: return this.getWaterPoloStatusCodeByStatusID(status);
                case 32: return this.getBeachVolleyStatusCodeByStatusID(status);
                default:
                    return null;
            }
        },

        getSoccerStatusCodeByStatusID: function (status, config)
        {
            var codes = (config && config.isShortStatusText) ? 'jsonShortStatusCodes' : 'jsonStatusCodes';

            switch (Math.abs(status))
            {
                case this.jsonStatusCodes.firstHalf:
                    return this[codes].halves.first;
                case this.jsonStatusCodes.secondHalf:
                    return this[codes].halves.second;
                case this.jsonStatusCodes.halfTime:
                    return this.jsonStatusCodes.halves.halfTime;
                default:
                    return null;
            }
        },

        getHalvesBasketballResultTypeStatusText: function (isHalvesBasketballResultType, statusText, defaultStatusText)
        {
            if (isHalvesBasketballResultType)
            {
                return statusText;
            }

            return defaultStatusText;
        },

        getBasketStatusCodeByStatusID: function (status, config)
        {
            var resultType = Events.getBasketballResultTypeKey(config.additionalData);
            var isHalvesBasketballResultType = Events.isHalvesBasketballResultTypeValue(resultType);

            if (config.isShortStatusText)
            {
                if (config.timeText)
                {
                    return this.getBasketShortStatusCodeByStatusIDWithTime(status, isHalvesBasketballResultType, config.timeText);
                }
                else
                {
                    return this.getBasketShortStatusCodeByStatusID(status, isHalvesBasketballResultType);
                }            
            }

            return this.getBasketFullStatusCodeByStatusID(status, isHalvesBasketballResultType);
        },

        getBasketFullStatusCodeByStatusID: function (status, isHalvesBasketballResultType)
        {
            switch (Math.abs(status))
            {
                case this.jsonStatusCodes.firstQuarter:
                    return this.getHalvesBasketballResultTypeStatusText(isHalvesBasketballResultType, this.jsonStatusCodes.halves.first, this.jsonStatusCodes.quarters.first);
                case this.jsonStatusCodes.secondQuarter:
                    return this.getHalvesBasketballResultTypeStatusText(isHalvesBasketballResultType, this.jsonStatusCodes.halves.second, this.jsonStatusCodes.quarters.second);
                case this.jsonStatusCodes.thirdQuarter:
                    return this.jsonStatusCodes.quarters.third;
                case this.jsonStatusCodes.fourthQuarter:
                    return this.jsonStatusCodes.quarters.fourth;
                case this.jsonStatusCodes.firstBreak:
                    return this.getHalvesBasketballResultTypeStatusText(isHalvesBasketballResultType, this.jsonStatusCodes.quarters.halfTime, this.jsonShortStatusCodes.breaks.first);
                case this.jsonStatusCodes.secondBreak:
                    return this.jsonShortStatusCodes.breaks.second;
                case this.jsonStatusCodes.thirthBreak:
                    return this.jsonShortStatusCodes.breaks.third;                
                case this.jsonStatusCodes.halfTime:
                    return this.jsonStatusCodes.quarters.halfTime;
                case this.jsonStatusCodes.overtime:
                    return this.jsonStatusCodes.quarters.overtime;
                default:
                    return null;
            }
        },

        getBasketShortStatusCodeByStatusID: function(status, isHalvesBasketballResultType)
        {
            switch (Math.abs(status))
            {
                case this.jsonStatusCodes.firstQuarter:
                    return this.getHalvesBasketballResultTypeStatusText(isHalvesBasketballResultType, this.jsonShortStatusCodes.halves.firstH, this.jsonShortStatusCodes.quarters.firstQ);

                case this.jsonStatusCodes.secondQuarter:
                    return this.getHalvesBasketballResultTypeStatusText(isHalvesBasketballResultType, this.jsonShortStatusCodes.halves.secondH, this.jsonShortStatusCodes.quarters.secondQ);

                case this.jsonStatusCodes.thirdQuarter:
                    return this.jsonShortStatusCodes.quarters.thirdQ;
                case this.jsonStatusCodes.fourthQuarter:
                    return this.jsonShortStatusCodes.quarters.fourthQ;
                case this.jsonStatusCodes.firstBreak:
                    return this.getHalvesBasketballResultTypeStatusText(isHalvesBasketballResultType, this.jsonStatusCodes.quarters.halfTime, this.jsonShortStatusCodes.breaks.first);
                case this.jsonStatusCodes.secondBreak:                    
                    return this.jsonShortStatusCodes.breaks.second;
                case this.jsonStatusCodes.thirthBreak:
                    return this.jsonShortStatusCodes.breaks.third;
                case this.jsonStatusCodes.overtime:
                    return this.jsonShortStatusCodes.parts.overtime;
                default:
                    return null;
            }
        },

        getBasketShortStatusCodeByStatusIDWithTime: function (status, isHalvesBasketballResultType, timeText)
        {
            var json = {
                'quarters':
                {
                    'first': timeText + " (" + this.jsonShortStatusCodes.quarters.first + ")",
                    'second': timeText + " (" + this.jsonShortStatusCodes.quarters.second + ")",
                    'third': timeText + " (" + this.jsonShortStatusCodes.quarters.third + ")",
                    'fourth': timeText + " (" + this.jsonShortStatusCodes.quarters.fourth + ")"
                },

                'halves':
                    {
                        'first': timeText + " (" + this.jsonShortStatusCodes.halves.first + ")",
                        'second': timeText + " (" + this.jsonShortStatusCodes.halves.second + ")",
                    },

                'parts':
                    {
                        'overtime': timeText + " (" + this.jsonStatusCodes.quarters.overtime + ")"
                    }
            }

            switch (Math.abs(status))
            {
                case this.jsonStatusCodes.firstQuarter:
                    return this.getHalvesBasketballResultTypeStatusText(isHalvesBasketballResultType, json.halves.first, json.quarters.first);
                   
                case this.jsonStatusCodes.secondQuarter:
                    return this.getHalvesBasketballResultTypeStatusText(isHalvesBasketballResultType, json.halves.second, json.quarters.second);
                  
                case this.jsonStatusCodes.thirdQuarter:
                    return json.quarters.third;
                case this.jsonStatusCodes.fourthQuarter:
                    return json.quarters.fourth;
                case this.jsonStatusCodes.overtime:
                    return json.parts.overtime;
                default:
                    return null;
            }
        },

        getAmFootballStatusCodeByStatusID: function (status, config) {
            var codes = (config && config.isShortStatusText) ? 'jsonShortStatusCodes' : 'jsonStatusCodes';

            switch (Math.abs(status)) {
                case this.jsonStatusCodes.firstQuarter:
                    return this[codes].quarters.first;
                case this.jsonStatusCodes.secondQuarter:
                    return this[codes].quarters.second;
                case this.jsonStatusCodes.thirdQuarter:
                    return this[codes].quarters.third;
                case this.jsonStatusCodes.fourthQuarter:
                    return this[codes].quarters.fourth;
                case this.jsonStatusCodes.firstBreak:
                    return this.jsonShortStatusCodes.breaks.first;
                case this.jsonStatusCodes.secondBreak:
                    return this.jsonShortStatusCodes.breaks.second;
                case this.jsonStatusCodes.thirthBreak:
                    return this.jsonShortStatusCodes.breaks.third;
                case this.jsonStatusCodes.halfTime:
                    return this.jsonStatusCodes.quarters.halfTime;
                default:
                    return null;
            }
        },

        getTennisStatusCodeByStatusID: function (status, tennis) {
                var json = {
                    'firstSet': tennis.Player1Set1 != '-' || tennis.Player2Set1 != '-',
                'secondSet': tennis.Player1Set2 != '-'||  tennis.Player2Set2 != '-',
                'thirdSet': tennis.Player1Set3 != '-' || tennis.Player2Set3 != '-',
                'fourthSet': tennis.Player1Set4 != '-' || tennis.Player2Set4 != '-',
                'fifthSet': tennis.Player1Set5 != '-' || tennis.Player2Set5 != '-' ,
                'sets':
                {
                    'first': $string("LiveBettingGameStatus").S1,
                    'second': $string("LiveBettingGameStatus").S2,
                    'third': $string("LiveBettingGameStatus").S3,
                    'fourth': $string("LiveBettingGameStatus").S4,
                    'fifth': $string("LiveBettingGameStatus").S5,
                }
            };

            if (json.fifthSet) {
                return json.sets.fifth;
            }
            else if (json.fourthSet) {
                return json.sets.fourth;
            }
            else if (json.thirdSet) {
                return json.sets.third;
            }
            else if (json.secondSet) {
                return json.sets.second;
            }
            else if (json.firstSet) {
                return json.sets.first;
            }

            return null;
        },

        getBaseBallStatusCodeByStatusID: function (status) {
            switch (Math.abs(status)) {
                case this.jsonStatusCodes.firstInn:
                    return this.jsonStatusCodes.Inns.first;
                case this.jsonStatusCodes.secondInn:
                    return this.jsonStatusCodes.Inns.second;
                case this.jsonStatusCodes.thirdInn:
                    return this.jsonStatusCodes.Inns.third;
                case this.jsonStatusCodes.fourthInn:
                    return this.jsonStatusCodes.Inns.fourth;
                case this.jsonStatusCodes.fifthInn:
                    return this.jsonStatusCodes.Inns.fifth;
                case this.jsonStatusCodes.sixthInn:
                    return this.jsonStatusCodes.Inns.sixth;
                case this.jsonStatusCodes.seventhInn:
                    return this.jsonStatusCodes.Inns.seven;
                case this.jsonStatusCodes.eighthInn:
                    return this.jsonStatusCodes.Inns.eight;
                case this.jsonStatusCodes.ninethInn:
                    return this.jsonStatusCodes.Inns.nine;
                default:
                    return null;
            }
        },

        getIceHockeyStatusCodeByStatusID: function (status, config) {
            var codes = (config && config.isShortStatusText) ? 'jsonShortStatusCodes' : 'jsonStatusCodes';

            switch (Math.abs(status)) {
                case this.jsonStatusCodes.firstPer:
                case this.jsonStatusCodes.firstHalf:
                    return this[codes].pers.first;
                case this.jsonStatusCodes.secondPer:
                case this.jsonStatusCodes.secondHalf:
                    return this[codes].pers.second;
                case this.jsonStatusCodes.thirdPer:
                    return this[codes].pers.third;
                default:
                    return null;
            }
        },

        getHandballStatusCodeByStatusID: function (status) {
            switch (Math.abs(status)) {
                case this.jsonStatusCodes.first:
                    return this.jsonStatusCodes.halves.firstHalf;
                case this.jsonStatusCodes.second:
                    return this.jsonStatusCodes.halves.secondHalf;
                case this.jsonStatusCodes.breakTime:
                case this.jsonStatusCodes.halfTime:
                    return this.jsonStatusCodes.halves.halfTime;
                default:
                    return null;
            }
        },

        getRugbyLeagueStatusCodeByStatusID: function (status) {
            switch (Math.abs(status)) {
                case this.jsonStatusCodes.first:
                    return this.jsonStatusCodes.halves.firstHalf;
                case this.jsonStatusCodes.second:
                    return this.jsonStatusCodes.halves.secondHalf;
                case this.jsonStatusCodes.halfTime:
                case this.jsonStatusCodes.breakTime:
                    return this.jsonStatusCodes.halves.halfTime;
                default:
                    return null;
            }
        },

        getSnookerAndPoolStatusCodeByStatusID: function (status) {
            switch (Math.abs(status)) {
                case this.jsonStatusCodes.firstFra:
                    return this.jsonStatusCodes.frames.first;
                case this.jsonStatusCodes.secondFra:
                    return this.jsonStatusCodes.frames.second;
                case this.jsonStatusCodes.thirdFra:
                    return this.jsonStatusCodes.frames.third;
                case this.jsonStatusCodes.fourthFra:
                    return this.jsonStatusCodes.frames.fourth;
                case this.jsonStatusCodes.fifthFra:
                    return this.jsonStatusCodes.frames.fifth;
                case this.jsonStatusCodes.sixthFra:
                    return this.jsonStatusCodes.frames.sixth;
                case this.jsonStatusCodes.seventhFra:
                    return this.jsonStatusCodes.frames.seventh;
                case this.jsonStatusCodes.eightFra:
                    return this.jsonStatusCodes.frames.eighth;
                case this.jsonStatusCodes.ninethFra:
                    return this.jsonStatusCodes.frames.nineth;
                case this.jsonStatusCodes.tenthFra:
                    return this.jsonStatusCodes.frames.tenth;
                case this.jsonStatusCodes.eleventhFra:
                    return this.jsonStatusCodes.frames.eleventh;
                default:
                    return null;
            }
        },

        //under construction
        getDartsStatusCodeByStatusID: function (status) {
            return null;

            var json = {
                FirstQuarter: 11,
                SecondQuarter: 12,
                ThirdQuarter: 13,
                FourthQuarter: 14,
                HT: 15,
                Quarters:
                    {
                        First: '1st Per',
                        Second: '2nd Per',
                        Third: '3rd Per'
                    }
            };

            switch (Math.abs(status)) {
                case json.FirstQuarter:
                    return json.FirstQuarter;
                case BasketballScore.QuarterStatuses.SecondQuarter:
                    return json.SecondQuarter;
                case json.ThirdQuarter:
                    return json.Quarters.Third;
                case json.FourthQuarter:
                    return json.Quarters.Fourth;
                case json.HT:
                    return json.Quarters.HT;
            }

            return -1;
        },

        getVolleyballStatusCodeByStatusID: function (status) {
            switch (Math.abs(status)) {
                case this.jsonStatusCodes.firstSet:
                    return this.jsonStatusCodes.sets.first;
                case this.jsonStatusCodes.secondSet:
                    return this.jsonStatusCodes.sets.second;
                case this.jsonStatusCodes.thirdSet:
                    return this.jsonStatusCodes.sets.third;
                case this.jsonStatusCodes.fourthSet:
                    return this.jsonStatusCodes.sets.fourth;
                case this.jsonStatusCodes.fifthSet:
                    return this.jsonStatusCodes.sets.fifth;
                default:
                    return null;
            }
        },

        //under construction
        getCricketStatusCodeByStatusID: function (status) {
            return null;

            var json = {
                FirstQuarter: 11,
                SecondQuarter: 12,
                ThirdQuarter: 13,
                FourthQuarter: 14,
                HT: 15,
                Quarters:
                    {
                        First: '1st Per',
                        Second: '2nd Per',
                        Third: '3rd Per'
                    }
            };

            switch (Math.abs(status)) {
                case json.FirstQuarter:
                    return json.FirstQuarter;
                case BasketballScore.QuarterStatuses.SecondQuarter:
                    return json.SecondQuarter;
                case json.ThirdQuarter:
                    return json.Quarters.Third;
                case json.FourthQuarter:
                    return json.Quarters.Fourth;
                case json.HT:
                    return json.Quarters.HT;
            }

            return -1;
        },

        //under construction
        getBadmintonStatusCodeByStatusID: function (status) {
            return null;

            var json = {
                FirstQuarter: 11,
                SecondQuarter: 12,
                ThirdQuarter: 13,
                FourthQuarter: 14,
                HT: 15,
                Quarters:
                    {
                        First: '1st Per',
                        Second: '2nd Per',
                        Third: '3rd Per'
                    }
            };

            switch (Math.abs(status)) {
                case json.FirstQuarter:
                    return json.FirstQuarter;
                case BasketballScore.QuarterStatuses.SecondQuarter:
                    return json.SecondQuarter;
                case json.ThirdQuarter:
                    return json.Quarters.Third;
                case json.FourthQuarter:
                    return json.Quarters.Fourth;
                case json.HT:
                    return json.Quarters.HT;
            }

            return -1;
        },

        getRugbyUnionStatusCodeByStatusID: function (status) {
            switch (Math.abs(status)) {
                case this.jsonStatusCodes.firstHalf:
                    return this.jsonStatusCodes.halves.first;
                case this.jsonStatusCodes.secondHalf:
                    return this.jsonStatusCodes.halves.second;
                case this.jsonStatusCodes.breakTime:
                case this.jsonStatusCodes.halfTime:
                    return this.jsonStatusCodes.halves.halfTime;
                default:
                    return null;
            }             
        },

        getFutsalStatusCodeByStatusID: function (status) {
            switch (Math.abs(status)) {
                case this.jsonStatusCodes.firstHalf:
                    return this.jsonStatusCodes.halves.first;
                case this.jsonStatusCodes.secondHalf:
                    return this.jsonStatusCodes.halves.second;
                case this.jsonStatusCodes.breakTime:
                case this.jsonStatusCodes.halfTime:
                    return this.jsonStatusCodes.halves.halfTime;
                default:
                    return null;
            }
        },

        //under construction
        getTableTennisStatusCodeByStatusID: function (status, tennis) {
           
            var json = {
                'firstSet': tennis.Player1Set1 != '-' || tennis.Player2Set1 != '-',
                'secondSet': tennis.Player1Set2 != '-' || tennis.Player2Set2 != '-',
                'thirdSet': tennis.Player1Set3 != '-' || tennis.Player2Set3 != '-',
                'fourthSet': tennis.Player1Set4 != '-' || tennis.Player2Set4 != '-',
                'fifthSet': tennis.Player1Set5 != '-' || tennis.Player2Set5 != '-',
                'sets':
                {
                    'first': $string("LiveBettingGameStatus").S1,
                    'second': $string("LiveBettingGameStatus").S2,
                    'third': $string("LiveBettingGameStatus").S3,
                    'fourth': $string("LiveBettingGameStatus").S4,
                    'fifth': $string("LiveBettingGameStatus").S5,
                }
            };

            if (json.fifthSet) {
                return json.sets.fifth;
            }
            else if (json.fourthSet) {
                return json.sets.fourth;
            }
            else if (json.thirdSet) {
                return json.sets.third;
            }
            else if (json.secondSet) {
                return json.sets.second;
            }
            else if (json.firstSet) {
                return json.sets.first;
            }

            return null;
        },

        getWaterPoloStatusCodeByStatusID: function (status) {
            switch (Math.abs(status)) {
                case this.jsonStatusCodes.firstQuarter:
                    return this.jsonStatusCodes.quarters.first;
                case this.jsonStatusCodes.secondQuarter:
                    return this.jsonStatusCodes.quarters.second;
                case this.jsonStatusCodes.thirdQuarter:
                    return this.jsonStatusCodes.quarters.third;
                case this.jsonStatusCodes.fourthQuarter:
                    return this.jsonStatusCodes.quarters.fourth;
                case this.jsonStatusCodes.breakTime:
                case this.jsonStatusCodes.halfTime:
                    return this.jsonStatusCodes.quarters.halfTime;
                default:
                    return null;
            }
        },

        //under construction
        getBeachVolleyStatusCodeByStatusID: function (status) {
            return null;

            var json = {
                FirstQuarter: 11,
                SecondQuarter: 12,
                ThirdQuarter: 13,
                FourthQuarter: 14,
                HT: 15,
                Quarters:
                    {
                        First: $string("LiveBettingGameStatus").P1,
                        Second: $string("LiveBettingGameStatus").P2,
                        Third: $string("LiveBettingGameStatus").P3
                    }
            };

            switch (Math.abs(status)) {
                case json.FirstQuarter:
                    return json.FirstQuarter;
                case BasketballScore.QuarterStatuses.SecondQuarter:
                    return json.SecondQuarter;
                case json.ThirdQuarter:
                    return json.Quarters.Third;
                case json.FourthQuarter:
                    return json.Quarters.Fourth;
                case json.HT:
                    return json.Quarters.HT;
            }

            return -1;
        }
    }

    return {
        getGameStatus: function () {
            return new GameStatus();
        }
    };
})();

window.gameStatus = gameStatus;