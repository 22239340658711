﻿/**
 * Slide miscellaneous content inside of cusom HTML
 * Initialising:
 *
 * var newSlider = Object.create(CustomSlider);
 * TODO Update the description when the integration with the existing code is done
 * newSlider.init({hasPages: true,
 *     containerID: 'test-slider', 
 *     slides: [
 *         {content: '<img src=\'1.jpg\' alt=\'\' />'}, 
 *         {content: '<img src=\'2.jpg\' alt=\'\' />'}, 
 *         {content: '<img src=\'3.jpg\' alt=\'\' />'},
 *         {content: '<img src=\'4.jpg\' alt=\'\' />'},
 *         {content: '<img src=\'5.jpg\' alt=\'\' />'}
 *     ]}
 * );

*/

/* exported CustomSlider */

/* globals Easing */

var CustomSlider =
{
    'config': {},
    'defaultConfig':
    {
        hasPages: false, // has pagination or not
        hasArrows: false, // has arrows or not
        containerID: '',
        slides: [],
        timeSliding: 1000, // default time for the sliding animation to perform
        timeLooping: 5000, // default time for the slides to change inside a loop
        loop: false,
        customClasses: [],
        forDevice: 0,
        isDraggable: false,
        parent: {}
    },
    'deviceTypes': ['IsDesktop','IsTablet','IsMobile'],
    'isLooping': false, // is setting on true when the loop is starting
    'loop': false, // It is on by default, set by the config 
    'currentSlide': 1, // the active slide
    'sliderWrap': null, // dom element .custom-slider-wrap
    'slides': [], // array of objects, containing content of every slide as a string
    'slidesCount': 0, // number of slides
    'container': null, // main component container found by ID mentioned in the user input in config
    'sliderList': null,
    'sliderContainer': null,
    'sliderTriggersContainer': null,
    'sliderArrows': {},
    'sliderTranslateX': 0,
    'infLoopInterval': '', // Property that holds the id of the active interval on looping
    'timeSliding': 0, // time for the sliding animation to perform
    'timeLooping': 0, // time for the slides to change inside a loop
    'initialized': false, // this property prevent the init to execue over and over again
    'isAnimating': false, // is the animation going on.
    'onAnimationComplete': {}, //list of callbacks, can be filled from the outside
    'udpateSlidesQueue': null, // callback used to update slides, when called while animation is on
    'viewportWidth': 0,
    'slidesWidth' : 0,
    'drag': {
            isGrabbing: false,
            grabbingClass: 'is-grabbing',
            sensitiveDelta: 10, // required to distinguish between drag and click events
            start: 0,
            delta: 0
        },

    init: function (_config)
    {
        if (this.initialized)
        {
            this.destroySlider();
        }

        this.config = Object.assign({}, this.defaultConfig, _config || {});

        this.container = document.getElementById(this.config.containerID);

        var classes = (['custom-slider', 'custom-slider-' + this.deviceTypes[this.config.forDevice]].concat(this.config.customClasses)).join(' ');

        this.container && (this.container.className = classes);

        this.slidesCount = this.config.slides.length;

        this.sliderContainer = this.buildSliderContainer();

        this.updateSlides(this.config.slides);

        this.initForDevice[this.deviceTypes[this.config.forDevice]].call(this);

        this.initialized = true;

        return this.sliderWrap;
    },

    initForDevice: {
        IsDesktop: function()
        {
            this.timeSliding = this.config.timeSliding;

            this.timeLooping = this.config.timeLooping;

            this.config.loop && this.startLoop();

            this.currentSlide = 1;

        },

        IsMobile: function()
        {
            this.currentSlide = 0;
        },

        IsTablet: function()
        {
         this.currentSlide = 0;
        }
    },

    updateEvents: function ()
    {
    },

    updateSlides: function (slides)
    {
        this.config.slides = slides || this.config.slides;

        this.animRef = rafPaint(this.runAnimation);

        if (this.isAnimating)
        {
            this.onAnimationComplete['updateSlidesQueue'] = this.updateSlides.bind(this);
            //this.udpateSlidesQueue = this.config.slides;
            return;
        }

        this.isLooping && this.stopLoop;

        while (this.sliderContainer.firstChild)
        {
            this.sliderContainer.removeChild(this.sliderContainer.firstChild);
        }

        this.slidesCount = this.config.slides.length;

        if (!this.slidesCount) {return;}

        this.sliderWrap = this.buildSlider();

        this.sliderContainer.appendChild(this.sliderWrap);
        this.sliderContainer.appendChild(this.sliderTriggersContainer);
        this.container.appendChild(this.sliderContainer);

        this.attachActiveClass();
        
        this.viewportWidth = this.sliderWrap.clientWidth;

        this.slidesWidth = this.viewportWidth * this.slidesCount;

        this.config.forDevice === 0 && this.attachEventListeners();

        this.updatePage(this.currentSlide);

        (!this.config.forDevice && this.config.loop) && this.startLoop();
        
        return this.config.slides;
    },

    destroySlider: function ()
    {
        if (!this.initialized) { return; }

        this.detachEventListeners();
        this.sliderWrap = null;
        this.sliderTriggersContainer = null;
        this.sliderArrows = {};
        this.container && this.sliderContainer && this.sliderContainer.parentNode && this.container.removeChild(this.sliderContainer);
        this.initialized = false;
        this.isLooping && this.stopLoop();

        return this.container.innerHTML;
    },

    attachEventListeners: function ()
    {
        this.sliderContainer.addEventListener('click', this.stopLoop.bind(this));
        this.config.isDraggable && this.sliderWrap && this.toggleGrabbing();
    },

    detachEventListeners: function ()
    {
        if (!this.initialized) { return; }

        this.sliderContainer.removeEventListener('click', this.stopLoop.bind(this));
        this.config.isDraggable && this.sliderWrap && this.toggleGrabbing();
    },

    buildSliderContainer: function ()
    {
        var sliderContainer = document.createElement('div');
        sliderContainer.classList.add('custom-slider-container');

        return sliderContainer;
    },

    buildSlider: function ()
    {
        var sliderWrap = document.createElement('div');
        sliderWrap.classList.add('custom-slider-wrap');
        sliderWrap.appendChild(this.buildList());

        if (this.config.hasArrows &&  this.slidesCount > 1) 
        {
            sliderWrap.appendChild(this.buildSingleArrow('prev'));
            sliderWrap.appendChild(this.buildSingleArrow('next'));
            // this.checkArrowsAvailability();
        }
        return sliderWrap;
    },

    buildList: function ()
    {
        this.sliderList = document.createElement('div');
        this.sliderList.classList.add('custom-slider-list');
        
        this.sliderTriggersContainer = document.createElement('div');
        this.sliderTriggersContainer.classList.add('custom-slider-trigger-container');
        
        for (var item = 0; item < this.config.slides.length; item += 1)
        {
            this.sliderList.appendChild(this.buildItem(this.config.slides[item]));
            
            if ( this.slidesCount > 1 )
            {
                this.config.hasPages && this.sliderTriggersContainer.appendChild(this.buildTriggers(item + 1));
            }
        }

        this.config.forDevice === 0 && this.cloneNodes();

        this.translateSliderToPage(0);
        return this.sliderList;
    },

    startLoop: function ()
    {
        if (this.config.loop && this.timeLooping < this.timeSliding) // invalid time values
        {
            console.log('Invalid time values. Time for looping must be mre than time for sliding');
            return;
        }

        if (this.slidesCount < 2)
        {
            return;
        }

        this.isLooping = true;


        this.infLoopInterval && clearInterval(this.infLoopInterval);
        this.infLoopInterval = setInterval(this.looping.bind(this), this.timeLooping);

        return this.infLoopInterval;
    },

    stopLoop: function ()
    {
        if (!this.isLooping)
        {
            return;
        }
        clearInterval(this.infLoopInterval);

        this.isLooping = false;

        this.translateSliderToPage(this.currentSlide);

        return this.infLoopInterval;
    },

    getSlidesCount: function()
    {
        return this.sliderList.children.length;
    },

    looping: function ()
    {
        if (document.visibilityState != 'visible') { return; }
        this.goToPage(this.currentSlide + 1);
        return this.currentSlide + 1;
    },

    cloneNodes: function ()
    {
        if (!this.slidesCount) {return;}

        var firstSlideClone = this.sliderList.firstElementChild.cloneNode(true);
        var lastSlideClone = this.sliderList.lastElementChild.cloneNode(true);

        firstSlideClone.dataset.cloned = true;
        lastSlideClone.dataset.cloned = true;

        firstSlideClone = this.cleanCloneIDs(firstSlideClone);
        lastSlideClone = this.cleanCloneIDs(lastSlideClone);

        this.sliderList.appendChild(firstSlideClone);
        this.sliderList.insertBefore(lastSlideClone, this.sliderList.children[0]);

        return this.sliderList;
    },

    cleanCloneIDs: function(node)
    {
        var ref = this;
        node.children.length && Array.prototype.forEach.call(node.children, function(el){ref.cleanCloneIDs(el);});
        node.removeAttribute('id');
        node.removeAttribute('name');
        return node;
    },

    changeSlide: function (pageNumber)
    {
        this.stopLoop();
        this.goToPage(pageNumber);

        return this.currentSlide;
    },

    navigateSlides: function(direction)
    {
        if (this.isAnimating) { return false; }
        var number = direction === 'prev'? this.currentSlide - 1 : this.currentSlide + 1;
        this.goToPage(number);
        return number;
    },

    checkArrowsAvailability: function()
    {
        if (!this.config.hasArrows) { return false; }
        /*jshint ignore:start*/
        var hideNextArrow = !this.sliderList.children[this.currentSlide].dataset.cloned && (this.currentSlide === this.slidesCount  || this.sliderList.children[this.currentSlide + 1].dataset.cloned);
        var hidePrevArrow = this.currentSlide === 1 || this.sliderList.children[this.currentSlide].dataset.cloned;
       
        this.sliderArrows.next.classList[hideNextArrow? 'add':'remove']('isHidden');
        this.sliderArrows.prev.classList[hidePrevArrow? 'add':'remove']('isHidden');
         /*jshint ignore:end*/
    },

    goToPage: function (pageNumber, hardCodedTime)
    {
        var startStamp = new Date().getTime();

        var sliderStartPosition = this.sliderTranslateX;
        var velocity = pageNumber - sliderStartPosition;

        this.currentSlide = pageNumber;

        this.attachActiveClass();
        // this.checkArrowsAvailability();
        this.animRef.call(this, startStamp, sliderStartPosition, velocity, hardCodedTime);
        
        return this.currentSlide;
    },

    runAnimation: function (startStamp, sliderStartPosition, velocity, hardCodedTime)
    {
        var currentStamp = new Date().getTime();
        var elapsedTime = currentStamp - startStamp;
        var time = hardCodedTime || this.timeSliding;

        if (elapsedTime <= time)
        {
            this.isAnimating = true;

            var distanceX = Easing.quad.easeInOut(elapsedTime,sliderStartPosition, velocity, time);

            this.translateSliderToPage(distanceX);

            this.animRef.call(this, startStamp, sliderStartPosition, velocity, time);
        }

        else
        {
            if (this.sliderList.children[this.currentSlide].dataset.cloned)
            {

                if (this.currentSlide === 0) 
                {
                    this.updatePage(this.slidesCount);
                }
                else 
                {
                    this.updatePage(1);
                }
            }
            else
            {
                this.translateSliderToPage(this.currentSlide);
                
                this.executeOnAnimationComplete();

                if (this.onAnimationComplete['updateSlidesQueue'])
                {
                    delete this.onAnimationComplete['updateSlidesQueue'];
                }
            }
            this.isAnimating = false;
        }

        return this.isAnimating;
    },

    // full update of the current slide

    updatePage: function (pageNumber)
    {
        this.currentSlide = pageNumber;
        this.translateSliderToPage(this.currentSlide);
        this.attachActiveClass();

        return this.currentSlide;
    },

    translateSliderToPage: function (page)
    {
        this.sliderTranslateX = page;
        var slidePercentage = page * 100;
        this.translateSlider(slidePercentage);
        
        return this.sliderTranslateX;
    },

    translateSlider: function(slidePercentage)
    {
        var sliderPerc = -1 * slidePercentage;
        this.sliderList.style.transform = 'translate3d(' + sliderPerc + '%,0,0)';
        return this.sliderList.style.transform;
    },

    buildItem: function (item)
    {
        var sliderListItem = document.createElement('div');
        sliderListItem.classList.add('custom-slider-list-item');

        sliderListItem.innerHTML = item.content || '';

        return sliderListItem;
    },

    buildTriggers: function (item)
    {
        var sliderListTrigger = document.createElement('div');
        sliderListTrigger.classList.add('custom-slider-trigger');
        sliderListTrigger.addEventListener('click', this.changeSlide.bind(this, item));

        return sliderListTrigger;
    },

    buildSingleArrow: function(direction)
    {
        var arrow = document.createElement('div');
        var ref = this;
        var icon = document.createElement('div');

        icon.className = 'custom-slider-arrow-icon custom-slider-arrow-icon-' + direction;
        
        arrow.className = 'custom-slider-arrow custom-slider-arrow-' + direction;
        arrow.dataset.direction = direction;
        arrow.addEventListener('click', ref.navigateSlides.bind(ref, arrow.dataset.direction));

        arrow.appendChild(icon);

        this.sliderArrows[direction] = arrow;
        return arrow;
    },

    attachActiveClass: function ()
    {
        var triggers = this.sliderTriggersContainer.children;
        var slides = Array.prototype.filter.call(this.sliderList.children, function (el) { return !el.dataset.cloned; });
        var i;
        var slidesLen = this.slidesCount;

        for (i = 0; i < slidesLen; i += 1)
        {
            triggers.length && triggers[i].classList.remove('custom-slider-active');
            slides[i].classList.remove('custom-slider-active');
        }

        triggers.length && triggers[triggers[this.currentSlide - 1] ? this.currentSlide - 1 : 0].classList.add('custom-slider-active');
        slides[this.currentSlide -1] && slides[this.currentSlide - 1].classList.add('custom-slider-active');

        return this.sliderList;
    },

    executeOnAnimationComplete: function ()
    {
        for (var func in this.onAnimationComplete)
        {
            if (typeof this.onAnimationComplete[func] === 'function')
            {
                this.onAnimationComplete[func]();
                //delete this.onAnimationComplete[func];
            }
        }

        return this.onAnimationComplete;
    },

    handleEvent: function (e) {
        if (this.isAnimating) { return; }
        typeof this[e.type] === 'function' && this[e.type](e);
    },

    toggleGrabbing: function () {
        if (this.slidesCount > 1) {
            this.sliderWrap.classList.add('custom-slider-draggable');
            this.sliderWrap.addEventListener('mousedown', this);
        } else {
            this.sliderWrap && this.sliderWrap.classList.remove('custom-slider-draggable');
            this.sliderWrap && this.sliderWrap.removeEventListener('mousedown', this);
        }
    },

    mousedown: function (e) {
        e.preventDefault();

        this.drag.start = e.pageX;
        
        this.sliderWrap.addEventListener('mouseup', this);
        this.sliderWrap.addEventListener('mousemove', this);
        this.sliderWrap.addEventListener('mouseleave', this);
    },

    mousemove: function (e) {
        e.preventDefault();

        this.drag.delta = e.pageX - this.drag.start;
        
        if (Math.abs(this.drag.delta) > this.drag.sensitiveDelta) {
            this.drag.isGrabbing = true;
            this.sliderList.classList.remove('custom-slider-animate');
            this.sliderWrap.classList.add(this.drag.grabbingClass);
            this.sliderTranslateX = -1 * this.drag.delta/this.viewportWidth + this.currentSlide;
            this.translateSlider(this.sliderTranslateX * 100);
        }
    },

    mouseup: function (e) {
        e.preventDefault();

        this.sliderWrap.classList.remove(this.drag.grabbingClass);
        this.sliderList.classList.add('custom-slider-animate');
        if (this.drag.isGrabbing){
            if (Math.abs(this.drag.delta/this.viewportWidth) > 0.2)
            {
                this.goToPage(this.drag.delta > 0 ? this.currentSlide - 1 : this.currentSlide + 1, 300);
            }

            else
            {
                this.goToPage(this.currentSlide, 300);
            }
        }

        this.drag.start = 0;
        this.drag.delta = 0;
        this.drag.isGrabbing = false;
        
        this.sliderWrap.removeEventListener('mouseleave', this);
        this.sliderWrap.removeEventListener('mousemove', this);
        this.sliderWrap.removeEventListener('mouseup', this);
    },

    mouseleave: function (e) {

        this.mouseup(e);
    },

};

window.CustomSlider = CustomSlider;