﻿// JScript File

/****************************************************************************************************************************************
/*
/*  TimerControl class and some service functions
/*
/**/


TimerControl = function()
{
  this.lastUpdate = undefined;
  this.serverUTCTime = undefined;
  this.serverTime = undefined;
  this.serverOffset = undefined;
  this.clientToServerDelta = 0;
  this.timeOut = 3600 * 1000;
  this.tickTimeOut = 2 * 1000;
  this.initialized = false;
  this.eventsManager = new EventsManager();

  var temp = this;

  this.eventsManager.addEventListener("onPlacedPulseBetTimeReload", this, this.forceReloadServerTime, true);
  
  this.inerval = setInterval(function() {temp.makeTick();} , 250);
}

TimerControl.prototype =
{
   /*  main function for time synchronization, recieves and parses time form server answer (both from html call and form ajax)
       and initializes|corrects all timers. Requires full server time info in ISO format YYYY-MM-ddTHH:mm:ss.fffK, with server
       timezone information, not only UTC time.
       string isoTime  -  time in ISO format  */
  setServerTime : function(isoTime)
  {
      try
      {
          this.serverTime = Date.fromISO(isoTime);
          this.serverUTCTime = Date.UTCFromISO(isoTime);
          this.serverOffset = this.serverTime.getTime() - this.serverUTCTime.getTime();
          this.lastUpdate = new Date();
          this.clientToServerDelta = this.lastUpdate.getTime() - this.serverTime.getTime();
          this.lastServerReply = isoTime;
          this.lastTickTime = this.getTimeWithClientTimeZoneOffset();
          window.serverdate = this.getServerTime();
      }
      catch(err)
      {
          throw new TypeError('Param: ' + isoTime + "Msg: " + err.message, "TimeControl.js", 5);
      }
  },
  
  /// returns correct server time
  getServerTime : function()
  {
      var res = new Date();

      if (!isFinite(this.clientToServerDelta))
      {
          this.reloadServerTime();
          return;
      }
    res.setTime(res.getTime() - this.clientToServerDelta);
    return res;
  },
  
  /// returns UTC time, sinchronized with server
  getServerUTCTime : function()
  {
    var res = new Date();
    var delta = res.getTime() - this.lastUpdate;
    res.setTime(this.serverUTCTime.getTime() + delta);
    return res;
  },

  //returns currentTimeZone
  getCurrentTimeZone: function () 
  {
    return currentTimeZone;
  },

  /// returns client time, sinchronized with server
  getClientTime : function()
  {
    var res = new Date(this.getServerUTCTime());
    res.setMinutes(res.getMinutes() - res.getTimezoneOffset());
    return res;
  },
  
  /// private service function, executes 4 times per second
  makeTick : function()
  {
    window.serverdate = this.getServerTime();

    var newTickTime = this.getTimeWithClientTimeZoneOffset();
    if (Math.abs(this.lastTickTime - newTickTime) > this.tickTimeOut){
      this.lastUpdate = undefined;
    }

    this.lastTickTime = newTickTime;

    if((!this.lastUpdate) || (new Date).getTime() -  this.lastUpdate.getTime() > this.timeOut) this.reloadServerTime();
  },
  
  getTimeWithClientTimeZoneOffset : function()
  {
    var date = new Date;
    return date.getTime() + date.getTimezoneOffset() * 60 * 1000;
  },

  /// AJAX call, recieves update for server time
  reloadServerTime : function()
  {
    if(this.reloadServerTime.locked) return;
    this.reloadServerTime.locked = true;
    
    if((PageMethods) && (PageMethods.GetServerTime))
      PageMethods.GetServerTime(
          function(result, context, name)
          {
            context.reloadServerTime.locked = false;
            context.setServerTime(result);
            context.initialized = true;
            context.eventsManager.dispatchEvent('onTimeControlInitialized', null, true);
          },
          
          function(result, context, name)
          {
            context.reloadServerTime.locked = false;
          },
          
          this
      );
  },

  forceReloadServerTime: function ()
  {
      this.reloadServerTime.locked = false;
      this.reloadServerTime();
  },
  
  /// converts server time to local time (requred to calculate correctly time for events in local time zone)
  fromServerToLocal : function(atServer)
  {
    var res = new Date(atServer);
    res.setMinutes(res.getMinutes() - res.getTimezoneOffset());
    res.setTime(res.getTime() - this.serverOffset);
    return res;
  }
}

// obsolete, only for backward compatibility, use timeControl.getServerTime
var serverdate = null;

// singleton for TimerControl()
var timeControl = new TimerControl();

/****************************************************************************************************************************************
/*
/*  Date formating functions
/*
/**/

if (!Date.fromISO)
{

    /// Decoding time from ISO string representation
    Date.fromISO = function(ms)
    {
        //2008-05-12T15:28:45.6642211+03:00 - example of server answer

        arr = ms.match(/(\d+)-(\d+)-(\d+)T(\d+):(\d+):(\d+)\.?(\d*)/i);
        if (!arr) return undefined;

        return new Date(
    parseInt(arr[1], 10),
    parseInt(arr[2], 10) - 1,
    parseInt(arr[3], 10),
    parseInt(arr[4], 10),
    parseInt(arr[5], 10),
    parseInt(arr[6], 10),
    arr[7] ? parseFloat("0." + arr[7]) * 1000 : 0
  );
    }

    /// Decoding time from ISO string representation and applying timezone shift
    Date.UTCFromISO = function(ms)
    {
        //2008-05-12T15:28:45.6642211+03:00 - example of server answer
        if (ms.match(/Z\s*$/i)) return Date.fromISO(ms);

        var pre = Date.fromISO(ms);

        arr = ms.match(/([+-])(\d+):(\d+)\s*$/i);
        if (!arr) return undefined;

        var delta = (parseInt(arr[2]) * 60 + parseInt(arr[3])) * (arr[1] == "+" ? -1 : +1);
        pre.setMinutes(pre.getMinutes() + delta);
        return pre;
    }
}

window.TimerControl = TimerControl;
window.serverdate = serverdate;
window.timeControl = timeControl;