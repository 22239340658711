﻿/// For debug only - when set to true AJAX will throw an exception if more then one blocking call will be called.
var show_lock_errors = false;

/// 10Bet Ajax system class, there is no need to call it directly - all methods will be generated by server stub composer
var AJAX =
{
    /*********************************************** PUBLIC ****************************************************************/

    // <summary>Entry point for standart call</summary>
    // <param name="isPost" type="bool">Is this post call</param>
    // <param name="base" type="string">Service URL</param>
    // <param name="request" type="Hashtable">request pairs key=value</param>
    // <param name="__success_callback" type="function">call back for successeful call</param>
    // <param name="__error_callback" type="function">call back for error</param>
    // <param name="__call_context" type="object">option object, will be passed to callbacks</param>
    call: function (isPost, base, func, request, __success_callback, __error_callback, __call_context) {
        var obj = this.__createQueueObject(isPost, base, func, request, __success_callback, __error_callback, __call_context);
        this.__execute_nonBlocking(obj);
    },

    // <summary>Entry point for standart call =with JSON param transfer</summary>
    // <param name="base" type="string">Service URL</param>
    // <param name="request" type="Hashtable">request pairs key=value</param>
    // <param name="__success_callback" type="function">call back for successeful call</param>
    // <param name="__error_callback" type="function">call back for error</param>
    // <param name="__call_context" type="object">option object, will be passed to callbacks</param>
    callJSON: function (base, func, request, __success_callback, __error_callback, __call_context) {
        var obj = this.__createQueueObjectJSON(base, func, request, __success_callback, __error_callback, __call_context);
        this.__execute_nonBlocking(obj);
    },

    // <summary>Entry point for fast call</summary>
    // <param name="isPost" type="bool">Is this post call</param>
    // <param name="base" type="string">Service URL</param>
    // <param name="request" type="Hashtable">request pairs key=value</param>
    // <param name="__success_callback" type="function">call back for successeful call</param>
    // <param name="__error_callback" type="function">call back for error</param>
    // <param name="__call_context" type="object">option object, will be passed to callbacks</param>
    callShort: function (isPost, base, func, request, __success_callback, __error_callback, __call_context) {
        var obj = this.__createQueueObject(isPost, base, func, request, __success_callback, __error_callback, __call_context);
        this.__execute_nonBlocking(obj);
    },

    // <summary>Entry point for fast call with JSON param transfer</summary>
    // <param name="base" type="string">Service URL</param>
    // <param name="request" type="Hashtable">request pairs key=value</param>
    // <param name="__success_callback" type="function">call back for successeful call</param>
    // <param name="__error_callback" type="function">call back for error</param>
    // <param name="__call_context" type="object">option object, will be passed to callbacks</param>
    callShortJSON: function (base, func, request, __success_callback, __error_callback, __call_context) {
        var obj = this.__createQueueObjectJSON(base, func, request, __success_callback, __error_callback, __call_context);
        this.__execute_nonBlocking(obj);
    },

    // <summary>Entry point for fast call</summary>
    // <param name="isPost" type="bool">Is this post call</param>
    // <param name="base" type="string">Service URL</param>
    // <param name="request" type="Hashtable">request pairs key=value</param>
    // <returns>String, returned by server</returns>
    // <throws>Error, if something goes wrong</throws>
    callBlock: function (isPost, base, func, request) {
        var obj = this.__createQueueObject(isPost, base, func, request);
        try {
            var res = this.__execute_blocking(obj);

            return res;
        }
        catch (ex) {
            throw ex;
        }
    },

    // <summary>Entry point for fast call</summary>
    // <param name="isPost" type="bool">Is this post call</param>
    // <param name="base" type="string">Service URL</param>
    // <param name="request" type="Hashtable">request pairs key=value</param>
    // <returns>String, returned by server</returns>
    // <throws>Error, if something goes wrong</throws>
    callBlockJSON: function (isPost, base, func, request) {
        var obj = this.__createQueueObjectJSON(base, func, request);
        try {
            var res = this.__execute_blocking(obj);

            return res;
        }
        catch (ex) {
            throw ex;
        }
    },

    /*********************************************** REQUEST OBJECTS ********************************************************/

    // System call - Creates object for queue
    __createQueueObject: function (isPost, base, func, request, __success_callback, __error_callback, __call_context) {
        return ({
            URL: base + "/" + func + (isPost ? "" : "?" + this.__createRequestString(request)),
            isPost: (isPost),
            body: (isPost ? this.__createRequestString(request) : null),
            sCB: __success_callback,
            eCB: __error_callback,
            con: __call_context
        });
    },

    // System call - Creates quesry string or post body from request object
    __createRequestString: function (request) {
        var res = [];
        for (var key in request) {
            var val = request[key];
            var type = typeof (val);
            if (val != null && (type == "object" || val.constructor == Array)) val = JSON.stringify(val);
            res.push(key + "=" + encodeURIComponent(val));
        }
        return res.join("&");
    },

    __createQueueObjectJSON: function (base, func, request, __success_callback, __error_callback, __call_context) {
        return ({
            URL: base + "/" + func,
            isPost: true,
            body: this.__createRequestJSON(request),
            sCB: __success_callback,
            eCB: __error_callback,
            con: __call_context
        });
    },

    __createRequestJSON: function (request) {
        return JSON.stringify(request);
    },

    /************************************************** CALLS ****************************************************************/

    // Executes blocking call
    // <param name="req" type="Object">Queue object, created by __createQueueObject</param>
    __execute_blocking: function (req) {
        var xmlHttp = __createXmlHttp();

        xmlHttp.open((req.isPost) ? "POST" : "GET", req.URL, false);
        xmlHttp.setRequestHeader("RequestTarget", "AJAXService");                               // By this header AJAX core discerns service calls
        xmlHttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xmlHttp.setRequestHeader("X-JWToken", JWT_TOKEN);

        if ((req.isPost) && (req.body))
            xmlHttp.send(req.body);
        else
            xmlHttp.send(null);

        var Status = xmlHttp.status;
        if ((Status != 200) && (Status != 304)) {
            throw xmlHttp.responseText;
        }

        return xmlHttp.responseText;
    },

    // Exexutes nonblocking call
    // <param name="req" type="Object">Queue object, created by __createQueueObject</param
    __execute_nonBlocking: function (req) {
        var xmlHttp = __createXmlHttp();

        xmlHttp.open((req.isPost) ? "POST" : "GET", req.URL, true);
        xmlHttp.setRequestHeader("RequestTarget", "AJAXService");                                // By this header AJAX core discerns service calls
        xmlHttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xmlHttp.setRequestHeader("X-JWToken", JWT_TOKEN);

        xmlHttp.onreadystatechange = function () {                                               // Standard way for wainitng of result callback
            if (xmlHttp.readyState != 4) return;                                                  // when 4 - result is ready
            var xmlHttpStatus = xmlHttp.status;

            if (xmlHttpStatus == 200 || xmlHttpStatus == 304) {                                 // checking status code                            // at first

                var res = xmlHttp.responseText;                                                   // Calling callback
                if (req.sCB) req.sCB(res, req.con);
            }
            else {                                                                                // All the same but with error callback
                var res = xmlHttp.responseText;
                var needCallErrorCallback = (xmlHttpStatus > 0) &&                                 //For cancelled requests Chrome returns status 0. We dont need to call error callback at this situation (see EC-10580).
                    (xmlHttpStatus != 206) &&
                    !!req.eCB;
                if (needCallErrorCallback) req.eCB(res, req.con);
            }
        }

        xmlHttp.onerror = function()
        {
            req.eCB(xmlHttp.responseText, req.con);
        };
        xmlHttp.ontimeout = function()
        {
            req.eCB(xmlHttp.responseText, req.con);
        };

        // making the call
        if ((req.isPost) && (req.body))
            xmlHttp.send(req.body);
        else
            xmlHttp.send(null);

    }
}

/******************************************************** SUBSTITUTERS ****************************************************************/

// !!! This is replacement function for JSIclude functionality - it makes unblocking call through the 
// !!! ajax system instead of making call old manner.
// 
// Performs non blocking, no return waiting ajax call to the url. 
//    URL      - URL of script to call
//    isPost   - [optional] is this post request
//    postbody - [optional] body for post request (XmlDocument or string)
function __jsInclude__nonblockingAJAXCall(URL, isPost, postbody) {
    AJAX.__execute_nonBlocking({ URL: application_root + URL, isPost: isPost, body: isPost ? postbody : null });
}

// !!! This is replacement function for JSIclude functionality - it copies JSIclude behaviour, but increases
// !!! blocking counter in AJAX system. 
//
// Performs blocking ajax call to the url. Returns object like { bool status, string statusText,  string text }
//    URL      - URL of script to call
//    isPost   - [optional] is this post request
//    postbody - [optional] body for post request (XmlDocument or string)
function __jsInclude__blockingAJAXCall(URL, isPost, postbody) {
    var xmlHttp = __createXmlHttp();

    var vURL = (URL.length > 6) && (URL.substr(0, 5) == "http:" || URL.substr(0, 6) == "https:") ? URL : application_root + URL;
    xmlHttp.open((isPost) ? "POST" : "GET", vURL, false);
    xmlHttp.setRequestHeader("RequestTarget", "XmlService");
    xmlHttp.setRequestHeader("X-JWToken", JWT_TOKEN);

    if ((isPost) && (postbody))
        xmlHttp.send(postbody);
    else
        xmlHttp.send(null);

    var Status = xmlHttp.status;
    if (Status != 200) {
        return { status: Status, statusText: xmlHttp.statusText, text: xmlHttp.responseText };
    }

    return { status: 200, statusText: 'OK', text: xmlHttp.responseText };
}


// !!! This is replacement function for StringEx functionality - it copies StringEx behaviour, but increases
// !!! blocking counter in AJAX system. 
//
/// Performs blocking ajax call to the url. Returns object like { bool status, string statusText,  string text }
///    URL      - URL of script to call
///    isPost   - [optional] is this post request
///    postbody - [optional] body for post request (XmlDocument or string)
function __blockingAJAXCall(URL, isPost, postbody) {
    var xmlHttp = __createXmlHttp();

    xmlHttp.open((isPost) ? "POST" : "GET", application_root + URL, false);
    xmlHttp.setRequestHeader("RequestTarget", "XmlService");
    xmlHttp.setRequestHeader("JWT_TOKEN", JWT_TOKEN);

    if ((isPost) && (postbody))
        xmlHttp.send(postbody);
    else
        xmlHttp.send(null);

    var Status = xmlHttp.status;
    if (Status != 200) {
        return { status: Status, statusText: xmlHttp.statusText, text: xmlHttp.responseText };
    }

    return { status: 200, statusText: 'OK', text: xmlHttp.responseText };
}

window.show_lock_errors = show_lock_errors;
window.AJAX = AJAX;
window.__jsInclude__nonblockingAJAXCall = __jsInclude__nonblockingAJAXCall;
window.__jsInclude__blockingAJAXCall = __jsInclude__blockingAJAXCall
window.__blockingAJAXCall = __blockingAJAXCall;
