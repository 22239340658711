/* exported UISCarousel */
/* globals Application, debounce */

var UISCarousel = {
    defaultSettings: {
        containerID: '', // REQUIRED: string value
        classes: [], // OPTIONAL: array of strings
        items: [], // REQUIRED: array of objects
        step: 120, // OPTIONAL: integer value
        hasPages: false, // OPTIONAL: boolean value. Works only with slides that have fixed width
        hasDelay: false, // only used in cases, where we need to draw the carousel containing panel before the other panels are still drawn
        useMouseWheel: true,
        isDraggable: false,
        hasArrows: true,
        parent: {}
    },
    settings: null,
    counter: 0,
    container: null, // the HTML element that the carousel will be appended to
    carouselEl: null, // .scarousel
    listWrapper: null, //.scarousel-list-wrapper
    list: null, //ul.scarousel-list
    activeItem: null, // .scarousel-item.active
    prevBtn: null,
    nextBtn: null,
    pagination: null,
    navigationT: null,
    currentPage: 0,
    pagesCount: 0,
    translateX: 0,
    initialized: false,
    scrollTicking: false,
    stepScroll: 120,
    drag: {
        isGrabbing: false,
        grabbingClass: 'is-grabbing',
        sensitiveDelta: 10, // required to distinguish between drag and click events
        start: 0,
        delta: 0
    },

    init: function (userSettings) {
        if (this.initialized) {
            this.destroy();
        }

        this.settings = this.setParams(userSettings);

        this.container = document.getElementById(this.settings.containerID);
        if (this.container === null) {
            console.log('Cannot get carousel container!');
            return this.container;
        }
        this.container.appendChild(this.buildCarousel());
        
        Application.winResizeEvent.addCallback({
            name: 'detectMode',
            func: this.detectMode,
            context: this
        });
        this.detectMode();

        // This is required because we are displaying Left and Right blocks with a delay.
        // Without this time out the super banner block takes the whole width on init
        // and navigation calculations return wrong results. 
        if (this.settings.hasDelay && !this.navigationT) {
            this.navigationT = setTimeout(this.updateNavigation.bind(this), 200);
        }

        this.initialized = true;

        return this.carouselEl;
    },

    destroy: function () {
        if (!this.initialized) { return; }
        this.detachEventListeners();
        try
        {
            this.container.removeChild(this.carouselEl);
        } catch (e)
        {
            // nothing to catch
        }
        this.translateX = 0;
        Application.winResizeEvent.removeCallback('detectMode');
        clearTimeout(this.navigationT);
        this.navigationT = null;
        this.paginationT = null;
        this.pagination = null;
        this.initialized = false;

        return this.carouselEl;
    },

    removeChildren: function (parent) {
        while (parent.firstChild) {
            parent.removeChild(parent.firstChild);
        }

        return parent;
    },

    updateList: function (items) {
        this.listWrapper.removeChild(this.list);
        this.listWrapper.appendChild(this.buildList(items));
        this.reposition(true);

        return this.list;
    },

    removeItems: function (indices) {
        var len = indices.length;
        var itemsList = this.list.querySelectorAll('.scarousel-item');

        for (var i = 0; i < len; i += 1) {
            this.settings.items.splice(indices[i], 1);
            itemsList[indices[i]] && this.list.removeChild(itemsList[indices[i]]);
        }
        this.toggleBtns();

        return this.list;
    },

    reposition: function (repainted) {
        this.toggleBtns();

        this.settings.hasPages && this.updateNavigation();
        this.settings.isDraggable && this.toggleGrabbing();

        this.list.classList.remove('scarousel-animate');

        if (this.list.clientWidth > this.listWrapper.clientWidth) {
            this.prev(!repainted? this.listWrapper.clientWidth - this.list.clientWidth - this.translateX : 0);
        } else {
            this.prev(this.list.clientWidth - this.translateX);
        }


        this.list.classList.add('scarousel-animate');

        return this.list;
    },

    setParams: function (userSettings) {
        if (typeof userSettings !== 'object') {
            // If none or invalid user settings, apply defaultSettings
            userSettings = this.defaultSettings;
        } else {
            for (var key in this.defaultSettings) {
                if (!userSettings.hasOwnProperty(key) || typeof this.defaultSettings[key] !== typeof userSettings[key]) {
                    userSettings[key] = this.defaultSettings[key];
                }
            }
        }
        // Set user Params
        return userSettings;
    },

    setActiveItem: function (itemIndex) {
        var items = this.list.getElementsByClassName('scarousel-item'),
            activeItem = items[itemIndex],
            len = items.length;

        for (var i = 0; i < len; i += 1) {
            items[i].classList.remove('scarousel-active');
        }

        if (activeItem) {
            activeItem.classList.add('scarousel-active');
        }

        this.activeItem = activeItem;
        return activeItem;
    },

    buildCarousel: function () {
        this.counter += 1;
        this.carouselEl = document.createElement('div');
        this.addId(this.carouselEl, this.counter, 'scarousel');
        this.carouselEl.className = 'scarousel ' + this.settings.classes.join(' ') + (!this.settings.hasArrows ? ' scarousel-no-arrows' : '');
        // data-uat attribute for automation
        this.carouselEl.setAttribute('data-uat', 'scarousel');
        this.carouselEl.appendChild(this.buildListWrapper(this.settings.items));
        this.carouselEl.appendChild(this.buildPrevBtn());
        this.carouselEl.appendChild(this.buildNextBtn());

        this.settings.hasPages && this.carouselEl.appendChild(this.buildPaginationWrapper(this.settings.items));

        return this.carouselEl;
    },

    buildListWrapper: function (items) {
        this.listWrapper = document.createElement('div');
        this.listWrapper.classList.add('scarousel-list-wrapper');

        var list = this.buildList(items);

        this.listWrapper.appendChild(list);
        return this.listWrapper;
    },

    buildList: function (items) {
        this.list = document.createElement('ul');
        this.list.className = 'scarousel-list scarousel-animate';

        var len = items.length;

        for (var item = 0; item < len; item += 1) {
            this.list.appendChild(this.buildListItem(items[item].content));
        }

        return this.list;
    },

    buildPaginationWrapper: function (items) {
        this.pagination = document.createElement('div');
        this.pagination.className = 'scarousel-pagination';

        return this.pagination;
    },

    buildPaginationBtn: function (idx) {
        var cl = 'scarousel-pagination-btn' + (idx === this.currentPage ? ' active' : '');
        var btn = document.createElement('button');
        btn.className = cl;
        btn.id = this.settings.containerID + '-pager-' + idx;
        btn.setAttribute('data-page-index', idx);

        return btn;
    },

    updateNavigation: function () {

        if (this.settings.hasPages) 
        {
            var invisibleSpace = this.list.clientWidth - this.listWrapper.clientWidth;
            this.pagesCount = Math.ceil(invisibleSpace / this.settings.step) + 1;
            var fragment = document.createDocumentFragment();
        
            for (var i = 0; i < this.pagesCount && this.pagesCount > 1; i += 1) {
                fragment.appendChild(this.buildPaginationBtn(i));
            }
            this.removeChildren(this.pagination).appendChild(fragment);
            this.translateX <= -invisibleSpace && this.updateActivePage(this.pagesCount - 1);
        }
        
        this.toggleBtns();

        return this.pagesCount;
    },

    updateActivePage: function (index) {
        var pagerActiveClass = 'active';
        var currentPageBtn = this.pagination.getElementsByClassName(pagerActiveClass)[0];        
        currentPageBtn && currentPageBtn.classList.remove(pagerActiveClass);
        this.currentPage = index;
        this.currentPage = Math.max(Math.min(this.currentPage, this.pagesCount - 1), 0); // 0 <= this.currentPage < this.pagesCount
        var newPageBtn = document.getElementById(this.settings.containerID + '-pager-' + this.currentPage);
        newPageBtn && newPageBtn.classList.add(pagerActiveClass);
        return newPageBtn;
    },

    paginationClick: function (e) {
        var target = e.target;
        if (target.tagName !== 'BUTTON') { return; }
        var index = target.getAttribute('data-page-index');
        this.paginationMove(index);
    },

    paginationMove: function (index) {
        var direction = index < this.currentPage ? 'prev' : 'next';
        this.move(direction, index * this.settings.step);
        this.updateActivePage(index);
    },

    updateListItem: function (itemIndex, content)
    {
        var items = this.list.getElementsByClassName('scarousel-item'),
            itemToBeUpdated = items[itemIndex];
        this.list.replaceChild(this.buildListItem(content), itemToBeUpdated);
        this.settings.isDraggable && this.toggleGrabbing();
    },

    buildListItem: function (content) {
        var li = document.createElement('li');
        li.classList.add('scarousel-item');
        // data-uat attribute for automation
        li.setAttribute('data-uat', 'scarousel-item');
        if (content) {
            li.innerHTML = content;
        }

        return li;
    },

    buildPrevBtn: function () {
        this.prevBtn = document.createElement('div');
        this.prevBtn.className = 'scarousel-btn scarousel-btn-prev';
        // data-uat attribute for automation
        this.prevBtn.setAttribute('data-uat', 'scarousel-btn-prev');

        var btn = document.createElement('div');
        btn.className = 'scarousel-btn-el';

        var icon = document.createElement('div');
        icon.className = 'scarousel-btn-prev-icon';
        btn.appendChild(icon);

        this.prevBtn.appendChild(btn);

        return this.prevBtn;
    },

    buildNextBtn: function () {
        this.nextBtn = document.createElement('div');
        this.nextBtn.className = 'scarousel-btn scarousel-btn-next';
        // data-uat attribute for automation
        this.nextBtn.setAttribute('data-uat', 'scarousel-btn-next');

        var btn = document.createElement('div');
        btn.className = 'scarousel-btn-el';

        var icon = document.createElement('div');
        icon.className = 'scarousel-btn-next-icon';
        btn.appendChild(icon);

        this.nextBtn.appendChild(btn);

        return this.nextBtn;
    },

    prev: function (_delta) {
        var delta = (typeof _delta === 'number') ? _delta : this.settings.step;
        
        if (this.translateX < 0) {
            this.translateX = (this.translateX + delta <= 0) ? this.translateX + delta : 0; //don't go beyond 0
            this.transformList(this.translateX);
            this.toggleBtns();
            this.settings.hasPages && this.updateActivePage(this.currentPage - 1);
        }
        this.scrollTicking = false;
    },

    next: function (_delta) {
        var delta = (typeof _delta === 'number') ? _delta : this.settings.step;
        var listPos = this.list.clientWidth + this.translateX;

        if (listPos >= this.listWrapper.clientWidth) {
            this.translateX = (listPos - delta > this.listWrapper.clientWidth) ? this.translateX - delta : (this.listWrapper.clientWidth - this.list.clientWidth); //don't go beyond last item
            this.transformList(this.translateX);
            this.toggleBtns();
            this.settings.hasPages && this.updateActivePage(this.currentPage + 1);
        }
        this.scrollTicking = false;
    },

    move: function (direction, _delta) {
        var delta = Math.abs(_delta + this.translateX);

        typeof this[direction] === 'function' && this[direction](delta);
    },

    prevScroll: function (delta) {
        this.prev(this.stepScroll);
    },

    nextScroll: function (delta) {
        this.next(this.stepScroll);
    },

    toggleBtns: function () {
        var nextBtnInactive = this.list.clientWidth + this.translateX * 1 <= this.listWrapper.clientWidth;
        var prevBtnInactive = this.translateX >= 0;

        this.nextBtn.classList[nextBtnInactive? 'add' : 'remove']('scarousel-btn-inactive');
        this.prevBtn.classList[prevBtnInactive? 'add' : 'remove']('scarousel-btn-inactive');
        this.listWrapper.classList[prevBtnInactive && nextBtnInactive ? 'add' : 'remove']('scarousel-static');

    },

    scrollAnim: function (e) {
        if (this.list.clientWidth <= this.listWrapper.clientWidth) {
            return false;
        }

        e.preventDefault();

        if (this.scrollTicking === false && Application.deviceType.isDesktop()) {
            if (e.deltaY < 0) {
                window.requestAnimationFrame(this.prevScroll.bind(this));
            } else {
                window.requestAnimationFrame(this.nextScroll.bind(this));
            }
        }
        this.scrollTicking = true;
    },

    attachEventListeners: function () {
        this.scrollAnimRef = this.scrollAnim.bind(this);
        this.prevRef = this.prev.bind(this);
        this.nextRef = this.next.bind(this);
        this.paginationClickRef = this.paginationClick.bind(this);
        this.repositionRef = debounce(this.reposition.bind(this), 20);

        this.settings.useMouseWheel && this.listWrapper.addEventListener('wheel', this.scrollAnimRef);
        this.prevBtn.addEventListener('click', this.prevRef);
        this.nextBtn.addEventListener('click', this.nextRef);
        this.pagination && this.pagination.addEventListener('click', this.paginationClickRef);
        this.settings.isDraggable && this.toggleGrabbing();
        window.addEventListener('resize', this.repositionRef);
    },

    detachEventListeners: function () {
        // TODO: Check why removing event listeners is NOT working

        this.settings && this.settings.useMouseWheel && this.listWrapper.removeEventListener('wheel', this.scrollAnimRef);
        this.prevBtn && this.prevBtn.removeEventListener('click', this.prevRef);
        this.nextBtn && this.nextBtn.removeEventListener('click', this.nextRef);
        this.pagination && this.pagination.removeEventListener('click', this.paginationClickRef);
        this.settings && this.settings.isDraggable && this.toggleGrabbing();
        window.removeEventListener('resize', this.repositionRef);
    },

    handleEvent: function (e) {
        typeof this[e.type] === 'function' && this[e.type](e);
    },

    toggleGrabbing: function () {
        if (this.list.clientWidth > this.listWrapper.clientWidth) {
            this.carouselEl.classList.add('scarousel-draggable');
            this.listWrapper.addEventListener('mousedown', this);
        } else {
            this.carouselEl.classList.remove('scarousel-draggable');
            this.listWrapper.removeEventListener('mousedown', this);
        }
    },

    mousedown: function (e) {
        e.preventDefault();

        this.drag.start = e.pageX;
        
        this.listWrapper.addEventListener('mouseup', this);
        this.listWrapper.addEventListener('mousemove', this);
        this.listWrapper.addEventListener('mouseleave', this);
    },

    mousemove: function (e) {
        e.preventDefault();

        this.drag.delta = e.pageX - this.drag.start;
        
        if (Math.abs(this.drag.delta) > this.drag.sensitiveDelta) {
            this.drag.isGrabbing = true;
            this.list.classList.remove('scarousel-animate');
            this.list.classList.add(this.drag.grabbingClass);
            this.transformList(this.translateX + this.drag.delta);
        }
    },

    mouseup: function (e) {
        e.preventDefault();
        
        this.list.classList.remove(this.drag.grabbingClass);
        this.list.classList.add('scarousel-animate');

        if (this.settings.hasPages && this.drag.isGrabbing) {
            var index = Math.max(Math.min(Math.round(-(this.translateX + this.drag.delta) / this.settings.step), this.pagesCount - 1), 0);
            this.paginationMove(index);
        } else {
            this.translateX = Math.min(Math.max(this.translateX + this.drag.delta, this.listWrapper.clientWidth - this.list.clientWidth), 0);
            this.transformList(this.translateX);
        }
        
        this.drag.start = 0;
        this.drag.delta = 0;
        this.drag.isGrabbing = false;
        
        this.listWrapper.removeEventListener('mouseleave', this);
        this.listWrapper.removeEventListener('mousemove', this);
        this.listWrapper.removeEventListener('mouseup', this);
    },

    mouseleave: function (e) {
        this.mouseup(e);
    },

    transformList: function(val)
    {
        this.list.style.transform = 'translate3d(' + val + 'px,0,0)';
    },

    addId: function (element, counter, defaultClass) {
        element.id = defaultClass + '-' + counter;
        return element.id;
    },

    detectMode: function () {
        if (Application.deviceType.isMobile() || Application.deviceType.isTablet()) {
            return this.adjustToMobile();
        } else if (Application.deviceType.isDesktop()) {
            return this.adjustToDesktop();
        } else {
            return Application.deviceType;
        }
    },

    adjustToMobile: function () {
        this.carouselEl.classList.add('scarousel-mobile');
        this.detachEventListeners();
        this.translateX = 0;
        this.transformList(this.translateX);
        this.revealActiveTab();
    },

    adjustToDesktop: function () {
        this.carouselEl.classList.remove('scarousel-mobile');
        this.currentPage = 0;
        this.translateX = 0;
        this.transformList(this.translateX);
        this.listWrapper.scrollLeft = 0;
        this.toggleBtns();
        this.attachEventListeners();
    },

    revealActiveTab: function()
    {
        if (!Application.deviceType.isDesktop() && this.activeItem) {
            this.activeItem.closest('.scarousel-list-wrapper').scrollLeft = this.activeItem.offsetLeft;
        }
    }
};

window.UISCarousel = UISCarousel;