﻿/* exported PushOddsMessageHandler */
/* globals PushBaseMessageHandler, isAsianView, Seo4Ajax */

var PushOddsMessageHandler = (function (_super)
{
    var instance;

    function getInstance()
    {
        if (!instance)
        {
            instance = new PushOddsMessageHandler();
        }

        return instance;
    }

    function PushOddsMessageHandler()
    {
        _super.apply(this, arguments);
        this.EventsToUpdate = [];
        this.IsUpdateRunning = false;
    }

    $.extend(PushOddsMessageHandler.prototype, _super.prototype);

    PushOddsMessageHandler.prototype.addEventForUpdate = function (event)
    {
        if (!this.EventsToUpdate[event.ID])
        {
            this.EventsToUpdate[event.ID] = event;
        }
    };

    PushOddsMessageHandler.prototype.appendGuidId = function (messageId, updateArray)
    {
        if (typeof messageId !== 'undefined')
        {
            updateArray.push('GUID' + messageId);
        }

        return updateArray;
    };

    PushOddsMessageHandler.prototype.clearDebounceEvents = function (subscriberKey)
    {
        if (this.Subscribers.hasOwnProperty(subscriberKey))
        {
            this.Subscribers[subscriberKey].EventsForUpdate = [];
        }
    };

    PushOddsMessageHandler.prototype.intersectBlockEvents = function (subscriberEvents, updatedEventsArr)
    {
        var toReturn = [],
            subscriberEventKeys = Object.keys(subscriberEvents),
            subscriberEvtsKeysLength = subscriberEventKeys.length,
            subscribedEvIndex,
            subscribedEv = null,
            updatedEventKeys = Object.keys(updatedEventsArr),
            updatedEvtsKeysLength = updatedEventKeys.length,
            updatedEvIndex,
            updatedEv = null,
            updatedEventsHashTable = [];

        for (var updatedEventKey = 0; updatedEventKey < updatedEvtsKeysLength; updatedEventKey += 1)
        {
            updatedEvIndex = updatedEventKeys[updatedEventKey];
            updatedEv = updatedEventsArr[updatedEvIndex];
            updatedEventsHashTable[updatedEv[0]] = updatedEv;
        }

        for (var subscriberEventKey = 0; subscriberEventKey < subscriberEvtsKeysLength; subscriberEventKey += 1)
        {
            subscribedEvIndex = subscriberEventKeys[subscriberEventKey];
            subscribedEv = subscriberEvents[subscribedEvIndex];

            if (updatedEventsHashTable[subscribedEv.ID])
            {
                toReturn.push(updatedEventsHashTable[subscribedEv.ID]);
            }
        }

        return toReturn;
    };

    PushOddsMessageHandler.prototype.intersectEventsToUpdate = function (events, updatedEventsIds, isBetSlip, eventsForRedraw)
    {
        var result = [],
            evtsKeys = Object.keys(events),
            evtsKeysLength = evtsKeys.length,
            currentEvent;

        for (var key = 0; key < evtsKeysLength; key += 1)
        {
            currentEvent = events[evtsKeys[key]];
            if (updatedEventsIds[currentEvent.ID])
            {
                result[evtsKeys[key]] = currentEvent;

                if (isBetSlip)
                {
                    eventsForRedraw[evtsKeys[key]] = currentEvent;
                    continue;
                }

                this.addEventForUpdate(currentEvent);
            }
        }

        return result;
    };

    PushOddsMessageHandler.prototype.parseCustomParameters = function (subscribedItemKey, customHandlerParameters)
    {
        this.Subscribers[subscribedItemKey]['SubscriberEvents'] = customHandlerParameters.SubscriberEvents;
        this.Subscribers[subscribedItemKey]['HasUpdates'] = false;
        this.Subscribers[subscribedItemKey]['EventsForUpdate'] = [];
        this.Subscribers[subscribedItemKey]['UseCustomUpdateHandler'] = customHandlerParameters.UseCustomUpdateHandler;
    };

    PushOddsMessageHandler.prototype.processMessage = function (message)
    {
        var idKeys = Object.keys(message.EventIDs),
            subscribersKeys = Object.keys(this.Subscribers),
            subscriberIndex,
            blockEvts = [],
            currentSubscriber = null;

        for (var i = 0; i < idKeys.length; i += 1)
        {
            message.EventIDs[message.EventIDs[i]] = message.EventIDs[i];
            delete message.EventIDs[i];
        }

        for (var key = 0; key < subscribersKeys.length; key += 1)
        {
            subscriberIndex = subscribersKeys[key];
            currentSubscriber = this.Subscribers[subscriberIndex] || null;
            if (currentSubscriber !== null && currentSubscriber.SubscriberEvents)
            {
                blockEvts = this.intersectEventsToUpdate(currentSubscriber.SubscriberEvents, message.EventIDs, currentSubscriber.IsBetSlip,
                                                                    currentSubscriber.EventsForUpdate);

                if (Array.getLength(blockEvts) > 0 || currentSubscriber.HasUpdates)
                {
                    if (currentSubscriber.UseCustomUpdateHandler)
                    {
                        currentSubscriber.subscriberHandleFunction(currentSubscriber.EventsForUpdate, subscriberIndex);
                        continue;
                    }

                    currentSubscriber.HasUpdates = true;
                }
            }
        }

        if (this.EventsToUpdate.length === 0 || this.IsUpdateRunning)
        {
            return;
        }

        this.updatePushEvents(this.EventsToUpdate, message.MessageId);
    };

    PushOddsMessageHandler.prototype.updateBlockEvents = function (data)
    {
        var subscribersKeys = Object.keys(this.Subscribers),
            subscriberIndex,
            currentSubscriber = null;

        for (var key = 0; key < subscribersKeys.length; key += 1)
        {
            subscriberIndex = subscribersKeys[key];
            currentSubscriber = this.Subscribers[subscriberIndex] || null;
            if (currentSubscriber !== null && currentSubscriber.HasUpdates)
            {
                var updatedData = this.intersectBlockEvents(currentSubscriber.SubscriberEvents, data);
                currentSubscriber.EventsForUpdate = currentSubscriber.EventsForUpdate.concat(updatedData);
                currentSubscriber.HasUpdates = false;
                if (currentSubscriber.EventsForUpdate.length > 0)
                {
                    currentSubscriber.subscriberHandleFunction(currentSubscriber.EventsForUpdate, subscriberIndex);
                }
            }
        }
    };

    PushOddsMessageHandler.prototype.updateEvents = function (evtsToUpdate)
    {
        if (typeof (Seo4Ajax) !== 'undefined' && Seo4Ajax.isSeo4AjaxRequest())
        {
            return;
        }

        var ref = this;
        PageMethods.UpdateEvents(
            evtsToUpdate.join('@'),

             function (result)
             {
                ref.EventsToUpdate = [];
                ref.IsUpdateRunning = false;

                /* jshint evil: true */
                var updatedData = eval(result);
                /* jshint evil: false */

                ref.updateBlockEvents(updatedData);
             },

             function ()
             {
                ref.IsUpdateRunning = false;
             }
        );
    };

    PushOddsMessageHandler.prototype.updateEventsForAsian = function (evtsToUpdate)
    {
        if (typeof (Seo4Ajax) !== 'undefined' && Seo4Ajax.isSeo4AjaxRequest()) {
            return;
        }

        var ref = this;
        PageMethods.UpdateAsianSkinEvents(
                evtsToUpdate.join('@'),

                 function (result)
                 {
                    ref.EventsToUpdate = [];
                    ref.IsUpdateRunning = false;

                     /* jshint evil: true */
                    var updatedData = eval(result);
                     /* jshint evil: false */

                    ref.updateBlockEvents(updatedData);
                 },

                 function ()
                 {
                    ref.IsUpdateRunning = false;
                 }
            );
    };

    PushOddsMessageHandler.prototype.updatePushEvents = function (events, messageId)
    {
        var evtsToUpdate = [];

        for (var idx in events)
        {
            if (events.hasOwnProperty(idx))
            {
                var evt = events[idx];
                if (evt && typeof evt.getUpdateString === "function")
                {
                    evtsToUpdate.push(evt.getUpdateString());
                }
            }
        }
      	evtsToUpdate = evtsToUpdate.filter(function(evUpdateStr) {
            return !!evUpdateStr;
        });
        if (!evtsToUpdate.length) return;
        
        this.IsUpdateRunning = true;
        evtsToUpdate = this.appendGuidId(messageId, evtsToUpdate);
        if (isAsianView)
        {
            this.updateEventsForAsian(evtsToUpdate);
        }
        else
        {
            this.updateEvents(evtsToUpdate);
        }
    };

    return getInstance();
}(PushBaseMessageHandler));

window.PushOddsMessageHandler = PushOddsMessageHandler;
