﻿var SogeiPlugin = (function (Communicator)
{
    'use strict';

    var version = 1;

    function init(pushServerConnectionPointUrl)
    {
        Communicator.Start(pushServerConnectionPointUrl, 'PushSogei');
    }

    function sendMessageToPush(messageType, messageParams)
    {
        Communicator.SendMessage(messageType, JSON.stringify(messageParams));

    }
    return {
        Version: version,
        Run: init,
        sendMessageToPush: sendMessageToPush
    }
})(Communicator);

window.SogeiPlugin = SogeiPlugin;