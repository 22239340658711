﻿// JScript File
function ArrayOf(initArray, objectFunc)
{
    var res = [];

    if (initArray)
    {
        for (var i = 0; i < initArray.length; i++)
        {
            //for (var item in initArray) {
            res.push(new objectFunc(initArray[i]));
            //res.push(new objectFunc(item));
        }
    }

    return res;
}

function HashtableOf(initArray, objectFunc, idIndex)
{
    var res = [];

    if (typeof idIndex == "undefined")
        idIndex = 0;

    if (initArray)
    {
        for (var i in initArray)
        {
            if (initArray[i])
                res[parseInt(initArray[i][idIndex])] =
                    initArray[i] instanceof objectFunc ? initArray[i] : new objectFunc(initArray[i]);
        }
    }

    return res;
}


function HashtableOfWithParam(initArray, objectFunc, idIndex, param)
{
    var res = [];

    if (typeof idIndex == "undefined")
        idIndex = 0;

    if (initArray)
    {
        for (var i in initArray)
        {
            if (initArray[i])
                res[parseInt(initArray[i][idIndex])] = new objectFunc(initArray[i], param);
        }
    }

    return res;
}

function ArrayOfFunc(initArray, objectFunc)
{
    var res = [];

    if (initArray)
    {
        for (var i = 0; i < initArray.length; i++)
        {
            res.push(objectFunc(initArray[i]));
        }
    }

    return res;
}

HashtableOf.indexOf = function (hashtable, key)
{
    var i = 0;
    for (var currKey in hashtable)
    {
        if (currKey == key) return i;
        i++;
    }

    return -1;
}

Array.getValues = function (array)
{
    var res = [];
    for (var key in array) res.push(array[key]);
    return res;
}

Array.getFlatValues = function (array)
{

    var res = [];
    var keys = Array.getKeys(array);
    keys.sort();
    for (var i in keys) res.push(array[keys[i]]);
    return res;
}


Array.getKeys = function (array)
{
    var res = [];
    for (var key in array) res.push(key);
    return res;
}


Array.indexOf = function (array, what)
{
    if (typeof what == "function")
    {
        for (var key in array)
        {
            if (what(array[key])) return key;
        }
    }

    for (var key in array)
    {
        if (what == array[key]) return key;
    }

    return -1;
}

Array.findAll = function (array, filter)
{
    var res = [];
    for (var key in array)
        if (filter(array[key])) res[key] = array[key];

    return res;
}

Array.find = function (array, filter)
{
    if (filter)
    {
        for (var key in array)
            if (filter(array[key])) return array[key];
    } else
    {
        for (var key in array)
            return array[key];
    }
    return null;
}

Array.findAllValues = function (array, filter)
{
    var res = [];
    for (var key in array)
        if (filter(array[key])) res.push(array[key]);

    return res;
}

Array.removeAll = function (array, filter)
{
    for (var key in array)
        if (filter(array[key]))
            delete array[key];
}

Array.removeOneByValue = function(array, value) 
{
    var index = array.indexOf(value);
    if (index > -1) 
    {
        array.splice(index, 1);
    }
}

Array.getLength = function (array, filterOptional)
{
    var cnt = 0;
    for (var key in array)
    {
        if (!filterOptional || filterOptional(array[key]))
        {
            cnt++;
        }
    }
    return cnt;
}

Array.safeLength = function (array)
{
    var res = 0;
    for (var i in array)
    {
        if (array.hasOwnProperty(i))
        {
            res = i;
        }
    }

    return res * 1 + 1;
};

Array.last = function (array)
{
    var result = null;
    for (var key in array)
    {
        if (array.hasOwnProperty(key))
        {
            result = array[key];
        }
    }

    return result;
}

Array.first = function (array)
{
    var result = null;
    for (var key in array)
    {
        result = array[key];
        break;
    }
    return result;
};

Array.addRange = function (arrayTo, arrayFrom)
{
    for (var key in arrayFrom) arrayTo.push(arrayFrom[key]);
}

Array.addHash = function (arrayTo, arrayFrom)
{
    for (var key in arrayFrom) arrayTo[key] = arrayFrom[key];
}

Array.createHash = function (array, hash, value)
{
    for (var key in array)
    {
        hash[array[key]] = value;
    }
}

Array.isEmpty = function (array)
{
    for (var key in array)
    {
        return false;
    }

    return true;
}

Array.getLengthNotNull = function (array)
{
    var cnt = 0;
    for (var key in array)
        if (array[key] != null) cnt++;
    return cnt;
}

Array.BinarySearch = function (array, comparer)
{
    var low = 0;
    var hi = array.length;

    while (low <= hi)
    {
        var num2;
        var num1 = Array.GetMedian(low, hi);

        try
        {
            num2 = comparer(array[num1]);
        }
        catch (e)
        {
            alert(e.Message);
        }

        if (num2 == 0) // if found value
            return num1;

        if (num2 < 0)
        {
            low = num1 + 1;
            continue;
        }
        else
            hi = num1 - 1;
    }

    return ~low;
}

Array.GetMedian = function (low, hi)
{
    return (low + ((hi - low) >> 1));
}

Array.concat = function ()
{
    var res = [];
    for (var i = 0; i < arguments.length; i++)
    {
        res = res.concat(arguments[i]);
    }
    return res;
}

Array.getMinValue = function (arr, filter, getter)
{
    var filteredValues = Array.findAll(arr, filter);

    var values = Array.getValues(filteredValues.map(getter));

    var result = values.length > 0
        ? Math.min.apply(null, Array.getValues(values.map(function (val) { return val; })))
        : 0;

    return result;
}

function MergeSort(left, right, compare)
{
    var result = [];

    while (left.length > 0 || right.length > 0)
    {
        if (left.length > 0 && right.length > 0)
        {
            if (compare(left[0], right[0]) <= 0)
            {
                result.push(left[0]);
                left = left.slice(1);
            }
            else
            {
                result.push(right[0]);
                right = right.slice(1);
            }
        }
        else if (left.length > 0)
        {
            result.push(left[0]);
            left = left.slice(1);
        }
        else if (right.length > 0)
        {
            result.push(right[0]);
            right = right.slice(1);
        }
    }

    return result;
}

Array.filter = function (array, fun)
{
    'use strict';

    if (array === void 0 || array === null)
    {
        throw new TypeError();
    }

    var t = Object(array);
    var len = t.length >>> 0;
    if (typeof fun !== 'function')
    {
        throw new TypeError();
    }

    var res = [];
    var thisArg = arguments.length >= 2 ? arguments[1] : void 0;
    for (var i = 0; i < len; i++)
    {
        if (i in t)
        {
            var val = t[i];
            if (fun.call(thisArg, val, i, t))
            {
                res.push(val);
            }
        }
    }

    return res;
};

Array.mergeSort = function (array, compare)
{
    var length = array.length,
        middle = Math.floor(length / 2);

    // define default comparison function if none is defined
    if (!compare)
    {
        compare = function (left, right)
        {
            if (left < right)
            {
                return -1;
            } else if (left === right)
            {
                return 0;
            } else
            {
                return 1;
            }
        };
    }

    if (length < 2)
    {
        return array;
    }

    return MergeSort(
        Array.mergeSort(array.slice(0, middle), compare),
        Array.mergeSort(array.slice(middle, length), compare),
        compare
    );
};

function chainSort()
{
    var chain = arguments;

    return function (a, b)
    {
        for (var i = 0; i < chain.length; i++)
        {
            var p = chain[i](a, b);
            if (p != 0) return p;
        }
        return 0;
    };
}

var BetMath = new (function ()
{
    function combinations(arr, k) {
        var i, subI, ret = [], sub, next, result;
        for (i = 0; i < arr.length; i++) {
            if (k === 1) {
                ret.push([arr[i]]);
            } else {
                sub = combinations(arr.slice(i + 1, arr.length), k - 1);
                for (subI = 0; subI < sub.length; subI++) {
                    next = sub[subI];
                    next.unshift(arr[i]);
                    ret.push(next);
                }
            }
        }
        return ret;
    }

    this.sumOfProducts = function (values)
    {
        var totals = [0, 1];

        for (var i = 0; i < values.length; i++)
        {
            totals[0] = 0;
            totals[i + 2] = 0;

            for (var j = i + 2; --j;)
                totals[j + 1] = totals[j + 1] + totals[j] * values[i];
        }

        return totals.slice(1);
    };

    this.sumOfRoundedProducts = function (values, bankerOdd)
    {
        bankerOdd = bankerOdd || 1;
        var totals = [1];
        for (var n = 1; n <= values.length; n++)
        {
            totals[n] = BetMath.roundDecimalOdds(
                combinations(values, n).reduce(
                    function (previousValue, currentValue, index, array) {
                        return previousValue + BetMath.roundDecimalOdds(
                                currentValue.reduce(function (previousValue, currentValue, index, array) {
                                    return previousValue * currentValue;
                                }) * bankerOdd,
                                OddsRoundingStyle.ROUND,
                                true
                            );
                    }, 0),
                OddsRoundingStyle.ROUND,
                true
            );
        }
        return totals;
    }

    this.truncateDecimalOdds = function (odds)
    {
        return Math.floor((odds * 100).toFixed(12)) / 100;
    };

    this.roundDecimalOdds = function (odds, roundingMode, allowThirdDecimal)
    {
        if (!roundingMode)
            return odds;

        var roundFactor = 100;
        if (odds < 1.01 && allowThirdDecimal)
        {
            roundFactor = 1000;
        }
        
        switch (roundingMode)
        {
            case OddsRoundingStyle.ROUND:
                return Math.round((odds * roundFactor).toFixed(12)) / roundFactor;
            case OddsRoundingStyle.CEILING:
                return Math.ceil((odds * roundFactor).toFixed(12)) / roundFactor;
            case OddsRoundingStyle.FLOOR:
                return Math.floor((odds * roundFactor).toFixed(12)) / roundFactor;
            case OddsRoundingStyle.NONE:
            default:
                return odds;
        }
    }

    return this;
});

window.ArrayOf = ArrayOf;
window.HashtableOf = HashtableOf;
window.HashtableOfWithParam = HashtableOfWithParam;
window.ArrayOfFunc = ArrayOfFunc;
window.MergeSort = MergeSort;
window.chainSort = chainSort;
window.BetMath = BetMath;
