﻿function styleSendProfile()
{
    var styles = extractStyles();
    postAjax("/SetStyleProfile.ashx", styles);
}

function postAjax(url, postBody)
{
    var xmlHttp = __createXmlHttp();

    xmlHttp.open("POST", url, false);
    xmlHttp.setRequestHeader("RequestTarget", "XmlService");

    xmlHttp.send(postBody);
}

function loadStyle(href, callback)
{
    // avoid duplicates
    for (var i = 0; i < document.styleSheets.length; i++)
    {
        if (document.styleSheets[i].href == href)
        {
            return;
        }
    }
    var head = document.getElementsByTagName('head')[0];
    var link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = href;

    if (callback)
    {
        link.onload = function () { callback() }
    }

    head.appendChild(link);
}

// TODO: Extract Chaov's script for getting styles
function extractStyles()
{
    var usedCSS = [];
    function pushToUsed(x)
    {
        usedCSS.indexOf(x) === -1 && usedCSS.push(x);
    }

    var styleSheet = Array.prototype.filter.call(
        document.styleSheets,
        function (x)
        {
            if (typeof x.href === "string")
            {
                return x.href.match("app.css")
            }
            else
            {
                return false;
            }
        }
    );

    styleSheet = styleSheet[0] && styleSheet[0].cssRules || [];

    Array.prototype.forEach.call(
        styleSheet,
        function (x)
        {
            var y = false;
            if (document.querySelector(x.selectorText) !== null)
            {
                y = true;
            }
            else if (x.selectorText && x.selectorText.match(/sb-/gim))
            {
                y = true;
            }
            else if (x.selectorText && x.selectorText.match(/rj-/gim))
            {
                y = true;
            }
            else if (x.selectorText && x.selectorText.match(/page-header/gim))
            {
                y = true;
            }
            else if (x.selectorText && x.selectorText.match(/balance-refresh-link/gim))
            {
                y = true;
            }
            else if (x.selectorText && x.selectorText.match(/breadcrumb/gim))
            {
                y = true;
            }
            else if (x.selectorText && x.selectorText.match(/filter-label/gim))
            {
                y = true;
            }
            else if (x.selectorText && x.selectorText.match(/filter-component/gim))
            {
                y = true;
            }
            else if (x.selectorText && x.selectorText.match(/LBettingOverview/gim))
            {
                y = true;
            }
            else if (x.selectorText && x.selectorText.match(/LBettingEventView/gim))
            {
                y = true;
            }
            else if (x.selectorText && x.selectorText.match(/lbetting-tab/gim))
            {
                y = true;
            }
            else if (x.selectorText && x.selectorText.match(/side-menu-card/gim))
            {
                y = true;
            }
            else if (x.selectorText && x.selectorText.match(/sports-list/gim))
            {
                y = true;
            }
            else if (x.selectorText && x.selectorText.match(/super-banner-slide/gim))
            {
                y = true;
            }
            else if (x.selectorText && x.selectorText.match(/bet-search/gim))
            {
                y = true;
            }
            else if (x.selectorText && x.selectorText.match(/league-list/gim))
            {
                y = true;
            }
            else if (x.selectorText && x.selectorText.match(/\:/gim))
            {
                var selector = x.selectorText.split(":")[0];
                var el;
                try
                {
                    el = document.querySelector(selector);
                }
                catch (e)
                {
                    el = null;
                }

                if (selector !== "" && el !== null)
                {
                    y = true;
                }
            }
            /*
             * 1 => default -> already mapped above
             * 3 => import -> not applicable
             * 5 => font -> not applicable
             */
            else if (x.type !== 3 && x.type !== 1 && x.type !== 5)
            {
                y = true;
            }
            else if (x.selectorText === undefined)
            {
                y = true;
            }

            y && pushToUsed(x);
        }
    )

    var styles = usedCSS.reduce(function (a, b)
    {
        var c = "";
        if ('cssText' in b)
        {
            c = b.cssText
        } else
        {
            c = b.selectorText + ' {\n' + b.style.cssText + '\n}\n';
        }
        return a += c;
    }, "");

    return styles;
}

window.styleSendProfile = styleSendProfile;
window.postAjax = postAjax;
window.loadStyle = loadStyle;
window.extractStyles = extractStyles;



