// =============================================================================
// Namespace Pattern
// =============================================================================
// Namespaces help reduce the number of globals required by our programs and at
// the same time also help avoid naming collisions or excessive name prefixing.

var SBT = SBT || {};

(function ()
{
    'use strict';

    /**
     * Namespace function
     * @param {String} name Name of the namespace
     * @return {Object} Reference to the created namespace
     * @example
     * (function () {
     *     'use strict';
     *     var ns = SBT.namespace('SBT.modules.module');
     *     ns.method = function () {};
     * }());
     * // equivalent to:
     * // var SBT = SBT || {};
     * // SBT.modules = SBT.modules || {};
     * // SBT.modules.module = SBT.modules.module || {};
     * // SBT.modules.module.method = function () {};
     */
    SBT.namespace = function (name)
    {
        var parts = name.split('.'),
            parent = SBT,
            i;

        // Strip redundant leading global
        if (parts[0] === 'SBT')
        {
            parts = parts.slice(1);
        }

        for (i = 0; i < parts.length; i += 1)
        {
            // Create a property if it doesn't exist
            if (typeof parent[parts[i]] === 'undefined')
            {
                parent[parts[i]] = {};
            }
            parent = parent[parts[i]];
        }

        return parent;
    };
}());

window.SBT = SBT;