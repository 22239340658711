﻿/* exported HomePanel */
/* globals MobileCenterPanel, sbMsgBus */

function HomePanel(config)
{
    'use strict';
    NativePanelRedirection.onInit(config.defaultCode);
    MobileCenterPanel.call(this, config);
}

$.extend(HomePanel.prototype, MobileCenterPanel.prototype,
{
    activate: function ()
    {
        MobileCenterPanel.prototype.activate.call(this);
        document.body.classList.add('isHome');
    },

    deactivate: function ()
    {
        MobileCenterPanel.prototype.deactivate.call(this);
        document.body.classList.remove('isHome');
    }
});

window.HomePanel = HomePanel;