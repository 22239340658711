(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("SBTech"), require("sbInternalMsgBus"), require("sbJsExtends"));
	else if(typeof define === 'function' && define.amd)
		define("sbBffIntegration", ["SBTech", "sbInternalMsgBus", "sbJsExtends"], factory);
	else if(typeof exports === 'object')
		exports["sbBffIntegration"] = factory(require("SBTech"), require("sbInternalMsgBus"), require("sbJsExtends"));
	else
		root["sbBffIntegration"] = factory(root["SBTech"], root["sbInternalMsgBus"], root["sbJsExtends"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__22__, __WEBPACK_EXTERNAL_MODULE__62__) {
return 