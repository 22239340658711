﻿/* exported PeripheralPanelsHolder, TopPanelsHolder, BottomPanelsHolder */
function PeripheralPanelsHolder(elementID)
{
    'use strict';
    this.element = document.getElementById(elementID);
    this.Position = '';
}

$.extend(PeripheralPanelsHolder.prototype,
{
    init: function ()
    {
        'use strict';

        return this.element;
    }
});


function TopPanelsHolder(elementID)
{
    'use strict';
    PeripheralPanelsHolder.call(this, elementID);

    this.Position = PanelPosition.TOP;
}

$.extend(TopPanelsHolder.prototype, PeripheralPanelsHolder.prototype);

function BottomPanelsHolder(elementID)
{
    'use strict';
    PeripheralPanelsHolder.call(this, elementID);

    this.Position = PanelPosition.BOTTOM;
}
$.extend(BottomPanelsHolder.prototype, PeripheralPanelsHolder.prototype);

window.PeripheralPanelsHolder = PeripheralPanelsHolder;
window.TopPanelsHolder = TopPanelsHolder;
window.BottomPanelsHolder = BottomPanelsHolder;