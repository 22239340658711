function RacecardLineInfo(initArray)
{
    this.LineID = parseInt(initArray[0]);
    this.HorseName = initArray[1];
    this.SilkFile = parseInt(initArray[2]);
    this.Trainer = initArray[3];
    this.Jockey = initArray[4];
    this.Stall = parseInt(initArray[5]);
    this.EventDate = Date.fromISO(initArray[6]);
    this.HorseNumber = parseInt(initArray[7]);
    this.RaceConditions = initArray[8];
    this.Weight = initArray[9];
    if (initArray[10])
    {
        this.PrevOddsFractional = initArray[10][0];
        this.PrevOddsAmerican = initArray[10][1]
    }
    this.CurrentOdds = parseInt(initArray[11]);
    this.Age = parseInt(initArray[12]);
    this.Form = initArray[13];
    this.QAEventID = parseInt(initArray[14]);
    this.LeagueID = parseInt(initArray[15]);
    this.Rule4Deduction = initArray[16];
    this.RPR = initArray[17];
    this.DiomedComment = initArray[18];
    this.SilkData = initArray[19];
    RacecardLineInfo.Current = this;
}

window.RacecardLineInfo = RacecardLineInfo;