function ShowZeroBalancePopup() {
    if (typeof (popups) == 'undefined') {
        return;
    }

    var isFirstShow = Cookies.get(popupZeroBalanceCookie) * 1;
    if (popups.zeroBalance == undefined) {
        delete UserInfo.onDataUpdated['zeroBalancePopup'];
        return;
    }

    if (UserInfo.current.NotlockedBalance <= 1 && !isFirstShow) {
        popups.zeroBalance.show();
        Cookies.set(popupZeroBalanceCookie, 1);
        delete UserInfo.onDataUpdated['zeroBalancePopup'];
    }
}

function AppendCurrencyCodeWhenEnabled(currencyCode, value) {
    if (UserInfo.current && ShowCurrencySignInOpenBetsAndBettingHistory && value && !isNaN(value.toString().toStrippedNumberString())) {
        return UserInfo.current.toUserFormatedCurrencyString(value);
    }

    return value;
}

window.ShowZeroBalancePopup = ShowZeroBalancePopup;
window.AppendCurrencyCodeWhenEnabled = AppendCurrencyCodeWhenEnabled;