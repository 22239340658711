/* globals sbMsgBus, sbWrapperIntegrations */
var NativePanelRedirection = (function () {

	onInit = function (code) {
		var message = {};
		message[sbWrapperIntegrations.NativeNavigationChannels.initHomePanel] = {
			HomePanelCode: code
		}; /* as sbWrapperIntegrations.InitHomePanelMessage */
		sbMsgBus.post(message);
	}

	onBackRedirect = function(options) {
		var message = {};
		message[sbWrapperIntegrations.NativeNavigationChannels.beforeNavigation] = {
				panelCode: options.panel.ID,
				params: options.params,
				path: options.path,
				isBackCall: options.isBackCall,
				pageNavigationConfig: options.pageNavigationConfig			 
		}; /* as sbWrapperIntegrations.BeforeNavigationMessage */

		sbMsgBus.post(message);
	}

	/**
	 * msg : sbWrapperIntegrations.OpenGameMessageData
	 */

	openGameHandler = function (msg) {
		var hasEventViewOnPage = Array.find(Application.Panels, function (panel) {
			return panel.hasBlock("EventViewResponsiveBlock");
		});

		if (hasEventViewOnPage) {
			return Application.OpenGame(null, parseInt(msg.eventId), msg.isLive, msg.eventTypeForGameBlock, msg.shouldHideSidePanels, parseInt(msg.sportId), parseInt(msg.leagueId));
		}

		PageMethods.GetEventUrlForNative(msg.eventId, msg.eventTypeForGameBlock, function (redirectUrl) {
			
			window.location.href = redirectUrl;
		}, function (error) {
			console.error(error);
		});
	}

	if (sbMsgBus && Application.IsNativeApp()) {
		sbMsgBus.subscribe(sbWrapperIntegrations.NativeNavigationChannels.openGame, openGameHandler);
	}

	return {
		onInit: onInit,
		onBackRedirect: onBackRedirect
	};
})();

window.NativePanelRedirection = NativePanelRedirection;
