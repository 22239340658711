﻿var jsErrHandler_Constants = {
    // true/false - active/stopped
    activate: false,
    // ajax params
    ajax_url: typeof jsErrorLoggerUrl !== "undefined" ? jsErrorLoggerUrl : "",
    ajax_contentType: "application/json; charset=utf-8",
    // maximum number of errors per page it will collect
    handler_maxErrsPerPage: 5,
    // SECONDS interval for repeatedly sending the errors to the server
    thread_interval_time: 10
}

var pageTimings_Constants = {
    // true/false - active/stopped
    activate: true,
    // ajax params
    ajax_url: "https://jslogger.sbtech.com/jsLog.png",
    ajax_contentType: "application/json; charset=utf-8",
    // SECONDS interval for repeatedly sending the errors to the server
    thread_interval_time: 10
}

window.jsErrHandler_Constants = jsErrHandler_Constants;
window.pageTimings_Constants = pageTimings_Constants;
