﻿var PushOddsUtils = (function ()
{
    'use strict';

    function getPushUpdateEvents(evt, PushUpdateEvents)
    {
        PushUpdateEvents[evt.ID] = evt;
    }

    return {
        getPushUpdateEvents: getPushUpdateEvents
    };
})();

window.PushOddsUtils = PushOddsUtils;