var EmptyTaxProvider = Object.assign({}, BaseTaxProvider,
{
    TaxInfo: { ProviderName: 'EmptyTaxProvider', CountryTax: 0 },

    isUserUnderTax: function ()
    {
        return false;
    },

    getDBTaxAmount: function (bet)
    {
        return 0;
    }
});

window.EmptyTaxProvider = EmptyTaxProvider;