﻿//this extension is a workaround for arbitrary-precision decimal arithmetic problems

Number.MaxDecimalPlaces = 10;

Number.prototype.add = function(number)
{
	var result = (this + number).toFixed(Number.MaxDecimalPlaces) * 1;
	return result;
}

Number.prototype.substract = function(number)
{
	var result = (this - number).toFixed(Number.MaxDecimalPlaces) * 1;
	return result;
}

Number.prototype.multiply = function(number)
{
	var result = (this * number).toFixed(Number.MaxDecimalPlaces) * 1;
	return result;
}

Number.prototype.divide = function(number)
{
	var result = (this / number).toFixed(Number.MaxDecimalPlaces) * 1;
	return result;
}

Number.prototype.eq = function(number)
{
	var result = this.toFixed(Number.MaxDecimalPlaces) * 1 == number.toFixed(Number.MaxDecimalPlaces) * 1;
	return result;
}

Number.prototype.gt = function(number)
{
	var result = this.toFixed(Number.MaxDecimalPlaces) * 1 > number.toFixed(Number.MaxDecimalPlaces) * 1;
	return result;
}

Number.prototype.gte = function(number)
{
	var result = this.toFixed(Number.MaxDecimalPlaces) * 1 >= number.toFixed(Number.MaxDecimalPlaces) * 1;
	return result;
}

Number.prototype.lt = function(number)
{
	var result = this.toFixed(Number.MaxDecimalPlaces) * 1 < number.toFixed(Number.MaxDecimalPlaces) * 1;
	return result;
}

Number.prototype.lte = function(number)
{
	var result = this.toFixed(Number.MaxDecimalPlaces) * 1 <= number.toFixed(Number.MaxDecimalPlaces) * 1;
	return result;
}