﻿/**
 * Extends
 *
 * adds missing or extends existings JS capabilities
 *
 */

/**
 * Extend object with muliple properties
 * @param {Object} N objects (first param may be true/false to control deep extensions)
 * @example
 * var ext = Object.extend([true/false], destination, source1, source2, ...);
 * @returns {Object} The first object argument as the extended object
 * USE CLONEMERGE SO YOU DON'T OVERWRITE THE ORIGINAL CONFIG OBJECT
 */

/* jshint ignore:start */
/* exported extend*/
/* jshint maxcomplexity: 16 */
/* jshint maxdepth: 5 */
Object.extend = Object.extend || function ()
{
    var name, src, copy, copyIsArray, clone,
        target = arguments[0] || {}, i = 1, iLen = arguments.length,
        keys, k, kLen,
        deep = false;

    // Handle a deep copy situation
    if (typeof target === 'boolean')
    {
        deep = target;

        // skip the boolean and the target
        target = arguments[1] || {};
        i += 1;
    }

    // Handle case when target is a string or something (possible in deep copy)
    if (typeof target !== 'object' && typeof target !== 'function')
    {
        target = {};
    }

    // throw error if only one object argument is passed
    if (i >= iLen)
    {
        throw new Error('Object.merge requires at least 2 object arguments to merge');
    }

    for (; i < iLen; i += 1)
    {
        // Only deal with non-null/undefined values
        if (arguments[i] != null)
        {
            // Extend the base object
            for (var name in arguments[i])
            {
                src = target[name];
                copy = arguments[i][name];

                // Prevent never-ending loop
                if (target === copy)
                {
                    continue;
                }

                // Recurse if we're merging plain objects or arrays
                if (deep && copy && (Object.isPlainObject(copy) || (copyIsArray = Array.isArray(copy))))
                {
                    if (copyIsArray)
                    {
                        copyIsArray = false;
                        clone = src && Array.isArray(src) ? src : [];
                    }
                    else
                    {
                        clone = src && Object.isPlainObject(src) ? src : {};
                    }

                    // Never move original objects, clone them
                    target[name] = Object.extend(deep, clone, copy);
                }
                else if (copy !== undefined)
                {
                    // Don't bring in undefined values
                    target[name] = copy;
                }
            }
        }
    }

    // Return the modified object
    return target;
};
/* jshint maxcomplexity: 5 */

/**
 * Extend object with multiple properties
 * @param {Object} N objects
 * @example
 * var ext = Object.merge(destination, source1, source2, ...);
 * @returns {Object} The first argument as the extended object
 * USE CLONEMERGE SO YOU DON'T OVERWRITE THE ORIGINAL CONFIG OBJECT
 */
/* exported merge*/
Object.merge = Object.merge || function ()
{
    return Object.extend.apply(this, [true].concat([].slice.call(arguments)));
};

/**
 * Extend object with multiple properties without modifying any of the passed arguments
 * @param {Object} N objects
 * @example
 * var ext = Object.cloneMerge(source1, source2, ...);
 * @returns {Object} The extended object
 */
/* exported cloneMerge*/
Object.cloneMerge = Object.cloneMerge || function ()
{
    'use strict';

    return Object.extend.apply(this, [{}].concat([].slice.call(arguments)));
};

Object.assign = Object.assign || function (target)
{
    'use strict';
    // We must check against these specific cases.
    if (target === undefined || target === null)
    {
        throw new TypeError('Cannot convert undefined or null to object');
    }

    var output = Object(target);
    for (var index = 1; index < arguments.length; index++)
    {
        var source = arguments[index];
        if (source !== undefined && source !== null)
        {
            for (var nextKey in source) {
                if (hasOwnProperty.call(source, nextKey)) {
                    output[nextKey] = source[nextKey];
                }
            }
        }
    }
    return output;
};

// Reuse Object.extend under a jQuery API alias (for reused scripts)
var $ = window.jQuery || {};
$.extend = $.extend || Object.extend;

/* exported isPlainObject*/
/* jshint maxcomplexity: 7 */
Object.isPlainObject = function (obj)
{
    // Not plain objects:
    // - Any object or value whose internal [[Class]] property is not '[object Object]' (RegExp included)
    // - DOM nodes
    // - window
    if (obj === null || typeof obj !== 'object' || obj instanceof RegExp || obj.nodeType || obj === window)
    {
        return false;
    }

    if (obj.constructor && obj.constructor !== Object)
    {
        return false;
    }

    // If the function hasn't returned already, we're confident that
    // |obj| is a plain object, created by {} or constructed with new Object
    return true;
};
/* jshint maxcomplexity: 5 */

//check if object is empty
/* exported isEmpty*/
/* jshint maxcomplexity: 6 */
Object.isEmpty = function (obj)
{
    // null and undefined are 'empty'
    if (obj == null)
    {
        return true;
    }

    // Assume if it has a length property with a non-zero value
    // that that property is correct.
    if (obj.length > 0)
    {
        return false;
    }

    if (obj.length === 0)
    {
        return true;
    }

    // Otherwise, does it have any properties of its own?
    // Note that this doesn't handle
    // toString and valueOf enumeration bugs in IE < 9
    for (var key in obj)
    {
        if (hasOwnProperty.call(obj, key))
        {
            return false;
        }
    }

    return true;
};
/* jshint maxcomplexity: 5 */

/**
 * Clone object
 */
/* exported CloneObject*/
function CloneObject(obj)
{
    return JSON.parse(JSON.stringify(obj));
}

Date.now = (Date.now || function ()
{
    // Thanks IE8
    return new Date().getTime();
});

/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing.
 * http://modernjavascript.blogspot.co.uk/2013/08/building-better-debounce.html
 *
 * Usage:
 * var myEfficientFn = debounce(function() { //DO STUFF }, 250);
 *
 */
/* exported debounce*/
function debounce(func, wait, immediate)
{
    var timeout, args, context, timestamp, later;

    later = function ()
    {
        var last = Date.now() - timestamp;

        if (last < wait && last >= 0)
        {
            timeout = setTimeout(later, wait - last);
        }
        else
        {
            timeout = null;
            if (!immediate)
            {
                func.apply(context, args);
            }
        }
    };

    return function ()
    {
        var callNow;

        context = this;
        args = arguments;
        timestamp = Date.now();
        callNow = immediate && !timeout;

        if (!timeout)
        {
            timeout = setTimeout(later, wait);
        }

        if (callNow)
        {
            func.apply(context, args);
        }
    };
}

function throttle(func, limit)
{
    var wait = false;
    return function ()
    {
        if (!wait)
        {
            func();
            wait = true;
            setTimeout(function ()
            {
                wait = false;
            }, limit);
        }
    };
}

/**
 * Returns a function, that, will when executed will chain itself with the next
 * animation frame (usually running every 16ms).
 * This behavior is required to achieve VSync even with large DOM updates.
 *
 * Usage:
 * var myEfficientFn = rafPaint(function(element, hugeHTML) { element.innerHTML = hugeHTML; });
 *
 */
/* exported rafPaint*/
var rafPaint = (function ()
{
    var vendors = ['ms', 'moz', 'webkit', 'o'];
    for(var x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
        window.requestAnimationFrame = window[vendors[x]+'RequestAnimationFrame'];
        window.cancelAnimationFrame = window[vendors[x]+'CancelAnimationFrame'] 
                                   || window[vendors[x]+'CancelRequestAnimationFrame'];
    }

    if (!window.requestAnimationFrame)
    {
        return function (func)
        {
            return func.bind(this);
        };
    }

    return function (func)
    {
        return function ()
        {
            var context = this,
                args = arguments;

            return requestAnimationFrame(function ()
            {
                func.apply(context, args);
            });
        };
    };
}());

/**
 * EVENTS HANDLING
 *
 * Smart events managing by altering the properties of a HTML element
 *
 * usage:
 *
 var myEventVariable = new UIElement({
     name:               NAME OF YOUR EVENT,
     eventHtmlElement:   PASS HTML ELEMENT,
     handler:            ANONYMOS FUNCTION OR FUNCTION PASSED BY REF,
     eventType:          EVENT TYPE
     useCapture:         DEFAULT IS FALSE, you can pass TRUE
 });
 * 
 * examples:
 *
 var myEventVariable = new UIElement({
     name:               'My event',
     eventHtmlElement:   document.getElementById('myElement'),
     handler:            function(){alert('it works')},
     eventType:          'click'
 });
 
 uiElement({
     name:               'My event',
     eventHtmlElement:   document.getElementById('myElement'),
     handler:            function(){alert('it works')},
     eventType:          'click'
 });
  
 document.getElementById('myElement').hasEvent(EVENT NAME); // HANDLER or false
 document.getElementById('myElement').detach(EVENT NAME);   // detaches the requested event
 document.getElementById('myElement').detach();             // detaches all events
 document.getElementById('myElement').events;               // returns object of all events if any
 document.getElementById('myElement').trigger(EVENT NAME);  // calls the handler
 * 
 * inside the events handler || stored variable:
 * 
 this.detach();                                             // detaches self
 this.detach('name');                                       // detaches other event
 this.trigger();                                        	// triggers self
 
 var evt = uiElement(...);
 evt.detach();                                              // detaches self
 evt.detach('name');                                        // detaches other event
 evt.trigger();                                        	    // triggers self
  
 * Inside the event handler 'this' refers to the event attached
 * and stores its configuration and a reference to the HTML element.
 *
 * You can detach these events from within the handler like this:
 this.eventHtmlElement.detach(this.eventConfig.name);
 *
 *
 * If you need to control it from somewhere else you can store the event
 * into a variable and access it from there like this:
 var myEventVariable = new UIElement({...});
 *
 * console.log(myEventVariable) will output the same as console.log(this)
 * from withing the event handler
 *
 * Proper FOR/WHILE loop attach of events - the class is handling the check for that.
 * You can't attach events with the same name.
 */
/* exported UIElement */
var UIElement = (function ()
{
    //'use strict'; -> issues with iOS Safari on tablet devices: 09.11.2015

    /* globals Window, Document, Element */
    function addToProto(prop, value)
    {
        window[prop] = value;
        document[prop] = value;
        Element.prototype[prop] = value;
    }

    function uiElementPreInit()
    {
        return false;
    }

    addToProto('trigger', uiElementPreInit);
    addToProto('hasEvent', uiElementPreInit);
    addToProto('detach', uiElementPreInit);
    addToProto('events', false);

    function removeEvent(name)
    {
        var ev, type, handler, useCapture;
        ev = this.events[name];
        useCapture = ev.useCapture;
        type = ev.eventType;
        handler = ev.handler;
        this.removeEventListener(type, handler, useCapture);
        delete this.eventsList[name];
    }

    function detachEvent(name)
    {
        var i;

        if (name === undefined || name === '')
        {

            for (i in this.eventsList)
            {
                removeEvent.call(this, i);
            }
            this.eventsList = {};
        }
        else if (this.hasEvent(name))
        {
            removeEvent.call(this, name);
        }

        return this.eventsList;
    }

    function hasEvent(name)
    {
        return typeof this.eventsList[name] === 'object' ? this.eventsList[name] : false;
    }

    function triggerEvent(name)
    {
        var evt = this.hasEvent(name);
        if (typeof evt.handler === 'function')
        {
            return evt.handler();
        }
        return false;
    }

    function UIElement(config)
    {
        if (!config)
        {
            return false;
        }

        if ((this instanceof UIElement) === false)
        {
            return new UIElement(config);
        }

        this.eventHtmlElement = config.eventHtmlElement;

        this.eventConfig = {
            name: config.name,
            eventType: config.eventType,
            handler: config.handler === undefined ? false : config.handler,
            useCapture: config.useCapture === undefined ? false : config.useCapture
        };

        this.init();
    }

    UIElement.prototype.init = function ()
    {
        if (this.eventHtmlElement.eventsList === undefined)
        {
            Object.defineProperties(this.eventHtmlElement, {
                'eventsList': {
                    writable: true,
                    enumerable: false,
                    configurable: false,
                    value: {}
                },
                'events': {
                    enumerable: false,
                    configurable: false,
                    get: function ()
                    {
                        return this.eventsList;
                    },
                    set: function (e)
                    {
                        return this.eventsList[e.name] = e;
                    }
                },
                'trigger': {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: triggerEvent
                },
                'hasEvent': {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: hasEvent
                },
                'detach': {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: detachEvent
                }
            });
        }
        else if (this.eventHtmlElement.hasEvent(this.eventConfig.name))
        {
            return false;
        }

        this.eventConfig.handler = this.eventConfig.handler.bind(this);
        this.eventHtmlElement.addEventListener(this.eventConfig.eventType, this.eventConfig.handler, this.eventConfig.useCapture);
        this.eventHtmlElement.events = this.eventConfig;
    };


    Object.defineProperties(UIElement.prototype, {
        'detach': {
            writable: false,
            enumerable: false,
            configurable: false,
            value: function (name)
            {
                return detachEvent.call(this.eventHtmlElement, name);
            }
        },
        'trigger': {
            writable: false,
            enumerable: false,
            configurable: false,
            value: function (name)
            {
                return triggerEvent.call(this.eventHtmlElement, name || this.eventConfig.name);
            }
        }

    });

    return UIElement;
}());

// To prevent JSHint errors when creating an instance of UIElement without using
// the `new` keyword or without storing the instance to a variable.
/* exported uiElement */
var uiElement = UIElement;

/**
 * Are you a HTML Element or a cucumber
 * Gues what - you pass a variable and it returns TRUE or FALSE
 */
/* exported isHtmlElement */
function isHtmlElement(o)
{
    return (typeof HTMLElement === 'object' ? o instanceof HTMLElement : o && typeof o === 'object' && o !== null && o.nodeType === 1 && typeof o.nodeName === 'string');
}

/**
 * Hides devices' software keyboard, if it has one by
 * loosing focus of the active input/textarea element
 */
/* exported hideDeviceKeyboard */
function hideDeviceKeyboard(event)
{
    if (event && this.constructor == 'uiElement' && event.target == document.activeElement)
    {
        return false;
    }

    return document.activeElement.blur();
}

/**
 * Gets a DOM element's text content using either textContent or innerHTML
 * depending on browser support.
 *
 * 2015-08-14
 * Removed the non-standard `innerText` property. It affected the performance of
 * our application.
 * http://www.kellegous.com/j/2013/02/27/innertext-vs-textcontent/
 * http://perfectionkills.com/the-poor-misunderstood-innerText/
 *
 * @param {Element} DOM element
 * @example
 * var text = getDomText(document.getElementById('textElement'));
 */
/* exported getDomText */
function getDomText(element)
{
    return element.textContent || element.innerHTML;
}

/**
 * Sets a DOM element's text content using either textContent or innerHTML
 * depending on browser support
 *
 * 2015-08-14
 * Removed the non-standard `innerText` property. It affected the performance of
 * our application.
 * http://www.kellegous.com/j/2013/02/27/innertext-vs-textcontent/
 * http://perfectionkills.com/the-poor-misunderstood-innerText/
 *
 * @param {Element} DOM element
 * @param {String} Text to set
 * @example
 * setDomText(document.getElementById('textElement'), 'text');
 */
/* exported setDomText */
var setDomText = (function ()
{
    // NOTE: In Mobile `extends.js` is executed before body to be rendered on
    // the page.
    var temp = document.createElement('div'),
        useTextContent = 'textContent' in temp;

    return function (element, text)
    {
        if (useTextContent)
        {
            element.textContent = text;
        }
        else
        {
            element.innerHTML = $escapeHTML(text);
        }
    };
})();

/**
 * Executes functions in the events argument object by passing optional sender and data values
 * @param {events} Object containing function properties
 * @param {sender} Sender as the first passed handler argument
 * @param {data} Data as the second passed handler argument
 * @example
 * executeEvents(UserInfo.OnLogin, UserInfo, { opt1: 1, ... });
 */
/* exported executeEvents */
function executeEvents(events, sender, data)
{
    if (events)
    {
        var i, len, keys = Object.keys(events);

        for (i = 0, len = keys.length; i < len; i += 1)
        {
            typeof events[keys[i]] === 'function' && events[keys[i]](sender, data);
        }
    }
}

/* 
    @example
    var events = [
    {
        subscriber: "TopBranchBlock",
        executer: "UpcomingEventsPerBranch" or "UI.scrollUtil",
        eventName:"onShowAll",
        useCurrent: true,
        callback: TopBranchBlock.Current.hide(),
    }];
    subscribeEvents(events);
*/
/* exported subscribeEvents */
function subscribeEvents(events)
{
    for (var i = 0; i < events.length; i++)
    {
        var event = events[i];
        var parts = event.executer.split('.');

        if (typeof window[parts[0]] !== undefined)
        {
            var currentInstance = window[parts[0]];
            for (var j = 1; j < parts.length; j++)
            {
                if (currentInstance[parts[j]])
                {
                    currentInstance = currentInstance[parts[j]]
                }
            }

            if (event.useCurrent && currentInstance
                && currentInstance.Current
                && currentInstance.Current[event.eventName])
            {
                currentInstance.Current[event.eventName][event.subscriber] = event.callback;
            }
            else if (!event.useCurrent && currentInstance
                && currentInstance[event.eventName])
            {
                currentInstance[event.eventName][event.subscriber] = event.callback;
            }
        }
    }
}

(function ()
{
    if (Function.prototype.name === undefined && Object.defineProperty !== undefined)
    {
        var funcNameRegex = /function\s([^(]{1,})\(/;
        Object.defineProperty(Function.prototype, 'name', {
            get: function ()
            {
                var results = (funcNameRegex).exec(this.toString());

                return (results && results.length > 1) ? results[1].trim() : '';
            },
            set: function () { }
        });
    }
}());

/**
 * Returns a specific parent node
     el -> {HTML Element}
     condition -> {string} class || attr
     test -> {string} what to test for
 */
function getParent(el, condition, test)
{
    var parent = el.parentNode || null;

    if (parent == null) return false;

    switch (condition)
    {
        case "class":
            if (parent.classList && parent.classList.contains(test))
            {
                return parent;
            }
            break;

        case "attr":
            if (parent.hasAttribute && parent.hasAttribute(test))
            {
                return parent;
            }
            break;

        default:
            //return false;
    }
    return getParent(parent, condition, test);
}

(function ()
{
    'use strict';

    var ElementPrototype = Element.prototype;

    if (typeof ElementPrototype.matches === 'undefined')
    {
        /**
         * Method that returns `true` if the element would be selected by
         * the specified selector string. Otherwise it returns `false`
         * @param {String} selector is a string representing the selector to test.
         * @returns {Boolean} true or false
         */

        ElementPrototype.matches = ElementPrototype.matchesSelector ||
            ElementPrototype.webkitMatchesSelector ||
            ElementPrototype.mozMatchesSelector ||
            ElementPrototype.msMatchesSelector ||
            function matches(selector)
            {
                var parentNode = this.parentNode;

                return !!parentNode && -1 < indexOf.call(
                    parentNode.querySelectorAll(selector),
                    this
                );
            };

        // most likely an IE9 only issue
        // see https://github.com/WebReflection/dom4/issues/6
    }

    if (typeof ElementPrototype.closest === 'undefined')
    {
        /**
         * Method that returns the closest ancestor of the current element
         * (or the current element itself) which matches the selectors given
         * in parameter. It there isn't such an selector, it returns null.
         * @param {DOMString} selector ("a:hover, .news + h1")
         * @returns {Element} the closest ancestor. It may be null
         */
        ElementPrototype.closest = function (selector)
        {
            var parentNode = this,
                matches;

            while ((matches = parentNode && parentNode.matches) &&
                !parentNode.matches(selector))
            {
                parentNode = parentNode.parentNode;
            }

            return matches ? parentNode : null;
        };
    }
}());

/*
 * Function currying
 */
Function.prototype.curry = function ()
{
    if (arguments.length < 1)
    {
        return this;
    }
    var __method = this, args = Array.prototype.slice.call(arguments);;

    return function ()
    {
        return __method.apply(this, args.concat(Array.prototype.slice.call(arguments)));
    };
};
CanvasRenderingContext2D.prototype.clear = CanvasRenderingContext2D.prototype.clear || function (preserveTransform)
{
    if (preserveTransform)
    {
        this.save();
        this.setTransform(1, 0, 0, 1, 0, 0);
    }

    this.clearRect(0, 0, this.canvas.width, this.canvas.height);

    if (preserveTransform)
    {
        this.restore();
    }
};

if (!String.prototype.padStart) 
{
    String.prototype.padStart = function padStart(targetLength, padString) 
    {
        targetLength = targetLength >> 0;
        padString = String(typeof padString !== 'undefined' ? padString : ' ');
        if (this.length >= targetLength) 
        {
            return String(this);
        } else 
        {
            targetLength = targetLength - this.length;
            if (targetLength > padString.length) 
            {
                padString += padString.repeat(targetLength / padString.length);
            }
            return padString.slice(0, targetLength) + String(this);
        }
    };
};

/* Easings factory, to be furtherly updated */

var Easing = {};

Easing.quad = {};

Easing.quad.easeInOut = function (t, b, c, d)
{
    t /= d / 2;
    if (t < 1) { return c / 2 * t * t + b; }
    t -= 1;
    return -c / 2 * (t * (t - 2) - 1) + b;
};

/**
 * Creating and triggering cross-browser events
 * @param {String} type - 'change' or any custom type.
 * @param {Boolean} bubbles - indicating whether the event bubbles.
 * @param {Boolean} cancelable - indicating whether the event can be canceled.
 * @returns {Object} a new Event
 * @example
 *  var testEvent = CrossEvent('test', true, true);
 *  document.addEventListener('test', function (e) {}, false);
 *  document.dispatchEvent(testEvent); 
 */
function CrossEvent(type, bubbles, cancelable)
{
    var event;

    if (typeof Event === 'function')
    {
        event = new Event(type, { bubbles: bubbles, cancelable: cancelable });
    }
    else
    {
        var event = document.createEvent('Event');
        event.initEvent(type, bubbles, cancelable);
    }

    return event;
}

// Check if element is visible on the page 
// Translated from JQuery Visible.js
// takes 5 parameters: the 'element' as an HTML Node, 
// 'partial' - if the method returns true or false if element is partially visible
// 'hidden' - returns if the element is hidden by CSS
// 'direction' - it can check if an element is moved horizontally or vertically of the viewport. Options are 'vertical' and 'horizontal'.
// false or left empty means 'both at once'
// 'container' - calculates the visibility of an element relative to the container given

function IsElementVisible(elem, partial, hidden, direction, container)
{

    if (elem.length < 1)
        return;
	
    // Set direction default to 'both'.
    direction = direction || 'both';
	    
    var t         = elem,
		isContained = typeof container !== 'undefined' && container !== null,
		c				  = isContained ? container : window,
		wPosition        = isContained ? c.getBoundingClientRect() : 0,
        vpWidth     = c.outerWidth,
        vpHeight    = c.outerHeight,
        clientSize  = hidden === true ? t.offsetWidth * t.offsetHeight : true;

    if (typeof t.getBoundingClientRect === 'function'){

        // Use this native browser method, if available.
        var rec = t.getBoundingClientRect(),
            tViz = isContained ?
											rec.top - wPosition.top >= 0 && rec.top < vpHeight + wPosition.top :
											rec.top >= 0 && rec.top < vpHeight,
            bViz = isContained ?
											rec.bottom - wPosition.top > 0 && rec.bottom <= vpHeight + wPosition.top :
											rec.bottom > 0 && rec.bottom <= vpHeight,
            lViz = isContained ?
											rec.left - wPosition.left >= 0 && rec.left < vpWidth + wPosition.left :
											rec.left >= 0 && rec.left <  vpWidth,
            rViz = isContained ?
											rec.right - wPosition.left > 0  && rec.right < vpWidth + wPosition.left  :
											rec.right > 0 && rec.right <= vpWidth,
            vVisible   = partial ? tViz || bViz : tViz && bViz,
            hVisible   = partial ? lViz || rViz : lViz && rViz,
	vVisible = (rec.top < 0 && rec.bottom > vpHeight) ? true : vVisible,
            hVisible = (rec.left < 0 && rec.right > vpWidth) ? true : hVisible;

        if(direction === 'both')
            return clientSize && vVisible && hVisible;
        else if(direction === 'vertical')
            return clientSize && vVisible;
        else if(direction === 'horizontal')
            return clientSize && hVisible;
    } else {

        var viewTop 		= isContained ? 0 : wPosition,
            viewBottom      = viewTop + vpHeight,
            viewLeft        = c.scrollLeft,
            viewRight       = viewLeft + vpWidth,
            position          = t.getBoundingClientRect(),
            _top            = position.top,
            _bottom         = _top + t.clientHeight,
            _left           = position.left,
            _right          = _left + t.clientWidth,
            compareTop      = partial === true ? _bottom : _top,
            compareBottom   = partial === true ? _top : _bottom,
            compareLeft     = partial === true ? _right : _left,
            compareRight    = partial === true ? _left : _right;

        if(direction === 'both')
            return !!clientSize && ((compareBottom <= viewBottom) && (compareTop >= viewTop)) && ((compareRight <= viewRight) && (compareLeft >= viewLeft));
        else if(direction === 'vertical')
            return !!clientSize && ((compareBottom <= viewBottom) && (compareTop >= viewTop));
        else if(direction === 'horizontal')
            return !!clientSize && ((compareRight <= viewRight) && (compareLeft >= viewLeft));
    }
};

window.CloneObject = CloneObject;
window.CrossEvent = CrossEvent;
window.debounce = debounce;
window.throttle = throttle;
window.rafPaint = rafPaint;
window.UIElement = UIElement;
window.uiElement = uiElement;
window.isHtmlElement = isHtmlElement;
window.hideDeviceKeyboard = hideDeviceKeyboard;
window.getDomText = getDomText;
window.setDomText = setDomText;
window.executeEvents = executeEvents;
window.subscribeEvents = subscribeEvents;
window.getParent = getParent;
window.Easing = Easing;
window.$ = $;

/* jshint ignore:end */
