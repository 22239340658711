var betSlip = {};

betSlip.OnLoaded = {};
betSlip.OnAdd = {};
betSlip.OnSelectionRemoved = {};
betSlip.OnViewUpdated = {};
betSlip.OnItemUpdate = ({});
betSlip.OnDelete = ({});
betSlip.Modes = [];
betSlip.getSDEventId = () => "";
betSlip.getSDMarketId = () => "";
betSlip.getSDSelectionId = () => "";
betSlip.getSelectionsCount = () => 0;
betSlip.Selections = [];
betSlip.CurrentMode = {
    incrementStake:()=> {},
    decrementStake:() => {},
    setStake:() => {},
    placeBets:() => {},
    YourBetStake: "",
    ID: "",
    mapToYourBetFreeBet: () => {},
    YourBetOdds: 0
};
betSlip.Purchases = [];
betSlip.restorePurchaseSelections = () => {};
betSlip.acceptOffer = () => {};
betSlip.declineOffer = () => {};
betSlip.acceptChangedOdds = () => {};
betSlip.setSelectedFreeBet = () => {};
betSlip.clear = () => {};
betSlip.autoSelectMode = () => {};
betSlip.selectSlipMode = () => {};
betSlip.removePurchase = () => {};
betSlip.getCurrencyWithStakeValues = () => {};
betSlip.GainRoundingMode = "";
betSlip.ComboOddsRoundingMode = "";
betSlip.ForceTwoDigitsForOddsRounding = "";
betSlip.OnLineClosedUpdate = {};
betSlip.OnPurchaseUpdated = {};
betSlip.OnOfferAccepted = {};
betSlip.OnOfferDeclined = {};
betSlip.togglePlaceBetButton = () => {};
betSlip.exitFromEditMode = () => {};

window.BetSlip = betSlip;

var template = {};
window.TUniSlip = template;


var betSlipUtil = {};

betSlipUtil.addSelection = () => { };
betSlipUtil.removeSelection = () => { };
betSlipUtil.getSelections = () => ({
    receiverIds: [],
    items: []
});
betSlipUtil.clearAll = () => { };
betSlipUtil.isOddInSlip = () => { };
betSlipUtil.addOddToSlip = () => ({ });
betSlipUtil.getSelectionType = () => { };
betSlipUtil.removeAllSelectedFreePulseBets = () => {};
betSlipUtil.getAvailableFreeBetsForPulse = () => [];
betSlipUtil.addQAInitilizedSelectionToBetSlip = () => {};
betSlipUtil.getSDMarketId = () => {};
betSlipUtil.getSDEventId = () => {};
betSlipUtil.getSDSelectionId = () => {};
betSlipUtil.sendQACastMsgItems = () => {};
betSlipUtil.sendQAOddHRMsgItem = () => {};
betSlipUtil.addQAOddHRMsgItem = () => {};
betSlipUtil.createQAOddHRParams = () => {};
betSlipUtil.getSelections = () => {};

var uniSlip = getUniSlip();

function getUniSlip() {
    uniSlip = uniSlip || (typeof BetSlip !== 'undefined' ? BetSlip : undefined);
    return uniSlip;
}

betSlipUtil.onAdded = function () { return getUniSlip() && uniSlip.OnAdd; }
betSlipUtil.onRemoved = function () { return getUniSlip() && uniSlip.OnSelectionRemoved; }

betSlipUtil.subscribeOnSelectionRemoved = function (subscriberId, handler) {
    BetSlip.OnSelectionRemoved[subscriberId] = function (selection) {
        handler(selection.BranchID, selection.MasterEventID, selection.EventID, selection.LineID, selection.BetTypeID);
    };
}

betSlipUtil.subscribeOnSelectionAdded = function (subscriberId, handler) {
    BetSlip.OnAdd[subscriberId] = function (sender, selection) {
        handler(selection.BranchID, selection.MasterEventID, selection.EventID, selection.LineID, selection.BetTypeID);
    };
}

window.BetSlipUtil = betSlipUtil;

function CashoutManager() {
    this.UpdateCashOutData = () => { };
    this.GetCashOutDataUpdate = () => ({});
    this.ProcessCashoutResult = () => { };
    this.CashOutBet = () => { };
};

window.CashoutManager = CashoutManager;

function SPMyBetsCashoutBetDrawer() {
    this.onCashoutAvailabilityChanged = () => { };
    this.removeBet = () => { };
    this.redrawBet = () => { };
    this.showHideErrorMessage = () => { };
    this.updateCashOutButtonAmounts = () => { };
    this.updateCashOutTaxInfo = () => { };
    this.showHideDangerNotification = () => { };
    this.refreshPartialCashoutAvailability = () => { };
    this.hidePartialCashout = () => { };
    this.redrawCashoutConfirmationState = () => { };
    this.getAllHosts = () => [];
    this.registerHost = () => { };
    this.unregisterHost = () => { };
    this.showHidePartialCashout = () => { };
    this.buildCashOutBetView = () => { };
}

window.SPMyBetsCashoutBetDrawer = SPMyBetsCashoutBetDrawer;

var modes = {};
window.Modes = modes;

window.addQAOdd = () => {};
window.addOdd = () => {};
window.addOdd2 = () => {};
window.addOdd3 = () => {};
window.addOddQALive = () => {};
window.addOddLive = () => {};
window.getTeamNameExtension = () => {};

window.BaseSPSlipMode = {};
window.ComboSPSlipMode = {};
window.BetSlipStyles = {};
window.SelectionHelper = {
    create: () => ({
        getPickLine: () => "",
        getPulseBetPickLine: () => ""
    })
}
window.TLoyaltyPointsPresenter = {
    buildMyBetsView: () => ""
};

function SplitTypeFactory() {}
SplitTypeFactory.Create = () => ({
    buildPickLine: () => ({}),
    getPickLine: () => ({}),
    buildEventNameForBet: () => "",
    buildPreliveEventView: () => "",
    buildWebClientInnerView: () => "",
    buildEventTypeTitle: () => "",
    buildEventTypeTitleSplit: () => ""
});
window.SplitTypeFactory = SplitTypeFactory;