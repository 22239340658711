/*********************************************************************************************************************************************\
/*
/* Line groups
/*
/**/

function LineGroup(initArray)
{
    this.ID = parseInt(initArray[0], 10);

    this.ML = initArray[1] ? new MLOdds(initArray[1]) : null;
    this.Spread = initArray[2] ? new HCOdds(initArray[2]) : null;
    this.OU = initArray[3] ? new OUOdds(initArray[3]) : null;

    this.IsOptional = (initArray[4] != undefined) ? initArray[4] != 0 : false;

    this.ML_ComboRate = initArray[6];
    this.HC_ComboRate = initArray[7];
    this.OU_ComboRate = initArray[8];


    //  if(this.ML && this.ML.Deleted) this.ML = null;
    //  if(this.Spread && this.Spread.Deleted) this.Spread = null;
    //  if(this.OU && this.OU.Deleted) this.OU = null;

    this.Deleted = (initArray[5] && (initArray[5] == -1));
    this.Updated = (this.ML != null) || (this.Spread != null) || (this.OU != null) ||
        (this.ML_ComboRate != undefined) || (this.HC_ComboRate != undefined) || (this.OU_ComboRate != undefined);

    if (!this.Deleted)
    {
        if (this.ML && this.ML_ComboRate != undefined) this.ML.ComboRate = this.ML_ComboRate;
        if (this.Spread && this.HC_ComboRate != undefined) this.Spread.ComboRate = this.HC_ComboRate;
        if (this.OU && this.OU_ComboRate != undefined) this.OU.ComboRate = this.OU_ComboRate;

    }

    this.HasCombo = (this.ML_ComboRate || this.HC_ComboRate || this.OU_ComboRate);
}

LineGroup.prototype =
{
    getUpdateString: function ()
    {
        return "{0},{1:0.###},{2:0.###},{3:0.###},{4:0.###},{5:0.###},{6:0.###},{7:0.###},{8:0.###},{9:0.###}".format(
          this.ID,
          (this.ML && this.ML.ML1_Odds != undefined && !isNaN(this.ML.ML1_Odds) ? this.ML.ML1_Odds : -1),
          (this.ML && this.ML.ML2_ID  ? this.ML.ML2_Odds : -1),
          (this.Spread && this.Spread.Points1 != undefined && !isNaN(this.Spread.Points1) ? this.Spread.Points1 : -1),
          (this.Spread && this.Spread.Odds1 != undefined && !isNaN(this.Spread.Odds1) ? this.Spread.Odds1 : -1),
          (this.OU && this.OU.Points1 != undefined && !isNaN(this.OU.Points1) ? this.OU.Points1 : -1),
          (this.OU && this.OU.Odds1 != undefined && !isNaN(this.OU.Odds1) ? this.OU.Odds1 : -1),
          this.ML_ComboRate,
          this.HC_ComboRate,
          this.OU_ComboRate
        )
    },

    updateFromServer: function (data, evType)
    {
        if (!data.Updated && !data.Deleted)
        {
            this.__resetUpdated();
            return;
        }

        this.Updated = true;

        this.__updatePoints("ML", data, evType);
        this.__updatePoints("Spread", data, evType);
        this.__updatePoints("OU", data, evType);

        if (data.ML_ComboRate != undefined) this.ML_ComboRate = data.ML_ComboRate;
        if (data.HC_ComboRate != undefined) this.HC_ComboRate = data.HC_ComboRate;
        if (data.OU_ComboRate != undefined) this.OU_ComboRate = data.OU_ComboRate;

        this.Deleted = data.Deleted;

        if (!data.Deleted)
        {
            if (this.ML) this.ML.ComboRate = this.ML_ComboRate;
            if (this.Spread) this.Spread.ComboRate = this.HC_ComboRate;
            if (this.OU) this.OU.ComboRate = this.OU_ComboRate;

            this.HasCombo = (this.HC_ComboRate || this.HC_ComboRate || this.OU_ComboRate);
        }
    },

    __updatePoints: function (propname, data, evType)
    {
        if (data[propname] && data[propname].Updated)
        {
            if (!this[propname])
                this[propname] = data[propname];
            else
                this[propname].updateFromServer(data[propname], evType);
        }

        if (data[propname] && data[propname].Deleted)
        {
            this[propname] = null;
        }
    },

    __resetUpdated: function (propname)
    {
        if (!propname)
        {
            this.Updated = false;
            this.__resetUpdated("ML");
            this.__resetUpdated("Spread");
            this.__resetUpdated("OU");

            return;
        }

        if (this[propname] && this[propname].__resetUpdated)
        {
            this[propname].__resetUpdated();
        }
    },

    hasOdds: function (prop)
    {
        if (!prop)
            return (this.ML && this.ML.hasOdds()) || (this.Spread && this.Spread.hasOdds()) || (this.OU && this.OU.hasOdds());
        else
            return (this[prop] && this[prop].hasOdds());
    },

    containsNonQuaterPoints: function (prop)
    {
        return (this[prop] && this[prop].containsQuaterPoints() === false);
    },

    hasSpreadOdds: function ()
    {
        return (this.Spread && this.Spread.hasOdds());
    },

    hasOUOdds: function ()
    {
        return (this.OU && this.OU.hasOdds());
    },

    hasDraw: function ()
    {
        return this.ML && this.ML.hasOdds && this.ML.ML2_ID && this.ML.ML2_Odds;
    },

    getBetConditions: function (Type, BetSide, MasterEvent)
    {
        var cb = null;
        switch (Type)
        {
            case "ML":
                if (!this.ML) return null;

                switch (BetSide)
                {
                    case 1:
                        return { LineID: this.ML.ML1_ID, Name: MasterEvent.Team1Name, Odds: this.ML.ML1_Odds, Updated: this.ML.Updated1 };

                    case 2:
                        return { LineID: this.ML.ML2_ID, Name: $string("General").Draw, Odds: this.ML.ML2_Odds, Updated: this.ML.Updated2 };

                    case 3:
                        return { LineID: this.ML.ML3_ID, Name: MasterEvent.Team2Name, Odds: this.ML.ML3_Odds, Updated: this.ML.Updated3 };
                }

            case "OU":
                if (!this.OU) return null;

                switch (BetSide)
                {
                    case 1:
                        return { LineID: this.OU.ID1, Name: $string("General").Over, Odds: this.OU.Odds1, Points: this.OU.Points1, Updated: this.OU.Updated1 };

                    case 3:
                        return { LineID: this.OU.ID3, Name: $string("General").Under, Odds: this.OU.Odds3, Points: this.OU.Points1, Updated: this.OU.Updated3 };
                }
                break;

            case "HC":
                if (!this.Spread) return null;

                switch (BetSide)
                {
                    case 1:
                        return { LineID: this.Spread.ID1, Name: MasterEvent.Team1Name, Odds: this.Spread.Odds1, Points: this.Spread.Points1, Updated: this.Spread.Updated1 };

                    case 3:
                        return { LineID: this.Spread.ID3, Name: MasterEvent.Team2Name, Odds: this.Spread.Odds3, Points: -this.Spread.Points1, Updated: this.Spread.Updated3 };
                }
                break;
        }
    },

    updateSingleLine: function (betType, betSide, odds)
    {
        switch (betType)
        {
            case "ML":
                if (!this.ML) return;
                this.ML.setNewOdds(betSide, odds);
                break;

            case "HC":
                if (!this.Spread) return;
                this.Spread.setNewOdds(betSide, odds);
                break;

            case "OU":
                if (!this.OU) return;
                this.OU.setNewOdds(betSide, odds);
                break;
        }
    }

}

window.LineGroup = LineGroup;