﻿// JScript File
// Dynamic, on-demand, loading of js files.

// versioning ... this variable will be added to all dynamically loaded JS and XSTL files, to be sure, thal new versions are loaded
var global_version = (new Date()).getTime();
window.app_version = global_version; // this is set later on AGAIN!!! in the desing.js file in the responsive application repository
var application_root = "";
var __js__preloaded = window.__js__preloaded || {};
var __js__preloadedcore = null;

var __js_loaded_files_count = 0;

var __server_ip_address = "";

// internal hash - list of loaded scripts
var __JSLoaded = __JSLoaded || {};
// internal hash - list of loaded scripts that are not in JSProfile
var __JSLoadedNew = __JSLoadedNew || {};

var __isIE8 = null;

var SendProfileThreshold = 5000;

var SendProfileTimer = 0;

// function to detect IE and version
function isIE8() {
    if (__isIE8 !== null) { return __isIE8; }

    var b = document.createElement('B'),
        docElem = document.documentElement;

    b.innerHTML = '<!--[if IE 8]><b id="iecctest"></b><![endif]-->';
    docElem.appendChild(b);
    __isIE8 = !!document.getElementById('iecctest');
    docElem.removeChild(b);
    return __isIE8;
}

// including script file (without check)
function jsInclude(url) {
    var preloaded;
    var found = false;

    if ((__js__preloadedcore && url in __js__preloadedcore)) {
        preloaded = __js__preloadedcore[url];
        found = true;
    }

    if ((__js__preloaded && url in __js__preloaded)) {
        preloaded = __js__preloaded[url];
        found = true;
    }

    if (found) {
        try {
            __jsInclude__execScript(preloaded + "\n//# sourceURL=" + url);
        }
        catch (ex) {
            alert("An error in the file " + url);
            throw ex;
        }

        //    delete __js__preloaded[url];
        return;
    }

    __JSLoadedNew[url.toLowerCase()] = true;
    
    var script = __jsInclude__blockingAJAXCall(url + "?version=" + global_version, false);
    __js_loaded_files_count++;

    if (script.status == 200 || script.status == 304) {
        try {
            __jsInclude__execScript(script.text + "\n//# sourceURL=" + url);
        }
        catch (ex) {
            //alert("An error in the file " + url);
            ex.customErrorMsg = "An error in the file " + url;
            throw ex;
        }
    }
    else {
        alert("Include error " + script.status + "/" + script.statusText + " \n" + script.text);
    }
}

// Loads file once. If file already loaded (or registered by jsRegister) call returns with no action
function jsRequire(url) {
    if (url.toLowerCase() in __JSLoaded) return;
    jsRegister(url);
    jsInclude(url.toLowerCase());
}

// register loaded script, script files can register himself by this call, and this prevents second file load, if we have to load the file
// with <script> tag. Its recommended to add this call to all js files.
function jsRegister(url) {
    __JSLoaded[url.toLowerCase()] = true;
}

// Loads file once from external url or cdn, doesn't load this file in jsProfile. If file already loaded call returns with no action
function jsLoadNoCache(url) {
    if (url.toLowerCase() in __JSLoaded) return;
    var xmlHttp = __createXmlHttp();
    xmlHttp.open('GET', url, false);
    xmlHttp.send(null);

    if (xmlHttp.status === 200 || xmlHttp.status == 304) {
        try {
            __jsInclude__execScript(xmlHttp.responseText + "\n//# sourceURL=" + url);
            jsRegister(url);
        }
        catch (ex) {
            ex.customErrorMsg = "An error in the file " + url;
            throw ex;
        }
    }
    else {
        alert("Include error " + xmlHttp.status + "/" + xmlHttp.statusText + " \n" + xmlHttp.responseText);
    }
}

function jsSendProfile(deviceType, pageId) {
    var body = [];
    var jsonBody = {};
    jsonBody["version"] = app_version;
	var pageString = pageId ? "pageId=" + pageId: "";
    // we have the profile from the server, no reason to send it back
    //if (__js__preloaded)
    //{
    //    return;
    //}

    //body.push(pageId);
    for (var key in __JSLoadedNew) {
        body.push(key);
    }

    stopJsSendProfileUpdate();

    // no scripts where loaded
    if (body.length === 0) {
        return;
    }
	
    jsonBody["files"] = body;
	
    window.__jsInclude__nonblockingAJAXCall("/setprofile.ashx" + (deviceType ? "?deviceType=" + deviceType + "&"+ pageString : "?"+ pageString), true, JSON.stringify(jsonBody));
}

function jsLoadProfile(fullmode, deviceType, pageId) {
    if (app_version === global_version)
        return;

    //var fullMode = getUrlParameterByName("fullmode");
    var fullModeParam = fullmode && fullmode.toString() === "1" ? "&fullmode=1" : "";
    var deviceTypeParam = (deviceType ? "&deviceType=" + deviceType : "");
  	var pageIdParam = pageId ? "&pageId=" + pageId : "";
    var script;
    var appUrl = "/jsprofile.js?version=" + app_version + fullModeParam + deviceTypeParam + pageIdParam;

    if (app_version.indexOf("https") == -1) {
        script = __jsInclude__blockingAJAXCall(appUrl, false);
    } else {
        try {
            script = __jsInclude__blockingAJAXCall(app_version, false);
        } catch (ex) {
            script = __jsInclude__blockingAJAXCall(appUrl, false);
        }
    }

    var text = script.text;

    if (!text || text == 'null')
        return;

    if (script.status == 200 || script.status == 304) {
        __js__preloaded = JSON.parse(text);
    }
}

function getUrlParameterByName(name, url) {
    if (!url) {
        url = window.location.href;
    }
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);

    if (!results) return null;
    if (!results[2]) return "";

    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function jsLoadProfileCore() {
    jsLoadProfile();
}

/// Service function for creating cross-brouser XMLHttpRequest
function __createXmlHttp() {
    var createXmlHttpForIe = function () {
        try {
            return new ActiveXObject("Msxml2.XMLHTTP.6.0");
        }
        catch (e) { }

        try {
            return new ActiveXObject("Msxml2.XMLHTTP.3.0");
        }
        catch (e) { }

        try {
            return new ActiveXObject("Microsoft.XMLHTTP");
        }
        catch (e) { }

        return null;
    };


    if (isIE8(8)) {
        return createXmlHttpForIe();
    }
    else if (window.XMLHttpRequest) {
        return new XMLHttpRequest();
    }
    else if (window.ActiveXObject) {
        return createXmlHttpForIe();
    }

    return null;
}

/// Performs blocking ajax call to the url. Returns object like { bool status, string statusText,  string text }
///    URL      - URL of script to call
///    isPost   - [optional] is this post request
///    postbody - [optional] body for post request (XmlDocument or string)
function __jsInclude__blockingAJAXCall(URL, isPost, postbody) {
    var xmlHttp = __createXmlHttp();

    var vUrl = (URL.length > 6) && (URL.substr(0, 5) == "http:" || URL.substr(0, 6) == "https:") ? URL : application_root + URL;
    xmlHttp.open((isPost) ? "POST" : "GET", vUrl, false);

    xmlHttp.setRequestHeader("RequestTarget", "XmlService");

    if ((isPost) && (postbody))
        xmlHttp.send(postbody);
    else
        xmlHttp.send(null);

    var status = xmlHttp.status;

    if (__server_ip_address === undefined || __server_ip_address == null || __server_ip_address.length <= 0) {
        try {
            __server_ip_address = xmlHttp.getResponseHeader("x-upstream");
        }
        catch (e) { }
    }

    if (status != 200) {
        return { status: status, statusText: xmlHttp.statusText, text: xmlHttp.responseText };
    }

    return { status: 200, statusText: 'OK', text: xmlHttp.responseText };
}

/// Performs non blocking, no return waiting ajax call to the url.
///    URL      - URL of script to call
///    isPost   - [optional] is this post request
///    postbody - [optional] body for post request (XmlDocument or string)
function __jsInclude__nonblockingAJAXCall(URL, isPost, postbody) {
    var xmlHttp = __createXmlHttp();

    xmlHttp.open((isPost) ? "POST" : "GET", application_root + URL, true);
    xmlHttp.setRequestHeader("RequestTarget", "XmlService");

    if ((isPost) && (postbody))
        xmlHttp.send(postbody);
    else
        xmlHttp.send(null);
}


// i love IE and this execution scopes. This is walkaround of IE feature - all aval calls execute in current (not global) scope.
function __jsInclude__execScript(code) {
    var djGlobal = this; // global scope reference
    if (window.execScript) {
        window.execScript(code); // eval in global scope for IE
        return null; // execScript doesn’t return anything
    }
    return djGlobal.eval ? djGlobal.eval(code) : eval(code);
}

function startJsProfileUpdate() {
    var deviceType =  typeof (Application) !== "undefined" && typeof (Application.deviceType) !== "undefined" ?
        Application.deviceType.type : "";

    SendProfileTimer = setTimeout(function () {
        jsSendProfile(deviceType)
    }, SendProfileThreshold);
}

function stopJsSendProfileUpdate() {
    clearTimeout(SendProfileTimer);
}

window.global_version = global_version;
window.application_root = application_root;
window.__js__preloaded = __js__preloaded;
window.__js__preloadedcore = __js__preloadedcore;
window.__js_loaded_files_count = __js_loaded_files_count;
window.__JSLoaded = __JSLoaded;
window.__JSLoadedNew = __JSLoadedNew;
window.__isIE8 = __isIE8;

window.isIE8 = isIE8;
window.jsInclude = jsInclude;
window.jsRequire = jsRequire;
window.jsRegister = jsRegister;
window.jsLoadNoCache = jsLoadNoCache;
window.jsSendProfile = jsSendProfile;
window.jsLoadProfile = jsLoadProfile;
window.getUrlParameterByName = getUrlParameterByName;
window.jsLoadProfileCore = jsLoadProfileCore;
window.__createXmlHttp = __createXmlHttp;
window.__jsInclude__blockingAJAXCall = __jsInclude__blockingAJAXCall;
window.__jsInclude__nonblockingAJAXCall = __jsInclude__nonblockingAJAXCall;
window.__jsInclude__execScript = __jsInclude__execScript;
window.startJsProfileUpdate = startJsProfileUpdate;
