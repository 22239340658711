﻿function BasketballScore( score1, score2, game )
{
    this.scores =
    {
        firstQuarterScore1: 0,
        firstQuarterScore2: 0,
        secondQuarterScore1: 0,
        secondQuarterScore2: 0,
        firstHalfScore1: 0,
        firstHalfScore2: 0,
        thirdQuarterScore1: 0,
        thirdQuarterScore2: 0,
        fourthQuarterScore1: 0,
        fourthQuarterScore2: 0,
        secondHalfScore1: 0,
        secondHalfScore2: 0,
        totalScore1: 0,
        totalScore2: 0,
        overTimeScore1: 0,
        overTimeScore2: 0
    }

    this.basketballPart = -1;

    if (game.GameData)
    {
        this.basketballPart = BasketballScore.getBasketballPartByGameStatus(game.GameData.Status);
    }

    var scoreAdditionalData = BasketballScore.filterScoreAdditionalData(game.AdditionalData);

    if ( Array.getLength( scoreAdditionalData ) > 0 )
    {
        this.updateScoreFromAdditionalData(scoreAdditionalData);
        
        if (game.GameData && BasketballScore.isOvertimeStatus(game.GameData.Status))
        {
            this.calculateOverTimeScore();
        }
    }
    else
    {
        this.updateTotalScoreOnly( score1, score2 );
    }

    if(game.IsTeamSwapEnabled)
    {
        this.swapScores();
    }

    return this.scores;
}

BasketballScore.emptyScoreValue = "";

BasketballScore.AdditionalDataKeys =
{
    FirstQuarterScore1: 102,
    FirstQuarterScore2: 103,
    SecondQuarterScore1: 104,
    SecondQuarterScore2: 105,
    FirstHalfScore1: 106,
    FirstHalfScore2: 107,
    ThirdQuarterScore1: 108,
    ThirdQuarterScore2: 109,
    FourthQuarterScore1: 110,
    FourthQuarterScore2: 111,
    SecondHalfScore1: 112,
    SecondHalfScore2: 113,
    TotalScore1: 114,
    TotalScore2: 115
};

BasketballScore.PartStatuses =
{
    FirstPart: 11,
    SecondPart: 12,
    ThirdPart: 13,
    FourthPart: 14,
    OverTime: 60
};

BasketballScore.Parts =
{
    First: 1,
    Second: 2,
    Third: 3,
    Fourth: 4,
    OverTime: 5
};

BasketballScore.filterScoreAdditionalData = function ( additionalData )
{
    return Array.findAllValues( additionalData, function ( row )
    {
        return Array.indexOf( BasketballScore.AdditionalDataKeys, row[0] ) != -1 ? true : false;
    } );
};

BasketballScore.getBasketballPartByGameStatus = function (status)
{
    switch ( Math.abs( status ) )
    {
        case BasketballScore.PartStatuses.FirstPart:
            return BasketballScore.Parts.First;
        case BasketballScore.PartStatuses.SecondPart:
            return BasketballScore.Parts.Second;
        case BasketballScore.PartStatuses.ThirdPart:
            return BasketballScore.Parts.Third;
        case BasketballScore.PartStatuses.FourthPart:
            return BasketballScore.Parts.Fourth;
        case BasketballScore.PartStatuses.OverTime:
            return BasketballScore.Parts.OverTime;
    }

    return -1;
};

BasketballScore.isStarted = function ( status )
{
    if (BasketballScore.getBasketballPartByGameStatus(status) != -1)
    {
        return true;
    }

    return false;
};

BasketballScore.isPaused = function ( status )
{
    if (status < 0 && BasketballScore.getBasketballPartByGameStatus(status) != -1)
    {
        return true;
    }

    return false;
};

BasketballScore.getScore = function (score)
{
    return score === this.emptyScoreValue ? '-' : score;
};

BasketballScore.isOvertimeStatus = function (gameStatus)
{
    return gameStatus === BasketballScore.PartStatuses.OverTime;
};

BasketballScore.prototype = 
{ 
    updateScoreFromAdditionalData: function ( scoreAdditionalData )
    {
        for ( var k in scoreAdditionalData )
        {
            var key = scoreAdditionalData[k][0] * 1;
            var score = scoreAdditionalData[k][1] * 1;
            switch ( key )
            {
                case BasketballScore.AdditionalDataKeys.FirstQuarterScore1:
                    this.scores.firstQuarterScore1 = this.formatPartScore(score, BasketballScore.Parts.First);
                    break;
                case BasketballScore.AdditionalDataKeys.FirstQuarterScore2:
                    this.scores.firstQuarterScore2 = this.formatPartScore( score, BasketballScore.Parts.First );
                    break;
                case BasketballScore.AdditionalDataKeys.SecondQuarterScore1:
                    this.scores.secondQuarterScore1 = this.formatPartScore( score, BasketballScore.Parts.Second );
                    break;
                case BasketballScore.AdditionalDataKeys.SecondQuarterScore2:
                    this.scores.secondQuarterScore2 = this.formatPartScore( score, BasketballScore.Parts.Second );
                    break;
                case BasketballScore.AdditionalDataKeys.FirstHalfScore1:
                    this.scores.firstHalfScore1 = score;
                    break;
                case BasketballScore.AdditionalDataKeys.FirstHalfScore2:
                    this.scores.firstHalfScore2 = score;
                    break;
                case BasketballScore.AdditionalDataKeys.ThirdQuarterScore1:
                    this.scores.thirdQuarterScore1 = this.formatPartScore( score, BasketballScore.Parts.Third );
                    break;
                case BasketballScore.AdditionalDataKeys.ThirdQuarterScore2:
                    this.scores.thirdQuarterScore2 = this.formatPartScore( score, BasketballScore.Parts.Third );
                    break;
                case BasketballScore.AdditionalDataKeys.FourthQuarterScore1:
                    this.scores.fourthQuarterScore1 = this.formatPartScore( score, BasketballScore.Parts.Fourth );
                    break;
                case BasketballScore.AdditionalDataKeys.FourthQuarterScore2:
                    this.scores.fourthQuarterScore2 = this.formatPartScore( score, BasketballScore.Parts.Fourth );
                    break;
                case BasketballScore.AdditionalDataKeys.SecondHalfScore1:
                    this.scores.secondHalfScore1 = score;
                    break;
                case BasketballScore.AdditionalDataKeys.SecondHalfScore2:
                    this.scores.secondHalfScore2 = score;
                    break;
                case BasketballScore.AdditionalDataKeys.TotalScore1:
                    this.scores.totalScore1 = score;
                    break;
                case BasketballScore.AdditionalDataKeys.TotalScore2:
                    this.scores.totalScore2 = score;
                    break;                
            }
        }
    },

    formatPartScore: function(score, part)
    {
        if (this.basketballPart !== -1 && this.basketballPart < part)
        {
            return BasketballScore.emptyScoreValue;
        }

        return score;
    },

    updateTotalScoreOnly: function ( totalScore1, totalScore2 )
    {
        this.scores.firstQuarterScore1 = BasketballScore.emptyScoreValue;
        this.scores.firstQuarterScore2 = BasketballScore.emptyScoreValue;
        this.scores.secondQuarterScore1 = BasketballScore.emptyScoreValue;
        this.scores.secondQuarterScore2 = BasketballScore.emptyScoreValue;
        this.scores.firstHalfScore1 = BasketballScore.emptyScoreValue;
        this.scores.firstHalfScore2 = BasketballScore.emptyScoreValue;
        this.scores.thirdQuarterScore1 = BasketballScore.emptyScoreValue;
        this.scores.thirdQuarterScore2 = BasketballScore.emptyScoreValue;
        this.scores.fourthQuarterScore1 = BasketballScore.emptyScoreValue;
        this.scores.fourthQuarterScore2 = BasketballScore.emptyScoreValue;
        this.scores.secondHalfScore1 = BasketballScore.emptyScoreValue;
        this.scores.secondHalfScore2 = BasketballScore.emptyScoreValue;
        this.scores.totalScore1 = totalScore1 * 1;
        this.scores.totalScore2 = totalScore2 * 1;
    },

    swapScores: function ()
    {
        var temp = this.scores.firstQuarterScore1;
        this.scores.firstQuarterScore1 = this.scores.firstQuarterScore2;
        this.scores.firstQuarterScore2 = temp;

        temp = this.scores.secondQuarterScore1;
        this.scores.secondQuarterScore1 = this.scores.secondQuarterScore2;
        this.scores.secondQuarterScore2 = temp;

        temp = this.scores.firstHalfScore1;
        this.scores.firstHalfScore1 = this.scores.firstHalfScore2;
        this.scores.firstHalfScore2 = temp;

        temp = this.scores.thirdQuarterScore1;
        this.scores.thirdQuarterScore1 = this.scores.thirdQuarterScore2;
        this.scores.thirdQuarterScore2 = temp;

        temp = this.scores.fourthQuarterScore1;
        this.scores.fourthQuarterScore1 = this.scores.fourthQuarterScore2;
        this.scores.fourthQuarterScore2 = temp;

        temp = this.scores.secondHalfScore1;
        this.scores.secondHalfScore1 = this.scores.secondHalfScore2;
        this.scores.secondHalfScore2 = temp;

        temp = this.scores.totalScore1;
        this.scores.totalScore1 = this.scores.totalScore2;
        this.scores.totalScore2 = temp;

        temp = this.scores.overTimeScore1;
        this.scores.overTimeScore1 = this.scores.overTimeScore2;
        this.scores.overTimeScore2 = temp;
    },

    calculateOverTimeScore: function()
    {
        this.scores.overTimeScore1 = this.scores.totalScore1 - (this.scores.firstHalfScore1 + this.scores.secondHalfScore1);
        this.scores.overTimeScore2 = this.scores.totalScore2 - (this.scores.firstHalfScore2 + this.scores.secondHalfScore2);
    }
}

window.BasketballScore = BasketballScore;