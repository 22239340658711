﻿var CryptoHelper = (function ()
{
    var api = {},
        key, iv,
        storageKey = 'CryptoHelperConfig';

    var saveConfig = function(config)
    {
        if (config)
        {
            StorageUtils.saveToStorage(storageKey, config, localStorage);
        }
    };

    var restoreConfig = function()
    {
        var savedConfigJson = StorageUtils.getFromStorage(storageKey, localStorage);
        if (savedConfigJson)
        {
            var config = JSON.parse(savedConfigJson);
            key = config.key;
            iv = config.iv;
        }
    };

    var getByteFromStringWithExactSize = function(str, size)
    {
        var value = CryptoJS.enc.Utf8.parse(str).toString();
        if (value.length < size)
        {
            value = value + Array(size - value.length + 1).join('0');
        }

        if (value.length > size)
        {
            value = value.substr(0, size);
        }

        return CryptoJS.enc.Hex.parse(value);
    };

    api.setConfig = function (config)
    {
        if (!config || !config.key || !config.iv)
        {
            return;
        }

        // Clear previously saved config from localStorage before we set a new one
        api.clearConfig();

        key = getByteFromStringWithExactSize(config.key, 64);
        iv = getByteFromStringWithExactSize(config.iv, 32);
        var saveConfigJson = JSON.stringify(
            {
                key: key,
                iv: iv
            });
        saveConfig(saveConfigJson);
    };

    api.clearConfig = function ()
    {
        StorageUtils.removeFromStorage(storageKey, localStorage);
    };

    api.decrypt = function(data)
    {
        restoreConfig();
        var cipherData = CryptoJS.enc.Hex.parse(data);
        if (!cipherData)
        {
            return '';
        }

        var decrypted = CryptoJS.AES.decrypt(
            {
                ciphertext: cipherData
            },
            key,
            {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });
        return decrypted.toString(CryptoJS.enc.Utf8);
    };

    api.checkResult = function(test, data)
    {
        if (!test || !data)
        {
            return false;
        }

        return test == this.decrypt(data);
    };

    return api;
})();

window.CryptoHelper = CryptoHelper;
