﻿var FractionalOddsConverter =
{
    FractionalTable: [],

    init: function ()
    {   // all fractional odds are hardcoded here, 
        //because they are pretty much static
        //and this way we can store them in jsprofile and brouser cache 
        var res = [[-100000, '1/1000'], [-50000, '1/500'], [-35000, '1/350'], [-30000, '1/300'], [-25000, '1/250'], [-20000, '1/200'], [-15000, '1/150'], [-10000, '1/100'], [-8000, '1/80'],
            [-6600, '1/66'], [-5000, '1/50'], [-4000, '1/40'], [-3300, '1/33'], [-3000, '1/30'], [-2800, '1/28'], [-2750, '2/55'], [-2500, '1/25'], [-2200, '1/22'], [-2000, '1/20'],
            [-1800, '1/18'], [-1750, '2/35'], [-1600, '1/16'], [-1500, '1/15'], [-1400, '1/14'], [-1250, '2/25'], [-1200, '1/12'], [-1100, '1/11'], [-1000, '1/10'], [-900, '1/9'],
            [-850, '2/17'], [-800, '1/8'], [-750, '2/15'], [-700, '1/7'], [-675, '4/27'], [-650, '2/13'], [-625, '4/25'], [-600, '1/6'], [-575, '4/23'], [-550, '2/11'], [-525, '4/21'],
            [-500, '1/5'], [-475, '4/19'], [-450, '2/9'], [-435, '20/87'], [-425, '4/17'], [-415, '20/83'], [-400, '1/4'], [-380, '5/19'], [-365, '20/73'], [-360, '5/18'], [-350, '2/7'],
            [-335, '20/67'], [-333, '3/10'], [-325, '4/13'], [-310, '10/31'], [-300, '1/3'], [-295, '20/59'], [-285, '20/57'], [-280, '5/14'], [-275, '4/11'], [-270, '10/27'], [-260, '5/13'],
            [-255, '20/51'], [-250, '2/5'], [-245, '20/49'], [-240, '5/12'], [-230, '10/23'], [-225, '4/9'], [-220, '5/11'], [-215, '20/43'], [-210, '10/21'], [-205, '20/41'], [-200, '1/2'],
            [-195, '20/39'], [-190, '10/19'], [-188, '8/15'], [-180, '5/9'], [-175, '4/7'], [-170, '10/17'], [-165, '20/33'], [-163, '8/13'], [-160, '5/8'], [-155, '20/31'], [-150, '4/6'],
            [-145, '20/29'], [-140, '5/7'], [-138, '8/11'], [-135, '20/27'], [-130, '10/13'], [-125, '4/5'], [-120, '5/6'], [-118, '50/59'], [-116, '25/29'], [-115, '20/23'], [-114, '50/57'],
            [-112, '25/28'], [-110, '10/11'], [-108, '25/27'], [-106, '50/53'], [-105, '20/21'], [-104, '25/26'], [-102, '50/51'], [100, '1/1'], [102, '51/50'], [104, '26/25'], [105, '21/20'],
            [106, '53/50'], [108, '27/25'], [110, '11/10'], [112, '28/25'], [114, '57/50'], [115, '23/20'], [116, '29/25'], [118, '59/50'], [120, '6/5'], [125, '5/4'], [130, '13/10'],
            [135, '27/20'], [138, '11/8'], [140, '7/5'], [145, '29/20'], [150, '6/4'], [155, '31/20'], [160, '8/5'], [162, '81/50'], [163, '13/8'], [165, '33/20'], [170, '17/10'], [175, '7/4'],
            [180, '9/5'], [185, '37/20'], [188, '15/8'], [190, '19/10'], [195, '39/20'], [200, '2/1'], [205, '41/20'], [210, '21/10'], [215, '43/20'], [220, '11/5'], [225, '9/4'], [230, '23/10'],
            [235, '47/20'], [240, '12/5'], [245, '49/20'], [250, '5/2'], [255, '51/20'], [260, '13/5'], [265, '53/20'], [270, '27/10'], [275, '11/4'], [280, '14/5'], [285, '57/20'],
            [290, '29/10'], [295, '59/20'], [300, '3/1'], [320, '16/5'], [330, '33/10'], [333, '10/3'], [340, '17/5'], [350, '7/2'], [360, '18/5'], [375, '15/4'], [380, '19/5'], [400, '4/1'], [425, '17/4'], [450, '9/2'],
            [475, '19/4'], [500, '5/1'], [525, '21/4'], [550, '11/2'], [575, '23/4'], [600, '6/1'], [625, '25/4'], [650, '13/2'], [675, '27/4'], [700, '7/1'], [725, '29/4'], [750, '15/2'],
            [775, '31/4'], [800, '8/1'], [825, '33/4'], [850, '17/2'], [875, '35/4'], [900, '9/1'], [925, '37/4'], [950, '19/2'], [975, '39/4'], [1000, '10/1'], [1050, '21/2'], [1100, '11/1'],
            [1200, '12/1'], [1300, '13/1'], [1400, '14/1'], [1500, '15/1'], [1600, '16/1'], [1700, '17/1'], [1800, '18/1'], [1900, '19/1'], [2000, '20/1'], [2100, '21/1'], [2200, '22/1'],
            [2300, '23/1'], [2400, '24/1'], [2500, '25/1'], [2600, '26/1'], [2700, '27/1'], [2800, '28/1'], [2900, '29/1'], [3000, '30/1'], [3100, '31/1'], [3200, '32/1'], [3300, '33/1'],
            [3400, '34/1'], [3500, '35/1'], [3600, '36/1'], [3700, '37/1'], [3800, '38/1'], [3900, '39/1'], [4000, '40/1'], [4100, '41/1'], [4200, '42/1'], [4300, '43/1'], [4400, '44/1'],
            [4500, '45/1'], [4600, '46/1'], [4700, '47/1'], [4800, '48/1'], [4900, '49/1'], [5000, '50/1'], [5100, '51/1'], [5200, '52/1'], [5300, '53/1'], [5400, '54/1'], [5500, '55/1'],
            [5600, '56/1'], [5700, '57/1'], [5800, '58/1'], [5900, '59/1'], [6000, '60/1'], [6100, '61/1'], [6200, '62/1'], [6300, '63/1'], [6400, '64/1'], [6500, '65/1'], [6600, '66/1'],
            [6700, '67/1'], [6800, '68/1'], [6900, '69/1'], [7000, '70/1'], [7100, '71/1'], [7200, '72/1'], [7300, '73/1'], [7400, '74/1'], [7500, '75/1'], [7600, '76/1'], [7700, '77/1'],
            [7800, '78/1'], [7900, '79/1'], [8000, '80/1'], [8100, '81/1'], [8200, '82/1'], [8300, '83/1'], [8400, '84/1'], [8500, '85/1'], [8600, '86/1'], [8700, '87/1'], [8800, '88/1'],
            [8900, '89/1'], [9000, '90/1'], [9100, '91/1'], [9200, '92/1'], [9300, '93/1'], [9400, '94/1'], [9500, '95/1'], [9600, '96/1'], [9700, '97/1'], [9800, '98/1'], [9900, '99/1'],
            [10000, '100/1'], [10500, '105/1'], [11000, '110/1'], [11500, '115/1'], [12000, '120/1'], [12500, '125/1'], [13000, '130/1'], [13500, '135/1'], [14000, '140/1'], [14500, '145/1'],
            [15000, '150/1'], [15500, '155/1'], [16000, '160/1'], [16500, '165/1'], [17000, '170/1'], [17500, '175/1'], [18000, '180/1'], [18500, '185/1'], [19000, '190/1'], [19500, '195/1'],
            [20000, '200/1'], [22500, '225/1'], [25000, '250/1'], [27500, '275/1'], [30000, '300/1'], [32500, '325/1'], [35000, '350/1'], [37500, '375/1'], [40000, '400/1'], [42500, '425/1'],
            [45000, '450/1'], [47500, '475/1'], [50000, '500/1'], [52500, '525/1'], [55000, '550/1'], [57500, '575/1'], [60000, '600/1'], [62500, '625/1'], [65000, '650/1'], [67500, '675/1'],
            [70000, '700/1'], [72500, '725/1'], [75000, '750/1'], [77500, '775/1'], [80000, '800/1'], [82500, '825/1'], [85000, '850/1'], [87500, '875/1'], [90000, '900/1'], [92500, '925/1'],
            [95000, '950/1'], [97500, '975/1'], [100000, '1000/1']];
        FractionalOddsConverter.parse(res);
    },

    parse: function (data)
    {
        //        var res = [];
        //        for (var i = 0; i < data.length; i++)
        //        {
        //            var dd = data[i];
        //            res[dd[0]] = dd[1];
        //        }
        //        FractionalOddsConverter.FractionalTable = res;
        FractionalOddsConverter.FractionalTable = data;
    },

    convert: function (odds)
    {
        if (odds > 100000) // number is not in table
            return Math.floor(odds / 100) + "/1";
        if (odds < -100000) // number is not in table
            return "1/" + Math.floor(-odds / 100);

        var rrs = Array.BinarySearch(FractionalOddsConverter.FractionalTable, function (item) { return item[0] - odds; });
        if (rrs < 0) rrs = ~rrs;

        if (FractionalOddsConverter.FractionalTable[rrs][0] > odds)
            rrs--;

        return FractionalOddsConverter.FractionalTable[rrs][1];
    },

    // Encodes the numerator/denominator pair of the fraction odd to an integer value
    encodeFraction: function (fraction)
    {
        if (typeof fraction !== "string")
            return 0;
        var n = fraction.indexOf("/");
        var num = fraction.substr(0, n) * 1;
        var denom = fraction.substr(n + 1, fraction.length - n - 1) * 1;

        // Use one million as number with which to encode, cause it is almost 
        // impossible to have fractions with deniminators bigger than it
        return Math.round(1000000 * num + denom);
    },

    // Does the opposite of encodeFraction(). Takes and argument like the number 79000012
    // and returns the resulting decoded fraction odd as a string - 79/12.
    decodeFraction: function (n)
    {
        var num = Math.floor(n / 1000000);
        var denom = n % 1000000;
        return num + "/" + denom;
    }
}

FractionalOddsConverter.init();

window.FractionalOddsConverter = FractionalOddsConverter;
