﻿/*globals  Facade, PushOddsUrl, UserInfo, usePushOddsForAllUsers, BetSlipRegulations */
/* exported sogeiRun*/

function initPushServer ()
{
  	var pushOddsUrl = typeof PushOddsUrl !== "undefined" ? PushOddsUrl : "";
	window.PushServer = {
    	server: pushOddsUrl,
    	signalRServer: pushOddsUrl + "/signalr"
	};
}

initPushServer();
/*jshint latedef: nofunc */

function sogeiRun() {
    "use strict";

    if (window.SogeiPlugin) {
        window.SogeiPlugin.Run(window.PushServer.server);
        setCustomerIDForPush("SogeiPlugin");
    }
    else {
        setTimeout(sogeiRun, 1000);
    }
}

function userBetsPluginRun() {
    "use strict";

    if (window.UserBetsPlugin) {
        window.UserBetsPlugin.Run(window.PushServer.server);
        setCustomerIDForPush("UserBetsPlugin");
    }
    else {
        setTimeout(userBetsPluginRun, 1000);
    }
}

function setCustomerIDForPush(pluginName) {
    "use strict";

    if (UserInfo.current && UserInfo.current.userID && window[pluginName]) {
        window[pluginName].sendMessageToPush("LogIn", { "UserID": UserInfo.current.userID });
        UserInfo.onLogout["StopPushForLoggedIn"] = function () {
            if (UserInfo.current) {
                window[pluginName].sendMessageToPush("LogOut", { "UserID": UserInfo.current.userID });
            }
        };
    }
    else {
        setTimeout(setCustomerIDForPush.bind(null, pluginName), 1000);
    }
}

window.addEventListener("load", function () {
    "use strict";
    initPushServer();
    setTimeout(function () {
        if (window.PushServer.server) {
            if (usePushOddsForAllUsers || UserInfo.current) {
                runPlugins(!!UserInfo.current);
            }
            else {
                UserInfo.onLogin["PushOddsOnlyForLoggedIn"] = function () {
                    runPlugins(true);
                };
            }
        }

        function runPlugins(isLoggedIn) {
            window.PushOdds.Run(window.PushServer.signalRServer);
            window.Communicator.Events.OnConnectionEstablished["OnConnectionEstablished"] = Facade.onConnectionEstablished;

            if (isLoggedIn) {
                userBetsPluginRun();
                if (typeof (BetSlipRegulations) != "undefined" && BetSlipRegulations.IsItalian) {
                    sogeiRun();
                }
            }
        }
    }, 0);
});

window.sogeiRun = sogeiRun;
window.userBetsPluginRun = userBetsPluginRun;
window.setCustomerIDForPush = setCustomerIDForPush;
