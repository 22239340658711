function QALine(initArray)
{
    'use strict';

    if (initArray)
    {
        this.ID = initArray[0];
        this.Odds = initArray[1];
        this.LineName = initArray[2];
        this.AsianLineName = initArray[11];
        this.LineTypeID = initArray[3];
        this.FractOddsDividend = initArray[5];
        this.FractOddsDivisor = initArray[6];
        this.LineStateID = initArray[7];

        if (this.FractOddsDividend && this.FractOddsDivisor)
        {
            this.FractionalOdds = this.FractOddsDividend + '/' + this.FractOddsDivisor;
        }
        else
        {
            this.FractionalOdds = null;
        }

        if (isLiveBettingPage)
        {
            this.Updated = false;
            this.Deleted = false;
            this.HasCombo = false;

            this.IsOptional = true;
        }
        else
        {
            this.Points = initArray[4];
        }
        this.LineTypeName = initArray[8];
        this.QATypeParameter2 = initArray[9];
        this.QATypeParameter1 = initArray[10];
        this.Team = initArray[12];
        this.FeedSelectionID = initArray[13];
        this.IsYourBetEnabled = !!initArray[14];
        this.AlternativeLineId = initArray[15];
        this.AlternativeLineTypeId = parseInt(initArray[16]);
        this.AlternativeLineQATypeParameter1 = initArray[17];
        this.AlternativeLineQATypeParameter2 = initArray[18];
    }
    else
    {
        this.ID = 0;
        this.Odds = false;
        this.LineName = false;
        this.LineTypeID = false;
        if (isLiveBettingPage)
        {
            this.Updated = false;
            this.Deleted = false;
            this.HasCombo = false;
            this.IsOptional = true;
        }
        else
        {
            this.Points = false;
        }
        this.IsYourBetEnabled = false;
    }

    this.Swapped = false;
}

QALine.prototype =
{
    // live only!!!
    getUpdateString: function ()
    {
        'use strict';

        return '{0},{1}'.format(this.ID, this.Odds);
    },

    /* jshint maxcomplexity: 8 */
    updateFromServer: function (data)
    {
        'use strict';

        if (data.LineName)
        {
            this.LineName = data.LineName;

            // Mark line for swapping because of name changing from updateEvents
            this.Swapped = false;
        }

        if (data.AsianLineName)
        {
            this.AsianLineName = data.AsianLineName;

            // Mark line for swapping because of name changing from updateEvents
            this.Swapped = false;
        }

        if (data.QATypeParameter2 !== undefined){
            this.QATypeParameter2 = data.QATypeParameter2;
        }

        if (data.QATypeParameter1 !== undefined) {
            this.QATypeParameter1 = data.QATypeParameter1;
        }
      
        if (data.AlternativeLineQATypeParameter1 !== undefined){
            this.AlternativeLineQATypeParameter1 = data.AlternativeLineQATypeParameter1;
        }

        if (data.AlternativeLineQATypeParameter2 !== undefined) {
            this.AlternativeLineQATypeParameter2 = data.AlternativeLineQATypeParameter2;
        }

        if (data.Odds === undefined)
        {
            this.Updated = false;
            return;
        }

        if (data.Odds == -1)
        {
            this.Deleted = true;
            return;
        }

        this.Updated = this.compareOdds(Odds.round(this.Odds), Odds.round(data.Odds));
        this.Deleted = false;

        this.Odds = data.Odds;
        this.FractOddsDividend = data.FractOddsDividend;
        this.FractOddsDivisor = data.FractOddsDivisor;
        this.LineStateID = data.LineStateID;

        if (this.FractOddsDividend && this.FractOddsDivisor)
        {
            this.FractionalOdds = this.FractOddsDividend + '/' + this.FractOddsDivisor;
        }
        else
        {
            this.FractionalOdds = null;
        }
    },
    /* jshint maxcomplexity: 5 */

    __resetUpdated: function ()
    {
        'use strict';

        this.Updated = false;
    },

    setNewOdds: function (odds)
    {
        'use strict';

        this.Updated = this.compareOdds(this.Odds, odds);
        this.Odds = odds;
    },

    hasOdds: function ()
    {
        'use strict';

        return this.Odds;
    },

    compareOdds: function (currentOdds, newOdds)
    {
        return Data.compare(currentOdds, newOdds);
    }
};

window.QALine = QALine;
