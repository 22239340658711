﻿/* exported PopupPanel */
/* globals Application, MobilePanel, PanelPosition */

function PopupPanel(config)
{
    'use strict';
    MobileCenterPanel.call(this, config);

    this.Position = PanelPosition.POPUP;
    this.element = {};

    this.disableClosing = config.disableClosing;
    this.scrollPosition = 0;
}

$.extend(PopupPanel.prototype, MobileCenterPanel.prototype, {
    init: function ()
    {
        'use strict';
        MobileCenterPanel.prototype.init.call(this);

        this.element = document.getElementById(this.ID);
        document.body.insertBefore(this.element, document.getElementById('overlay'));

        var closeBtns = this.element.getElementsByClassName('close_btn');
        var closedBtnsCount = closeBtns.length;

        for (var i = 0; i < closedBtnsCount; i += 1) {
            closeBtns[i].addEventListener('click', function ()
            {
                Application.goBack();
            }, false);
        }

        if (!this.disableClosing)
        {
            this.initClosingByClickOutside();
        }
    },

    activate: function()
    {
        'use strict';

        MobilePanel.prototype.activate.call(this);
    },

    switchToPanel: function (toPanel, transitionSettings)
    {
        'use strict';
        if (this.ID === toPanel.ID) { return; }
        if(toPanel.Position === PanelPosition.POPUP) {
            this.switchToPopupPanel(toPanel, transitionSettings);
        } else {
            this.switchToStandardPanel(toPanel, transitionSettings);
        }
    },

    switchToPopupPanel: function(toPanel, transitionSettings) 
    {
        if(transitionSettings.Reverse) {
            this.leave(transitionSettings);
            toPanel.enter(transitionSettings);
        } else {
            toPanel.enter(transitionSettings, this);
        }
    },

    switchToStandardPanel: function(toPanel, transitionSettings) 
    {
        let panel = this;
        while(panel && panel.Position === PanelPosition.POPUP) {
            panel.leave(transitionSettings);
            panel = panel.previousPanel;
        }
        if(panel != toPanel) {
            panel.switchToPanel(toPanel, transitionSettings);
        }
    },

    freezeBodyScroll: function() 
    {
        if (Application.isInIframe()) {
            document.body.classList.add('popup_panel_open'); 
        } else {
            this.scrollPosition = window.pageYOffset;
            document.body.classList.add('popup_panel_open-fixed-body');
            document.body.style.top = '-' + this.scrollPosition + 'px';
        }
    },

    unfreezeBodyScroll: function() 
    {
        if (Application.isInIframe()) {
            document.body.classList.remove('popup_panel_open');    
        } else {
            document.body.classList.remove('popup_panel_open-fixed-body');
            document.body.style.removeProperty('top');
            window.scrollTo(0, this.scrollPosition);
        }
    },

    enter: function (transitionSettings, previousPanel)
    {
        'use strict';
        if(previousPanel) {
            this.previousPanel = previousPanel;
            if(this.previousPanel.Position === PanelPosition.POPUP && !this.previousPanel.isInPopupHost) {
                this.previousPanel.element.classList.remove('isVisible');
            }
        }
        if(!transitionSettings.Reverse)
        {
            var baseZIndex = 12;
            this.index = this.previousPanel && this.previousPanel.index ? this.previousPanel.index + 1 : baseZIndex;
        }
        this.setParams(transitionSettings.Params);
        this.element.classList.add('isVisible');
        this.element.style.zIndex = this.index;
        this.freezeBodyScroll();

        this.activate();

        Application.enableTransition();
    },

    leave: function ()
    {
        'use strict';

        this.element.classList.remove('isVisible');
        this.unfreezeBodyScroll();

        this.deactivate();
    },

    closeByClickingOutside: function (e)
    {
        if (!e.target.classList.contains('close_btn') &&
            (e.target.classList.contains('popup_panel') || e.target.classList.contains('popup_panel_content_wrap')))
        {
            var panelWidth = this.element.clientWidth;
            var scrollBarWidth = 10;
            var clickableArea = panelWidth - scrollBarWidth;
            var blocksContainer = this.element.getElementsByClassName('popup_panel_content')[0];
            var eventTargetClientRect = (blocksContainer && blocksContainer.getBoundingClientRect()) || {};

            //no clicking on the vertical scrollbar area:
            if (e.clientX < clickableArea &&
                    //not in dialogue panel
                  !((eventTargetClientRect.left <= e.clientX && eventTargetClientRect.right  >= e.clientX) &&
                    (eventTargetClientRect.top  <= e.clientY && eventTargetClientRect.bottom >= e.clientY))
               )
            {
                Application.goBack();
            }
        }
    },

    initClosingByClickOutside: function ()
    {
        this.element.addEventListener('click', this.closeByClickingOutside.bind(this), false);

        //var popuppanelcontentwrap = this.element.getelementsbyclassname('popup_panel_content_wrap')[0];
        //popuppanelcontentwrap.addeventlistener('click', function (e)
        //{
        //    e.stoppropagation();

        //}, false);
    },

    hideSidePanels: function ()
    {
        return false;
    }
});

window.PopupPanel = PopupPanel;
