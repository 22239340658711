﻿function TennisScore(score1, score2)
{
    // Tries to define match type and whether last set is advantage set
    // If this additional information is coded into score of the user, it should be in following format
    /*********************************************************
    * 2  ---- Matchtype = 3 and LastSetIsAdvantageSet = False
    * 3  ---- Matchtype = 3 and LastSetIsAdvantageSet = True
    * 4  ---- Matchtype = 5 and LastSetIsAdvantageSet = False
    * 5  ---- Matchtype = 5 and LastSetIsAdvantageSet = True
    *********************************************************/
    if (score1.charAt(score1.length - 1) == "1")
    {
        if (score2.charAt(score2.length - 1) == "2")
            initialize(score1, score2, 3, false, this);
        else if (score2.charAt(score2.length - 1) == "3")
            initialize(score1, score2, 3, true, this);
        else if (score2.charAt(score2.length - 1) == "4")
            initialize(score1, score2, 5, false, this);
        else if (score2.charAt(score2.length - 1) == "5")
            initialize(score1, score2, 5, true, this);
    }
    else
    {
        if (score1.charAt(score1.length - 1) == "2")
            initialize(score1, score2, 3, false, this);
        else if (score1.charAt(score1.length - 1) == "3")
            initialize(score1, score2, 3, true, this);
        else if (score1.charAt(score1.length - 1) == "4")
            initialize(score1, score2, 5, false, this);
        else if (score1.charAt(score1.length - 1) == "5")
            initialize(score1, score2, 5, true, this);
    }
}

function initialize(score1, score2, matchType, lastSetIsAdvantageSet, ref)
{
    ref.MatchType = matchType;
    ref.LastSetIsAdvantageSet = lastSetIsAdvantageSet;

    if (score1.charAt(score1.length - 1) == '1')
        ref.Servs = 0;
    else if (score2.charAt(score2.length - 1) == '1')
        ref.Servs = 1;

    var startIndex = score1.length - 3;
    if (startIndex >= 0)
    {
        ref.Player1Points = parseInt(score1.substr(startIndex, 2));
        if (parseInt(ref.Player1Points) > 40)
            ref.Player1Points = 'A';

        // Set 1
        startIndex = score1.length - 4;
        if (startIndex >= 0)
        {
            var set1 = score1.substr(startIndex, 1);
            if (set1)
                ref.Player1Set1 = parseInt(set1);

            // Set 2
            startIndex = score1.length - 5;
            if (startIndex >= 0)
            {
                var set2 = score1.substr(startIndex, 1);
                if (set2)
                    ref.Player1Set2 = parseInt(set2);

                // Set 3
                startIndex = score1.length - 7;
                if (startIndex >= -1)
                {
                    if (startIndex == -1)
                        set3 = score1.substr(0, 1);
                    else
                        set3 = score1.substr(startIndex, 2);
                    if (set3)
                        ref.Player1Set3 = parseInt(set3);

                    // Set 4
                    startIndex = score1.length - 8;
                    if (startIndex >= 0)
                    {
                        var set4 = score1.substr(startIndex, 1);
                        if (set4)
                            ref.Player1Set4 = parseInt(set4);

                        // Set 5                            
                        if (startIndex > 0)
                        {
                            var set5 = score1.substr(0, startIndex);
                            if (set5)
                                ref.Player1Set5 = parseInt(set5);
                        }
                    }
                }
            }
        }
    }

    startIndex = score2.length - 3;
    if (startIndex >= 0)
    {
        ref.Player2Points = parseInt(score2.substr(startIndex, 2));
        if (parseInt(ref.Player2Points) > 40)
            ref.Player2Points = 'A';

        startIndex = score2.length - 4;
        if (startIndex >= 0)
        {
            // Set 1
            set1 = score2.substr(startIndex, 1);
            if (set1)
                ref.Player2Set1 = parseInt(set1);

            startIndex = score2.length - 5;
            if (startIndex >= 0)
            {
                // Set 2
                set2 = score2.substr(startIndex, 1);
                if (set2)
                    ref.Player2Set2 = parseInt(set2);

                startIndex = score2.length - 7;
                if (startIndex >= -1)
                {
                    // Set 3
                    if (startIndex == -1)
                        set3 = score2.substr(0, 1);
                    else
                        set3 = score2.substr(startIndex, 2);
                    if (set3)
                        ref.Player2Set3 = parseInt(set3);

                    startIndex = score2.length - 8;
                    if (startIndex >= 0)
                    {
                        // Set 4
                        set4 = score2.substr(startIndex, 1);
                        if (set4)
                            ref.Player2Set4 = parseInt(set4);

                        // Set 5                            
                        if (startIndex > 0)
                        {
                            set5 = score2.substr(0, startIndex);
                            if (set5)
                                ref.Player2Set5 = parseInt(set5);
                        }
                    }
                }
            }
        }
    }

    // Setting uninitialized games values
    // Points
    if (ref.Player1Points == '-' & ref.Player2Points != '-')
        ref.Player1Points = '0';

    if (ref.Player1Points != '-' & ref.Player2Points == '-')
        ref.Player2Points = '0';

    // Set 1
    if (ref.Player1Set1 == '-' & ref.Player2Set1 != '-')
        ref.Player1Set1 = '0';

    if (ref.Player1Set1 != '-' & ref.Player2Set1 == '-')
        ref.Player2Set1 = '0';

    // Set 2
    if (ref.Player1Set2 == '-' & ref.Player2Set2 != '-')
        ref.Player1Set2 = '0';

    if (ref.Player1Set2 != '-' & ref.Player2Set2 == '-')
        ref.Player2Set2 = '0';

    // Set 3
    if (ref.Player1Set3 == '-' & ref.Player2Set3 != '-')
        ref.Player1Set3 = '0';

    if (ref.Player1Set3 != '-' & ref.Player2Set3 == '-')
        ref.Player2Set3 = '0';

    // Set 4
    if (ref.Player1Set4 == '-' & ref.Player2Set4 != '-')
        ref.Player1Set4 = '0';

    if (ref.Player1Set4 != '-' & ref.Player2Set4 == '-')
        ref.Player2Set4 = '0';

    // Set 5
    if (ref.Player1Set5 == '-' & ref.Player2Set5 != '-')
        ref.Player1Set5 = '0';

    if (ref.Player1Set5 != '-' & ref.Player2Set5 == '-')
        ref.Player2Set5 = '0';

    // Defining sets score
    if (ref.Player1Set1 != '-' & ref.Player2Set1 != '-')
    {
        var set1Winner = getSetWinner(ref.Player1Set1, ref.Player2Set1);
        if (set1Winner > 0)
            ref.ScoreSetsPlayer1++;
        else if (set1Winner < 0)
            ref.ScoreSetsPlayer2++;

        if (ref.Player1Set2 != '-' & ref.Player2Set2 != '-')
        {
            var set2Winner = getSetWinner(ref.Player1Set2, ref.Player2Set2);
            if (set2Winner > 0)
                ref.ScoreSetsPlayer1++;
            else if (set2Winner < 0)
                ref.ScoreSetsPlayer2++;

            if (ref.Player1Set3 != '-' & ref.Player2Set3 != '-')
            {
                var set3Winner;

                if (!isLiveBettingPage)
                {
                    if (ref.MatchType == 3)
                        set3Winner = getLastSetSetWinner(ref.Player1Set3, ref.Player2Set3);
                    else
                        set3Winner = getSetWinner(ref.Player1Set3, ref.Player2Set3);
                }
                else
                {
                    set3Winner = getSetWinner(ref.Player1Set3, ref.Player2Set3);
                }

                if (set3Winner > 0)
                    ref.ScoreSetsPlayer1++;
                else if (set3Winner < 0)
                    ref.ScoreSetsPlayer2++;

                if (ref.Player1Set4 != '-' & ref.Player2Set4 != '-')
                {
                    var set4Winner = getSetWinner(ref.Player1Set4, ref.Player2Set4);
                    if (set4Winner > 0)
                        ref.ScoreSetsPlayer1++;
                    else if (set4Winner < 0)
                        ref.ScoreSetsPlayer2++;

                    if (ref.Player1Set5 != '-' & ref.Player2Set5 != '-')
                    {
                        if (isLiveBettingPage)
                        {
                            var set5Winner = getLastSetSetWinner(ref.Player1Set5, ref.Player2Set5);
                        }
                        else
                        {
                            var set5Winner = getSetWinner(ref.Player1Set5, ref.Player2Set5);
                        }
                        if (set5Winner > 0)
                            ref.ScoreSetsPlayer1++;
                        else if (set5Winner < 0)
                            ref.ScoreSetsPlayer2++;
                    }
                }
            }
        }
    }
}

function getSetWinner(gamesPlayer1, gamesPlayer2)
{
    if (Math.abs(gamesPlayer1 - gamesPlayer2) > 1)
    {
        if (gamesPlayer1 >= 6) // Player 1 wins the set
            return 2;
        else if (gamesPlayer2 >= 6) // Player 2 wins the set
            return -2;
    }
    else if (Math.abs(gamesPlayer1 - gamesPlayer2) == 1)
    {
        if (gamesPlayer1 == 7)
            return 1;
        else if (gamesPlayer2 == 7)
            return -1;
    }
    return 0;
}

function getLastSetSetWinner(gamesPlayer1, gamesPlayer2)
{
    if (Math.abs(gamesPlayer1 - gamesPlayer2) > 1)
    {
        if (gamesPlayer1 >= 6) // Player 1 wins the set
            return 2;
        else if (gamesPlayer2 >= 6) // Player 2 wins the set
            return -2;
    }
    else if (Math.abs(gamesPlayer1 - gamesPlayer2) == 1)
    {
        if (this.LastSetIsAdvantageSet)
        {
            return 0;
        }
        else
        {
            if (gamesPlayer1 == 7)
                return 1;
            else if (gamesPlayer2 == 7)
                return -1;
        }
    }
    return 0;
}

TennisScore.prototype =
{
    Servs: -1,

    Player1Points: '-',
    Player2Points: '-',

    Player1Games: '-',
    Player2Games: '-',

    Player1Set1: '-',
    Player1Set2: '-',
    Player1Set3: '-',
    Player1Set4: '-',
    Player1Set5: '-',

    Player2Set1: '-',
    Player2Set2: '-',
    Player2Set3: '-',
    Player2Set4: '-',
    Player2Set5: '-',

    ScoreSetsPlayer1: 0,
    ScoreSetsPlayer2: 0,

    MatchType: 3,
    LastSetIsAdvantageSet: 0
}

window.TennisScore = TennisScore;