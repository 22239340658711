// not live !!!
var __AJAXDataServiceBase = __AJAXDataServiceBase ? __AJAXDataServiceBase : "/";

function AJAXDataService(url, connid)
{
    this.CurrentRequest = null;
    this.ServiceURL = url;
    this.Channels = [];
    this.ConnectionID = connid;
    this.ServiceCount = 1;
    this.HandShakeComplete = false;
}

AJAXDataService.getBase = function ()
{
    return __AJAXDataServiceBase;
}

AJAXDataService.prototype =
{
    registerChannel: function (name, connid)
    {
        var nc = {
            Success: function () { },
            Error: function () { },
            Data: false,
            Service: this,

            isActive: function ()
            {
                return (!!this.Data) && (!!this.Service.CurrentRequest);
            },

            start: function (data)
            {
                this.Data = data;
                this.Service.__openChan();
            },

            stop: function ()
            {
                this.Data = false;
                this.Service.__openChan();
            }
        };

        this.Channels[name] = nc;
        return nc;
    },

    unregisterChannel: function (name)
    {
        delete this.Channels[name];
        this.__openChan();
    },

    handShake: function (forced)
    {
        xhr = __createXmlHttp();
        xhr.open("GET", this.GetHandShakeURL() + "?cid=" + this.ConnectionID + "&action=handshake", false);
        xhr.setRequestHeader("RequestTarget", "AJAXService");                                // By this header AJAX core discerns service calls
        xhr.setRequestHeader("X-JWToken", JWT_TOKEN);
        xhr.send();

        if (xhr.status == 200)
        {
            return this.HandShakeComplete = true;
        }
        else
        {
            return this.HandShakeComplete = false;
        }
    },

    command: function (name, req)
    {
        xhr = __createXmlHttp();
        xhr.open("POST", this.GetHandShakeURL() + "?cid=" + this.ConnectionID + "&action=command&name=" + name, false);
        xhr.setRequestHeader("RequestTarget", "AJAXService");                                // By this header AJAX core discerns service calls
        xhr.setRequestHeader("Content-Type", "application/JSON");
        xhr.setRequestHeader("JWT_TOKEN", JWT_TOKEN);
        xhr.send(JSON.stringify(req));

        if (xhr.status != 200)
        {
            return false;
        }
        else
        {
            return xhr.responseText;
        }
    },

    __openChan: function ()
    {
        var req = this.__prepareRequest();

        if (Array.isEmpty(req))
        {
            this.__abort(true);
            return;
        }

        if (this.CurrentRequest)
        {
            this.__abort(false);
        }

        if (!this.HandShakeComplete)
        {
            this.handShake();
        }

        var ref = this;

        var xhr = __createXmlHttp();
        xhr.onreadystatechange = function ()
        {                                               // Standard way for wainitng of result callback
            if (xhr.readyState != 4) return;                                                  // when 4 - result is ready

            try
            {
                if (xhr.status == 200 || xhr.status == 304)
                {                                                                                     // checking status code
                    ref.__execSuccess(xhr.responseText);
                }
                else if (xhr.status == 403)
                {
                    if (!ref.handShake())
                    {
                        ref.__abort();
                        ref.__execError(600, "Handshake error");
                        return;
                    }
                }
                else
                {                                                                                // All the same but with error callback
                    ref.__execError(xhr.status, xhr.responseText);
                }
            }
            catch (e) { ref.__execError(500, e); }
            ref.__openChan();
        };

        xhr.open("POST", this.GetServiceURL() + "?cid=" + this.ConnectionID, true);
        xhr.setRequestHeader("RequestTarget", "AJAXService");                                // By this header AJAX core discerns service calls
        xhr.setRequestHeader("Content-Type", "application/JSON");
        xhr.setRequestHeader("X-JWToken", JWT_TOKEN);
        xhr.send(JSON.stringify(req));

        this.CurrentRequest = xhr;
    },

    isActive: function ()
    {
        return !!this.CurrentRequest;
    },

    __abort: function (fullclose)
    {
        var axhr = this.CurrentRequest;

        if (axhr)
        {
            axhr.onreadystatechange = function () { };
            axhr.abort();
        }

        this.CurrentRequest = null;

        if (fullclose)
        {
            var xhr = __createXmlHttp();
            xhr.open("GET", this.GetHandShakeURL() + "?cid=" + this.ConnectionID + "&action=close", true);
            xhr.setRequestHeader("RequestTarget", "AJAXService");                                // By this header AJAX core discerns service calls
            xhr.setRequestHeader("X-JWToken", JWT_TOKEN);
            xhr.onreadystatechange = function () { ; }
            xhr.send()

            this.HandShakeComplete = false;
        }
    },

    GetServiceURL: function ()
    {
        return this.ServiceURL + "/" + (this.ServiceCount++) + "/";
    },

    GetHandShakeURL: function ()
    {
        return AJAXDataService.HandShakeURL + "/" + (this.ServiceCount++) + "/";
    },

    __prepareRequest: function ()
    {
        var obj = {};
        for (var i in this.Channels)
        {
            if (this.Channels[i].Data)
                obj[i] = this.Channels[i].Data;
        }
        return obj;
    },

    __execSuccess: function (res)
    {
        var data = res ? JSON.parse(res) : null;

        for (var i in this.Channels)
        {
            if (!(i in data)) continue;

            var ch = this.Channels[i];
            var cdata = data[i];

            try
            {
                if (ch.Success) ch.Success(cdata);
            }
            catch (e) { ch.Error(500, e) }
        }
    },

    __execError: function (status, res)
    {
        for (var i in this.Channels)
        {
            var ch = this.Channels[i];
            try
            {
                if (ch.Error) ch.Error(status, res);
            }
            catch (e) { }
        }
    }
}

window.__AJAXDataServiceBase = __AJAXDataServiceBase;
window.AJAXDataService = AJAXDataService;
