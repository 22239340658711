﻿/*!
 * Popup window function
 * 
 * Method Show: popups.<instance>.show();
 * Method Hide: popups.<instance>.hide();
 */

/*'use strict';*/

/**
 * Popup constructor
 * @param {String} name - popup ID
 * @param {Object literal} params - popup settings
 */
function Popup(name, params)
{
    // Default settings (overrided by 'params')
    var defaults = {
        visibleClass: 'isPopupVisible',     // class name for visible popup
        isOverlay: true,                    // display overlay
        isVCentering: true,                 // vertical centering
        offsetTop: 0                        // top offset of popup window in pixels
    };

    this.settings = multiExtend(defaults, params);

    if (name)
    {
        this.popup = document.getElementById(name);
    }
    else
    {
        // If 'name' is empty or undefined get the first popup
        this.popup = document.querySelector('.popup');
    }
    this.popupWin = this.popup.querySelector('.popupWindow');

    this.positionBind = this.position.bind(this);
}

/**
 * Show the popup window
 * @returns this
 */
Popup.prototype.show = function ()
{
    if (!this.popup)
    {
        throw 'Cannot find a popup window!';
    }

    if (this.settings.isVCentering)
    {
        this.popup.style.opacity = 0;
    }
    else
    {
        this.popupWin.style.top = this.settings.offsetTop + 'px';
    }

    if (this.settings.isOverlay)
    {
        this.popup.classList.add('isOverlay');
    }

    // Show
    this.popup.classList.add(this.settings.visibleClass);

    if (this.settings.isVCentering)
    {
        this.position();
    }

    this.closeBtn = this.popup.querySelectorAll('.popupClose');

    if (this.closeBtn)
    {
        for (var i = 0, len = this.closeBtn.length; i < len; i++)
        {
            this.closeBtn[i].addEventListener('click', this.hide.bind(this), false);
        }
    }

    window.addEventListener('resize', this.positionBind, false);

    return this;
};

/**
 * Hide the popup window
 * @returns this
 */
Popup.prototype.hide = function ()
{
    if (!this.popup)
    {
        throw 'Cannot find a popup window!';
    }

    // Hide
    this.popup.classList.remove(this.settings.visibleClass);

    if (this.settings.isOverlay)
    {
        this.popup.classList.remove('isOverlay');
    }

    if (this.closeBtn)
    {
        for (var i = 0, len = this.closeBtn.length; i < len; i++)
        {
            this.closeBtn[i].removeEventListener('click', this.hide.bind(this), false);
        }
    }

    window.removeEventListener('resize', this.positionBind, false);

    return this;
};

/**
 * Vertical centering the popup window
 * @returns this
 */
Popup.prototype.position = function ()
{
    var winH = window.innerHeight,
        popH = this.popupWin.clientHeight,
        header = this.popupWin.getElementsByClassName('popupWindowHead')[0];

    if (!header.classList.contains('static') && this.popup.parentNode !== document.body)
    {
        // The popup window is nested into the DOM tree and the z-index is not
        // working properly. Remove the height of the header from the visible
        // part of the window.
        winH -= (2 * 68);
    }

    if (popH > winH)
    {
        this.popupWin.classList.remove('isPopupCenter');
        this.popupWin.style.top = this.settings.offsetTop + 'px';
        this.popupWin.style.position = 'absolute';
        window.scrollTo(0, 0);
    }
    else
    {
        this.popupWin.classList.add('isPopupCenter');
        this.popupWin.removeAttribute('style');
    }

    this.popup.style.opacity = 1;

    return this;
};

/**
 * Muliple inheritance of Object properties
 * @param {Object} - multiple objects
 * @returns {Object} n - extended object
 */
function multiExtend()
{
    var n = {},
        obj,
        len = arguments.length;

    for (var j = 0; j < len; j++)
    {
        obj = arguments[j];
        for (var i in obj)
        {
            if (obj.hasOwnProperty(i))
            {
                n[i] = obj[i];
            }
        }
    }

    return n;
}

window.Popup = Popup;
window.multiExtend = multiExtend;