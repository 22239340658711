﻿/* exported Seo4Ajax */

var Seo4Ajax = (function ()
{
    var count = 0;
    var isSeo4AjaxAttached = !!window.onCaptureReady;

    var isSeo4AjaxRequest = function ()
    {
        var result = false;
        if (isSeo4AjaxAttached)
        {
            if (count > 0)
            {
                result = true;
            }
            else
            {
                count = count + 1;
            }
        }

        return result;
    };

    return {
        isSeo4AjaxRequest: isSeo4AjaxRequest
    };
})();

window.Seo4Ajax = Seo4Ajax;