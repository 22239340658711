﻿/* exported DisableBrowserBack */
function DisableBrowserBack() {
    'use strict';
    if (window.history) {
        history.pushState(0, null);
        window.addEventListener('load', function () {
            this.addEventListener('popstate', function () {
                window.history.forward(1);
            }, false);
        }, false);
    }
}

/* exported PreventCommaInput */
function PreventCommaInput(event) {
    'use strict';
    if (!event) {
        event = window.event;
        event.which = event.keyCode;
    }
    if (event.which == '44') { //comma keycode
        event.preventDefault(true);
        event.stopPropagation(true);
    }
}

/* exported RedirectWithPost */
function RedirectWithPost(path, params, method) {
    method = method || 'post'; // Set method to post by default if not specified.

    var form = document.createElement('form');
    form.setAttribute('method', method);
    form.setAttribute('action', path);

    for (var key in params) {
        if (params.hasOwnProperty(key)) {
            var hiddenField = document.createElement('input');
            hiddenField.setAttribute('type', 'hidden');
            hiddenField.setAttribute('name', key);
            hiddenField.setAttribute('value', params[key]);

            form.appendChild(hiddenField);
        }
    }

	if (typeof(QuickDepositSystemBlock) == "undefined") {
        document.body.appendChild(form);
	} else if (QuickDepositSystemBlock.AlreadyOpenedCashier) {
        var newWindow = window.open();
        newWindow.document.body.appendChild(form);
    } else {
        document.body.appendChild(form);
    }

    form.submit();
    form.remove();
}

function HandleEpsRedirectRequest(url, isPostMethodRequired, postParameters) {
    if (url) {
        if (isPostMethodRequired === true) {
            RedirectWithPost(url, postParameters, 'post');
            return;
        }

		var quickDepositWidgetVersion = typeof(QuickDepositSystemBlock) !== "undefined" ? sessionStorage.getItem(QuickDepositSystemBlock.SessionConstants.WidgetVersion) : CONSTANTS.CashierWidgetVersions.FullCashier;

		if (Application.deviceType.isDesktop() && quickDepositWidgetVersion == CONSTANTS.CashierWidgetVersions.QuickCashier) {
			Application.HandlePopupRedirection(url);
		} else {
			window.location.replace(url);
		}
    }
}

/* exported AddEpsMessagesListener */
function AddEpsMessagesListener(EPSCashier, QuickDepositSystemBlock) {
	var EPSCashier = EPSCashier;
	var QuickDepositSystemBlock = QuickDepositSystemBlock;

    window.addEventListener('message', function (msg) {
        if(!checkTargetOrigin(QuickDepositSystemBlock, msg)){
            return;
        }

        if (msg.data && msg.data.name && msg.data.id) {
            var requestName = msg.data.name;
            var requestData = msg.data.data;

            switch (requestName) {
				case 'adjustFrameHeight':
					if (requestData && requestData.height) {
						var frameHeight = requestData.height;
						QuickDepositSystemBlock && QuickDepositSystemBlock.elements.wrapper.classList.remove(QuickDepositSystemBlock.Classes.quickDepositThirdParty);
						QuickDepositSystemBlock && QuickDepositSystemBlock.elements.wrapper.classList.add(QuickDepositSystemBlock.Classes.quickDepositNormal);
						QuickDepositSystemBlock && 
						QuickDepositSystemBlock.elements.iframe && 
						EPSCashier.HandleFrameHeight(QuickDepositSystemBlock.elements.iframe, frameHeight);
					}
					break;
					
				case 'adjustFrameFullScreen':
					if (!Application.deviceType.isDesktop()) {
                      	var frameHeight = requestData && requestData.ifameHeight ? requestData.ifameHeight : "100%";
						QuickDepositSystemBlock && QuickDepositSystemBlock.elements.wrapper.classList.remove(QuickDepositSystemBlock.Classes.quickDepositNormal);
						QuickDepositSystemBlock && QuickDepositSystemBlock.elements.wrapper.classList.add(QuickDepositSystemBlock.Classes.quickDepositThirdParty);
						QuickDepositSystemBlock && QuickDepositSystemBlock.elements.iframe && EPSCashier.HandleFrameHeight(QuickDepositSystemBlock.elements.iframe,  frameHeight);
					}
					break;

                case 'openFullCashier':
                    QuickDepositSystemBlock && QuickDepositSystemBlock.hide();
                    EPSCashier.HandleOpenFullCashier(QuickDepositSystemBlock ? QuickDepositSystemBlock.Url : "");
                    break;

                case 'openBalance':
                    EPSCashier.HandleOpenBalance();
					break;
					
				case 'showOpenFullCashierButton':
					QuickDepositSystemBlock && QuickDepositSystemBlock.elements.fullCashierButton.classList.remove("isHidden");
					QuickDepositSystemBlock && QuickDepositSystemBlock.elements.outerButtonWrapper.classList.remove("isHidden");
					break;
				
				case 'hideOpenFullCashierButton':
					QuickDepositSystemBlock && QuickDepositSystemBlock.elements.fullCashierButton.classList.add("isHidden");
					QuickDepositSystemBlock && QuickDepositSystemBlock.elements.outerButtonWrapper.classList.add("isHidden");
					break;

                case 'redirect':
                    if (requestData && requestData.url && requestData.postParameters) {					
						var quickDepositWidgetVersion;
						
						if (QuickDepositSystemBlock)
							quickDepositWidgetVersion = StorageUtils.getFromStorage(QuickDepositSystemBlock.SessionConstants.WidgetVersion);

						if (quickDepositWidgetVersion == CONSTANTS.CashierWidgetVersions.QuickCashier)
							Cookies.set(CONSTANTS.QuickCashier.cookieName, "true");

                        if (QuickDepositSystemBlock && QuickDepositSystemBlock.WidgetVersion === CONSTANTS.CashierWidgetVersions.QuickCashier && Application.deviceType.isDesktop()) {
							QuickDepositSystemBlock.showRedirectionConsent({
								redirectUrl: requestData.url,
								isPostMethodRequired: requestData.isPostMethodRequired,
								postParameters: requestData.postParameters
							});
						} else {
							QuickDepositSystemBlock && QuickDepositSystemBlock.hide();                            
							HandleEpsRedirectRequest(requestData.url, requestData.isPostMethodRequired, requestData.postParameters);
						}
                    }
					break;

				case 'close':
                    if (!QuickDepositSystemBlock || (QuickDepositSystemBlock && QuickDepositSystemBlock.WidgetVersion === CONSTANTS.CashierWidgetVersions.FullCashier)) {
                        window.close();
                    }
                    else {
                        QuickDepositSystemBlock && QuickDepositSystemBlock.hide();
                    }
                    break;
            }
        }

        function checkTargetOrigin(QuickDepositSystemBlock, msg){
            if(QuickDepositSystemBlock && QuickDepositSystemBlock.QuickCashierUrl){
                return QuickDepositSystemBlock.QuickCashierUrl.includes(msg.origin) ? true : false;
            }
            return true;
        }
    }, false);
}

/* exported SubscribeToBalanceChange */
function SubscribeToBalanceChange(epsMoneyTransferBlockV2)
{
    var element = document.getElementById('EPS_Frame');
    var targetOrigin = setTargetOrigin(epsMoneyTransferBlockV2);
    if (!element) {
        return;
    }

    function cashierIframeLoaded() {
        UserInfo.current && balanceUpdated();
        UserInfo.onGetBalance['cashier'] = balanceUpdated;
    }

    function balanceUpdated() {
        var user = UserInfo.current;
        var balance = user.currencyStringFormat.format((user.TotalBalance - user.CreditBalanceCustomerCurrency).toFixed(2), user.currencyCode);
        element.contentWindow.postMessage({ topic: 'SBTech.topics.epsChashierTopics.balance', value: balance }, targetOrigin);
    }
    
    function setTargetOrigin(epsMoneyTransferBlockV2) {
        if(epsMoneyTransferBlockV2 && epsMoneyTransferBlockV2.OriginUrl){
            return epsMoneyTransferBlockV2.OriginUrl;
        }
        return "*";
    }

    if (element.addEventListener) {

        element.addEventListener('load', cashierIframeLoaded, false);
    }
    else {
        element.attachEvent('onload', cashierIframeLoaded);
    }
}

window.DisableBrowserBack = DisableBrowserBack;
window.PreventCommaInput = PreventCommaInput;
window.RedirectWithPost = RedirectWithPost;
window.HandleEpsRedirectRequest = HandleEpsRedirectRequest;
window.AddEpsMessagesListener = AddEpsMessagesListener;
window.SubscribeToBalanceChange = SubscribeToBalanceChange;
