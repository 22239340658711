﻿var SPMyBetsCache = (function () {

    var instance = null;
    function getInstance()
    {
        if (!instance) {
            instance = new SPMyBetsCache();
        }
        return instance;
    };

    function SPMyBetsCache()
    {
        var ref = this;
        var allPurchases = [];

        // if the My Bets tab is active or not
        var isActive = false;

        // the count of my bets as received from server side
        var serverMyBetsCount = -1;

        var onContentChanged = {};
        var onPurchaseChanged = {};
        var onBetRemoved = {};
        var onBetsCountChanged = {};
        var onLiveMasterEvents = {};
        var cashoutUpdatesSubsribers = {};
		var onPurchaseUpdateCashOutData = {};
        // table -> key = PurchaseID, val = cashout data
        var purchasesCashOutData = [];

        var isCashOutEnabled = IsCashoutEnabledFromCMS;
        var isCashOutEnabledForCustomer = false;
        var minimalCashOutAmount = 5;

        var updateIntervalID = 0;
        var isDownloadingContent = false;
        var lastCheck = new Date(0).getTime();
        var cashOutUpdateIntervalID = 0;
        var isDownloadingCashOutData = false;
        var liveGameDataUpdateIntervalID = 0;
        var isDownloadingLiveGameData = false;

        // is first content update ever
        var isFirstContentUpdate = true;

        // is first content update after tab activated
        var isFirstContentUpdateAfterActivation = true;
        var cashoutManager = new CashoutManager();
        var betDrawer = new SPMyBetsCashoutBetDrawer();

        function activate()
        {
            if (isActive)
            {
                return;
            }

            isActive = true;
            UserInfo.onDataUpdated["SPMyBetsCache"] = onUserInfoDataUpdated;
            isCashOutEnabledForCustomer = isCashOutEnabled && UserInfo.current && UserInfo.current.IsCashOutAllowed;
            updateMyBetsCountFromSever();

            isFirstContentUpdateAfterActivation = true;

            updateLiveGameData();
            startLiveGameDataUpdateMonitor();

            updateContent();
            startUpdateMonitor();
            (Object.keys(cashoutUpdatesSubsribers).length > 0) && startCashOutUpdateMonitor();
        }

        function deactivate()
        {
            if (!isActive)
            {
                return;
            }

            isActive = false;
            stopUpdateMonitor();
            stopCashOutUpdateMonitor();
            stopLiveGameDataUpdateMonitor();

            delete UserInfo.onDataUpdated["SPMyBetsCache"];
        }

        function findPurchase(id)
        {
            var len = allPurchases.length;
            for (var i = 0; i < len; i++)
            {
                var purchase = allPurchases[i];
                if (purchase && purchase.PurchaseID == id)
                {
                    return purchase;
                }
            }

            return null;
        }

        function getLastPurchaseId()
        {
            if (Array.isEmpty(allPurchases))
                return 0;

            var i = allPurchases.length - 1;
            var lastPurchase;

            do
            {
                lastPurchase = allPurchases[i];
                i--;
            }
            while (i >= 0 && lastPurchase == null);

            if (lastPurchase == null)
                return 0;

            return lastPurchase.PurchaseID;
        }

        function getLastBetId()
        {
            var lastPurchaseId = getLastPurchaseId();
            if (lastPurchaseId === 0)
                return 0;

            var purchase = findPurchase(lastPurchaseId);
            if (!purchase)
                return 0;

            var bets = Array.getValues(purchase.Bets);
            return bets[bets.length - 1].BetID;
        }

        // open purchases update

        function updateContent()
        {
            if (isDownloadingContent)   
            {
                setTimeout(updateContent, 1000);
                return;
            }

            isDownloadingContent = true;

            // if this is the first download,
            // execute events so that the view is refreshed and a progress is displayed
            if (isFirstContentUpdate)
            {
                var data = { toadd: [], toremove: [] };
                executeEvents(onContentChanged, ref, data);
            }

            BettingHistoryPageMethods.getUserMyBets(getLastPurchaseId(), getLastBetId(),
                function (result)
                {
                    isDownloadingContent = false;

                    if (result)
                    {
                        processContentUpdate(eval(result));
                    }

                    isFirstContentUpdate = false;
                    isFirstContentUpdateAfterActivation = false;
                    lastCheck = new Date().getTime();
                },

                function (result)
                {
                    isDownloadingContent = false;
                }
            );
        }

        function startUpdateMonitor()
        {
            if (!updateIntervalID)
            {
                Facade.addSubscriber(updateContent, "SPMyBetsCache", PushMessageTypes.UserBets, null);
                updateIntervalID = setInterval(updateContent, MyBetsUpdateTimeout);
            }
        }

        function stopUpdateMonitor()
        {
            if (updateIntervalID)
            {
                Facade.removeSubscriber("SPMyBetsCache", PushMessageTypes.UserBets);
                clearInterval(updateIntervalID);
                updateIntervalID = 0;
            }
        }

        //Cashout start

        var onCashoutAvailabilityChanged = function (cashOutBet, allowed)
        {
            betDrawer.onCashoutAvailabilityChanged(cashOutBet, allowed);
        }

        var onCashOutStatusChanged = function (cashOutBet, eventArgs)
        {
            if (eventArgs.PreviousStatus === SPPurchaseStatus.Accepted &&
                eventArgs.NewStatus === null)
            {
                betDrawer.removeBet(cashOutBet);

                executeEvents(onBetRemoved, ref, cashOutBet);
                return;
            }

            betDrawer.redrawBet(cashOutBet);
        }

        var onCashOutError = function (cashOutBet, hasError)
        {
            betDrawer.showHideErrorMessage(cashOutBet, hasError);
        }

        var onCashOutAmountChanged = function (cashOutBet, eventArgs)
        {
            betDrawer.updateCashOutButtonAmounts(cashOutBet, eventArgs.Amount, eventArgs.Percentage);
            betDrawer.updateCashOutTaxInfo(cashOutBet);
        }

        var onCashOutDangerStatusChanged = function (cashOutBet, hasDanger)
        {
            betDrawer.showHideDangerNotification(cashOutBet, hasDanger);
        }

        var onPartialCashOutAvailabilityChanged = function (cashOutBet, eventArgs)
        {
            if (typeof (eventArgs) === "undefined")
            {
                betDrawer.refreshPartialCashoutAvailability(cashOutBet.BetID, cashOutBet.PurchaseID);
                return;
            }

            if (typeof (eventArgs.Hide) !== "undefined" && eventArgs.Hide)
            {
                betDrawer.hidePartialCashout(cashOutBet);
                return;
            }
        }

        var onCashOutConfirmationStateChanged = function (cashOutBet, eventArgs)
        {
            betDrawer.redrawCashoutConfirmationState(cashOutBet, eventArgs.IsConfirmed, eventArgs.IsAllowed);
        }

        function subscribeCashoutEvents(purchase)
        {
            if (isCashOutEnabled)
            {
                for (var betId in purchase.Bets)
                {
                    var bet = purchase.Bets[betId];
                    bet.CashOutAvailabilityChanged["MyBets"] = onCashoutAvailabilityChanged;
                    bet.CashoutStatusChanged["MyBets"] = onCashOutStatusChanged;
                    bet.CashoutError["MyBets"] = onCashOutError;
                    bet.CashOutAmountChanged["MyBets"] = onCashOutAmountChanged;
                    bet.CashOutDangerStatusChanged["MyBets"] = onCashOutDangerStatusChanged;
                    bet.PartialCashOutAvailabilityChanged["MyBets"] = onPartialCashOutAvailabilityChanged;
                    bet.CashoutConfirmationStateChanged["MyBets"] = onCashOutConfirmationStateChanged;
                }
            }
        }

        //Cashout end

        function processContentUpdate(content)
        {
            if (content.SPBets == null)
                return;

            // array of purchases that have been closed since last check
            var closedPurchases = [];

            // array of purchases with at least one bet with updated status since last check
            // e.g. one of the bets have been settled
            var updatedPurchases = [];

            // newly added purchases
            var newPurchases = [];

            var statusesHash = [];
            for (var i in content.SPBets.All)
            {
                var statuses = content.SPBets.All[i];
                statusesHash[statuses.PurchaseID] = statuses.Bets;
            }

            for (var i in allPurchases)
            {
                var purchase = allPurchases[i];

                var statuses = statusesHash[purchase.PurchaseID];

                if (statuses == null)
                {
                    if (purchase.canRemovePurchase())
                    {
                        closedPurchases.push(purchase);
                        delete allPurchases[i];
                    }
                }
                else
                {
                    if (areBetsDifferent(purchase.Bets, statuses))
                    {
                        closedPurchases.push(purchase);
                        delete allPurchases[i];
                    }
                    else if (purchase.updateBetsStatuses(statuses))
                    {
                        updatedPurchases.push(purchase);
                    }
                }
            }

            // now handle the new purchases that should be added
            for (var i in content.SPBets.New)
            {
                var spbet = content.SPBets.New[i];
                if (spbet.IsPurchase)
                {
                    var resPurchase = new SPMyBetsPurchase(spbet);

                    newPurchases.push(resPurchase);
                    allPurchases.push(resPurchase);
                }
            }

            // we may have downloaded the cashout data along with the open purchases
            var cashOutData = content.CashOut;
            if (cashOutData && cashOutData.length > 0)
            {
                processCashOutDataUpdate(cashOutData, true);
            }

            getLiveGameData(newPurchases);

            if (newPurchases.length > 0 || closedPurchases.length > 0 || isFirstContentUpdate || isFirstContentUpdateAfterActivation)
            {
                var data = { toadd: newPurchases, toremove: closedPurchases, isFirstUpdate: isFirstContentUpdate, isTabActivated: isFirstContentUpdateAfterActivation };
                executeEvents(onContentChanged, ref, data);
            }

            for (var i in updatedPurchases)
            {
                executeEvents(onPurchaseChanged, ref, updatedPurchases[i]);
            }

            processMyBetsCountUpdate(getInstance().getBetsCount());
        }

        function areBetsDifferent(bets, statuses)
        {
            var betStatuses = [];
            statuses.forEach(function (status)
            {
                betStatuses[status.BetID] = status.StatusID;
            });

            if (Array.getLength(bets) != Array.getLength(betStatuses))
                return true;

            for (var i in bets)
            {
                if (betStatuses[i] === undefined)
                    return true;
            }

            return false;
        }

        function startLiveGameDataUpdateMonitor()
        {
            if (!liveGameDataUpdateIntervalID)
            {
                liveGameDataUpdateIntervalID = setInterval(updateLiveGameData, MyBetsLiveGameDataUpdateTimeout);
            }
        }

        function stopLiveGameDataUpdateMonitor()
        {
            if (liveGameDataUpdateIntervalID)
            {
                clearInterval(liveGameDataUpdateIntervalID);
                liveGameDataUpdateIntervalID = 0;
            }
        }

        function updateLiveGameData()
        {
            if (isDownloadingLiveGameData)
                return;

            getLiveGameData();
        }

        function getLiveGameData(purchases)
        {
            var isPartialUpdate = true;
            if (purchases == null)
            {
                purchases = allPurchases;
                isPartialUpdate = false;
            }

            var masterEventIdsHash = [];
            for (var i in purchases)
            {
                var purchase = purchases[i];
                for (var j in purchase.Selections)
                {
                    var selection = purchase.Selections[j];
                    if (selection.MasterEventID)
                    {
                        var purchIdsArr = masterEventIdsHash[selection.MasterEventID];
                        if (purchIdsArr)
                        {
                            purchIdsArr.push(purchase.PurchaseID);
                        }
                        else
                        {
                            masterEventIdsHash[selection.MasterEventID] = [purchase.PurchaseID]
                        }
                    }
                }
            }

            if (Array.isEmpty(masterEventIdsHash))
                return;

            isDownloadingLiveGameData = true;
            var requestString = Array.getKeys(masterEventIdsHash).join("@");

            (function (meIdsHash, isPartial)
            {

                PageMethods.getLiveMasterEventsData(requestString,
                    function (result)
                    {
                        processLiveGameDataUpdate(eval(result), meIdsHash, isPartial);
                        isDownloadingLiveGameData = false;
                        executeEvents(onLiveMasterEvents, ref, null);
                    },

                    function (result)
                    {
                        isDownloadingLiveGameData = false;

                    }
                );

            })(masterEventIdsHash, isPartialUpdate);
        }

        function processLiveGameDataUpdate(data, meIdsHash, isPartial)
        {
            var processedPurchases = [];
            var purchasesWithChangedIsLiveState = [];

            for (var i in data)
            {
                var masterEventData = data[i];
                var masterEventId = parseInt(masterEventData[0], 10);

                var purchaseIds = meIdsHash[masterEventId];
                if (!purchaseIds) continue;

                for (var j in purchaseIds)
                {
                    var pid = purchaseIds[j];
                    var purchase = findPurchase(pid);
                    if (purchase)
                    {
                        var changed = purchase.updateLiveGameData(masterEventId, masterEventData[9], Date.fromISO(masterEventData[4]), masterEventData[6], masterEventData[7], masterEventData[26]);
                        if (changed)
                        {
                            purchasesWithChangedIsLiveState.push(purchase);
                        }
                        processedPurchases[pid] = true;
                    }
                }
            }

            // if this is a regular update, in which we are updating the game data for all purchases,
            // make sure the purchases for which we have not received any live game data have their live data nulled out.
            if (!isPartial)
            {
                for (var i in allPurchases)
                {
                    var purchase = allPurchases[i];
                    if (!processedPurchases[purchase.PurchaseID])
                    {
                        purchase.updateLiveGameData(0, null, null, "", "");
                        if (changed)
                        {
                            purchasesWithChangedIsLiveState.push(purchase);
                        }
                    }
                }
            }

            // redraw the purchases that have selections that changed from pre-match to live or the other way around
            for (var i in purchasesWithChangedIsLiveState)
            {
                executeEvents(onPurchaseChanged, ref, purchasesWithChangedIsLiveState[i]);
            }
        }

        function updateMyBetsCountFromSever()
        {
            BettingHistoryPageMethods.getUserMyBetsCount(
                function (result)
                {
                    if (result)
                    {
                        var count = parseInt(result);
                        processMyBetsCountUpdate(count);
                    }
                },
                function (result)
                {
                }
            );
        }

        function processMyBetsCountUpdate(count)
        {
            if (count < 0) return;

            var oldCount = serverMyBetsCount;
            serverMyBetsCount = count;

            if (oldCount != count)
            {
                executeEvents(onBetsCountChanged, ref, count);
            }
        }

        // cashout update
        function updateCashOutData()
        {
            if (betDrawer.getAllHosts().length > 0)
            {
                cashoutManager.UpdateCashOutData(allPurchases, processCashOutDataUpdate);
            }
        }

        function startCashOutUpdateMonitor()
        {
            var isCashoutUpdateMonitorActive = !!cashOutUpdateIntervalID;
            if (!isActive || !isCashOutEnabledForCustomer || isCashoutUpdateMonitorActive) return;

            updateCashOutData();
            cashOutUpdateIntervalID = setInterval(updateCashOutData, MyBetsCashOutUpdateTimeout);
        }

        function stopCashOutUpdateMonitor()
        {
            if (cashOutUpdateIntervalID)
            {
                clearInterval(cashOutUpdateIntervalID);
                cashOutUpdateIntervalID = 0;
            }
        }

        function processCashOutDataUpdate(cashoutData, isPartialUpdate)
        {
            var cashoutDataArr = cashoutManager.GetCashOutDataUpdate(cashoutData, UserInfo.current && UserInfo.current.CashOutFee);
            setCashOutData(cashoutDataArr, isPartialUpdate);
        }

        function setCashOutData(cashoutDataArr, isPartialUpdate)
        {
            var hasChanges = false;

            for (var i in allPurchases)
            {
                var purchase = allPurchases[i];

                subscribeCashoutEvents(purchase);

                var data = cashoutDataArr[purchase.PurchaseID];

                // if we are doing a partial update of the cash out data,
                // which means we are updating the cash out data only for certain purchases,
                // do not null out the cash out data of all the others.
                if (!data && isPartialUpdate)
                    continue;

                var changed = purchase.updateCashOutData(data);
                hasChanges = hasChanges || changed;
            }

            executeEvents(onPurchaseUpdateCashOutData, ref, { Bets: data, PurchaseID: purchase.PurchaseID });

            if (hasChanges)
            {
                // this should update the scroll in my bets,
                // which is important if the "show only cash out bets" is on.
                var data = { toadd: [], toremove: [] };
                executeEvents(onContentChanged, ref, data);
            }
        }

        // cashout process

        // other

        function onUserInfoDataUpdated(sender, data)
        {
            var currentUser = UserInfo.current;
            if (!currentUser)
                return;

            isCashOutEnabledForCustomer = isCashOutEnabled && currentUser.IsCashOutAllowed;
        }

        // public api
        this.getPurchases = function ()
        {
            return allPurchases;
        };

        this.getPurchaseById = function (id)
        {
            return findPurchase(id);
        };

        this.getPurchaseByBetId = function (betId)
        {
            var purchase = allPurchases.firstOrDefault(function (purch)
            {
                return purch.Bets.any(function (bet)
                {
                    return bet.BetID == betId;
                });
            });

            return purchase;
        };

        this.getBetById = function (betId)
        {
            var purchase = this.getPurchaseByBetId(betId);

            var bet = purchase.Bets.firstOrDefault(function (bet)
            {
                return bet.BetID == betId;
            });

            return bet;
        };

        this.getBetsCount = function ()
        {
            if (!isActive || isFirstContentUpdate)
            {
                return serverMyBetsCount;
            }
            var count = 0;
            for (var i in allPurchases)
            {
                count += allPurchases[i].getVisibleBetsCount();
            }

            return count;
        };

        this.isLoadingContent = function ()
        {
            return isActive && isFirstContentUpdate && isDownloadingContent;
        };

        this.initPartialCashoutState = function ()
        {
            var purchases = this.getPurchases(),
                bets = null,
                purchaseId, betId;
            for (purchaseId in purchases)
            {
                bets = purchases[purchaseId].Bets;
                for (betId in bets)
                {
                    if (purchases[purchaseId].Bets[betId].CashoutSlider)
                    {
                        purchases[purchaseId].Bets[betId].CashoutSlider.destroy();
                    }
                    purchases[purchaseId].Bets[betId].CashoutSlider = null;
                    purchases[purchaseId].Bets[betId].LastPartialCashoutAmount = 0;
                }
            }
        };

        this.forceContentUpdate = function ()
        {
            updateContent();
        };

        this.restoreCashOutPurchase = function (serverData)
        {
            cashoutManager.ProcessCashoutResult(serverData, allPurchases);
        };

        this.addOnContentChanged = function (name, action)
        {
            onContentChanged[name] = action;
        };

        this.removeOnContentChanged = function removeOnContentChanged(name)
        {
            delete onContentChanged[name];
        };

        this.addOnPurchaseChanged = function (name, action)
        {
            onPurchaseChanged[name] = action;
        };

        this.removeOnPurchaseChanged = function (name)
        {
            delete onPurchaseChanged[name];
        };

        this.addPurchaseUpdateCashOutData = function (name, action)
        {
            onPurchaseUpdateCashOutData[name] = action;
        };

        this.removePurchaseUpdateCashOutData = function (name)
        {
            delete onPurchaseUpdateCashOutData[name];
        };

        this.addOnBetRemoved = function (name, action)
        {
            onBetRemoved[name] = action;
        };

        this.removeOnBetRemoved = function (name)
        {
            delete onBetRemoved[name];
        };

        this.addOnBetsCountChanged = function (name, action)
        {
            onBetsCountChanged[name] = action;
        };

        this.removeOnBetsCountChanged = function (name)
        {
            delete onBetsCountChanged[name];
        };

        this.addOnLiveMasterEvents = function (name, action)
        {
            onLiveMasterEvents[name] = action;
        };

        this.removeOnLiveMasterEvents = function (name)
        {
            delete onLiveMasterEvents[name];
        };

        this.addCashoutUpdatesSubsriber = function (name)
        {
            cashoutUpdatesSubsribers[name] = true;
            (Object.keys(cashoutUpdatesSubsribers).length > 0) && startCashOutUpdateMonitor();
        };

        this.removeCashoutUpdatesSubsriber = function (name)
        {
            delete cashoutUpdatesSubsribers[name];
            (Object.keys(cashoutUpdatesSubsribers).length == 0) && stopCashOutUpdateMonitor();
        };

        this.CashOut = function (purchaseId, betId)
        {
            cashoutManager.CashOutBet(purchaseId, betId, allPurchases);
        };

        this.manageCashout = function (betId)
        {
            var purchase = this.getPurchaseByBetId(betId);
			if (!purchase) return;
			
			this.postCashoutMessage(betId, purchase.PurchaseID);

			this.CashOut(purchase.PurchaseID, betId);

		};
		
		this.postCashoutMessage = function (betID, purchaseID)
		{
			if (sbMsgBus.sbMsgBusPredefinedTopics && sbMsgBus.sbMsgBusPredefinedTopics.GeneralTopics && sbMsgBus.sbMsgBusPredefinedTopics.GeneralTopics.SB_CashoutTopic)
			{
				var cashoutMessage = {};

				cashoutMessage[sbMsgBus.sbMsgBusPredefinedTopics.GeneralTopics.SB_CashoutTopic] = {
					senderId: sbMsgBus.sbMsgBusPredefinedTopics.GeoMessageSenderId.Sport,
					betId: betID,
					purchaseId: purchaseID
				};

				this.setBetWaitingStatusOnGeolocation(betID);

				sbMsgBus.post(cashoutMessage);
			}
		};

		this.setBetWaitingStatusOnGeolocation = function(betID)
		{
			if (typeof GeolocationFacade !== "undefined" && GeolocationFacade.shouldUserGeolocateOnPlaceBet())
			{
				this.getPurchaseByBetId(betID).changeBetCashOutStatus(betID, SPPurchaseStatus.Waiting);
			}
		}

        this.BetDrawer = betDrawer;

        this.getIsActive = function ()
        {
            return isActive;
        };

        this.getIsCashOutEnabledForCustomer = function ()
        {
            return isCashOutEnabledForCustomer;
        };

        UserInfo.onLogin["SPMyBetsCache"] = function ()
        {
            activate();
        };

        UserInfo.onNotLoggedIn["SPMyBetsCache"] = UserInfo.onLogout["SPMyBetsCache"] = function ()
        {
            deactivate();
        };

        UserInfo.current && activate();
    }

    return {
        getInstance: getInstance
    };
}());

window.SPMyBetsCache = SPMyBetsCache;