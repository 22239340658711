function LiveMasterEvent(initArray)  
{
    if (!(initArray instanceof Array)) return;
    this.ID = parseInt(initArray[0], 10);
    this.Team1Name = initArray[1];
    this.Team2Name = initArray[2];
    this.LeagueID = parseInt(initArray[3]);
    this.LeagueName = initArray[16];
    this.MasterEventDate = Date.fromISO(initArray[4]);
    this.Score1 = initArray[6];
    this.Score2 = initArray[7];
    this.BranchID = parseInt(initArray[3]); //we need this later on to set the right icons for the live events
    this.BranchName = initArray[15];
    this.LastUpdate = new Date(0);
    this.Active = initArray[5] ? true : false;
    this.QACount = initArray[10] ? initArray[10] : 0;
    this.ScoreUpdated = false;
    this.DangerUpdated = false;
    this.Danger = false;
    this.Link = initArray[11];
    this.liveStreaming = null;
    this.BetRadarEventID = initArray[31];
    this.hasLiveStream = initArray[32];
    this.Events = [];
    if (UseUrlNav && !this.Link) 
    {
        this.Link = Application.Navigation.getLiveEventLink(this.ID);
    }

    for (var key in initArray[5])
    {
        var line = initArray[5][key];
        this.Events[line[0]] = this.isLiveEventType(line[1]) ? new LiveEventInfo(line) : new LiveSpecialEventInfo(line);
    }

    if (initArray[9] && initArray[9][0])
    {
        if (!this.GameData) this.GameData = {};
        this.replaceGameData(new GameData(initArray[9][0], initArray[9][1], initArray[9][2], initArray[9][3] || initArray[9][0] == 60));
    }
    else
        this.GameData = null;
    
    if (this.BranchID == 6)
    {
        if (initArray[12] != "")
            this.TennisMatchType = initArray[12];

        if (initArray[13] != "")
            this.LastSetIsAdvantageSet = initArray[13];
    }

    if (initArray[14])
    {
        this.LeagueID = initArray[14];
    }
    
    this.IsTeamSwapEnabled = initArray[17] ? initArray[17] : false;
    this.IsAmericanOddsDisplayEnabled = initArray[18] ? initArray[18] : false;
    this.HomeTeamRotationNumber = parseInt(initArray[19]);//19 - hometeam rotation number
    this.AwayTeamRotationNumber = parseInt(initArray[20]);//20 - away team rotation number

    this.LastScore1 = this.Score1;
    this.LastScore2 = this.Score2;
    this.Score1Updated = false;
    this.Score2Updated = false;

    this.CornerScore1 = initArray[24] || "";
    this.CornerScore2 = initArray[25] || "";
    this.AdditionalData = (typeof initArray[26] !== "undefined") && initArray[26] !== "" ? initArray[26] : [];
    this.BaseData = null;

    if (!this.Events) return;
    for (var evtkey in this.Events) this.Events[evtkey].MasterEventID = this.ID;

    this.Live = true;
    this.Visible = true;
    this.HasFastMarketsSpecials = initArray[30] ? initArray[30] : false;
    this.GameStatus = gameStatus.getGameStatus();
    this.NumberOfParts = initArray[33];
    this.PlayByPlayProviderID = initArray[34];
    this.BaseballScoreBoardInfo = (typeof initArray[35] !== "undefined") && initArray[35] !== "" ? initArray[35] : null;
    this.HasPulseBets = initArray[38] || false;
    this.PulseBetsCount = (initArray[40] && initArray[40][1]) || 0;
    this.AggregateName = (typeof initArray[41] !== "undefined") ? initArray[41] : "";
    this.AggregatedEventsCount = (typeof initArray[42] !== "undefined") ? initArray[42] : 0;
    this.MultiViewRegularEvents = [];
    this.MultiViewQAEvents = [];
    this.NthGoalEvents = [];

    var multiViewRegularEventsIndex = 36;
    var multiViewQaEventsIndex = 37;
    var multiViewDataIndex = 2;
    var eventIdIndex = 0;
    var eventTypeIndex = 1;
    var nthGoalEventsIndex = 39;

    if (initArray[multiViewRegularEventsIndex]) {
        for (var key in initArray[multiViewRegularEventsIndex]) {
            var line = initArray[multiViewRegularEventsIndex][key];
            var isRegularLive = (line[eventTypeIndex] == 39);
            var eventId = line[eventIdIndex];
            this.MultiViewRegularEvents[eventId] = isRegularLive ? new LiveEventInfo(line) : new LiveSpecialEventInfo(line);
            !isRegularLive && this.MultiViewRegularEvents[eventId].updateFromServer(line[multiViewDataIndex]);
            this.MultiViewRegularEvents[eventId].MasterEventID = this.ID;
        }
    }

    if (initArray[multiViewQaEventsIndex]) {
        for (var key in initArray[multiViewQaEventsIndex]) {
            var line = initArray[multiViewQaEventsIndex][key];
            var eventId = line[eventIdIndex];
            this.MultiViewQAEvents[eventId] = new LiveQAEventInfo(line);
            this.MultiViewQAEvents[eventId].updateFromServer(line[multiViewDataIndex]);
            this.MultiViewQAEvents[eventId].MasterEventID = this.ID;
        }
    }

    if (initArray[nthGoalEventsIndex]) {
        for (var key in initArray[nthGoalEventsIndex]) {
            var line = initArray[nthGoalEventsIndex][key];
            var eventId = line[eventIdIndex];
            this.NthGoalEvents[eventId] = new LiveQAEventInfo(line);
            this.NthGoalEvents[eventId].updateFromServer(line[2]);
            this.NthGoalEvents[eventId].MasterEventID = this.ID;
        }
    }
}

LiveMasterEvent.prototype =
{
    isStarted: function ()
    {
        var now = getDataTime(timeControl.getServerTime());
        var mEvDate = getDataTime(this.MasterEventDate);
        return mEvDate.getTime() < now.getTime();
    },

    isEnabledForLive: function ()
    {
        var now = getDataTime(timeControl.getServerTime());
        // show live 5 minutes before Kickoff time
        var d = new Date(getDataTime(this.MasterEventDate));
        d.setMinutes(d.getMinutes() - StartLiveBettingInMinutes);
        return d.getTime() < now.getTime();
    },

    getTimeText: function ()
    {
        if (this.isEnabledForLive() && !this.isStarted())
        {
            return "{0} {1:HH:mm}".format($string('General').StartingAt, getDataTime(this.MasterEventDate));
        }

        if (!this.GameData) return "";
        
        return LiveEventsTimeGenerator.getTimeText(this.GameData, this.BranchID);
    },

    getTimeTextWithoutStatus: function ()
    {
        if (this.isEnabledForLive() && !this.isStarted())
        {
            return "{0} {1:HH:mm}".format($string('General').StartingAt, getDataTime(this.MasterEventDate));
        }

        if (!this.GameData) return "";

        return LiveEventsTimeGeneratorWithoutStatus.getTimeText(this.GameData, this.BranchID);
    },
    
    getKickOffTimeText: function ()
    {
        // Before the event has started none if its time has elapsed
        return "00:00";
    },

    getStatusText: function (ignoreStarted)
    {
        var config = {
                additionalData: this.AdditionalData,
                isShortStatusText: true
            };
        var statusText = "";

        if (this.GameData &&
            this.BranchID !== eBranches.Tennis && // For tennis live events we don't display which set it is because we show a scoreboard
            !this.isSoccerEventFinished() && // Don't show 'Full Time' if soccer event is over
            this.isEnabledForLive() && (ignoreStarted || this.isStarted())) // sometimes we want the status even before the event starts - ex. just before kick-off
        {
            if (Events.isExtraTimeEnabledForEventExceptBasketball(this.GameData, this.BranchID))
            {
                statusText = $string("GameStatusShort").ET;
            }
            else if (this.BranchID === eBranches.Volleyball && this.isVolleyballFinished())
            {
                statusText = ($string("GameStatus").Finished).toUpperCase();
            }
            // NOTE: If we need statuses for all sports we have to replace it 
            // with `else` statement only
            else if (this.BranchID === eBranches.Soccer ||
                this.BranchID === eBranches.Basketball ||
                this.BranchID === eBranches.Baseball ||
                this.BranchID === eBranches.Football ||
                this.BranchID === eBranches.IceHockey ||
                this.BranchID === eBranches.Volleyball)
            {
                statusText = this.GameStatus.getStatusController(this.BranchID, this.GameData.Status, null, config) || "";
            }
        }

        return statusText;
    },

    //used when the event is live, but haven`t started yet
    getKickOffStatusText: function()
    {
        return this.getStatusText(true);
    },

    getKickOffTime: function ()
    {
        if (!this.Live || !this.isEnabledForLive() || this.isStarted())
        {
            return "";
        }

        var targetDate = new Date(this.MasterEventDate || this.Date).getTime();
        var localT = new Date();
        var currentDate = localT.getTime() + (localT.getTimezoneOffset() * 60000);
        var timeLeft = (targetDate - currentDate) / 1000; /*difference amount in seconds */

        timeLeft = (timeLeft % 86400) % 3600; /*difference amount in seconds without days and hours */

        var min = parseInt(timeLeft / 60);
        var sec = parseInt(timeLeft % 60);

        min = (min + '').length < 2 ? '0' + min : min;
        sec = (sec + '').length < 2 ? '0' + sec : sec;

        return min + ':' + sec;
    },

    isExpired: function (braVisible)
    {
        if (!this.Visible && !braVisible) return false;
        var timeout = LiveEventTimeOut;
        if (!UserInfo.current) timeout = timeout * notLoggedMultiplier;
        return ((new Date()).getTime() - this.LastUpdate.getTime()) > timeout;
    },

    getHtmlCache: function (odd)
    {
        return null;
    },

    setHtmlCache: function (odd, html)
    {

    },

    updateFromServer: function (data)
    {
        this.GameData = data.GameData;

        // updating events for masterevent
        for (var evtkey in data.Events)
        {
            var evt = this.Events[evtkey]
            if (!evt) this.Events[evtkey] = data.Events[evtkey];
            else this.updateData(this.Events[evtkey], data.Events[evtkey])
        }
    },

    updateGameData: function (data)
    {
        if (data)
            this.replaceGameData(new GameData(data.status, data.sec, data.updated, data.et || data.status == 60 , data.redcards));
        else
            this.replaceGameData(false);
    },

    replaceGameData: function (gameData) {
            this.GameData = gameData;
    },

    updateData: function (oldEv, newEv)
    {
        if (!oldEv.IsMLChanged)
        {
            if (oldEv.SavedMLPayout != newEv.MLPayout)
                oldEv.SavedMLPayout = newEv.MLPayout;
            if (oldEv.SavedMLIsFrozen != newEv.MLIsFrozen)
                oldEv.SavedMLIsFrozen = newEv.MLIsFrozen;
        }
        if (!oldEv.IsSpreadChanged)
        {
            if (oldEv.SavedSpreadPayout != newEv.SpreadPayout)
                oldEv.SavedSpreadPayout = newEv.SpreadPayout;
            if (oldEv.SavedHCIsFrozen != newEv.HCIsFrozen)
                oldEv.SavedHCIsFrozen = newEv.HCIsFrozen;
            if (oldEv.OptSpreadPayout != newEv.OptSpreadPayout)
                oldEv.SavedOptSpreadPayout = newEv.OptSpreadPayout;
        }
        if (!oldEv.IsOUChanged)
        {
            if (oldEv.SavedOUPayout != newEv.OUPayout)
                oldEv.SavedOUPayout = newEv.OUPayout;
            if (oldEv.SavedOUIsFrozen != newEv.OUIsFrozen)
                oldEv.SavedOUIsFrozen = newEv.OUIsFrozen;
            if (oldEv.OptOUPayout != newEv.OptOUPayout)
                oldEv.SavedOptOUPayout = newEv.OptOUPayout;
        }
        if (!oldEv.IsChanged)
        {
            if (oldEv.SavedPayout != newEv.Payout)
                oldEv.SavedPayout = newEv.Payout;
            if (oldEv.SavedIsFrozen != newEv.IsFrozen)
                oldEv.SavedIsFrozen = newEv.IsFrozen;
        }
    },

    setSpecials: function (hash)
    {
        for (var key in this.Events)
        {
            var evt = this.Events[key];
            if (evt.Type == 39 || evt.Type == 1) continue;

            if (!hash[key])
                delete this.Events[key];
        }

        for (var key in hash)
        {
            if (this.Events[key]) continue;

            var evt = hash[key];
            evt.MasterEventID = this.ID;
            evt.setVisible();
            this.Events[key] = evt;
        }
    },

    clearSpecials: function ()
    {
        var res = [];

        for (var key in this.Events)
        {
            var evt = this.Events[key];
            if (evt.Type == 39) continue;
            delete this.Events[key];
            res[key] = 1;
        }

        return res;
    },

    getScore: function (eventTypeID)
    {
        var searchtype = calcMappings[eventTypeID];
        if (searchtype == undefined) searchtype = 39;

        if (searchtype == 39) return [this.Score1, this.Score2];

        var ev = Array.find(this.Events, function (ev) { return ev.Type == searchtype; });
        if (ev) return [ev.Score1, ev.Score2];
        return [-1, -1];
    },

    // returns number of events with types specified
    getActiveEventsCount: function ()
    {
        var hash = [];
        for (var i = 0; i < arguments.length; i++)
        {
            hash[arguments[i]] = true;
        }

        var res = 0;

        for (var key in this.Events)
        {
            var evt = this.Events[key];
            if (hash[evt.Type]) res++;
        }

        return res;
    },

    // returns number of events with types specified
    getEventsCount: function ()
    {
        var res = 0;
        for (var key in this.Events) res++;
        return res;
    },

    hasCombo: function ()
    {
        for (var key in this.Events)
        {
            if (this.Events[key].HasCombo) return true;
        }
        return false;
    },

    updateLiveInfo: function (gev)
    {
        if (gev.Type != 39) return;

        this.Score1Updated = this.isScoreUpdated(this.Score1, gev.Score1);
        this.Score2Updated = this.isScoreUpdated(this.Score2, gev.Score2);
        this.ScoreUpdated = this.Score1Updated || this.Score2Updated;

        if (this.ScoreUpdated)
        {
            this.LastScore1 = this.Score1;
            this.LastScore2 = this.Score2;
            this.Score1 = gev.Score1;
            this.Score2 = gev.Score2;
        }

        this.DangerUpdated = this.Danger != gev.Danger;
        this.Danger = gev.Danger;

        this.CornerScore1 = gev.CornerScore1
        this.CornerScore2 = gev.CornerScore2

        if (gev.AdditionalData)
        {
            this.AdditionalData = gev.AdditionalData;
        }

        if (gev.GameData !== undefined && gev.length > 0 && gev.GameData[0]) {
            this.replaceGameData(new GameData(gev.GameData[0], gev.GameData[1], gev.GameData[2], gev.GameData[3]));
        }
    },

    // function searches the event in the events array.
    // If tplist is not empty one will be used both as a filter and as order info. First event with type with minimal index in the tplist will be returned. 
    // If callback is specified events also willbe filtered by callback call.
    findSutableEvent: function (tplist, callback)
    {
        if (!this.Events) return null;

        if (tplist.length != 0)
        {
            for (var key in tplist)
            {
                var tp = tplist[key];
                for (var evkey in this.Events)
                {
                    var ev = this.Events[evkey];
                    if (ev.Type != tp) continue;
                    if (callback && !callback(ev)) continue;
                    return ev;
                }
            }
        }
        else
        {
            for (var evkey in this.Events)
            {
                var ev = this.Events[evkey];
                if (!callback(ev)) continue;
                return ev;
            }
        }
        return null;
    },

    hasDraw: function ()
    {
        var main = Array.find(this.Events, function (i) { return i.Type == 39; });
        if (main == null) return false;

        var mainline = Array.find(main.Lines, function (i) { return !i.IsOptional; });
        if (mainline == null) return false;

        return mainline.ML && mainline.ML.ML2_ID;
    },

    getAllLinesCount: function ()
    {
        var main = Array.find(this.Events, function (i) { return i.Type == 39; });

        return main ? main.getAllLinesCount() : 0;
    },

    getMainLiveEventID: function ()
    {
        if (!this.Events) return 0;

        var ref = this;
        var main = Array.find(this.Events, function (i)
        {
            return ref.isLiveEventType(i.Type);
        });

        if (main == null) return 0;
        return main.ID;
    },

    getShortTime: function ()
    {
        if (!this.GameData || this.GameData.Status == 0) return "{0:HH:mm}".format(getDataTime(this.MasterEventDate));

        var currentTime = LiveEventsTime.getMinutesAndSeconds(this.BranchID, this.GameData);
        var timeText = "{0:0}:{1:00}".format(currentTime.min, currentTime.sec);        

        if (this.GameData.Status === 1 || this.GameData.Status === 2 || this.GameData.Status === 60)
        {
            return Events.isExtraTimeEnabledForEventExceptBasketball(this.GameData, this.BranchID)
                ? $string("GameStatusShort").ET + " " + timeText
                : timeText;
        }

        var statusText;
        if (this.BranchID == eBranches.Basketball)
        {
            var config = {
                additionalData: this.AdditionalData,
                isShortStatusText: true
            };

            statusText = this.GameStatus.getStatusController(this.BranchID, this.GameData.Status, null, config);
            return statusText + " " + timeText;
        }
        else
        {
            statusText = $string("GameStatusShort")[this.GameData.Status];
        }
        
        if (statusText == undefined) return "{0:HH:mm}".format(getDataTime(this.MasterEventDate));
        return statusText;
    },

    getAllLiveTime: function ()
    {
        if (!this.GameData || this.GameData.Status == 0) return "{0:HH:mm}".format(getDataTime(this.MasterEventDate));

        var statusText = $string("GameStatusShort")[this.GameData.Status];
        if (statusText == undefined) statusText = "";

        var timeText = "";

        if (this.GameData.Status == 1 || this.GameData.Status == 2)
        {
            var currentTime = LiveEventsTime.getMinutesAndSeconds(this.BranchID, this.GameData);
            timeText = "{0:0}:{1:00}".format(min, sec);

            if (Events.isExtraTimeEnabledForEventExceptBasketball(this.GameData, this.BranchID))
            {
                statusText = $string("GameStatusShort").ET;
            }
        }
        else if (this.isSoccerEventFinished())
        {
            //this.Danger = true;
            timeText = $string("BetsTable").FullTime;
        }

        if (statusText && timeText)
            return "{0}, {1}".format(timeText, statusText);
        else
            return timeText + statusText;
    },

    isScoreUpdated: function (oldScore, newScore)
    {
        return ((newScore != undefined) ? (oldScore != newScore && parseInt(newScore) > 0) : false);
    },

    getMoreCount: function ()
    {
        return Application.deviceType.isDesktop() ? this.QACount : this.QACount + this.PulseBetsCount;
    },

    getGropedEventTypes: function (group)
    {
        var evts = [];       
        for (var i in group.EventTypes)
        {
            var evType = group.EventTypes[i];
            var evt = [];
            for(var evKey in this.Events)
            {
                evt = this.Events[evKey];
                //use only events for current group
                if (evType.ID == evt.Type)
                    evts.push(evt);
            }
        }
        return evts;
    },

    isSoccerEventFinished: function ()
    {
        return this.GameData.Status == 6 && this.BranchID == eBranches.Soccer;
    },

    isVolleyballFinished: function()
    {        
        var volleyballData = (new ScoreBoardParser()).parse(this).getScore();
        return volleyballData.IsOver;
    },

    hasGameData: function()
    {
        return this.GameData !== undefined && this.GameData;
    },

    isLiveEventType: function(eventTypeID)
    {
        return eventTypeID === CONSTANTS.EventTypeEnum.LiveBetting || eventTypeID === CONSTANTS.EventTypeEnum.ExtraTime || eventTypeID === CONSTANTS.EventTypeEnum.ToWinPenalties;
    }
};

window.LiveMasterEvent = LiveMasterEvent;