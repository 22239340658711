﻿/* exported MobilePeripheralPanel */
function MobilePeripheralPanel(config)
{
    'use strict';
    MobilePanel.call(this, config);
    this.Position = '';
    this.HeadingText = config.headingText || '';
}

$.extend(MobilePeripheralPanel.prototype, MobilePanel.prototype,
{
    activate: function ()
    {
        this.isActive = true;
    },

    deactivate: function ()
    {
        this.isActive = false;
    },

    switchToPanel: function (toPanel, transitionSettings)
    {
        if (this.ID === toPanel.ID)
        {
            return;
        }

        this.deactivate();
        toPanel.activate();

        // blocks have to know if their panel is isActive or not
        PanelSwitcher.switchBetweenBlocks(this, toPanel);

        toPanel.setParams(transitionSettings.Params);
    },

    registerBlock: function (block)
    {
        // This overrides MobilePanel.registerBlock() with empty implementation because when registering blocks on the side we just add them 
        // to Application.AllBlocks (see Application.registerBlock) which contains all unique blocks. Then after all blocks 
        // are registered in this array (only unique blocks are there) registerBlocksInPanel() is called and the panel fills in its own blocks in this.Blocks
    },

    registerBlocksInPanel: function (allBlocks)
    {
        allBlocks.forEach(function (blockId)
        {
            var block = Application.AllBlocks[blockId];
            if (block)
            {
                this.Blocks.push(block);
            }
        }, this);
    }
});

window.MobilePeripheralPanel = MobilePeripheralPanel;