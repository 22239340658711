﻿var LiveEventsTimeGeneratorWithoutStatus = (function ()
{
    var GetTimeTextHashTablePerBranch = {};

    GetTimeTextHashTablePerBranch[eBranches.Soccer] = function (gameData)
    {
        var timeText = "";
        if (gameData.Status === 1 || gameData.Status === 2 || gameData.Status === 60) // First half / second half / ET
        {
            var delta = serverdate.getTime() - gameData.LastTimeUpdate.getTime();
            var current = delta / 1000 + gameData.GameTime;

            var min = Math.floor(current / 60);
            var sec = Math.floor(current % 60);

            timeText = "{0:0}:{1:00}".format(min, sec);
        }

        return timeText;
    };

    GetTimeTextHashTablePerBranch[eBranches.Basketball] = function (gameData)
    {
        var timeText = "";
        // basketball 1Q to 4thQ and overtime 
        if (Math.abs(gameData.Status) >= 11 && Math.abs(gameData.Status) <= 14 || Math.abs(gameData.Status) === 60)
        {
            var current, min, sec, delta;
            if ((gameData.Status) > 0) // if it not freez
            {
                delta = serverdate.getTime() - gameData.LastTimeUpdate.getTime();
                current = gameData.GameTime - delta / 1000;
                min = Math.floor(current / 60);
                sec = Math.floor(current % 60);

                min = min < 0 ? 0 : min;
                sec = sec < 0 ? 0 : sec;

                timeText = "{0:0}:{1:00}".format(min, sec);
            }
            else // when freez
            {
                current = gameData.GameTime;
                min = Math.floor(current / 60);
                sec = Math.floor(current % 60);

                min = min < 0 ? 0 : min;
                sec = sec < 0 ? 0 : sec;

                timeText = "{0:0}:{1:00}".format(min, sec);
            }
        }

        return timeText;
    };

    GetTimeTextHashTablePerBranch[eBranches.IceHockey] = function (gameData)
    {

        var timeText = "";
        // Ice hockey 1Period to 3Period
        if (Math.abs(gameData.Status) >= 11 && Math.abs(gameData.Status) <= 13)
        {
            var current, min, sec, delta;
            if ((gameData.Status) > 0) // if it not freez
            {
                delta = serverdate.getTime() - gameData.LastTimeUpdate.getTime();
                current = gameData.GameTime - delta / 1000;
                min = Math.floor(current / 60);
                sec = Math.floor(current % 60);

                min = min < 0 ? 0 : min;
                sec = sec < 0 ? 0 : sec;

                timeText = "{0:0}:{1:00}".format(min, sec);
            }
            else // when freez
            {
                current = gameData.GameTime;
                min = Math.floor(current / 60);
                sec = Math.floor(current % 60);

                min = min < 0 ? 0 : min;
                sec = sec < 0 ? 0 : sec;

                timeText = "{0:0}:{1:00}".format(min, sec);
            }
        }

        return timeText;
    };

    GetTimeTextHashTablePerBranch[eBranches.Football] = GetTimeTextHashTablePerBranch[eBranches.Basketball];

    function getTimeText(gameData, branchID)
    {
        if (!GetTimeTextHashTablePerBranch.hasOwnProperty(branchID) || !gameData) { return ""; }

        var timeText = gameData.Status === 6 ?
            $string("BetsTable").FullTime : GetTimeTextHashTablePerBranch[branchID](gameData);

        return timeText;
    }

    return {
        getTimeText: getTimeText
    };

}());

window.LiveEventsTimeGeneratorWithoutStatus = LiveEventsTimeGeneratorWithoutStatus;
