﻿var Communicator = (function ()
{
    "use strict";

    var _connection;
    var _hubProxy;
    var _reconnectPeriod = 8000;
    var registeredPlugins = "";

    var _autoReconnect = true;
    var connectionStatus = "NotStarted";

    var _eventHandlers = {
        OnConnectionEstablished: [],
        OnMessageReceived: [],
        OnConnectionError: [],
        OnDisconnect: []
    };

    var connectionConstants = {
        Status: {
            Connected: 'Connected',
            Connecting: 'Connecting',
            NotStarted: 'NotStarted',
            Disconnected: 'Disconnected'
        },
        Events: {

        }
    };

    function start(pushServerUrl, pluginNames)
    {
        if (registeredPlugins.indexOf(pluginNames) == -1)
        {
            registeredPlugins += ",";
            registeredPlugins += pluginNames;
        }

        if (connectionStatus === connectionConstants.Status.Connected)
        {
            registerPlugins(pluginNames);
            return;
        }
        else if (connectionStatus === connectionConstants.Status.Connecting)
        {
            return;
        }

        _connection = hubConnection();
        _hubProxy = _connection.createHubProxy("communicationHub");

        _connection.url = pushServerUrl;
        _hubProxy.on("handleReceivedMessage", function (message)
        {
            executeEvents(_eventHandlers.OnMessageReceived, "Communicator.handleReceivedMessage", message);
        });

        _connection.error(function (error)
        {
            console.log("Communicator > SignalR error > " + error);
            executeEvents(_eventHandlers.OnConnectionError, "Communicator.Error:" + error, "");
        });

        _connection.stateChanged(function (data)
        {
            console.log("Communicator > State changed: " + data);
        });

        _connection.reconnecting(function ()
        {
            console.log("Communicator > Reconnecting...");
            connectionStatus = connectionConstants.Status.Disconnected;
        });

        _connection.reconnected(function ()
        {
            connectionStatus = connectionConstants.Status.Connected;
            console.log("Communicator > Reconnected");
            registerPlugins();
        });

        _connection.disconnected(function ()
        {
            console.log("Communicator > Connection lost");
            connectionStatus = connectionConstants.Status.Disconnected;
            executeEvents(_eventHandlers.OnDisconnect, "Communicator.onDisconnect", "");
            if (_autoReconnect)
            {
                setTimeout(function ()
                {
                    console.log("Communicator > Trying to re-connect");
                    startNewConnection();
                }, _reconnectPeriod);
            }
        });
        startNewConnection();
    }

    function startNewConnection()
    {
        // _connection.logging = true;
        connectionStatus = "Connecting";
        _connection.start({ withCredentials: false })
            .done(function ()
            {
                onConnected();
                executeEvents(_eventHandlers.OnConnectionEstablished, "Communicator.startNewConnection", {});
            });
    }

    function registerPlugins()
    {
        _hubProxy.invoke("Introduce", registeredPlugins)
            .done(function ()
            {
                console.log("Communicator > onConnected.done() > Introduce message sent to server. pluginNames: " + registeredPlugins);
            })
            .fail(function (error)
            {
                console.log("Communicator > onConnected.faile() > Failed to sent Introduce  message to the server. pluginNames: " + registeredPlugins);
            });
    }

    function onConnected()
    {
        connectionStatus = connectionConstants.Status.Connected;
        registerPlugins();
        // fire custom event indicating that login dependency is loaded
        var loginDependencyLoadedEvent = document.createEvent("Event");
        loginDependencyLoadedEvent.initEvent("sbCommunicator_LoginDependencyLoaded", false, false);
        loginDependencyLoadedEvent.detail = { source: "communicator" };

        window.document.dispatchEvent(loginDependencyLoadedEvent);
    }

    function sendMessage(type, params, onSent, onFail)
    {
        try
        {
            _hubProxy.invoke("sendMessage", type, params)
                .done(function ()
                {
                    console.log("Communicator > SendMessage.done() > New message sent to server. Type: " + type + (params ? ". Params:" : ""));
                    if (params) console.log(params);

                    if (onSent && typeof onSent === "function")
                    {
                        onSent();
                    }
                })
                .fail(function (error)
                {
                    console.log("Communicator > SendMessage.done() > Failed to sent a message to the server. Type: " + type + (params ? ". Params:" : ""));
                    if (params) console.log(params);

                    if (onFail && typeof onFail === "function")
                    {
                        onFail(error);
                    }
                });
        } catch (e)
        {
            console.log("Communicator > SendMessage > Failed to sent a message to the server. Exception: " + e);
        }
    }

    function disconnect()
    {
        _autoReconnect = false;
        _connection.stop();
        connectionStatus = connectionConstants.Status.Disconnected;
        console.log("Communicator > Disconnect() > SignalR disconnected");
    }



    function executeEvents(events, sender, data)
    {
        for (var key in events)
        {
            events[key](sender, data);
        }
    }

    return {
        Start: start,
        Disconnect: disconnect,
        SendMessage: sendMessage,
        Events: _eventHandlers
    };
})();

window.Communicator = Communicator;
