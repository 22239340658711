﻿/* exported VirtualSports */
/* globals BranchLinesCache, SportsContent */

var VirtualSports = (function ()
{
    'use strict';
    var api = {},
        currentVirtualBranches = [62, 70, 73, 75];

    function fallbackIsBranchVirtual(branchId)
    {
        return Array.indexOf(currentVirtualBranches, branchId) != -1;
    }

    api.isBranchVirtual = function (branchId)
    {
        if (!branchId)
        {
            return false;
        }

       

        // if branches cache is not loaded, we have to fallback to something
        if ((typeof SportsContent === 'undefined') || Array.isEmpty(SportsContent.BranchesCache.Branches))
        {
            return fallbackIsBranchVirtual(branchId);
        }

        var branchInfo = SportsContent.BranchesCache.Branches[branchId];
        if (branchInfo)
        {
            return branchInfo.IsVirtualSport;
        }

        return fallbackIsBranchVirtual(branchId);
    };

    api.isVirtualSportsRacing = function (branchId, eventTypeId)
    {
        return api.isBranchVirtual(branchId) && eventTypeId == 396;
    };

    api.isLeagueVirtualRacing = function (leagueName)
    {
        return (leagueName !== 'Virtual Soccer' && leagueName !== 'Virtual Basketball');
    };

    return api;
}());

window.VirtualSports = VirtualSports;