﻿/* exported MobileRightPanel */
function MobileRightPanel(config)
{
    'use strict';
    MobileSidePanel.call(this, config);
    this.Position = PanelPosition.RIGHT;
}

$.extend(MobileRightPanel.prototype, MobileSidePanel.prototype);

MobileRightPanel.EmptyPanel = new MobileRightPanel({id: 'panel-right-empty-panel'});
window.MobileRightPanel = MobileRightPanel;
