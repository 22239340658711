﻿/* exported PushUserBetsMessageHandler */
/* globals PushBaseMessageHandler, MyBetsPushUpdateTimeout */

var PushUserBetsMessageHandler = (function (_super)
{
    var instance;

    function getInstance()
    {
        if (!instance)
        {
            instance = new PushUserBetsMessageHandler();
        }

        return instance;
    }

    function PushUserBetsMessageHandler()
    {
        _super.apply(this, arguments);
    }

    $.extend(PushUserBetsMessageHandler.prototype, _super.prototype);

    PushUserBetsMessageHandler.prototype.processMessage = function (message)
    {
        var subscribersKeys = Object.keys(this.Subscribers),
            subscriberIndex,
            currentSubscriber = null;

        for (var key = 0; key < subscribersKeys.length; key += 1)
        {
            subscriberIndex = subscribersKeys[key];
            currentSubscriber = this.Subscribers[subscriberIndex] || null;
            if (currentSubscriber !== null)
            {
                // delay call so caches have time to refresh with actual bets
                setTimeout(currentSubscriber.subscriberHandleFunction, MyBetsPushUpdateTimeout);
            }
        }
    };

    return getInstance();
}(PushBaseMessageHandler));

window.PushUserBetsMessageHandler = PushUserBetsMessageHandler;