/* exported RightNavPanelsHolder */

function RightNavPanelsHolder(elementID)
{
    'use strict';
    this.element = document.getElementById(elementID);
    this.rootEl = document.getElementsByTagName('html')[0];
    this.isShown = true;
    this.hideRef = this.hide.bind(this);
}


$.extend(RightNavPanelsHolder.prototype,
{

    init: function ()
    {         
        this.contentOverlay = document.getElementById('main-nav-overlay');
        this.contentOverlay.addEventListener('click', this.hideRef);
        Application.BeforeSwitchPanels['close-right-nav-panel-holder'] = this.hideRef;                       
        return this.element;
    },

    show: function()
    {
        Application.preventBodyScroll.activate(this.element);
        this.rootEl.classList.add('panel-right-nav-open');
        this.isShown = true;

        return this.isShown;
    },

    hide: function()
    {
        Application.preventBodyScroll.deactivate(this.element);
        this.rootEl.classList.remove('panel-right-nav-open');
        this.isShown = false;

        return this.isShown;
    }
   
});

window.RightNavPanelsHolder = RightNavPanelsHolder;