﻿var BaseTaxProvider = (function ()
{
    return {

        /*      BEFORE   placing a bet     */
        TaxInfo: {},
        
        isUserUnderTax : function ()
        {
            return this.TaxInfo && this.TaxInfo.CountryTax;
        },

        isTurnoverTaxEnabledBeforePlacing: function ()
        {
            return false;
        },       

        applyTaxToDeposit: function (deposit)
        {           
            return deposit;
        },

        attachBetSlipMessage: function(betslipWrapper, totals)
        {
            return false;
        },

        getBetSlipTaxAmount: function (returnValue)
        {
            return 0;
        },

        getPurchaseTicketTaxAmount: function (returnValue)
        {
            return 0;
        },

        showTaxAmountInBetSlip: function () {
            return true;
        },

        showReturnAfterTaxRowInBetSlip: function () {
            return true;
        },

        /*            AFTER   placing a bet          */
        isBetTaxedAfterPlacing: function (taxInfo)
        {
            return taxInfo && taxInfo.ProviderName && taxInfo.TaxPercent;
        },

        isTurnoverTaxEnabledAfterPlacing: function (taxInfo)
        {
            return false;
        },

        getTaxAmountAfterPlacing: function (bet, returnValue)
        {
            return 0;
        },      

        getDBTaxAmount: function (bet)
        {
            var taxAmount = 0;

            var taxInfo = (bet.IsEachWay && bet.NormalBetRef.EWBetRef && bet.StatusID !== SPBetStatus.CashedOut) ? bet.NormalBetRef.EWBetRef  : bet.NormalBetRef ?  bet.NormalBetRef.TaxInfo : bet.TaxInfo;

            if (taxInfo.TaxAmount * 1)
            {
                var normalBet = bet.NormalBetRef ? bet.NormalBetRef : bet;
                if (normalBet.EWBetRef)
                {
                    taxAmount += normalBet.EWBetRef.TaxInfo.TaxAmount * 1;
                }

                if (normalBet.FreeBetRef)
                {
                    taxAmount += normalBet.FreeBetRef.TaxInfo.TaxAmount * 1;
                }

                taxAmount += normalBet.TaxInfo.TaxAmount * 1;
            }

            return taxAmount.toFixed(2) * 1;
        },

        shouldHasCashoutTaxMessege: function (bet)
        {
            return false;
        },

        hasPossibleTaxMessage: function (bet)
        {
            return false;
        },

        getTaxMessage: function (taxPercent)
        {
            return $dict.bs("RegulatoryTax").format(taxPercent);
        },
      
        getOpenBetsTaxAmount: function () 
        {
        	return 0;
        },
      
        getTaxesByAmount: function () 
        {
            return 0;
        }
    };
}());

window.BaseTaxProvider = BaseTaxProvider;
