var LiveEventsTime = (function()
{
    function getMinutesAndSeconds(branchID, gameData)
    {
        var delta = serverdate.getTime() - gameData.LastTimeUpdate.getTime();
        var currentTimeInSeconds = 0;

        switch (branchID)
        {
            case eBranches.Basketball:
                currentTimeInSeconds = BasketballScore.isPaused(gameData.Status) ? gameData.GameTime : gameData.GameTime - delta / 1000;
                break;
            case eBranches.IceHockey:
                currentTimeInSeconds = IceHockeyScore.isPaused(gameData.Status) ? gameData.GameTime : gameData.GameTime - delta / 1000;
                break;
            default:
                currentTimeInSeconds = gameData.GameTime + delta / 1000;
        }

        var currentTime = {
            min: currentTimeInSeconds > 0 ? Math.floor(currentTimeInSeconds / 60) : 0,
            sec: currentTimeInSeconds > 0 ? Math.floor(currentTimeInSeconds % 60) : 0
        };

        this.processCurrentGameTime(currentTime, branchID, gameData);

        return currentTime;
    }

    function processCurrentGameTime(currentTime, branchID, gameData)
    {
        if(branchID == eBranches.Soccer && (gameData.Status == 1 || gameData.Status == 2))
        {
            if (gameData.IsET == 1)
            {
                if ((currentTime.min >= 15 && gameData.Status == 1) || (currentTime.min >= 60 && gameData.Status == 2))
                {
                    currentTime.min = gameData.Status * 15;
                    currentTime.sec = 0;
                }
                else if (branchID == eBranches.Soccer && gameData.Status == 2)
                {
                    currentTime.min -= 30;
                }
            }          
        }
    }
  
    return {
        getMinutesAndSeconds: getMinutesAndSeconds,
        processCurrentGameTime: processCurrentGameTime
    };
}());

window.LiveEventsTime = LiveEventsTime;