﻿/* exported PushBaseMessageHandler */

var PushBaseMessageHandler = (function()
{
    function PushBaseMessageHandler()
    {
        this.Subscribers = [];
    }

    PushBaseMessageHandler.prototype.addSubscriber = function (subscribedItemKey, subscriberPushHandleFunction, customHandlerParameters)
    {
        if (!this.Subscribers.hasOwnProperty(subscribedItemKey))
        {
            this.Subscribers[subscribedItemKey] = {
                subscriberHandleFunction: subscriberPushHandleFunction
            };

            this.parseCustomParameters(subscribedItemKey, customHandlerParameters);
        }
    };

    PushBaseMessageHandler.prototype.parseCustomParameters = function (subscribedItemKey, customHandlerParameters)
    {
        // will be overridden in each Push Handler if custom parameters are required
    };

    PushBaseMessageHandler.prototype.processMessage = function (message)
    {
        // overridden in each Push Handler
    };

    PushBaseMessageHandler.prototype.removeSubscriber = function (subscribedItemKey)
    {
        if (this.Subscribers.hasOwnProperty(subscribedItemKey))
        {
            delete this.Subscribers[subscribedItemKey];
        }
    };

    return PushBaseMessageHandler;
}());

window.PushBaseMessageHandler = PushBaseMessageHandler;