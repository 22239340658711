//temp constant
var constTimeZoneCookie = "cTz";
var defaultTimeZoneCookie = "dTz";

window.constTimeZoneCookie = constTimeZoneCookie;
window.defaultTimeZoneCookie = defaultTimeZoneCookie;

var isDebug = false;

function pageBack()
{
    if (window.Page.current)
    {
        window.Page.current.popContent();
    }
}

function doLogin()
{
    window.PageMethods.Login
  (
	user, pass,

	function (result, context)
	{

	},

	function (result, context)
	{
	},

	window.Page.current
  );
}

function compareWC(a1, a2, a1team1, a1team2, a2team1, a2team2)
{
    if (isWCTeam(a1team1) && isWCTeam(a1team2))
    {
        return -1;
    }

    if (isWCTeam(a2team1) && isWCTeam(a2team2))
    {
        return 1;
    }

    return Data.compare(a1, a2);
}

function isWCTeam(team1)
{
    var WCTeams = ['South Africa', 'Mexico', 'France', 'Uruguay',
	 'Argentina', 'Nigeria', 'Greece', 'South Korea',
	 'England', 'USA', 'Slovenia', 'Algeria',
	 'Ghana', 'Serbia', 'Germany', 'Australia',
	 'Netherlands', 'Denmark', 'Cameroon', 'Japan',
	 'Italy', 'Paraguay', 'Slovakia', 'New Zealand',
	 'Ivory Coast', 'Portugal', 'Brazil', 'North Korea',
	 'Chile', 'Honduras', 'Spain', 'Switzerland',
	 'Μεξικό',
	'Νότια Αφρική',
	'Γαλλία',
	'Ουρουγουάη',
	'Νότια Κορέα',
	'Αργεντινή',
	'Νιγηρία',
	'Ελλάδα',
	'Σλοβενία',
	'Αγγλία',
	'Η.Π.Α.',
	'Αλγερία',
	'Γερμανία',
	'Γκάνα',
	'Σερβία',
	'Αυστραλία',
	'Καμερούν',
	'Δανία',
	'Ιαπωνία',
	'Ολλανδία',
	'Ιταλία',
	'Νέα Ζηλανδία',
	'Παραγουάη',
	'Σλοβακία',
	'Βραζιλία',
	'Ακτή Ελεφαντοστού',
	'Βόρεια Κορέα',
	'Πορτογαλία',
	'Χιλή',
	'Ονδούρα',
	'Ισπανία',
	'Ελβετία'
    ];

    for (var i = 0; i < WCTeams.length; i++)
    {
        if (team1 == WCTeams[i])
        {
            return true;
        }
    }
    return false;
}

var debug_texts = [];

function debug(text)
{
    return;

    if (debug_texts.length > 150)
    {
        return;
    } //debug_texts.shift();

    debug_texts.push((new Date).toStringEx('[HH:mm:ss]') + '&nbsp;' + text);
    var el = document.getElementById('debug_place');
    if (el)
    {
        el.innerHTML = debug_texts.join('<br>');
    }
}

function clear_debug()
{
    debug_texts = [];
    document.getElementById('debug_place').innerHTML = '';
}

window.isDebug = isDebug;
window.pageBack = pageBack;
window.doLogin = doLogin;
window.compareWC = compareWC;
window.isWCTeam = isWCTeam;
window.debug_texts = debug_texts;
window.debug = debug;
window.clear_debug = clear_debug;

/************************************************************************************/
/*  Logic of rounding min. bet that will be displayed to the user/customer.
/*  Use whereever you need to display min. bet to the user/customer. 
/**/

function roundMinBet(minBet)
{
    return Math.ceil(minBet * 100) / 100;
}

window.roundMinBet = roundMinBet;
/************************************************************************************/
/*  Odd style routines and callbacks
/**/

// event for site header selector
function switchOddsStyle()
{
    setOddStyle(document.getElementById('odd_style_select').value * 1);
}

// main switch procedure
function initOddStyle(style)
{
    style = style * 1;
    if (style == currentOddStyle)
    {
        return;
    }

    var isAsian = typeof isAsianView !== "undefined" && isAsianView;

    if (isNaN(style))
    {
        style = isAsian
            ? DefaultOrderedOddStyleIDAsianView
            : defaultOddStyle;
    }

    if (isAsian && OrderedOddStyleIDsAsianView.indexOf(style) < 0)
    {
        style = DefaultOrderedOddStyleIDAsianView;
    }

    currentOddStyle = style;

    switch (style)
    {
        case OddStyle.AMERICAN:
            {
                currentBetFormat = '{0:' + constBetFormatAmerican + '}';
                break;
            }
        case OddStyle.EUROPEAN:
            {
                currentBetFormat = BetFormat.European;
                break;
            }
        case OddStyle.FRACTIONAL:
            {
                currentBetFormat = '';
                break;
            }
        case OddStyle.MALAY:
            {
                currentBetFormat = BetFormat.Malay; break;
            }
        case OddStyle.INDO:
            {
                currentBetFormat = BetFormat.Indo; break;
            }
        case OddStyle.HONGKONG:
            {
                currentBetFormat = BetFormat.Hongkong; break;
            }

        default:
            {
                currentOddStyle = OddStyle.EUROPEAN;
                currentBetFormat = BetFormat.European;
                break;
            }
    }
}
// main switch procedure
function setOddStyle(style)
{
    initOddStyle(style);
    PageMethods.SetSessionOddsStyle(style);
    executeEvents(setOddStyle.listeners, null, style);
}

// Notification callbacks, add your callback here with unique key, and you will be notified when odd style changes.
// Don't forgot remove callbacks when page gone inactive
setOddStyle.listeners = {};

setBettingView.listeners = [];
function setBettingView(bettingViewID)
{
    executeEvents(setBettingView.listeners, null, bettingViewID);

    currentBettingViewID = bettingViewID;

    window.Cookies.set(bettingViewCookie, bettingViewID, 600);
}

window.switchOddsStyle = switchOddsStyle;
window.initOddStyle = initOddStyle;
window.setOddStyle = setOddStyle;
window.setBettingView = setBettingView;
/************************************************************************************/
/*  Filtering routines and callbacks - for 
/**/

function switchComboMode()
{
    if (EventFilterMode == enEventFilterMode.Standart)
    {
        setFilterStyle(enEventFilterMode.Combo);
    }
    else
    {
        setFilterStyle(enEventFilterMode.Standart);
    }
}

window.switchComboMode = switchComboMode;
/************************************************************************************/
/*  Site timer
/**/

var CLOCK = (function()
{
    var clockEl;
    var localdate;
    var timeZoneSign;
    var timezone;

    function updateTime() 
    {
        clockEl = clockEl || document.getElementById('time');

        if (serverdate && clockEl)
        {
            localdate = getDataTime(serverdate);
            timeZoneSign = (currentTimeZone > 0) ? '+' : '';
            timezone = (currentTimeZone != 0) ? timeZoneSign + currentTimeZone : '';

            var timeFormat = HideSecondsFromTimeZoneDropdown ? "HH:mm" : "HH:mm:ss";
            clockEl.innerHTML = localdate.toStringEx(timeFormat) + ' (GMT' + timezone + ')';
        }
    }

    function getTimeZone()
    {
        var timeZoneVal = getQuerystring('timezone', null);
        var defaultTimeZoneFromCookie = Cookies.get(defaultTimeZoneCookie);        

        if (timeZoneVal && !isNaN(timeZoneVal))
        {
            currentTimeZone = timeZoneVal * 1;
            Cookies.set(constTimeZoneCookie, currentTimeZone, 180); 
        }
        else if (typeof DeviceTimeZoneAsDefault !== "undefined" && DeviceTimeZoneAsDefault && typeof defaultTimeZoneFromCookie === "undefined")
        {
            currentTimeZone = -new Date().getTimezoneOffset() / 60;
            Cookies.set(constTimeZoneCookie, currentTimeZone, 180);
        }
        else 
        {
             var timezoneFromCookies =  Cookies.get(constTimeZoneCookie);
             if (timezoneFromCookies)
             {
                currentTimeZone = timezoneFromCookies;
             }
        }
    }

    function setDefaultTimeZone()
    {
        if (typeof DefaultTimeZone === "undefined")
        {
            Cookies.set(defaultTimeZoneCookie, currentTimeZone, 180);
            return;
        }

        var defaultTimeZoneFromCookie = Cookies.get(defaultTimeZoneCookie);    
        var isDefaultTimeZoneChanged = defaultTimeZoneFromCookie !== DefaultTimeZone;

        if (typeof defaultTimeZoneFromCookie !== "undefined" && isDefaultTimeZoneChanged)
        {
            Cookies.set(constTimeZoneCookie, DefaultTimeZone, 180);
            currentTimeZone = DefaultTimeZone;
        }

        if (typeof defaultTimeZoneFromCookie === "undefined" || isDefaultTimeZoneChanged) 
        {
            Cookies.set(defaultTimeZoneCookie, DefaultTimeZone, 180);
        }
    }

    function updateTimeInterval() 
    {
        setTimeout(function update() { 
            window.CLOCK.updateTime();
            setTimeout(update, 1000);
        }, 1000);
    }

    return {
        updateTime: updateTime,
        getTimeZone: getTimeZone,
        setDefaultTimeZone: setDefaultTimeZone,
        updateTimeInterval: updateTimeInterval
    }
})();

window.CLOCK = CLOCK;

(function ()
{
    window.timeControl.reloadServerTime();
    window.CLOCK.getTimeZone();
    window.CLOCK.setDefaultTimeZone();
    window.CLOCK.updateTime();
    window.CLOCK.updateTimeInterval();
})();

/************************************************************************
/*  Event caller
/**/

function cancelBubble(e)
{
    if (!e)
    {
        var e = window.event;
    }

    e.cancelBubble = true;
    if (e.stopPropagation)
    {
        e.stopPropagation();
    }
}

function showHideZero(elem, show)
{
    if (show)
    {
        if (elem.value.trim() == '0')
        {
            elem.value = '';
        }
    }
    else
    {
        if (elem.value.trim() == '')
        {
            elem.value = '0';
        }
    }
}

function underMaintence()
{
    window.location.replace(application_root + '/UnderMaintenance.html');
}

window.cancelBubble = cancelBubble;
window.showHideZero = showHideZero;
window.underMaintence = underMaintence;
window.addEventListener('load',function ()
{
    window.initOddStyle(defaultOddStyle);
});

/**************************************  SESSION EXPIRATION INTERVAL  ************************************/

/******* Send Events to Google Analytics *******/
function SendToGoogleAnalytics(mainCategory, subCategory, subject, value)
{
    if (__gaTracker)
    {
        __gaTracker('sbtech.send', mainCategory, subCategory, subject, value);
        __gaTracker('send', mainCategory, subCategory, subject, value);
    }
}

window.SendToGoogleAnalytics = SendToGoogleAnalytics;

/**********  Set Time According To User Wish  **********/

function getDataTime(d1)
{
    var d = new Date(d1);

    if (typeof window.currentTimeZone == 'undefined' || isNaN(window.currentTimeZone))
    {
        window.currentTimeZone = 0;
    }

    window.currentTimeZone = window.currentTimeZone * 1;

    if (window.currentTimeZone.toString().indexOf('.') == -1)
    {
        d.setHours(d.getHours() + window.currentTimeZone);
        return d;
    }

    var hoursDiff = parseInt(window.currentTimeZone);
    var minutesDiff = (parseFloat(window.currentTimeZone) - hoursDiff) * 60;

    d.setHours(d.getHours() + hoursDiff, d.getMinutes() + minutesDiff);
    return d;
}

function SetServerdateByTimeZone()
{
    if (!isNaN(window.currentTimeZone * 1))
    {
        var newDate = new Date(window.serverdate);
        if (window.currentTimeZone.toString().indexOf('.') == -1)
        {
            newDate.setHours(newDate.getHours() + window.currentTimeZone * 1);
            return newDate;
        }

        var hoursDiff = parseInt(window.currentTimeZone);
        var minutesDiff = (parseFloat(window.currentTimeZone) - hoursDiff) * 60;

        newDate.setHours(newDate.getHours() + hoursDiff, newDate.getMinutes() + minutesDiff);
        return newDate;
    }

    return window.serverdate;
}

function SetCurrentTimeZone(updatedTimeZone)
{
    if (typeof updatedTimeZone != 'undefined' && !isNaN(updatedTimeZone))
    {
        currentTimeZone = updatedTimeZone;
    }
}

function setTimeZone()
{
    executeEvents(setTimeZone.listeners, null, window.currentTimeZone);
    Cookies.set(constTimeZoneCookie, window.currentTimeZone)
}
setTimeZone.listeners = {};

function updateGmtListTime()
{
    $('#ulGMT li a').each(function (index)
    {
        var value = $(this).attr('name');

        var tempDate = new Date(serverdate);
        tempDate.setHours(tempDate.getHours() + value * 1);

        $(this).html(tempDate.toStringEx('HH:mm:ss') + ' (GMT' + (value != 0 ? (' ' + value) : '') + ')');
    });
}

window.getDataTime = getDataTime;
window.SetServerdateByTimeZone = SetServerdateByTimeZone;
window.SetCurrentTimeZone = SetCurrentTimeZone;
window.setTimeZone = setTimeZone;
window.updateGmtListTime = updateGmtListTime;
/**********  Set Time According To User Wish  **********/

/******* Handle Query String *******/
function getQuerystring(key, default_)
{
    if (default_ == null)
    {
        default_ = '';
    }

    key = key.replace(/[\[]/, '\\\[').replace(/[\]]/, '\\\]');
    var regex = new RegExp('[\\?&]' + key + '=([^&#]*)');
    var qs = regex.exec(window.location.href);
    if (qs == null)
    {
        return default_;
    }

    else
    {
        return qs[1];
    }
}

var isCasinoPage = function () {
    return (window.location.pathname.indexOf('casino') != -1) || (window.location.hash.indexOf('casino') != -1);
};

window.getQuerystring = getQuerystring;
window.isCasinoPage = isCasinoPage;
//#region UI enhancements

//Add event listener to odds buttons
function BetSlipAnimatedOddsAdd(me)
{
    //me = clicked object
    var placeholder = document.getElementById('OddsPlaceholder');
    var timedEvent;
    var intervalEvent;

    var offsetX = me.offsetLeft;
    var offsetY = me.offsetTop;
    var width = me.clientWidth;
    var height = me.clientHeight;

    reset();
    placeholder.style.display = 'block';
    placeholder.style.top = offsetY;
    placeholder.style.left = offsetX;
    placeholder.style.width = width;
    placeholder.style.height = height;
    //placeholder.css({
    //    display: 'block',
    //    top: offsetY,
    //    left: offsetX,
    //    width: width,
    //    height: height
    //});

    placeholder.classList.add('animate');

    // 02.08.13 UI Prototype update animation converted from JS to CSS3 animation, not ready for testing or production till the developers are
    // done with the Static Casino page
    /*intervalEvent = setInterval(function ()
    {*/
    me.classList.add('chosenBet');

    /*},130);*/

    function reset() {
        clearTimeout(timedEvent);
        placeholder.classList.remove('animate');
        placeholder.style = '';

    }

    timedEvent = setTimeout(function ()
    {
        //clearInterval(intervalEvent);
        reset();
    }, 400);

}

window.BetSlipAnimatedOddsAdd = BetSlipAnimatedOddsAdd;

function getUniqueOddsClassPrefix()
{
    return "UniqueOdd_";
}

function getUniqueOddsClass(lineId, isQA)
{
    if (!lineId)
        return "";

    return (getUniqueOddsClassPrefix() + lineId + "_" + (isQA ? "1": "0"));
};

window.getUniqueOddsClassPrefix = getUniqueOddsClassPrefix;
window.getUniqueOddsClass = getUniqueOddsClass;

//GLOBAL VARIABLES
var DOUBLETAPCHECK = 0;
var DOUBLETAPCHECKTIMEOUT;

window.DOUBLETAPCHECK = DOUBLETAPCHECK;
window.DOUBLETAPCHECKTIMEOUT = DOUBLETAPCHECKTIMEOUT;
//DOCUMENT EVENT LISTENER TO FILTER ZOOM GESTURES
//disabled all multi touch and double tap actions
document.addEventListener('touchstart', function (event)
{

    window.DOUBLETAPCHECK++;
    clearTimeout(window.DOUBLETAPCHECKTIMEOUT);

    if (event.touches.length > 1)
    {
        event.preventDefault();
        return false;
    }

    if (window.DOUBLETAPCHECK > 1)
    {
        event.preventDefault();
    }

    window.DOUBLETAPCHECKTIMEOUT = setTimeout(function ()
    {
        window.DOUBLETAPCHECK = 0;
    }, 100);

}, false);

//On touch move (scroll action) we should reset the variables
document.addEventListener('touchmove', function (event)
{
    window.DOUBLETAPCHECK = 0;
    clearTimeout(window.DOUBLETAPCHECKTIMEOUT);
}, false);

// check if the android browser is lower version than 4.0.1,
// and add branch list arrow scroll functionality
function activateBranchListArrowButtons()
{
    if (navigator.userAgent.match(/Android/i) && parseInt($.browser.version) < 534)
    {
        $('#promoBtn').css({
            'padding': '0 25px',
            'overflow-x': 'hidden'
        });

        applyScrollableMenuOnResize();
    }
}

// check if the device screen is big enough for all the branch list icons 
// if there's enought space for all the icons, remove scroll arrows and vice versa 
var BRANCHESSCROLLMENUTIMEOUT;
function applyScrollableMenuOnResize()
{
    clearTimeout(BRANCHESSCROLLMENUTIMEOUT);

    // *** in order to obtain true values, as the content loads dynamically *** //
    // *** and to trigger less events on window resize, the time out function is applied *** // 
    BRANCHESSCROLLMENUTIMEOUT = setTimeout(function ()
    {
        var branchListWidth = $('#promoBtn').width();
        var totalItemsWidth = ($('.branchList').children().length * $('.branchList a').width()) + 50;
        if (totalItemsWidth > branchListWidth && branchListWidth > 0)
        {
            $('.branhListArrowHolder-l, .branhListArrowHolder-r').show();
            $('.branchList').css({
                'position': 'absolute',
                'left': '25px',
                'right': 'auto'
            });
        }
        else
        {
            $('.branhListArrowHolder-l, .branhListArrowHolder-r').hide();
            $('.branchList').css({
                'position': 'absolute',
                'left': '25px',
                'right': '25px',
                'text-align': 'center'
            });
        }
    }, 50);
}

window.activateBranchListArrowButtons = activateBranchListArrowButtons;
window.BRANCHESSCROLLMENUTIMEOUT = BRANCHESSCROLLMENUTIMEOUT;
window.applyScrollableMenuOnResize = applyScrollableMenuOnResize;

// 08.07.13 as the accordion functionality is needed on the fixed menu bar, 
// the accordion function openSettingsBlock moved from SettingsBLock.js to Global.js

function openSettingsBlock(id, event, obj)
{
    event.preventDefault();

    var item = document.getElementById(id);
    if (!item || !obj)
    {
        return;
    }

    var icon = obj.getElementsByClassName('icon');
    if (item.classList.contains('opened'))
    {
        item.classList.remove('isVisible');
        item.classList.add('isHidden');
        item.classList.remove('opened');

        if (icon && icon.length > 0)
        {
            icon[0].classList.remove('arrow_up');
            icon[0].classList.add('arrow_down');
        }
    }
    else
    {
        item.classList.add('isVisible');
        item.classList.remove('isHidden');
        item.classList.add('opened');

        if (icon && icon.length > 0)
        {
            icon[0].classList.add('arrow_up');
            icon[0].classList.remove('arrow_down');
        }
        /*
        if (id == 'control-oddsChange')
        {
            $('#dlOddstyle').focus();
        }
        */
    }
}

function restoreState()
{
    var appStateRead = StorageUtils.getFromStorage('appStateRestored', sessionStorage);
    if (!appStateRead)
    {

        StorageUtils.saveToStorage('appStateRestored', true, sessionStorage);
        var path = window.localStorage.getItem('path');

        if (path)
        {
            var panel = window.localStorage.getItem('panelID');
            if (path == window.location.pathname && panel != window.location.hash)
            {
                Application.navigateTo(panel);
                return true;
            } else if (path != window.location.pathname)
            {
                window.location.href = window.location.origin + path + '#' + panel;
                return true;
            }
        }
    }

    return false;
}

window.openSettingsBlock = openSettingsBlock;
window.restoreState = restoreState;
//reset scroll to fix 1001 issues in iOS + fix header
function setSmallScreenMode(action)
{
    var el = document.getElementById('headerNav');
    if (action !== false)
    {
        document.body.classList.add('smallScreen');
        el && el.classList.add('static');
        //$('#headerNav').addClass('static');
    }
    else
    {
        document.body.classList.remove('smallScreen');
        el && el.classList.remove('static');
        //$('#headerNav').removeClass('static');
    }
}

function resetScroll()
{
    window.setTimeout(function () {
        window.scrollTo(0, 0);
    }, 100);
}

function displayError(errorMessage)
{
    alert(errorMessage);
}

window.setSmallScreenMode = setSmallScreenMode;
window.resetScroll = resetScroll;
window.displayError = displayError;
// Formats the bet points for Over/Under and Handicap in a specific way.
function getFormattedPoints(points, isOver, isUnder, showOUAbbreviation)
{
    //Quarter points have to display only for Asian View
    if (hasAsianOdds && currentBettingViewID == 2)
    {
        if (Math.abs(points - points.toFixed(0)) == 0.25) //Quarter
        {
            if (!isOver && !isUnder)
            {
                var isPositiveHC = (points + 0.25 > 0);
                return isPositiveHC ? constPtsAsianHCFormat.format(points - 0.25, points + 0.25)
                    : constPtsAsianHCFormatNegative.format((points + 0.25) == 0 ? '-0' : points + 0.25, points - 0.25);
            }

            // Over/Under
            if (showOUAbbreviation)
            {
                var OUAbbrev = isOver ? $string('General').OverShort : $string('General').UnderShort;
                return constPtsAsianOUFormat.format(OUAbbrev, points - 0.25, points + 0.25);
            }
            else
            {
                return constPtsAsianOUFormat.format('', points - 0.25, points + 0.25);
            }
        }
    }

    var pts = new Number(points);

    if (!isOver && !isUnder) //Handicap
    {
        return  pts > 0 ? "+" + PointsFormat.format(pts) : PointsFormat.format(pts);
    }

    // Over/Under
    if (showOUAbbreviation)
    {
        var OUAbbrev = isOver ? $string('General').OverShort : $string('General').UnderShort;
        return OUAbbrev + ' ' + PointsFormat.format(pts);
    }
    else
    {
        return PointsFormat.format(pts);
    }
}

function getAsianFormattedPoints(lineTypeID, teamPosition, points, formattedPoints)
{
    if (!Application.deviceType.isDesktop() && hasAsianOdds && currentBettingViewID == CONSTANTS.BettingView.Asian)
    {
        switch (lineTypeID)
        {
            case LineTypeIDs.Spread:
                if (points > 0) {
                    return "";
                }

                if (points == 0 && teamPosition != 1) {
                    return "";
                }
                break;
            case LineTypeIDs.OU:
                if (teamPosition == 3) {
                    return "";
                }
                break;
        }
    }
    return formattedPoints;
}

function isAsianChangesAllowed() {
    return !Application.deviceType.isDesktop() && hasAsianOdds && currentBettingViewID == CONSTANTS.BettingView.Asian;
}

function getOddsFromAmericanToEU(odds)
{
    if (odds == 0)
    {
        return 0;
    }

    var nod = (odds > 0) ? (1 + odds / 100) : (1 - 100 / odds);
    nod = odds > -10000 ? Math.floor((nod * 100).toPrecision(12)) / 100 : nod;
    return nod;
}

function getEachWayString(placeTerms, oddsTerms, full)
{
    if (typeof (placeTerms) == 'undefined' || typeof (oddsTerms) == 'undefined' || placeTerms <= 0 || oddsTerms <= 0)
    {
        return '';
    }

    var pt = placeTerms;
    var mpt = 0;
    var mainString = '';
    var fullString = '';
    if (full)
    {
        fullString = $string('General').EachWay + ' ' + '1/' + oddsTerms.toString() + ' ';
    }
    else
    {
        fullString = $string('General').EachWayTag + ' ' + '1/' + oddsTerms.toString() + ' ';
    }
    for (var i = 1; i <= pt; i++)
    {
        if (i == 1)
        {
            mainString = pt - (pt - 1);
            continue;
        }
        mpt = pt - (pt - i);
        if (mpt > 0)
        {
            mainString += '-' + mpt.toString();
        }
    }

    fullString = fullString + mainString;
    return fullString;
}

function getPlaceString(placeTerms)
{
    var placeTermsString = "";

    if ( typeof ( placeTerms ) != "undefined" && placeTerms > 0 )
    {
        for ( var p = 1; p <= placeTerms; p++ )
        {
            placeTermsString += p > 1 ? "-" + p : " " + p;
        }
    }

    return $string( "General" ).Place + placeTermsString;
}

window.getFormattedPoints = getFormattedPoints;
window.getAsianFormattedPoints = getAsianFormattedPoints; 
window.isAsianChangesAllowed = isAsianChangesAllowed;
window.getOddsFromAmericanToEU = getOddsFromAmericanToEU;
window.getEachWayString = getEachWayString;
window.getPlaceString = getPlaceString;

// Dynamic Pixels
function addDynamicPixels(messageParams)
{
    messageParams += ';gtmAccount=' + gtmAccount;

    addIframe('pixel.ashx', 'messageParams=' + messageParams + '&' + 'type=deposit');
}

function addIframe(url, queryString) {
    var fragment = document.createDocumentFragment(),
        iframe = document.createElement('iframe');

    iframe.style.width = 0;
    iframe.style.height = 0;
    iframe.src = url + '?' + queryString;

    fragment.appendChild(iframe);
    document.body.appendChild(fragment);
}

function isElmentExist(elementID)
{
    if (document.getElementById(elementID) !== null)
    {
        return true;
    }

    return false;
}

function setValueOfHtmlElement(elementID, value)
{
    if (isElmentExist(elementID))
    {
        return (document.getElementById(elementID).innerHTML = value);
    }
}

function isBranchThatSuspendsLiveBets(branchID)
{
    var SoccerID = 1;
    var BaseballID = 7;
    var IceHockeyID = 8;
    var FutsalID = 25;
    var WaterPoloID = 31;
    var NetBallID = 63;

    if (branchID > NetBallID) //default behaviour for new branches was agreed to be suspended, Itay Cohen's call
    {
        return true;
    }

    switch (branchID)
    {
        case SoccerID:
        case IceHockeyID:
        case FutsalID:
        case BaseballID:
        case WaterPoloID:
            return true;

        default: return false;
    }
}

function getParameterByName(name, searchString) {
    'use strict';

    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
    results = regex.exec(searchString ? searchString : location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

window.addDynamicPixels = addDynamicPixels;
window.addIframe = addIframe;
window.isElmentExist = isElmentExist;
window.setValueOfHtmlElement = setValueOfHtmlElement;
window.isBranchThatSuspendsLiveBets = isBranchThatSuspendsLiveBets;
window.getParameterByName = getParameterByName;
/* Function to add custom class if some condition is true */

function addCustomClass(classname, cond)
{
    return cond ? classname : "";
}

/* Hide and UI element */
var addHiddenClass = addCustomClass.curry('isHidden');

/* Toggle class */

function toggleClassName(classname, elem)
{
    return elem.classList.toggle(classname);
}

function GetWindowHeight() {
    var windowHeight = this.windowHeight;
    var height = new windowHeight();

    return height.height;
}

function OpenBranchPanel(event, branchId, element)
{
    Application.OpenBranchPanel(event, branchId, element);
}

window.addCustomClass = addCustomClass;
window.addHiddenClass = addHiddenClass;
window.toggleClassName = toggleClassName;
window.GetWindowHeight = GetWindowHeight;
window.OpenBranchPanel = OpenBranchPanel; 
