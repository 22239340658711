function BaseEventInfo(initArray)
{
    this.ID = parseInt(initArray[0]);
    this.Type = parseInt(initArray[1]);
    this.MinBetNumber = initArray[13];
    this.Deleted = false;
    if (isLiveBettingPage)
    {
        this.Live = false;
    }
    this.HasCombo = false;
    this.MasterEventID = 0; // it will be filled later - when event will be linked 
    // to the MasterEvent object    
    this.OrderID = 0;

    this.LastUpdate = new Date(0);
    if (!isLiveBettingPage)
    {
        this.LiveName = 1;
    }

    //if ((typeof(initArray[4]) === "undefined") && (typeof(initArray[5]) !== "undefined"))
    //{
    //    this.LinesCount = parseInt(initArray[5]);
    //}

    // Used for the combo event grouping. This property is used for checking if the event can be selected for combo with the selections that 
    // have already been picked in the betslip from the same master event. If this check is successful an icon is displayed in the bet type header
    this.LiveGroupID = 0;
}

BaseEventInfo.prototype =
{
    getUpdateString: function ()
    {
        //    if((!this.Lines) && (!this.Live)) return this.ID;

        var lls = [this.ID];

        for (var key in this.Lines)
            lls.push(this.Lines[key].getUpdateString());

        lls.push(this.Type);

        return lls.join("#");
    },

    isExpired: function (isSelected)  // it works onhly for events of selected ME
    {
        var timeout = (isSelected || this.Type == 39 || this.Type == 1) ? SelectedGameUpdateTimeout : SpecialEventUpdateTimeout;
        if (isLiveBettingPage && this.Type != 39 && this.Type == 1)
        {
            timeout = SpecialEventUpdateTimeoutLive;
        }
        timeout = this.getVisible() ? timeout : ClosedEventUpdateTimeout;
        if (!UserInfo.current) {
            timeout = timeout * notLoggedMultiplier;
        }
        if (isFastMarket(this.SplitType)) {
            timeout = FastMarketEventUpdateTimeoutLive;
        }
        return ((new Date()).getTime() - this.LastUpdate.getTime()) > timeout;
    },

    updateLines: function (Lines)
    {
        if (!this.Lines)
        {
            this.createLines(Lines);
            return;
        }

        for (var key in Lines)
        {
            if (this.Lines[key])
                this.Lines[key].updateFromServer(Lines[key], this.Type);
            else
                this.Lines[key] = Lines[key];
        }

        for (var key in this.Lines)
        {
            if (this.Lines[key].Deleted)
            {
                delete this.Lines[key];
                continue;
            }

            this.HasCombo = this.HasCombo || this.Lines[key].HasCombo;
        }
    },

    createLines: function (lines)
    {
        this.Lines = lines;
        for (var key in this.Lines)
        {
            this.HasCombo = this.HasCombo || this.Lines[key].HasCombo;
        }
    },

    __resetUpdated: function ()
    {
        this.Updated = false;

        if (!this.Lines) return;

        for (var key in this.Lines)
        {
            if (this.Lines[key])
                this.Lines[key].__resetUpdated();
        }
    },

    getActiveLineGroup: function ()
    {
        var lg = Array.find(this.Lines, function (line) { return !line.IsOptional && line.hasOdds(); });
        if (lg) return lg;
        lg = Array.find(this.Lines, function (line) { return line.hasOdds(); });
        return lg;
    },

    getActiveLineGroupForAsianLive: function ()
    {
        var lg = Array.find(this.Lines, function (line) { return !line.IsOptional && line.hasOdds(); });
        if (lg) return lg;
        lg = Array.find(this.Lines, function (line) { return !line.IsOptional; });
        return lg;
    },

    getActiveLineGroupForAsianSingleView: function ()
    {
        var lg = Array.find(this.Lines, function (line) { return (line.Spread != null && line.OU != null) && line.hasOdds(); });
        return lg;
    },

    getActiveLineGroupForAsianSingleViewLive: function ()
    {
        var lg = Array.find(this.Lines, function (line) { return (line.Spread != null && line.OU != null) && line.hasOdds(); });
        if (lg) return lg;
        lg = Array.find(this.Lines, function (line) { return !line.IsOptional; });
        return lg;
    },

    getActiveLineGroupFromTotalCornerEventForAsianSingleView: function ()
    {
        var lg = Array.find(this.Lines, function (line) { return (line.OU != null) && line.hasOdds(); });
        return lg;
    },

    getActiveLineGroupFromFirstHalfCornerEventForAsianSingleViewLive: function ()
    {
        var lg = Array.find(this.Lines, function (line) { return (line.OU != null) && line.hasOdds(); });
        if (lg) return lg;
        lg = Array.find(this.Lines, function (line) { return !line.IsOptional; });
        return lg;
    },

    updateSingleLine: function (lineGroupID, betType, betSide, odds)
    {
        if (!this.Lines) return;

        var lineGroup = this.Lines[lineGroupID];
        if (!lineGroup) return;

        lineGroup.updateSingleLine(betType, betSide, odds);
    },

    hasOdds: function (propname)
    {
        if (!this.Lines) return false;

        for (var key in this.Lines)
        {
            if (this.Lines[key].hasOdds(propname)) return true;
        }

        return false;
    },

    containsNonQuaterPoints: function (propname)
    {
        if (!this.Lines) return false;

        for (var key in this.Lines)
        {
            if (this.Lines[key].containsNonQuaterPoints(propname) === true)
                return true;
        }

        return false;
    },

    // returns current event score. me is optional paramente - MasterEvent, it is used only for optimisaton
    getCurrentScore: function (me)
    {
        if (!me) me = LiveEventsCache.MasterEvents[this.MasterEventID];

        var mtype = calcMappings[this.Type];
        if (!mtype) mtype = 39;

        var mapev = me.findSutableEvent([mtype]);
        if (mapev) return [mapev.Score1, mapev.Score2];

        return false;
    },

    updateData: function (obj)
    {
    },

    // Checks if the event can be selected for combo with the selections that have already been picked in the betslip from the same master 
    // event. If this check is successful an icon is displayed in the bet type header to notify that he can group the event in a combo
    checkForComboGrouping: function ()
    {
        var isComboGroupingAvailable = false;

        // Take into consideration only the selections marked, i.e. checked on the combo tab
        var selectionsInCombo = [];
        var betSlipModeName = BetSlip.IsBetslipUK ? 'ukslip' : 'combo';
        if (typeof BetSlip != "undefined" && typeof RegularLiveSlipSelection != "undefined" && typeof QALiveSlipSelection != "undefined" &&
                BetSlip.Modes[betSlipModeName] && Array.getLength(selectionsInCombo = BetSlip.Modes[betSlipModeName].getSelectionsInCombo()) > 0)
        {
            for (var i in selectionsInCombo)
            {
                var selection = selectionsInCombo[i];
                
                // Combo event grouping allows for different event types (for example FT and Corners) to be combined in a combo.
                // Group 0 cannot be combined with any other group including itself. Event types belonging to different groups (except 0) can be combined.
                if (selection.Live && !selection.LineClosed && this.MasterEventID != 0 && this.MasterEventID == selection.MasterEventID)
                {
                    if (this.LiveGroupID == 0 || this.LiveGroupID == selection.LiveGroupID || selection.LiveGroupID == 0)
                    {
                        isComboGroupingAvailable = false;
                        break;
                    }
                    else
                    {
                        isComboGroupingAvailable = true;
                    }
                }
            }
        }
        return isComboGroupingAvailable;
    },
    masterEventAdditionalData: function(me, favorite)
    {
        var parameters = {};
        parameters.was = false;
        parameters.hdr = false;
			
        parameters.lines = Array.findAllValues(this.Lines, function(line) { return !line.IsOptional; });
        Array.addRange(parameters.lines, Array.findAllValues(this.Lines, function(line) { return line.IsOptional; }));
		
        parameters.even = true;
        
        parameters.score1 = ((me.Score1) ? me.Score1 : 0);
        parameters.score2 = ((me.Score2) ? me.Score2 : 0);
        
        parameters.team1PosScore = parameters.score1;
        parameters.team2PosScore = parameters.score2;
        parameters.team1PosName = me.Team1Name;
        parameters.team2PosName = me.Team2Name;
        parameters.team1PosRedCards = 0;
        parameters.team2PosRedCards = 1;
        parameters.team1Pos = 1; 
        parameters.team2Pos = 3;

        if(me.IsTeamSwapEnabled)
        {
            parameters.team1PosScore = parameters.score2;
            parameters.team2PosScore = parameters.score1;
            parameters.team1PosName = me.Team2Name;
            parameters.team2PosName = me.Team1Name;
            parameters.team1PosRedCards = 1;
            parameters.team2PosRedCards = 0;
            parameters.team1Pos = 3; 
            parameters.team2Pos = 1;
        }
            
        parameters.currentSet = 0;        
            // If it is a tennis - format result        
        if (me.BranchID == 6)
        {
            var score = new TennisScore(parameters.team1PosScore.toString(), parameters.team2PosScore.toString());
            parameters.team1PosScore =score.ScoreSetsPlayer1;
            parameters.team2PosScore = score.ScoreSetsPlayer2;
            parameters.currentSet = ((score.ScoreSetsPlayer1 + score.ScoreSetsPlayer2) + 1);
        }
        this.numberOfItemsDisplayedInAsian = 0;

        parameters.branchBlockStyle = "";
        if(me.BranchID == 22 || me.BranchID == 59) //cricket
        {
            parameters.branchBlockStyle = " b_22";
        }
        return parameters;
    },

    isEventExpanded: function (masterEvent, prop)
    {
        if (!masterEvent.isGroupChanged && masterEvent.isGroupChanged !== undefined)
        {
            var elem = document.getElementById('event_' + this.ID + '_' + prop);
            if (elem != null) this.isExpanded = elem.classList.contains('expanded');
        }
    }
};

window.BaseEventInfo = BaseEventInfo;