﻿/* exported BetTypes */
var BetTypes =
{
    Regular: 1,
    Combo: 2,
    If: 3,
    Teaser: 4,
    BuyPoints: 5,
    ExactScore: 6,
    QA: 7,
    Casino: 8,
    Game: 9,
    Purchase: 10,
    ForeCast: 11,
    TriCast: 12,
    System: 13,
    VirtualRegular: 14,
    VirtualQA: 15,
    VirtualCombo: 16,
    VirtualSystem: 17,
    VirtualForeCast: 18,
    VirtualTriCast: 19,
    FirstFour: 21,
    Combinator: 23,
    YourBet: 24,
    PulseBet: 25
};

BetTypes.IsSingle = function (betType) {
    'use strict';

    return (betType == BetTypes.Regular || betType == BetTypes.QA || betType == BetTypes.VirtualRegular || betType == BetTypes.VirtualQA);
};

BetTypes.IsCombo = function (betType) {
    'use strict';

    return (betType == BetTypes.Combo || betType == BetTypes.VirtualCombo);
};

BetTypes.IsSystem = function (betType) {
    'use strict';

    return (betType == BetTypes.System || betType == BetTypes.VirtualSystem);
};

BetTypes.IsCombinator = function (betType) {
    'use strict';

    return betType == BetTypes.Combinator;
};

BetTypes.IsFirstFour = function (betType) {
    'use strict';

    return (betType == BetTypes.FirstFour);
};

BetTypes.IsForeCast = function (betType)
{
    'use strict';

    return (betType == BetTypes.ForeCast || betType == BetTypes.VirtualForeCast);
};

BetTypes.IsTriCast = function (betType)
{
    'use strict';

    return (betType == BetTypes.TriCast || betType == BetTypes.VirtualTriCast);
};

/* jshint maxcomplexity: 8 */ // TODO: Refactor to reduce complexity - use a map
BetTypes.IsVirtual = function (betType)
{
    'use strict';

    return (betType == BetTypes.VirtualRegular ||
            betType == BetTypes.VirtualQA ||
            betType == BetTypes.VirtualCombo ||
            betType == BetTypes.VirtualSystem ||
            betType == BetTypes.VirtualForeCast ||
            betType == BetTypes.VirtualTriCast);
};
/* jshint maxcomplexity: 5 */

BetTypes.IsPulseBet = function (betType)
{
    'use strict';

    return betType === BetTypes.PulseBet;
};

BetTypes.IsYourBet = function (betType) {
    'use strict';

    return betType === BetTypes.YourBet;
};

/* exported BetSubTypes */
var BetSubTypes =
{   //Forecast/Tricast
    Straight: 1,
    Reverse: 2,
    Combination: 3
};

window.BetTypes = BetTypes;
window.BetSubTypes = BetSubTypes;