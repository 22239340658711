function LiveQAEventInfo(initArray)
{
  BaseEventInfo.call(this, initArray);
  this.ID = initArray[0];
  this.Type = parseInt(initArray[1]);
  this.Live = true;
  this.Lines = [];
  this.EventName = false;
  this.ColumnNames = [];
  this.Live = true;   
  this.DrawGrid = false;
  this.DrawGridSize = 0;
  this.isQA = true;
  this.Visible = true;

  if (initArray[12])
  {
	this.SettlementDate = initArray[12];
  }
}
$.extend(LiveQAEventInfo.prototype, BaseEventInfo.prototype);

$.extend(LiveQAEventInfo.prototype, 
{
  setVisible : function()
  {
    this.Visible = true;
  },
  
  getVisible : function()
  {
    return this.Visible;
  },

  getUpdateString : function()
  {
    var lls = [this.ID];
    
    for(var key in this.Lines)
      lls.push(this.Lines[key].getUpdateString());

    lls.push(this.Type);
    
    return lls.join("#");
  },
  
  updateFromServer : function(initArray)
  {
    this.LastUpdate = new Date();
    
    if((initArray[2]) && (initArray[2] != -1))
    {
      this.updateLines(HashtableOf(initArray[2], QALine));
      this.DrawGrid = false;
      this.DrawGridSize = 0;
    }
    else if(initArray[2] == -1)
    {
      this.Deleted = true;
      return;
    }
    else
    { 
      this.__resetUpdated();
    }      
    
    if(initArray[5] == undefined) return;

    this.EventName =  initArray[3];
    this.TypeName = initArray[5];
    
    this.getColumnNames(initArray[4]);
    
    spt = specialsID[this.Type];
    this.SplitType = (spt != undefined) ? spt : initArray[6];
    
    this.OrderID = initArray[7];
    this.LiveGroupID = initArray[8];

    this.HasCombo = false;
    this.Updated = true;
  },
  
  getSlipName : function()
  {
    this.buildDrawGrid();

	  var cnt = Array.getLength(this.DrawGrid);
    if(cnt == 0) return this.TypeName;

		var nam = cnt == 1 ? this.getSingleColumnName() : this.TypeName;
		if(!nam) nam = this.TypeName;
		
		return nam;
  },
  
  getColumnNames : function(array)
  {
    this.ColumnNames = [];
    
    for(var i in array)
    {
      var item = array[i];
      this.ColumnNames[ item[0] ] = {ID: item[0], Name: item[1], MappedLineTypeID: item[2] };
    }
    
  },
  
  getLinesCount : function()
  {
    var cnt = 0;

    for(var i in this.Lines)
    {
      if(this.Lines[i] && this.Lines[i].Odds) cnt++;
    }
    
    return cnt;
  },
  
  getSingleColumnName : function()
  {
    for(var key in this.ColumnNames)
      return this.ColumnNames[key].LineTypeName;
    
    return null;
  },
  
  buildDrawGrid : function()
  {
    if(this.DrawGrid) return this.DrawGrid;
    
    var grid = [];
    var ggrid = Array.getValues(this.ColumnNames);
    ggrid.sort(function(a1,a2) { return Data.compare(a1.ID, a2.ID); });
    for(var i in ggrid) grid[ggrid[i].ID] = [];
    
    
    for(var key in this.Lines)
    {
      var line = this.Lines[key];
      if(!line.Odds) continue;

      var column = grid[line.LineTypeID];
      if(!column) column = grid[line.LineTypeID] = [];
      column.push(line);
    }
    
    this.DrawGrid = [];
    
    var msx = 0;
    
    for(var key in grid)
      if(grid[key].length == 0) 
        delete grid[key];
    
    for(var key in grid)
      msx = Math.max(msx, grid[key].length);
     
    this.DrawGridSize = msx;

    return this.DrawGrid = Array.getValues(grid);
  },
     
  post : function()
  {
    PageMethods.UpdateQAEventInfo(
       this.ID,this.SavedPayout,
       this.SavedIsFrozen,
       function(result, context)  /// Successul responce
       { 
        if (result=='0')  
        {
         context.IsChanged=false;
        }
        //else alert("Error1");
       },
       function(result, context)  /// Server returns error ( HTTP Status != 200), since bet is not accepted
       {
         //alert("Error2");
       },
      this
      );
  },
  
  updateSingleLine : function(lineID, odds)
  {
    var line = this.Lines[lineID];
    if(!line) return;
    
    line.setNewOdds(odds);
  },
  
  hasOdds : function()
  {
    for(var key in this.Lines)
    {
      if(this.Lines[key].Odds != 0) return true;
    }
    
    return false;
  }
}
);

window.LiveQAEventInfo = LiveQAEventInfo