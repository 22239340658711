/*********************************************************************************************************************************************\
/*
/* Odds
/*
/**/

function MLOdds(initArray)
{
    if (initArray == '')
    {
        this.Updated = false;
        this.Deleted = false;

        return;
    }

    if (initArray == -1)
    {
        this.Deleted = true;
        this.Updated = true;
        return;
    }

    this.Deleted = false;
    this.Updated = true;
    this.BetType = 1;

    if (Array.safeLength(initArray) == 10)
    {
        this.ML1_ID = parseInt(initArray[0], 10);
        this.ML1_Odds = parseFloat(initArray[1]);
        this.ML1_IsYourBetEnabled = !!initArray[6];

        if (initArray[2])
        {
            this.ML2_ID = parseInt(initArray[2], 10);
            this.ML2_Odds = parseFloat(initArray[3]);
            this.ML2_IsYourBetEnabled = !!initArray[7];
        }

        this.ML3_ID = parseInt(initArray[4], 10);
        this.ML3_Odds = parseFloat(initArray[5]);
        this.ML3_IsYourBetEnabled = !!initArray[8];
        this.Danger = !!initArray[9];

        return;
    }
    else if (Array.safeLength(initArray) == 12)
    {
        this.ML1_ID = parseInt(initArray[0], 10);
        this.ML1_Odds = parseFloat(initArray[1]);
        this.ML1_IsYourBetEnabled = !!initArray[8];

        if (initArray[2])
        {
            this.ML2_ID = parseInt(initArray[2], 10);
            this.ML2_Odds = parseFloat(initArray[3]);
            this.ML2_IsYourBetEnabled = !!initArray[9];
        }

        this.ML3_ID = parseInt(initArray[4], 10);
        this.ML3_Odds = parseFloat(initArray[5]);
        this.ML3_IsYourBetEnabled = !!initArray[9];

        this.Points1 = parseFloat(initArray[6]);
        this.Points3 = parseFloat(initArray[7]);
        this.Danger = !!initArray[11];

        return;
    }

    this.ML1_Odds = parseFloat(initArray[0]);
    if (initArray[1]) this.ML2_Odds = parseFloat(initArray[1]);
    this.ML3_Odds = parseFloat(initArray[2]);

    this.Updated1 = false;
    this.Updated2 = false;
    this.Updated3 = false;

    this.ComboRate = 0;
}

MLOdds.prototype =
{
    updateFromServer: function (data, evType)
    {
        if (!data.Updated)
        {
            this.Updated = false;

            this.Updated1 = false;
            this.Updated2 = false;
            this.Updated3 = false;
            return;
        }

        if (typeof evType !== 'undefined')
        {
            if (!Odds.shouldRound(evType, 1))
            {
                this.Updated = true;
                this.Updated1 = Data.compare(this.ML1_Odds, data.ML1_Odds);
                this.Updated2 = data.ML2_Odds != undefined ? Data.compare(this.ML2_Odds, data.ML2_Odds) : 0;
                this.Updated3 = Data.compare(this.ML3_Odds, data.ML3_Odds);
            }
            else
            {
                this.Updated1 = Data.compare(Odds.round(this.ML1_Odds), Odds.round(data.ML1_Odds));
                this.Updated2 = data.ML2_Odds != undefined ? Data.compare(Odds.round(this.ML2_Odds), Odds.round(data.ML2_Odds)) : 0;
                this.Updated3 = Data.compare(Odds.round(this.ML3_Odds), Odds.round(data.ML3_Odds));
                this.Updated = this.Updated1 || this.Updated2 || this.Updated3;
            }

            this.ML1_Odds = data.ML1_Odds;
            this.ML2_Odds = data.ML2_Odds;
            this.ML3_Odds = data.ML3_Odds;
        }
        else
        {
            this.Updated = true;
            this.Updated1 = Data.compare(this.ML1_Odds, data.ML1_Odds);
            this.Updated2 = data.ML2_Odds != undefined ? Data.compare(this.ML2_Odds, data.ML2_Odds) : 0;
            this.Updated3 = Data.compare(this.ML3_Odds, data.ML3_Odds);

            this.ML1_Odds = data.ML1_Odds;
            this.ML3_Odds = data.ML3_Odds;
            this.ML2_Odds = data.ML2_Odds;
          
        }
    },

    __resetUpdated: function ()
    {
        this.Updated = false;

        this.Updated1 = false;
        this.Updated2 = false;
        this.Updated3 = false;
    },

    hasOdds: function ()
    {
        return this.ML1_Odds && this.ML1_ID && this.ML3_Odds && this.ML3_ID;
    },
    containsQuaterPoints: function ()
    {
        return false;
    },

    hasPoints: function ()
    {
        return false;
    },

    hasDrawLine: function ()
    {
        return this.ML2_ID && this.ML2_Odds;
    },

    getLines: function ()
    {
        var res = [];
        res[1] = { ID: this.ML1_ID, odds: this.ML1_Odds };
        res[3] = { ID: this.ML3_ID, odds: this.ML3_Odds };
        if (this.ML2_ID) res[2] = { ID: this.ML2_ID, odds: this.ML2_Odds };
        return res;
    },

    setNewOdds: function (betSide, odds)
    {
        switch (betSide)
        {
            case 1:
                this.Updated1 = Data.compare(Odds.round(this.ML1_Odds), Odds.round(odds));
                this.ML1_Odds = odds;
                break;

            case 2:
                this.Updated2 = Data.compare(Odds.round(this.ML2_Odds), Odds.round(odds));
                this.ML2_Odds = odds;
                break;

            case 3:
                this.Updated3 = Data.compare(Odds.round(this.ML3_Odds), Odds.round(odds));
                this.ML3_Odds = odds;
                break;
        }
    }
}

function SpreadOUOdds(initArray)
{
    if (initArray == '')
    {
        this.Updated = false;
        this.Deleted = false;

        return;
    }

    if (initArray == -1)
    {
        this.Deleted = true;
        this.Updated = false;
        return;
    }

    this.Deleted = false;
    this.Updated = true;

    if (Array.safeLength(initArray) == 8)
    {
        this.ID1 = parseInt(initArray[0], 10);
        this.Points1 = parseFloat(initArray[1]);
        this.Odds1 = parseFloat(initArray[2]);
        this.ID3 = parseInt(initArray[3], 10);
        this.Odds3 = parseFloat(initArray[4]);
        this.IsYourBetEnabled1 = !!initArray[5];
        this.IsYourBetEnabled3 = !!initArray[6];
        this.Danger = !!initArray[7];

        return;
    }

    this.Points1 = parseFloat(initArray[0]);
    this.Odds1 = parseFloat(initArray[1]);
    this.Odds3 = parseFloat(initArray[2]);

    this.Updated1 = false;
    this.UpdatedPts = false;
    this.Updated3 = false;

    this.ComboRate = 0;
}

SpreadOUOdds.prototype =
{
    updateFromServer: function (data, evType)
    {
        if (!data.Updated)
        {
            this.Updated1 = false;
            this.UpdatedPts = false;
            this.Updated3 = false;
            this.Updated = false;
            return;
        }

        if (typeof evType !== 'undefined')
        {
            if (!Odds.shouldRound(evType, this.BetType))
            {
                this.Updated = true;
                this.Updated1 = Data.compare(this.Odds1, data.Odds1);
                this.Updated3 = Data.compare(this.Odds3, data.Odds3);
            }
            else
            {
                this.Updated1 = Data.compare(Odds.round(this.Odds1), Odds.round(data.Odds1));
                this.Updated3 = Data.compare(Odds.round(this.Odds3), Odds.round(data.Odds3));
                this.Updated = this.Updated1 || this.Updated3;
            }
            this.Odds1 = data.Odds1;
            this.Odds3 = data.Odds3;

            this.UpdatedPts = this.Points1 != data.Points1;
            this.Points1 = data.Points1;

            this.Updated = this.Updated || this.UpdatedPts;
        }
        else
        {
            this.Updated = true;
            this.Updated1 = this.Odds1 != data.Odds1;
            this.UpdatedPts = this.Points1 != data.Points1;
            this.Updated3 = this.Odds3 != data.Odds3;

            this.Points1 = data.Points1;
            this.Odds1 = data.Odds1;
            this.Odds3 = data.Odds3;
        }
    },

    __resetUpdated: function ()
    {
        this.Updated = false;

        this.Updated1 = false;
        this.UpdatedPts = false;
        this.Updated3 = false;
    },

    hasOdds: function ()
    {
        return this.Odds1 && this.ID1 && this.Odds3 && this.ID3;
    },

    containsQuaterPoints: function ()
    {
        return ((this.Points1 * 100) % 10 != 0);
    },

    hasPoints: function ()
    {
        return true;
    },

    hasDrawLine: function ()
    {
        return false;
    },

    setNewOdds: function (betSide, odds)
    {
        switch (betSide)
        {
            case 1:
                this.Updated1 = Data.compare(this.Odds1, odds);
                this.Odds1 = odds;
                break;

            case 3:
                this.Updated3 = Data.compare(this.Odds3, odds);
                this.Odds3 = odds;
                break;
        }
    }
}

function HCOdds(initArray)
{
    SpreadOUOdds.call(this, initArray);
    this.BetType = 2;
}
$.extend(HCOdds.prototype, SpreadOUOdds.prototype);
HCOdds.prototype.constructor = HCOdds;


function OUOdds(initArray)
{
    SpreadOUOdds.call(this, initArray);
    this.BetType = 3;
}
$.extend(OUOdds.prototype, SpreadOUOdds.prototype);
OUOdds.prototype.constructor = OUOdds;

$.extend(HCOdds.prototype,
{
    getLines: function ()
    {
        return {
            1: { ID: this.ID1, odds: this.Odds1, points: Odds.formatPoints(this.Points1) },
            3: { ID: this.ID3, odds: this.Odds3, points: Odds.formatPoints((-this.Points1)) }
        };
    }
});

$.extend(OUOdds.prototype,
{
    getLines: function ()
    {
        return {
            1: { ID: this.ID1, odds: this.Odds1, points: Odds.formatPoints(this.Points1, true, false, true) },
            3: { ID: this.ID3, odds: this.Odds3, points: Odds.formatPoints(this.Points1, false, true, true) }
        };
    }
});

function getEventTypeId(me, event) {
    if (event) {
        if (event instanceof EventInfo || event instanceof QAEventInfo) {
            if (event.Type) {
                return event.Type;
            }
            if (me && me.Events && typeof me.Events[event.ID] !== 'undefined') {
                return me.Events[event.ID].Type;
            }
            return 'null';
        } else {
            return event.ID;
        }
    }
    return 'null';
}

function QAOdds(qaEvent, qaLinesIds) {
    this.Deleted = false;
    this.Updated = true;
    this.isQA = true;
    this.Event = qaEvent;
    this.Lines = {
        line1: qaEvent.Lines[qaLinesIds[0]],
        line2: qaEvent.Lines[qaLinesIds[1]],
        line3: qaEvent.Lines[qaLinesIds[2]]
    };
    this.ID1 = this.Lines.line1.ID;
    this.Odds1 = this.Lines.line1.Odds;
    this.ID2 = this.Lines.line2.ID;
    this.Odds2 = this.Lines.line2.Odds;
    this.ID3 = this.Lines.line3.ID;
    this.Odds3 = this.Lines.line3.Odds;
    this.Lines.line1.LineName = this.Lines.line1.LineName.indexOf(',') > 0 ?  this.Lines.line1.LineName : qaEvent.TypeName + ", " + this.Lines.line1.LineName;
    this.Lines.line3.LineName = this.Lines.line3.LineName.indexOf(',') > 0 ?  this.Lines.line3.LineName : qaEvent.TypeName + ", " + this.Lines.line3.LineName;
}

$.extend(QAOdds.prototype,
{
    hasOdds: function () {
        return this.Odds1 && this.ID1 && this.Odds2 && this.ID2 && this.Odds3 && this.ID3;
    }
});

window.MLOdds = MLOdds;
window.SpreadOUOdds = SpreadOUOdds;
window.HCOdds = HCOdds;
window.OUOdds = OUOdds;
window.getEventTypeId = getEventTypeId;
window.QAOdds = QAOdds;