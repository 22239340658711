﻿
Application.Navigation = (function (){
    var api = {},
        navigationConfig,
        navigateInitState,
        isSportBranchInitializing = false,
        isSportLeagueInitializing = false,
        isSportEventInitializing = false;

    var openBranchView = function (viewState) 
    {
        Application.OpenBranchPanel(null, viewState.BranchID, null, viewState.EventTypeID, viewState.EventPathPartsCount, viewState.AdditionalParams);
        isSportBranchInitializing = false;
    };

    var openLeagueView = function(viewState) 
    {
        var eventsManager = new EventsManager();
        eventsManager.dispatchEvent('breadcrumbSuspendMessage', null, true);
        try
        {
            openBranchView(viewState);
        }
        finally
        {
            eventsManager.dispatchEvent('breadcrumbResumeMessage', null, true);
        }

        Application.OpenLeague(null, viewState.LeagueID, viewState.BranchID, null, viewState.EventTypeID, viewState.EventPathPartsCount);
        isSportLeagueInitializing = false;
    };

    var openEventView = function(viewState) 
    {
        var eventsManager = new EventsManager();
        eventsManager.dispatchEvent('breadcrumbSuspendMessage', null, true);
        try
        {
            openLeagueView(viewState);
        }
        finally
        {
            eventsManager.dispatchEvent('breadcrumbResumeMessage', null, true);
        }

        var isLive = false;
        var evTypeForGameBlock = 0; // pre live master event
        Application.OpenGame(null, viewState.MasterEventID, isLive, evTypeForGameBlock, false, viewState.BranchID);
        isSportEventInitializing = false;
    };

    var openLiveBettingPanel = function()
    {
        var liveGamePanelCode = api.pageNavigationConfig.LiveBettingNavigationCode;
        var panelToNavigate = Array.find(Application.Panels, function (panel) {
            return panel.Position == PanelPosition.CENTER && panel.hasBlock('EventViewResponsiveBlock') && panel.ID === liveGamePanelCode;
        });

        var currentPanel = Application.getCurrent();
        if (typeof panelToNavigate !== "undefined" && panelToNavigate !== null) {
            panelToNavigate != currentPanel && Application.navigateTo(panelToNavigate.ID, false, []);
            var liveNowBlock = panelToNavigate.getBlock('LiveNowBettingResponsiveBlock');
            liveNowBlock && liveNowBlock.switchView(this.LiveView);
        } else {
            console.warn("panelToNavigate is undefined");
        }
    };

    /* jshint maxcomplexity: 13 */
    var getInitStateNavigate = function (viewState) {
        if (viewState.HREventAdditionalParameters) {
            return function () {
                var hrEventParameters = viewState.HREventAdditionalParameters;
                var getEventId = function (property) {
                    return hrEventParameters[property] ? hrEventParameters[property] : 0;
                };
                OpenRacingGame({ preventDefault: false },
                    viewState.LeagueQaEventID, hrEventParameters.RegionID, hrEventParameters.Mode, viewState.BranchID,
                    getEventId('TrapMarketID'), getEventId('BettingWithoutFavID'),
                    getEventId('BettingWithoutTwoFavID'), getEventId('PlaceOnlyID'),
                    getEventId('MatchBettingID'), getEventId('AUHRToteID'),
                    getEventId('AUHRTotePlaceOnlyID'), getEventId('AUHRPlaceOnlyID'));
            };
        }

        if (viewState.MasterEventID) 
        {
            isSportEventInitializing = true;
            return function() {
                openEventView(viewState);
            };
        }

        if (viewState.LeagueQaEventID) {
            isSportEventInitializing = true;
            return function () {
                Application.OpenGame(null, viewState.LeagueQaEventID, false, CONSTANTS.EventTypes.PreLiveQa, true);
                isSportEventInitializing = false;
            };
        }

        if (viewState.LeagueID) 
        {
            isSportLeagueInitializing = true;
            return function() {
                openLeagueView(viewState);
            };
        }

        if (viewState.BranchID) 
        {
            // Because we are lacking a proper navigation controller this is an ugly workaround to implement SB-131995:
            // pre-selecting a tab depending on a URL parameter called 'tab'
            var tabParamFromURL = getParameterByName('tab');
            tabParamFromURL && (viewState.AdditionalParams = { TabParamFromURL: tabParamFromURL });

            isSportBranchInitializing = true;
            return function() {
                openBranchView(viewState);
            };
        }

        if (viewState.RequestedLiveBetting) 
        {
            var hashArray = window.location.hash.split('#');
            var eventId = hashArray.length >= 2 ? hashArray[1].split('?')[0] : 0;
            var branchId = hashArray.length >= 4 ? hashArray[3].split('?')[0] : 0;
            var evTypeForGameBlock = hashArray.length == 3  ? hashArray[2]: 1;
            
            if (eventId > 0)
            {
                return function()
                {
                    Application.OpenGame(null, eventId, true, evTypeForGameBlock);
                };
            }
            else if (!Application.deviceType.isDesktop())
            {
                // This case happnes when navigating from the hamburger navigation from a page different than sports page
                // or just typing /en/live-betting/ in the address on a mobile/tablet device
                return function () 
                {
                    Application.OpenMobileLiveBetting(null, null, branchId);
                };
            }
            else
            {
                return function () 
                {
                    openLiveBettingPanel();
                };
            }
        }

        if(viewState.IsRequestedTournamentDetails) {
            var locationHash = window.location.hash;
			var locationPathname = window.location.pathname;
            var tournamentIdAsString = locationHash.substr(1);
			var tournamentId = parseInt(tournamentIdAsString);
			var pathnameSplit = locationPathname.split('/');
			var tournamentPathSlug = pathnameSplit.length > 2 ? pathnameSplit[pathnameSplit.length - 2] : undefined;

            return function() {
				if(isNaN(tournamentId) || tournamentPathSlug == undefined) {
					return;
				}
				
                Application.OpenTournamentDetails(null, tournamentId, 
                    undefined, tournamentPathSlug, undefined);
            }
        }

        if (viewState.PanelID) 
        {
            return function ()
            {
                var panelToNavigate = Array.find(Application.Panels, function (panel)
                {
                    return panel.Position === PanelPosition.CENTER && (panel.hasBlock('LiveScheduleResponsiveBlock') || panel.hasBlock('LiveScheduleResponsiveBlockV2'));
                });

                var panelParams = panelToNavigate && panelToNavigate.ID === viewState.PanelID ? getParameterByName('tab').replace(/\/$/, '') : viewState.PanelParams;
                Application.navigateTo(viewState.PanelID, false, [panelParams]);
            };
        }
    };

    api.setup = function (innavigationConfig, viewState)
    {
        navigationConfig = innavigationConfig;
        navigateInitState = getInitStateNavigate(viewState);

        api.viewState = viewState;
        api.pageNavigationConfig = navigationConfig.PageNavigationData;
    };

    api.queryStringProcessing = function () { };

    api.init = function ()
    {
        api.queryStringProcessing();
        var path = window.location.origin + window.location.pathname + window.location.search;

        if (api.viewState.PanelID && !api.viewState.RequestedLiveBetting && !api.viewState.IsRequestedTournamentDetails) {
            path = window.location.origin + api.pageNavigationConfig.FullPagePath + window.location.search;
        }
        var initialPanelId = Application.getDefaultPanelId();
        var defaultPanelCode = Application.Panels[initialPanelId].defaultCode;
        var shouldNavigateToDefault = !(Application.IsNativeApp() && (NavigationPreset.RequestedLiveBetting || NavigationPreset.BranchID
             || NavigationPreset.PanelID || NavigationPreset.IsRequestedTournamentDetails));

        if (initialPanelId !== api.viewState.PanelID && defaultPanelCode !== api.viewState.PanelID && shouldNavigateToDefault)
        {
            Application.navigateTo(initialPanelId, false, [api.viewState.PanelParams], path);
        }

        if (navigateInitState)
        {
            navigateInitState();
        }
    };

    // Implementation of this method is taken from server js code. This is initialized in runtime.
    api.adjustPathByConfig;

    api.adjustPath = function (path, hidePageCode, keepHash)
    {
        if (path.startsWith('http://') || path.startsWith('https://') || path.startsWith('javascript'))
        {
            return path;
        }

        return api.adjustPathByConfig(navigationConfig, path, hidePageCode, keepHash);
    };

    api.getLiveEventLink = function(liveEventId)
    {
        var link = '/' + navigationConfig.PageNavigationData.LiveBettingNavigationCode;
        link = api.adjustPath(link, true) + '#' + liveEventId;
        return link;
    };

    api.getLiveBettingLink = function()
    {
        var link = '/' + navigationConfig.PageNavigationData.LiveBettingNavigationCode;
        link = api.adjustPath(link, true);
        return link;
    };

    api.adjustSportsPath = function (path)
    {
        if (!path.startsWith('/'))
        {
            path = '/' + path;
        }

        if (navigationConfig.IsSEOUrlStructure && api.pageNavigationConfig.SportsNavigationCode)
        {
            path = '/' + api.pageNavigationConfig.SportsNavigationCode + path;
        }

        return api.adjustPathByConfig(navigationConfig, path, true);
    };
	
	api.getTournamentEventLink = function(tournamentId, tournamentPathSlug)
	{
		var link = '/' + api.pageNavigationConfig.SportsNavigationCode + "/" + api.pageNavigationConfig.TournamentNavigationCode + "/" + tournamentPathSlug;
		link = api.adjustPath(link, true) + "#" + tournamentId;
		
		return link;
    };
    
    api.getLeagueLink = function(sportPathSlug, leaguePathSlug)
	{
        var sportsNavigationCode =  !api.pageNavigationConfig.SportsNavigationCode ? "" : '/' + api.pageNavigationConfig.SportsNavigationCode ;
        var link = sportsNavigationCode + '/' + sportPathSlug + "/" + leaguePathSlug;
		link = api.adjustPath(link, true);
		return link;
	};

    api.isSportInitializing = function ()
    {
        return isSportBranchInitializing || isSportLeagueInitializing || isSportEventInitializing;
    };

    return api;
})();

window.Application.Navigation = Application.Navigation;


window.addEventListener("load", function() 
{    
    includeExtension('/JSComponents/AppUrlNavigation.ext.js');
});
