﻿
/* globals Application, rafPaint,whl */
/*exported ToggleMainNavigation*/
var ToggleMainNavigation = (function ()
{
    'use strict';

    var html, btn, mainNav, header, panelTop, scrollingElement, transitionEndBound;
    var transitionEnd = 'ontransitionend' in window ? 'transitionend' : 'webkittransitionend';

    document.addEventListener('DOMContentLoaded', function ()
    {
        html = document.getElementsByTagName('html')[0];
        btn = document.getElementById('callMenu');
        mainNav = document.getElementById('mainNavigation');
        header = document.querySelector('.main_header');
        panelTop = document.getElementById('panels-top-holder');

        var contentOverlay = document.getElementById('main-nav-overlay');
        contentOverlay.addEventListener('click', hide, false);
    }, false);

    function toggle(callback)
    {
        if (html.classList.contains('fixedMenuEnabled'))
        {
            hide(callback);
        }
        else
        {
            show();
        }
    }

    function show()
    {
        //prepare for transition
        html.classList.add('prepareMenuTransition');
        rafPaint(showMenu)();
    }

    function showMenu()
    {
        /*jshint maxcomplexity:8 */
        html.classList.add('fixedMenuEnabled');
        if (btn)
        {
            btn.classList.add('selected');

            if (Application.getLeftPanel()) { Application.getLeftPanel().leave(); }
            if (Application.getRightPanel()) { Application.getRightPanel().leave(); }

            scrollingElement = mainNav.classList.contains('main-navigation-mobile') ? mainNav : document.querySelector('.rj-hamburger-tabs-scrolling');
            !Application.isInIframe() && Application.preventBodyScroll.activate(scrollingElement);
        }

        if (header) {
            header.addEventListener('click', hide);
        }

        if (panelTop) {
            panelTop.addEventListener('click', hide);
        }

        if (window.event) {
            window.event.stopPropagation();
        }

        seamlessSetPanelID(Application.getMobileLeftNavigationPanel());
    }

    function hide(callback)
    {
        if (!mainNav) {
            return;
        }

        transitionEndBound = function(){ 
            return transitionEndHandler(callback);
        };

        mainNav.addEventListener(transitionEnd, transitionEndBound, false);
        html.classList.remove('fixedMenuEnabled');
        if (btn)
        {
            btn.classList.remove('selected');
        }
        !Application.isInIframe() && Application.preventBodyScroll.deactivate(scrollingElement);

        if (header) {
            header.removeEventListener('click', hide);
        }

        if (panelTop) {
            panelTop.removeEventListener('click', hide);
        }

        seamlessSetPanelID(Application.getCurrent());
    }

    function seamlessSetPanelID(panel)
    {
        if (panel !== null && panel !== 'undefined' && typeof whl !== 'undefined' && typeof whl.iFramePageId === 'function') {            
                whl.iFramePageId(panel.ID);
        }
    }

    function transitionEndHandler(transitionEndCallback)
    {
        if (!html.classList.contains('fixedMenuEnabled'))
        {
            html.classList.remove('prepareMenuTransition');
            rafPaint(function() { mainNav.removeEventListener(transitionEnd, transitionEndBound); })();            
            typeof transitionEndCallback === "function" && transitionEndCallback();
        }
    }

    return {
        toggle: toggle,
        show: show,
        hide: hide
    };
})();

window.ToggleMainNavigation = ToggleMainNavigation;