﻿// we are passing the panel class and the active panel class selectors
// so we have them defined in only one place (MobilePage class)

/* exported MobilePanel, PanelPosition */
/* globals UI, MobilePanel, Application, resetScroll, StorageUtils, MobileBlocksPerf, isTestingEnv */
var PanelPosition = {
    LEFT: 'left',
    RIGHT: 'right',
    CENTER: 'center',
    POPUP: 'popup',
    TOP: 'top',
    BOTTOM: 'bottom',
    LEFT_NAV: 'left-nav',
    RIGHT_NAV: 'right-nav'
};

function MobilePanel(config)
{
    'use strict';
    this.ID = config.id;
    this.Blocks = [];
    this.Effect = 'slide';
    this.Timing = 'ease';

    this.isActive = false;
    this.additionalPanelClassName = config.additionalPanelClassName || '';
    this.defaultCode = config.defaultCode || '';
    this.activationMap = new Map();
}

MobilePanel.prototype =
    {
        init: function ()
        {
            'use strict';
            // MobileBlocksPerf.init({ BlockUniqueID: this.ID });
            // MobileBlocksPerf.log('beforeInit', { BlockUniqueID: this.ID });

            var blocks = this.Blocks;
            for (var i in blocks)
            {
                if (blocks.hasOwnProperty(i) && typeof blocks[i].init === 'function')
                {
                    MobileBlocksPerf.log('beforeInit', blocks[i]);
                    blocks[i].init();
                    MobileBlocksPerf.log('afterInit', blocks[i]);
                }
                else if (typeof blocks[i].init !== 'function' && isTestingEnv)
                {
                    console.error('issue with block.init: ', blocks[i]);
                }
            }
            // MobileBlocksPerf.log('afterInit', { BlockUniqueID: this.ID });
        },

        /*jshint maxcomplexity:7 */
        activate: function ()
        {
            'use strict';
            // MobileBlocksPerf.log('beforeActivate', { BlockUniqueID: this.ID });
            var blocks = this.Blocks;

            // when have custom footer
            var aTag = document.querySelector('#footerNav a[href="#' + this.ID + '"]');
            if (aTag)
            {
                aTag.classList.add('active');
            }

            for (var i in blocks)
            {
                if (blocks.hasOwnProperty(i) && typeof blocks[i].activate === 'function')
                {
                    this.activationMap.set(blocks[i].BlockUniqueID, setTimeout((function(x) {
                        return function() {
                            MobileBlocksPerf.log('beforeActivate', blocks[x]);
                            blocks[x].activate();
                            MobileBlocksPerf.log('afterActivate', blocks[x]);
                        }
                    })(i)));
                }
                else if (typeof blocks[i].activate !== 'function' && isTestingEnv)
                {
                    console.error('issue with block.activate: ', blocks[i]);
                }
            }

            this.isActive = true;

            if (Application.IsNativeApp())
            {
                this.saveAppState();
            }

            if (this.Position !== PanelPosition.POPUP)
            {
                resetScroll();
            }

            // MobileBlocksPerf.log('afterActivate', { BlockUniqueID: this.ID });
        },

        deactivate: function ()
        {
            'use strict';
            // MobileBlocksPerf.log('beforeDeactivate', { BlockUniqueID: this.ID });
            var blocks = this.Blocks;

            // when have custom footer
            var aTag = document.querySelector('#footerNav a[href="#' + this.ID + '"]');
            if (aTag)
            {
                aTag.classList.remove('active');
            }

            for (var i in blocks)
            {
                if (blocks.hasOwnProperty(i) && typeof blocks[i].deactivate === 'function')
                {
                    clearTimeout(this.activationMap.get(blocks[i].BlockUniqueID)) 
                    MobileBlocksPerf.log('beforeDeactivate', blocks[i]);
                    blocks[i].deactivate();
                    MobileBlocksPerf.log('afterDeactivate', blocks[i]);
 
                }
                else if (typeof blocks[i].deactivate !== 'function' && isTestingEnv)
                {
                    console.error('issue with block.deactivate: ', blocks[i]);
                }
            }

            this.isActive = false;
            // MobileBlocksPerf.log('beforeDeactivate', { BlockUniqueID: this.ID });
        },

        setParams: function (params)
        {
            'use strict';

            var blocks = this.Blocks;
            for (var i in blocks)
            {
                if (blocks.hasOwnProperty(i) && typeof blocks[i].setParams === 'function')
                {
                    blocks[i].setParams.apply(blocks[i], params);
                }
                else if (typeof blocks[i].setParams !== 'function' && isTestingEnv)
                {
                    console.error('issue with block.setParams: ', blocks[i]);
                }
            }
        },

        beforeEnter: function ()
        {
        },

        afterEnter: function ()
        {
            'use strict';
            this.executeMethodOnEveryBlock('afterActivate');
        },

        beforeLeave: function ()
        {
        },

        afterLeave: function ()
        {
        },

        execAnumation: function (transitionSettings, finalClasses, finishedCallback)
        {
            'use strict';

            var htmlObject = document.getElementById(this.ID);
            if (htmlObject)
            {
                htmlObject.className = finalClasses;
            }

            finishedCallback();
            return;
        },

        enter: function (transitionSettings)
        {
            'use strict';

            var self = this;

            // before animation collaback
            this.beforeEnter();
            this.setParams(transitionSettings.Params);

            this.execAnumation(
                transitionSettings.EnterSettings + ' ' + self.panelClassName + ' ' + self.activePanelClassName + (self.additionalPanelClassName ? ' ' + self.additionalPanelClassName : ''),
                self.panelClassName + ' ' + self.activePanelClassName + (self.additionalPanelClassName ? ' ' + self.additionalPanelClassName : ''),
                function ()
                {
                    setTimeout(function ()
                    {
                        Application.enableTransition();
                    }, 100);
                    self.afterEnter();
                }
            );

            this.activate();

            UI.scrollUtil.attachScroll();
        },

        leave: function (transitionSettings)
        {
            'use strict';

            var self = this;

            this.beforeLeave();

            this.execAnumation(
                transitionSettings.LeaveSettings + ' ' + self.panelClassName + (self.additionalPanelClassName ? ' ' + self.additionalPanelClassName : ''),
                self.panelClassName + (self.additionalPanelClassName ? ' ' + self.additionalPanelClassName : ''),
                function ()
                {
                    self.afterLeave();
                }
            );

            self.deactivate();
        },

        registerBlock: function (block)
        {
            'use strict';
            this.Blocks.push(block);
            MobileBlocksPerf.init(block);
        },

        hasBlock: function (typeOfBlock)
        {
            'use strict';

            return !!Array.find(this.Blocks, function (block)
            {
                return (window[typeOfBlock] && block instanceof window[typeOfBlock]) || block.class === typeOfBlock;
            });
        },

        sidePanelsHaveBlock: function (typeOfBlock)
        {
            'use strict';

            var leftPanelHasBlock = this.LeftPanel && this.LeftPanel.hasBlock(typeOfBlock);
            var rightPanelHasBlock = this.RightPanel && this.RightPanel.hasBlock(typeOfBlock);

            return leftPanelHasBlock || rightPanelHasBlock;
        },

        hasLiveContent: function ()
        {
            var hasLiveContent = this.hasLiveBlocks(this.Blocks);
            hasLiveContent = hasLiveContent || (this.LeftPanel && this.hasLiveBlocks(this.LeftPanel.Blocks));
            hasLiveContent = hasLiveContent || (this.RightPanel && this.hasLiveBlocks(this.RightPanel.Blocks));

            return hasLiveContent;
        },

        hasVirtualContent: function ()
        {
            var hasVirtualContent = this.hasVirtualBlocks(this.Blocks);
            hasVirtualContent = hasVirtualContent || (this.LeftPanel && this.hasVirtualBlocks(this.LeftPanel.Blocks));
            hasVirtualContent = hasVirtualContent || (this.RightPanel && this.hasVirtualBlocks(this.RightPanel.Blocks));

            return hasVirtualContent;
        },

        hasLiveBlocks: function (blocks) 
        {
            return blocks.any(function (block)
            {
                return block.HasLiveContent;
        });
    },


        hasVirtualBlocks: function (blocks)
        {
            return blocks.any(function (block)
            {
                return block.HasVirtualContent;
            });
        },

        getBlock: function (typeOfBlock)
        {
            'use strict';
            return this.getBlockInContainer(typeOfBlock, this.Blocks)
                || this.getBlockInContainer(typeOfBlock, this.LeftPanel && this.LeftPanel.Blocks)
                || this.getBlockInContainer(typeOfBlock, this.RightPanel && this.RightPanel.Blocks);
        },

        getBlockInContainer: function (typeOfBlock, container) 
        {
            'use strict';
            if (!container)
            {
                return null;
            }

            return container.firstOrDefault(function (block)
            {
                return window[typeOfBlock] && (block instanceof window[typeOfBlock] || block.Name === typeOfBlock);
            });
        },

        saveAppState: function ()
        {
            'use strict';

            StorageUtils.saveToStorage('path', window.location.pathname, localStorage);
            StorageUtils.saveToStorage('panelID', this.ID, localStorage);
        },

        executeMethodOnEveryBlock: function (methodName)
        {
            'use strict';
            var blocks = this.Blocks;

            for (var i in blocks)
            {
                if (blocks.hasOwnProperty(i))
                {
                    var block = blocks[i];
                    var method = block[methodName];
                    if (method && typeof method === 'function')
                    {
                        method.apply(block);
                    }
                }
            }
        },

        isSidePanel: function ()
        {
            'use strict';
            return this.Position === PanelPosition.LEFT || this.Position === PanelPosition.RIGHT;
        },

        isPeripheralPanel: function ()
        {
            return this.isSidePanel() || this.Position === PanelPosition.TOP;
        }
    };

window.PanelPosition = PanelPosition;
window.MobilePanel = MobilePanel;
