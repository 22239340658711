function BonusInfo(data)
{
    $.extend(this, data);
    this.Date = (data.Date instanceof Date) ? data.Date : Date.fromISO(data.Date);
    this.UpdateDate = (data.UpdateDate instanceof Date) ? data.UpdateDate : Date.fromISO(data.UpdateDate);
    this.ValidToDate = (data.ValidToDate instanceof Date) ? data.ValidToDate : Date.fromISO(data.ValidToDate);
    this.TurnOverFrom = (data.TurnOverFrom instanceof Date) ? data.TurnOverFrom : Date.fromISO(data.TurnOverFrom);
    this.TurnOverTo = (data.TurnOverTo instanceof Date) ? data.TurnOverTo : Date.fromISO(data.TurnOverTo);
    //Backwards compatibility for out-of-date templates
    if (!this.Requirements) this.Requirements = this.Conditions;
    if (!this.TermsAndConditionsMessage) this.TermsAndConditionsMessage = '';
}

window.BonusInfo = BonusInfo;