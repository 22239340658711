﻿var Lines = (function ()
{
    'use strict';
    var api = {};

    /* jshint maxcomplexity: 7 */ // TODO: Refactor to reduce complexity!
    api.isTypeSP = function (LineTypeID)
    {
        switch (LineTypeID)
        {
            case 24:    //SP
            case 25:    //SP E/W
            case 26:    //1st fav
            case 27:    //1st fav E/W
            case 28:    //2nd fav
            case 29:    //2nd fav E/W
                return true;
            default:
                return false;
        }
    };
    /* jshint maxcomplexity: 5 */

    /* jshint maxcomplexity: 7 */ // TODO: Refactor to reduce complexity!
    api.isTypeEW = function (LineTypeID)
    {
        switch (LineTypeID)
        {
            case 19:    //AntePost E/W
            case 21:    //NRNB E/W
            case 23:    //DOE E/W
            case 25:    //SP E/W
            case 27:    //1st fav E/W
            case 29:    //2nd fav E/W
                return true;
            default:
                return false;
        }
    };
    /* jshint maxcomplexity: 5 */

    // Used in Mobile
    /* jshint maxcomplexity: 10, maxparams: 4 */ // TODO: remove after website refactoring
    api.getFormattedPoints = function (points, isOver, isUnder, showOUAbbreviation)
    {
        //Quarter points have to display only for Asian View
        var OUAbbrev;

        if (hasAsianOdds && currentBettingViewID == 2)
        {
            if (Math.abs(points - points.toFixed(0)) == 0.25) //Quarter
            {
                if (!isOver && !isUnder)
                {
                    var isPositiveHC = (points + 0.25 > 0);
                    return isPositiveHC ? constPtsAsianHCFormat.format(points - 0.25, points + 0.25)
                        : constPtsAsianHCFormat.format(points + 0.25, points - 0.25);
                }

                // Over/Under
                if (showOUAbbreviation)
                {
                    OUAbbrev = isOver ? $string('General').OverShort : $string('General').UnderShort;
                    return constPtsAsianOUFormat.format(OUAbbrev, points - 0.25, points + 0.25);
                }
                else
                {
                    return constPtsAsianOUFormat.format('', points - 0.25, points + 0.25);
                }
            }
        }

        var pts = new Number(points);

        if (!isOver && !isUnder) //Handicap
        {
            return  pts > 0 ? "+" + PointsFormat.format(pts) : PointsFormat.format(pts);
        }

        // Over/Under
        if (showOUAbbreviation)
        {
            OUAbbrev = isOver ? $string('General').OverShort : $string('General').UnderShort;
            return OUAbbrev + ' ' + PointsFormat.format(pts);
        }
        else
        {
            return PointsFormat.format(pts);
        }
    };

    return api;
}());

window.Lines = Lines;