﻿/* exported MobileBottomPanel */
/* globals MobilePeripheralPanel */
function MobileBottomPanel(config) {
    'use strict';
    MobilePeripheralPanel.call(this, config);
    this.Position = PanelPosition.BOTTOM;
}

$.extend(MobileBottomPanel.prototype, MobilePeripheralPanel.prototype);

MobileBottomPanel.EmptyPanel = new MobileBottomPanel({ id: 'panel-bottom-empty-panel' });
window.MobileBottomPanel = MobileBottomPanel;