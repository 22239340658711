﻿/* exported PanelSwitcher */
/* globals MobileBlocksPerf */
var PanelSwitcher = (function ()
{
    'use strict';
    var BlocksCache = document.createDocumentFragment();

    function removeAllRenderedBlocks()
    {
        var allBlocks = document.querySelectorAll('sb-block > div');

        Array.prototype.forEach.call(allBlocks, function (htmlBlock)
        {
            // Clean <script> from block content because it is already executed
            var allScriptTags = htmlBlock.getElementsByTagName('script');
            while (allScriptTags.length > 0)  // this works because it is a live HTMLCollection
            {
                allScriptTags[0].parentNode.removeChild(allScriptTags[0]);
            }

            cacheBlock(htmlBlock);
        });
    }

    function switchBetweenBlocks(fromPanel, toPanel)
    {
        var blocksOnBothPanels = [];
        fromPanel.Blocks.forEach(function (b1)
        {
            if (toPanel.Blocks.some(function (b2) { return b1.BlockUniqueID == b2.BlockUniqueID; }))
            {
                blocksOnBothPanels[b1.BlockUniqueID] = true;
            }
        });

        fromPanel.Blocks.forEach(function (fromPanelBlock)
        {
            try
            {
                var isBlockOnBothPanels = blocksOnBothPanels[fromPanelBlock.BlockUniqueID];
                if (!isBlockOnBothPanels)
                {
                    fromPanelBlock.deactivate();
                    cacheBlock(fromPanelBlock.getBlockContainer());
                }
            }
            catch (e)
            {
                console.log(e);
            }
        });

        toPanel.Blocks.eachInReverse(function (toPanelBlock)
        {
            try
            {
                var isBlockOnBothPanels = blocksOnBothPanels[toPanelBlock.BlockUniqueID];
                var blockContainer = isBlockOnBothPanels ? toPanelBlock.getBlockContainer() : getCachedBlock(toPanelBlock);

                showBlock(toPanel, toPanelBlock, blockContainer);
                toPanelBlock.PanelID = toPanel.ID;
                if (!isBlockOnBothPanels)
                {
                    MobileBlocksPerf.log('beforeActivate', toPanelBlock);
                    toPanelBlock.activate();
                    MobileBlocksPerf.log('afterActivate', toPanelBlock);
                    // creates abnormal behavior with product navigation
                    // TODO: investigate
                    // setTimeout(function (b)
                    // {
                    //     MobileBlocksPerf.log('beforeActivate', toPanelBlock);
                    //     toPanelBlock.activate();
                    //     MobileBlocksPerf.log('afterActivate', toPanelBlock);
                    // }.curry(toPanelBlock), 0);
                }
            }
            catch (e)
            {
                console.log(e);
            }
        });
    }

    function showBlock(panel, block, blockContainer)
    {
        if (!blockContainer)
        {
            return;
        }

        var placeHolderIndex = panel.Blocks.where(function (bl) { return !bl.ParentBlock; }).indexOf(block) + 1;
        var blockPlaceholder = document.getElementById(panel.Position + '-panel-block-' + placeHolderIndex);
      	if(blockPlaceholder)
        {
          blockPlaceholder.appendChild(blockContainer);
          blockPlaceholder.classList.remove('isHidden');
        }
    }

    function cacheBlock(blockContainer)
    {
        if (!blockContainer)
        {
            return;
        }

        BlocksCache.appendChild(blockContainer);
    }

    function getCachedBlock(block)
    {
        return BlocksCache.querySelector('#' + block.BlockUniqueID);
    }

    return {
        removeAllRenderedBlocks: removeAllRenderedBlocks,
        switchBetweenBlocks: switchBetweenBlocks
    };
}());

window.PanelSwitcher = PanelSwitcher;
