var Events = (function ()
{
    'use strict';
    var api = {};        
     api.isDoubleMarketsAvailableFromSingleEventType = function (typeID, splitTypeID)
     {
         if (splitTypeID == 6)
         {
             return true;
         }
         else
         {
             var list = [979,1025, 1031, 1036,1322];
             return Array.find(list, function (id) { return id == typeID });
         }
    };

    api.getMainEvent = function (masterEvent)
    {
        return masterEvent.Events[masterEvent.Live ? masterEvent.getMainLiveEventID() : masterEvent.getMainEventID()];
    };

    api.countEvents = function (masterEvent, group)
    {
        if (typeof (LiveMasterEventForLiveOutrights) != 'undefined' && masterEvent instanceof LiveMasterEventForLiveOutrights)
        {
            return masterEvent.getEventsCount();
        }
        else
        {
            return countLiveBetTypeOptions(masterEvent, group);
        }
    };

    /*jshint loopfunc: true, maxcomplexity: 13 */
    var countLiveBetTypeOptions = function (masterEvent, group)
    {
        var count = 0, eventKey;
        var eventsKeys = Object.keys(masterEvent.Events),
            eventsKeysLength = eventsKeys.length;

        for (eventKey = 0; eventKey < eventsKeysLength; eventKey += 1)
        {
            var event = masterEvent.Events[eventsKeys[eventKey]];

            if (event.isQA && event.Lines && event.Lines.any(function (lg) { return lg.hasOdds(); }))
            {
                var isInGroups = group.EventTypes.any(function (evt) { return evt.ID == event.Type; });
                if (isInGroups)
                {
                    count += 1;
                }
                continue;
            }
            if (!event.Lines)
            {
                continue;
            }
            count += countBetTypesByLines(event, group);
        }

        return count;
    };

    var countBetTypesByLines = function (event, group)
    {
        var linesKeys = Object.keys(event.Lines), lineKey,
                linesKeysLength = linesKeys.length, count = 0;

        for (lineKey = 0; lineKey < linesKeysLength; lineKey += 1)
        {
            var lineGroup = event.Lines[linesKeys[lineKey]];
            if (lineGroup.ML && lineGroup.ML.hasOdds())
            {
                if (group.EventTypes.any(function (evt)
                {
                    var obj = { Event: event, LineTypeID: 1 };

                    return Events.checkMarketEventType(obj, evt);
                }))
                {
                    count += 1;
                }
            }

            if (lineGroup.Spread && lineGroup.Spread.hasOdds())
            {
                if (group.EventTypes.any(function (evt)
                {
                     var obj = { Event: event, LineTypeID: 2 };

                    return Events.checkMarketEventType(obj, evt);
                }))
                {
                    count += 1;
                }
            }

            if (lineGroup.OU && lineGroup.OU.hasOdds())
            {
                if (group.EventTypes.any(function (evt)
                {
                     var obj = { Event: event, LineTypeID: 3 };

                    return Events.checkMarketEventType(obj, evt);
                }))
                {
                    count += 1;
                }
            }
        }
        return count;
    };

    api.displayMasterEvent = function (masterEvent)
    {
        var mainEvent = api.getMainEvent(masterEvent);
        if (!(mainEvent && mainEvent.MainLine)) { return false; }

        var mainLine = mainEvent.MainLine;

        if (!(mainLine.ML && mainLine.ML.hasOdds()) &&
            !(mainLine.Spread && mainLine.Spread.hasOdds()) &&
            !(mainLine.OU && mainLine.OU.hasOdds())) { return false; }

        return true;
    };

    api.retrieveEventTitle = function (gridLength, eventInfo)
    {
        var fastMarket;

        /*globals isFastMarket, isTennisFastMarket, SplitTypeFactory */// SplitTypes.js, FastMarket.js
        if (isFastMarket(eventInfo.SplitType) || isTennisFastMarket(eventInfo.SplitType))
        {
            fastMarket = SplitTypeFactory.Create(eventInfo.SplitType);
            return fastMarket.buildEventTypeTitle(eventInfo.Lines, eventInfo.TypeName);
        }

        return eventInfo.eventName ? eventInfo.eventName : eventInfo.TypeName;
    };

    api.isTotalGoalsEvent = function (eventInfo)
    {
        return eventInfo.Type == 257 || eventInfo.Type == 7;
    };

    var getEventAndTeamName = function (masterEvent, eventInfo, context)
    {
        var teamName = eventInfo.TeamMapping != 2 ? masterEvent.Team1Name : masterEvent.Team2Name,
            eventName = eventInfo.Name || context.eventType.Name,
            typeIDs = [7, 257];

        if (typeIDs.indexOf(+eventInfo.Type) !== -1 || eventInfo.SplitType == 6)
        {
            return eventName + ' - ' + teamName;
        }

        return eventName;
    };

    api.getPreLiveEventEventName = function (masterEvent, eventInfo, context)
    {
        var splitTypeID = context.market.LineTypeID;

        if (splitTypeID == 1)
        {
            return context.eventType.LineNames[0] + ' ' + context.eventType.Name;
        }

        if ((splitTypeID == 2 || splitTypeID == 3) && context.eventType.ID != 200 && context.eventType.ID != 201)
        {
            return context.eventType.LineNames[1] + '&' + context.eventType.LineNames[2] + ' ' + context.eventType.Name;
        }

        return getEventAndTeamName(masterEvent, eventInfo, context);
    };

    api.getPreLiveEventEventNameWithSplitedHCAndOU = function (eventInfo, eventTypeInfo, split)
    {
        var eventName = eventInfo.EventName ? eventInfo.EventName : eventTypeInfo.Name;

        if (split == 1)
        {
            eventName = eventTypeInfo.LineNames[0] + ' ' + eventTypeInfo.Name;
        }

        if (split == 2 && eventTypeInfo.ID != 200 && eventTypeInfo.ID != 201)
        {
            eventName = eventTypeInfo.LineNames[1] + ' ' + eventTypeInfo.Name;
        }

        if (split == 3 && eventTypeInfo.ID != 200 && eventTypeInfo.ID != 201)
        {
            eventName = eventTypeInfo.LineNames[2] + ' ' + eventTypeInfo.Name;
        }

        return eventName;
    };

    function teamSwapCellIndexes(masterEvent, cellIndexes)
    {
        return masterEvent.IsTeamSwapEnabled ? cellIndexes.reverse() : cellIndexes;
    }

    api.getCellIndexes = function (masterEvent, lineGroup, prop)
    {
        var cellIndexes = [1, 2, 3];

        /*jshint -W106 */// ignore ML2_Odds
        if ((prop == 'ML' && lineGroup.ML && !lineGroup.ML.ML2_Odds) || (prop == 'OU' && lineGroup.OU) || (prop == 'Spread' && lineGroup.Spread))
        {
            /*jshint +W106 */
            cellIndexes = [1, 3];

            return teamSwapCellIndexes(masterEvent, cellIndexes);
        }

        return teamSwapCellIndexes(masterEvent, cellIndexes);
    };

    api.getMappedScore = function (masterEvent, eventInfo)
    {
        /*globals calcMappings */// SplitTypes.js
        var mappedType = calcMappings[eventInfo.Type],
            mappedEvent;

        if (mappedType)
        {
            mappedEvent = masterEvent.findSutableEvent([mappedType]);
            if (mappedEvent)
            {
                eventInfo.MappedScore1 = mappedEvent.Score1;
                eventInfo.MappedScore2 = mappedEvent.Score2;
            }
        }

    };

    api.generateUpdateString = function (pushUpdateEvents, args)
    {
        var array = [], i, len;

        for (i = 0, len = args.length; i < len; i += 1)
        {
            array = array.concat(buildArray(args[i], pushUpdateEvents));
        }

        return array.join('@');
    };

    var buildArray = function (masterEvents, pushUpdateEvents)
    {
        var array = [], meKey, masterEvent;

        for (meKey in masterEvents)
        {
            if (masterEvents.hasOwnProperty(meKey))
            {
                masterEvent = masterEvents[meKey];
                if (masterEvent.Events)
                {
                    array = array.concat(buildRegularArray(masterEvent, pushUpdateEvents));
                }
                else
                {
                    array = array.concat(buildOutrightArray(masterEvent, pushUpdateEvents));
                }
            }
        }

        return array;
    };

    var buildRegularArray = function (masterEvent, pushUpdateEvents)
    {
        var array = [], eKey, event;

        for (eKey in masterEvent.Events)
        {
            if (masterEvent.Events.hasOwnProperty(eKey))
            {

                event = masterEvent.Events[eKey];
                PushOddsUtils.getPushUpdateEvents(event, pushUpdateEvents);

                if (!event.isExpired(masterEvent.LastUpdate, masterEvent.UpdateTimeoutMultiplier))
                {
                    continue;
                }

                array.push(event.getUpdateString());
            }
        }

        return array;
    };

    var buildOutrightArray = function (qaEvent, pushUpdateEvents)
    {
        var array = [];
        if (!qaEvent.isExpired(qaEvent.LastUpdate, qaEvent.UpdateTimeoutMultiplier))
        {
            return array;
        }

        PushOddsUtils.getPushUpdateEvents(qaEvent, pushUpdateEvents);

        array.push(qaEvent.getUpdateString());

        return array;
    };

    api.processUpdates = function ()
    {
        var data = arguments[0], i, len,
            updatedObjects = api.getUpdatingObjects(data);

        for (i = 1, len = arguments.length; i < len; i += 1)
        {
            updateMasterEvents(arguments[i], updatedObjects);
        }

        return updatedObjects;
    };

    api.getUpdatingObjects = function (data)
    {
        var result = {}, key;

        for (key in data)
        {
            if (data.hasOwnProperty(key))
            {
                result[data[key][0]] = data[key];
            }
        }

        return result;
    };

    var updateMasterEvents = function (masterEvents, updatedObjects)
    {
        var meKey, masterEvent;

        for (meKey in masterEvents)
        {
            if (masterEvents.hasOwnProperty(meKey))
            {
                masterEvent = masterEvents[meKey];
                if (masterEvent.Events)
                {
                    updateEvents(masterEvent, updatedObjects);
                }
                else
                {
                    updateOutrightEvent(masterEvents, updatedObjects, meKey);
                }
            }
        }
    };

    var updateEvents = function (masterEvent, updatedObjects)
    {
        var eKey, event;

        for (eKey in masterEvent.Events)
        {
            if (masterEvent.Events.hasOwnProperty(eKey) && updatedObjects[eKey])
            {
                event = masterEvent.Events[eKey];
                event.updateFromServer(updatedObjects[eKey]);
                event.MasterEvent = masterEvent;

                if (masterEvent.Live)
                {
                    masterEvent.updateLiveInfo(event);
                }

                if (event.Deleted)
                {
                    delete masterEvent.Events[eKey];
                }
            }
        }
    };

    var updateOutrightEvent = function (qaEvents, updatedObjects, meKey)
    {
        if (!updatedObjects[meKey]) { return; }

        var qaEvent = qaEvents[meKey];
        qaEvent.updateFromServer(updatedObjects[meKey]);

        if (qaEvent.Deleted)
        {
            delete qaEvents[meKey];
        }
    };

    api.getPreLiveEventsToDisplay = function (masterEvent, league)
    {
        var eventKey,
            eventInfo,
            eventType,
            eventsToShow = [],
            eventTypesInLeague = league.EventTypes,
            res = { HasOneEvent: false, HasNoOddsEvent: false };

        for (eventKey in masterEvent.Events)
        {
            if (masterEvent.Events.hasOwnProperty(eventKey))
            {
                eventInfo = masterEvent.Events[eventKey];
                if (!eventInfo.hasOdds())
                {
                    res.HasNoOddsEvent = true;
                    continue;
                }

                eventType = eventTypesInLeague[eventInfo.Type];
                if (eventType)
                {
                    eventInfo.OrderID = eventType.OrderID;
                    res.HasOneEvent = true;
                    eventsToShow.push(eventInfo);
                }
            }
        }

        return eventsToShow;
    };

    api.getSplitedMarketInfo = function (sortingElement, branch, marketsSplit)
    {
        var wsorts = sortingElement[branch.ID];

        /*globals chainSort, BetTypesToBeMovedToEnd */
        marketsSplit.sort(chainSort(
            function (marketEvent1, marketEvent2)
            {
                var isEvtToBeMoved1 = Array.indexOf(BetTypesToBeMovedToEnd[branch.ID],
                    function (eventTypeToMove) { return eventTypeToMove.EventType == marketEvent1.Event.Type && eventTypeToMove.LineType == marketEvent1.LineTypeID && eventTypeToMove.ForLive; }) > -1;
                var isEvtToBeMoved2 = Array.indexOf(BetTypesToBeMovedToEnd[branch.ID],
                    function (eventTypeToMove) { return eventTypeToMove.EventType == marketEvent2.Event.Type && eventTypeToMove.LineType == marketEvent2.LineTypeID && eventTypeToMove.ForLive; }) > -1;
                if (isEvtToBeMoved1 && isEvtToBeMoved2)
                {
                    return 0;
                }
                if (isEvtToBeMoved1)
                {
                    return 1;
                }
                if (isEvtToBeMoved2)
                {
                    return -1;
                }
                return 0;
            },
            function (a1, a2) { return Data.compare(wsorts[a1.ID], wsorts[a2.ID]); },
            function (a1, a2) { return Data.compare(a1.Event.Type, a2.Event.Type); },
            function (a1, a2) { return Data.compare(a1.LineTypeID, a2.LineTypeID); }
        ));

        return marketsSplit;
    };

    api.setExpandedProperty = function (marketEvent, expandedElements, context)
    {
        if ((context.counter < 5 && !context.isDrawn || expandedElements.indexOf(context.elementID) > -1 && context.isDrawn) && marketEvent.hasOdds(context.prop))
        {
            return true;
        }

        return false;
    };

    api.displayPreLiveRegularEvents = function (marketEvent, market)
    {
        /*globals MarketProps */
        if (typeof MarketProps == 'undefined' || !marketEvent.hasOdds(MarketProps[market.LineTypeID]) || market.LineTypeID == 3 && marketEvent.hasOdds(MarketProps[2]))
        {
            return false;
        }
        return true;
    };

    api.displayPreLiveEvents = function (marketEvent, market, eventType)
    {
        if (!eventType.IsQA && market.LineTypeID !== 0 && Events.displayPreLiveRegularEvents(marketEvent, market) || eventType.IsQA || market.LineTypeID === 0)
        {
            return true;
        }

        return false;
    };

    api.getNumberOfAlreadyDisplayedLines = function (masterEvent, eventInfo)
    {
        var propLength,
            key,
            prop,
            counter = 0,
            props = ['ML', 'Spread', 'OU'],
            numberOfLines = Events.getNumberOfDisplayedLinesInMainEvent(masterEvent);

        for (key = 0, propLength = props.length; key < propLength; key += 1)
        {
            prop = props[key];
            if (eventInfo.MainLine[prop] === null) { continue; }

            if (eventInfo.MainLine[prop].hasOdds() !== 0 && counter < numberOfLines)
            {
                counter += 1;
            }
        }

        return counter;
    };

    api.getNumberOfLines = function (masterEvent, eventInfo)
    {
        var totalLinesToShow = masterEvent.getMoreCount();
        var alreadyShownLines = api.getNumberOfAlreadyDisplayedLines(masterEvent, eventInfo);

        totalLinesToShow -= alreadyShownLines;

        return totalLinesToShow;
    };

    api.getPropertiesOrder = function (masterEvent)
    {
        var props = ['ML', 'Spread', 'OU'];

        if (Bets.asianViewEnabledDisabled(masterEvent.BranchID))
        {
            /* for Asian view lines are displayed in 3 columns in the following order:
                  1 - Spread, 2 - OU, 3 - ML
              */
            props = ['Spread', 'OU', 'ML'];
        }
        return props;
    };

    api.getNumberOfDisplayedLinesInMainEvent = function (masterEvent)
    {
        var numberOfLines = 1;
        if (Bets.asianViewEnabledDisabled(masterEvent.BranchID) || Bets.americanOddsDiplayEnabled(masterEvent.BranchID))
        {
            /* for Asian view lines are displayed in 3 columns in the following order:
                1 - ML, 2 - Spread, 3 - OU
            */
            numberOfLines = 3;
        }
        return numberOfLines;
    };   

    api.displayNumberOfLines = function (masterEvent, eventInfo)
    {
        var countToShow = Events.getNumberOfLines(masterEvent, eventInfo),
            countString = '';

        if (countToShow >= 1)
        {
            countString = '+ ' + countToShow;
        }

        return countString;
    };

    api.displayFastMarketIcon = function (masterEvent)
    {
        if (masterEvent.HasFastMarketsSpecials)
        {
            return true;
        }

        return false;
    };

    api.displayLiveStreamingIcon = function (masterEvent)
    {
        if (masterEvent.hasLiveStream)
        {
            return true;
        }

        return false;
    };
    
    api.displayFastMarketIconOnEventTypeHeader = function (splitType, masterEvent)
    {
        if (splitType !== undefined && splitType !== null)
        {
            splitType = splitType.toString();
        }
        else
        {
            return false;
        }
        /*globals isFastMarket, isTennisFastMarket */
        if (Events.displayFastMarketIcon(masterEvent) && (isFastMarket(splitType) || isTennisFastMarket(splitType)))
        {
            return true;
        }

        return false;
    };

    api.getRacingEventDate = function (dateValue, dateFormat, branchID)
    {
        if (!dateValue) { return ''; }

        /*globals UseAustralianHorseRacingView*/
        var date = UseAustralianHorseRacingView || VirtualSports.isBranchVirtual(branchID) ? Data.getLocalTime(dateValue) : Data.getUkTime(dateValue);
        return date.toStringEx(dateFormat);
    };

    api.sortedGroupEventTypes = function (groupEventTypes)
    {
        var sortedGroupEventTypes = groupEventTypes.sort(function (evt1, evt2)
        {
            return Data.compare(evt1.OrderID, evt2.OrderID);
        });

        return sortedGroupEventTypes;
    };

    api.sortedEventsByDefault = function (eventTypesPerBranch, events)
    {
        var sortedEvents = events.sort(chainSort(function (a1, a2)
        {
            return Data.compare(eventTypesPerBranch[a1.ID], eventTypesPerBranch[a2.ID]);
        },
        function (a1, a2)
        {
            return Data.compare(a1.LineTypeID, a2.LineTypeID);
        }
        ));

        return sortedEvents;
    };

    api.sortedEventsInGroup = function (sortedGroupEventTypes, marketsSplit)
    {
        var i, k,
            eventType,
            marketEvent,
            sortedEvents = [],
            sortedGroupEventTypesKeys = Object.keys(sortedGroupEventTypes),
            sortedGroupEventTypesLength = sortedGroupEventTypesKeys.length,
            marketsSplitKeys = Object.keys(marketsSplit),
            marketsSplitLength = marketsSplitKeys.length;

        for (i = 0; i < sortedGroupEventTypesLength; i += 1)
        {
            eventType = sortedGroupEventTypes[i];
            for (k = 0; k < marketsSplitLength; k += 1)
            {
                marketEvent = marketsSplit[k];

                if (Events.checkMarketEventType(marketEvent, eventType))
                {
                    sortedEvents.push(marketEvent);

                    if (api.checkMarketEventTeamMapping(marketEvent))
                    {
                        break;
                    }
                }
            }
        }

        return sortedEvents;
    };

    api.checkMarketEventType = function (marketEvent, eventType)
    {
        return (marketEvent.Event.Type === eventType.ID && marketEvent.LineTypeID === eventType.LineTypeID && marketEvent.LineTypeID !== 0) ||
               (marketEvent.Event.Type === eventType.ID && marketEvent.LineTypeID === 0);
    };

    api.checkMarketEventTeamMapping = function (marketEvent)
    {
        return marketEvent.LineTypeID !== 0 || (marketEvent.LineTypeID === 0 && marketEvent.Event.TeamMapping === 'undefined');
    };

    api.sortedEventTypesWithFavouritesInGroup = function (sortedGroupEventTypes, marketsSplit, favouriteEventsIDs, branchID)
    {
        var favouriteEvents = getFavouritesEvents(marketsSplit, favouriteEventsIDs);
        var sortedFavEvents = sortedFavouriteEvents(favouriteEvents, favouriteEventsIDs, branchID);
        var eventsWithoutFavourites = getEventsWithoutFavourites(marketsSplit, favouriteEventsIDs);
        var sortedEventsWithoutFavourites = api.sortedEventsInGroup(sortedGroupEventTypes, eventsWithoutFavourites);

        if (sortedFavEvents.length === 0 )
        {
            return sortedEventsWithoutFavourites;
        }
        var favouriteEventsInGroup = favouriteInGroup(sortedGroupEventTypes, favouriteEvents);

        return favouriteEventsInGroup.concat(sortedEventsWithoutFavourites);

    };

    api.getDoubleChanceEventID = function(branchID, moneyLineID)
    {
        var ml = moneyLineID || 0;
        var branchDCTypes = CONSTANTS.BranchAndMLToDoubleChanceEventTypeDictionary[branchID];
        if (branchDCTypes)
        {
            return branchDCTypes[ml];
        }
    };

    api.branchContainsDoubleChanceEvent = function(branchID, doubleChanceEventID)
    {
        if (typeof (CONSTANTS.BranchAndMLToDoubleChanceEventTypeDictionary[branchID]) === 'undefined')
        {
            return false;
        }

        for (var i in CONSTANTS.BranchAndMLToDoubleChanceEventTypeDictionary[branchID])
        {
            if (CONSTANTS.BranchAndMLToDoubleChanceEventTypeDictionary[branchID][i] === doubleChanceEventID)
            {
                return true;
            }
        }

        return false;
    };

    api.isDoubleChanceEvent = function(event)
    {
        if(event)
        {
            return api.isDoubleChanceEventTypeID(event.Type);
        }

        return false;
    };

    api.isDoubleChanceEventTypeID = function(typeID)
    {
        for (var i in CONSTANTS.DoubleChanceEventTypeEnum)
        {
            if (CONSTANTS.DoubleChanceEventTypeEnum[i] === typeID)
            {
                return true;
            }
        }

        return false;
    };

    function favouriteInGroup(sortedGroupEventTypes, favouriteEvents)
    {
        var favouriteEventsIncludedInGroup = [];
        for (var key in sortedGroupEventTypes)
        {
            if (!sortedGroupEventTypes[key])
            {
                continue;
            }

            for (var key1 in favouriteEvents)
            {
                if (Events.checkMarketEventType( favouriteEvents[key1],  sortedGroupEventTypes[key]))
                {
                    favouriteEventsIncludedInGroup.push(favouriteEvents[key1]);
                }
            }
        }
        return favouriteEventsIncludedInGroup;
    }
   

    function getFavouritesEvents(marketsSplit, favouriteEventsIDs)
    {
        var favouriteEvents = Array.findAllValues(marketsSplit, function (marketEvent)
        {
            return favouriteEventsIDs[marketEvent.FavouriteEventID];
        });

        return favouriteEvents;
    }

    function getEventsWithoutFavourites(marketsSplit, favouriteEventsIDs)
    {
        var eventsWithoutFavourites = Array.findAllValues(marketsSplit, function (marketEvent)
        {
            return !favouriteEventsIDs[marketEvent.FavouriteEventID];
        });

        return eventsWithoutFavourites;
    }

    function sortedFavouriteEvents(favouriteEvents, favouriteEventsIDs, branchID)
    {
        var branchIndex = branchID * 1000000;

        return favouriteEvents.sort(
                    function (marketEvent1, marketEvent2)
                    {
                        return favouriteEventsIDs ?
                            Data.compare(favouriteEventsIDs[marketEvent2.ID + branchIndex], favouriteEventsIDs[marketEvent1.ID + branchIndex]) : 0;
                    });
    }

    api.getBasketballResultTypeKey = function (additionalData)
    {
        var additionalDataLength = additionalData ? additionalData.length : null;
        var currentAdditionalDataElement, i, resType = null;

        for (i = 0; i < additionalDataLength; i += 1)
        {
            currentAdditionalDataElement = additionalData[i];

            if (api.isBasketballResultType(currentAdditionalDataElement))
            {
                resType = currentAdditionalDataElement[1];
                break;
            }
        }

        return resType;
    };

    api.isBasketballResultType = function (additionalDataElement)
    {
        return additionalDataElement !== undefined && additionalDataElement.length == 2 && additionalDataElement[0] == CONSTANTS.BasketballResultTypeKey;
    };

    api.isFullTimeBasketballResultTypeValue = function (resType)
    {
        return resType == CONSTANTS.BasketballResultTypeValues.Fulltime;
    };

    api.isHalvesBasketballResultTypeValue = function (resType)
    {
        return resType == CONSTANTS.BasketballResultTypeValues.Halves;
    };

    api.isQuartersBasketballResultTypeValue = function (resType)
    {
        return resType == CONSTANTS.BasketballResultTypeValues.Quarters;
    },

    api.getGameStatusText = function (number)
    {
        var gameParts = gameStatus.getGameStatus().jsonStatusCodes.parts;

        switch (number)
        {
            case 1:
                return gameParts.first;
            case 2:
                return gameParts.second;
            case 3:
                return gameParts.third;
            case 4:
                return gameParts.fourth;
            case 5:
                return gameParts.fifth;
        }
    };

    api.getLiveBettingStatusOrTimeText = function (masterEvent)
    {
        var liveTimeText = masterEvent.getAllLiveTime();
        var statusText = masterEvent.getStatusText();
        var masterEventTimeOrStatusText = !liveTimeText && statusText ? statusText : liveTimeText;
        return masterEventTimeOrStatusText;
    };

    api.getSortedLines = function (lines, prop)
    {
        var rearrangedLines = Array.getValues(lines);
        switch (prop)
        {
            case 'Spread': return rearrangedLines.sort(function (line1, line2) { return Data.compare(line1.Spread.Points1, line2.Spread.Points1); });
            case 'OU': return rearrangedLines.sort(function (line1, line2) { return Data.compare(line1.OU.Points1, line2.OU.Points1); });
        }
    };

    api.shouldSortEventLines = function (event, prop)
    {
        return prop !== 'ML' && (event.Type === 721 || event.Type === 722 || event.Type === 723 || event.Type === 724 || event.Type === 725);
    };

    api.isExtraTimeEnabledForEventExceptBasketball = function (gameData, branchID)
    {
        //Basketball overtime is checked by the game status which is 60 when the Overtime part is started    
        return (gameData.IsET == 1 || gameData.Status == 60) && branchID !== eBranches.Basketball;
    };

    api.getTooltipKey = function (branchID, eventTypeID, lineTypeName)
    {
        return lineTypeName ? 'Branch' + branchID + '_' + eventTypeID + '_' + lineTypeName :
                              'Branch' + branchID + '_' + eventTypeID + '_';
    };

    /*jshint eqeqeq:false*/
    api.getBetTypeTooltip = function (tooltipKey, dictionary)
    {
        if (!dictionary) return '';
        var tooltipMessage = '';
        //try get branch specific  tooltip
        tooltipMessage = dictionary(tooltipKey);

        // try get branch UNspecific tooltip _39_spread
        //tooltipMessage==tooltipKey because if it hasn't been found returns the key
        if (tooltipMessage == tooltipKey)
        {
            var underline = tooltipKey.indexOf('_');
            var shortTooltipKey = tooltipKey.substring(underline);
            tooltipMessage = dictionary(shortTooltipKey);
            if (tooltipMessage == shortTooltipKey)
            {
                return '';
            }
        }

        return tooltipMessage;
    };    

    api.getEventKey = function (eventID, prop)
    {
        if (prop === 0 || prop)
        {
            return 'event_' + eventID + '_' + prop;
        }
        else
        {
            return 'event_' + eventID;
        }        
    };

    return api;
}());

window.Events = Events;