﻿var Bets = (function (UserInfo)
{
    'use strict';
    var api = {},
        minStakeValue = 1,
        betStakeStep = 10;

    api.roundMin = function (minBet)
    {
        return Math.ceil(minBet * 100) / 100;
    };

    api.roundMax = function (maxBet)
    {
        return Number(((Math.floor(maxBet.multiply(100))).divide(100)).toFixed(2));
    };

    api.getPlaceString = function (placeTerms)
    {
        var placeTermsString = '', p;

        if (placeTerms > 0)
        {
            for (p = 1; p <= placeTerms; p += 1)
            {
                placeTermsString += p > 1 ? '-' + p : ' ' + p;
            }
        }

        /*globals $string */
        return $string('General').Place + placeTermsString;
    };

    api.getEachWayString = function (placeTerms, oddsTerms, full)
    {
        if (typeof (placeTerms) == 'undefined' || typeof (oddsTerms) == 'undefined' || placeTerms <= 0 || oddsTerms <= 0
            || (typeof (BetSlipRegulations) != "undefined" && BetSlipRegulations.hasOwnProperty('HideEachWay') && BetSlipRegulations.HideEachWay)) { return ''; }

        var pt = placeTerms, i;
        var mpt = 0;
        var mainString = '';
        var fullString = '';

        if (full)
        {
            fullString = $string('General').EachWay + ' ' + '1/' + oddsTerms.toString() + ' ';
        }
        else
        {
            fullString = $string('General').EachWayTag + ' ' + '1/' + oddsTerms.toString() + ' ';
        }

        for (i = 1; i <= pt; i += 1)
        {
            if (i == 1)
            {
                mainString = pt - (pt - 1);
                continue;
            }

            mpt = pt - (pt - i);
            if (mpt > 0)
            {
                mainString += '-' + mpt.toString();
            }
        }

        fullString = fullString + mainString;

        return fullString;
    };

    api.getMinStakeValue = function ()
    {
        return minStakeValue;
    };

    api.formatStake = function (deposit)
    {
        return this.formatDeposit(deposit, MoneyFormat);
    };

    api.formatDeposit = function (deposit, format)
    {
        deposit = deposit || 0;
        return format.format(deposit);
    };

    api.formatCurrencyStake = function (deposit, currencySign)
    {
        var currencyFormat = stakeBreakdownCurrencyFormat;
        var defaultCurrencySign = '';

        if (UserInfo.current)
        {
            if (IsShowCurrencyCode)
            {
                currencyFormat = UserInfo.current.currencyStringFormat;
                defaultCurrencySign = UserInfo.current.currencyCode;
            }
            else
            {
                currencySign = '';
            }
        }

        var formatedDeposit = api.formatDeposit(deposit, MoneyFormat);
        return currencyFormat.format(formatedDeposit, currencySign || defaultCurrencySign).trim();
    };

    // BetSlipCurrencies.js - inc
    api.increase = function (element)
    {
        var input = element.parentElement.querySelector('input'),
            value = parseInt(input.value, 10) || 0, i;

        /*globals BetSlipCurrencies, defaultStake, stakeSeries */
        if (typeof BetSlipCurrencies === 'undefined' || !BetSlipCurrencies.isInitialized() || !stakeSeries.length)
        {
            input.value = value <= minStakeValue ? betStakeStep : value + betStakeStep;
        }
        else if (!value)
        {
            input.value = defaultStake;
        }
        else if (parseFloat(input.value) >= parseInt(stakeSeries[stakeSeries.length - 1], 10))
        {
            /*jslint bitwise: true */
            input.value = ((parseFloat(input.value) / parseInt(stakeSeries[stakeSeries.length - 1], 10) | 0) + 1) *
                parseInt(stakeSeries[stakeSeries.length - 1], 10);
        }
        else
        {
            for (i = 0; i < stakeSeries.length; i += 1)
            {
                if (parseInt(stakeSeries[i], 10) > parseFloat(input.value))
                {
                    input.value = stakeSeries[i];
                    break;
                }
            }
        }

        if (input.onkeyup)
        {
            input.onkeyup();
        }
        else
        {
            UI.validate.test(input, true);
        }

        executeEvents(BetSlip.OnDepositChanges);
    };

    // BetSlipCurrencies.js - dec
    api.decrease = function (element)
    {
        var input = element.parentElement.querySelector('input'),
            value = parseInt(input.value, 10) || 0,
            i;

        /*globals BetSlipCurrencies, defaultStake, stakeSeries */
        if (typeof BetSlipCurrencies === 'undefined' || !BetSlipCurrencies.isInitialized() || !stakeSeries.length)
        {
            value -= betStakeStep;
            input.value = value > minStakeValue ? value : minStakeValue;
        }
        else if (!value)
        {
            input.value = defaultStake;
        }
        else if (parseFloat(value) > parseInt(stakeSeries[stakeSeries.length - 1], 10))
        {
            /*jslint bitwise: true */
            input.value = ((parseFloat(value) - 0.001) / parseInt(stakeSeries[stakeSeries.length - 1], 10) | 0) *
                parseInt(stakeSeries[stakeSeries.length - 1], 10);
        }
        else if ((parseFloat(value) > 1) && (parseFloat(value) <= parseInt(stakeSeries[0], 10)))
        {
            input.value = parseFloat(value) - 1;
        }
        else
        {
            for (i = stakeSeries.length - 1; i >= 0; i -= 1)
            {
                if (parseInt(stakeSeries[i], 10) < parseFloat(value))
                {
                    input.value = stakeSeries[i];
                    break;
                }
            }
        }

        if (input.onkeyup)
        {
            input.onkeyup();
        }
        else
        {
            UI.validate.test(input, true);
        }

        executeEvents(BetSlip.OnDepositChanges);
    };

    api.isFavoriteHighlightingEnabled = function ()
    {
        return currentBettingViewID === CONSTANTS.BettingView.Asian;
    };

    api.asianViewEnabledDisabled = function (branchID)
    {
        var enabled = false;

        switch (branchID)
        {                                   //the Asian view affects only the following branches: 
            case 1:                         //Soccer
            case 2:                         //Basketball
            case 3:                         //Football
            case 7:                         //Baseball
            case 8:                         //Ice Hockey
                enabled = true;
                break;
        }

        /*globals currentBettingViewID */
        return currentBettingViewID == 2 && // 2 is ID for Asian View
            enabled;
    };

    api.isBranchThatSuspendsLiveBets = function (branchID)
    {
        var branchesThatSupendLiveBets =
        {
            SoccerID: 1,
            BaseballID: 7,
            IceHockeyID: 8,
            FutsalID: 25,
            WaterPoloID: 31,
            NetBallID: 63
        };

        if (branchID > branchesThatSupendLiveBets.NetBallID) //default behaviour for new branches was agreed to be suspended, Itay Cohen's call
        {
            return true;
        }

        switch (branchID)
        {
            case branchesThatSupendLiveBets.SoccerID:
            case branchesThatSupendLiveBets.IceHockeyID:
            case branchesThatSupendLiveBets.FutsalID:
            case branchesThatSupendLiveBets.BaseballID:
            case branchesThatSupendLiveBets.WaterPoloID:
                return true;

            default: return false;
        }
    };

    api.americanOddsDiplayEnabled = function (branchID)
    {
        var enabled = false;

        switch (branchID)
        {                               //the American odds display affects following branches in European View:
            case 2:                     //Basketball
            case 3:                     //American Football
            case 7:                     //Baseball
            case 8:                     //Ice Hockey
                enabled = true;
                break;
        }

        return enabled;
    };

    function isOddsElement(el)
    {
        return isHtmlElement(el) && el.classList.contains('bets');
    }

    function isDeselectedOddsEl(el)
    {
        return isOddsElement(el) && !el.classList.contains('selected');
    }

    function isSelectionsCountExceeded(betSlip)
    {
        return Array.getLength(betSlip.Selections) >= betSlip.maxSelectionsNumber;
    }

    api.highlightSelectedOdd = function (e)
    {
        var target = e && e.target;
        /*globals isHtmlElement, BetSlip */
        /*jshint -W106 */// ignore maxSelectionsNumber
        if (isDeselectedOddsEl(target) && isSelectionsCountExceeded(BetSlip))
        {
            return;
        }

        if (isOddsElement(target))
        {
            target.classList.toggle('selected');
        }
        else if (isOddsElement(target.parentNode))
        {
            target.parentNode.classList.toggle('selected');
        }
    };

    api.highlightCurrentLine = function (lineID, mode)
    {
        // we need to be able to iterate over multiple
        // elements with the same class name
        var lines = document.querySelectorAll("#ln_" + lineID, ".ln_" + lineID),
            m = mode === "add" ? "add" : "remove";

        if (lines.length === 0) return null;

        // return a list of all the affected elements
        return Array.prototype.map.call(lines, function (l) { return l.classList[m]("selected"); });
    };

    api.getStatusText = function (status)
    {
        if (status === 'Canceled')
        {
            return $string('General').Canceled;
        }
        else if (status === 'Won')
        {
            return $string('General').Won;
        }
        else if (status === 'Lost')
        {
            return $string('General').Lost;
        }
        else if (status === 'Draw')
        {
            return $string('General').Draw;
        }

        return status;
    };

    api.teamSwapValues = function (masterEvent)
    {
        if (masterEvent.IsTeamSwapEnabled)
        {
            return {
                Team1Name: masterEvent.Team2Name,
                Team2Name: masterEvent.Team1Name,
                Score1: masterEvent.Score2,
                Score2: masterEvent.Score1,
                ScoreSetsPlayer1: masterEvent.ScoreSetsPlayer2,
                ScoreSetsPlayer2: masterEvent.ScoreSetsPlayer1,
                CornerScore1: masterEvent.CornerScore2,
                CornerScore2: masterEvent.CornerScore1,
                Score1Updated: masterEvent.Score2Updated,
                Score2Updated: masterEvent.Score1Updated
            };
        }

        return masterEvent;
    };

    api.teamSwapPositions = function (masterEvent)
    {
        var team1Position = 1;
        var team2Position = 3;

        if (masterEvent.IsTeamSwapEnabled)
        {
            team1Position = 3;
            team2Position = 1;
        }

        return {
            team1Position: team1Position,
            team2Position: team2Position
        }
    };

    api.teamSwapIndexes = function (masterEvent)
    {
        var team1Index = 0;
        var team2Index = 1;

        if (masterEvent.IsTeamSwapEnabled)
        {
            team1Index = 1;
            team2Index = 0;
        }

        return {
            team1Index: team1Index,
            team2Index: team2Index
        }
    };

    api.getBranchAndLeagueName = function (bet)
    {
        var nameArray = [];

        if (bet.BranchName) { nameArray.push(bet.BranchName); }
        if (bet.LeagueName) { nameArray.push(bet.LeagueName); }

        return nameArray.join(', ');
    };

    api.getPlacedLines = function (currentSelections, uniSlip)
    {
        var placedLines = Array.findAllValues(currentSelections,
            function (selection)
            {
                if (uniSlip.Selections.indexOf(selection) === -1) { return selection; }
            });

        return placedLines;
    };

    api.formatProfitLossWithTax = function (amount, status)
    {
        if (amount === 0 && status !== 'Draw')
        {
            return $string('General').Canceled;
        }

        return MoneyFormat.format(amount);
    };

    api.formatMinMax = function (val)
    {
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    api.formatCashOutDate = function (val)
    {
        return '{0:dd/MM/yyyy HH:mm}'.format(val);
    };

    /* jshint maxparams: 4 */
    api.getWinFromStakeForNegativeAsianOdds = function (stake, americanOdds, oddStyle, precision)
    {
        precision = precision || 2;
        return oddStyle == OddStyle.MALAY
            ? (stake / -Odds.convertFromDecimalToMalaysian(Odds.convertFromAmericanToDecimal(americanOdds))).toFixed(precision) * 1 //malay
            : (stake * (Odds.convertFromAmericanToDecimal(americanOdds, true) - 1)).toFixed(precision) * 1; //indo
    };
    /* jshint maxparams: 3 */

    /* jshint maxcomplexity: 7 */ // TODO: Refactor to reduce complexity!
    var getSingelBetRequirments = function (betTypeCondition)
    {
        var betName = $dict.bi('BetNames_' + betTypeCondition.BetTypeID);

        var lineTypes = '';

        if (betTypeCondition.MLIsEnabled)
        {
            lineTypes += 'ML';
        }

        if (betTypeCondition.HCIsEnabled)
        {
            lineTypes += 'HC';
        }

        if (betTypeCondition.OUIsEnabled)
        {
            lineTypes += 'OU';
        }

        if (betTypeCondition.MLIsEnabled && betTypeCondition.HCIsEnabled && betTypeCondition.OUIsEnabled)
        {
            lineTypes = '';
        }

        if (lineTypes)
        {
            betName += ' ' + $dict.bi('Only' + lineTypes);
        }

        var minOdds = Odds.convertFormat(betTypeCondition.MinUSOdds * betTypeCondition.Ratio, undefined, undefined, true);

        return $dict.bi('WithMinOdds').format(betName, minOdds);
    };

    var getComboBetRequirments = function (betTypeCondition)
    {
        var result = '';

        var betName = $dict.bi('BetNames_' + betTypeCondition.BetTypeID);
        var minOdds = Odds.convertFormat(betTypeCondition.MinUSOdds * betTypeCondition.Ratio, undefined, undefined, true);
        var minSelectionOdds = betTypeCondition.MinComboUSOdds ? Odds.convertFormat(betTypeCondition.MinComboUSOdds * betTypeCondition.Ratio, undefined, undefined, true) : null;
        var minSelections = betTypeCondition.MinComboSelections ? betTypeCondition.MinComboSelections : 0;


        result += $dict.bi('WithMinOdds').format(betName, minOdds);

        if (minSelections > 0 && minSelectionOdds)
        {
            result += $dict.bi('WithMinSelections').format(minSelections, minSelectionOdds);
        }

        return result;
    };

    var getGameBetRequirments = function (betTypeCondition)
    {
        return $dict.bi('BetNames_' + betTypeCondition.BetTypeID);
    };

    api.getBonusBetRequirments = function (bet)
    {
        var requirments = [];

        if (bet.Conditions && bet.Conditions.BetTypes)
        {
            for (var idx in bet.Conditions.BetTypes)
            {
                if (bet.Conditions.BetTypes.hasOwnProperty(idx))
                {
                    var betTypeCond = bet.Conditions.BetTypes[idx];
                    switch (betTypeCond.BetTypeID * 1)
                    {
                        case 1:
                            requirments.push(getSingelBetRequirments(betTypeCond));
                            break;
                        case 2:
                            requirments.push(getComboBetRequirments(betTypeCond));
                            break;
                        case 9:
                            requirments.push(getGameBetRequirments(betTypeCond));
                            break;
                    }
                }
            }
        }

        return requirments;
    };
    /* jshint maxcomplexity: 5 */

    api.getQAEventName = function (selection)
    {
        var lineTypeIDs = [22, 24, 26, 28, 13, 14, 15];
        /*globals constHorseRacingBranchID, constGreyHoundRacingBranchID, UseAustralianHorseRacingView*/
        if ((selection.BranchID == constHorseRacingBranchID || selection.BranchID == constGreyHoundRacingBranchID) && Array.indexOf(lineTypeIDs, selection.LineTypeID) !== -1)
        {
            if (selection.EventTypeID)
            {
                var date = selection.EventDate;
                date = (selection.BranchID == constHorseRacingBranchID && UseAustralianHorseRacingView) ? date : Data.getDateForHR(date);
                return selection.LeagueName + ': ' + date.toStringEx('HH:mm');
            }
            return selection.LineTypeName;
        }

        if (selection.EventName)
        {
            return selection.EventName;
        }

        return selection.EventTypeName;
    };

    api.getEWMainSelection = function (selection)
    {
        var ewMainSelection;
        if (selection.IsEachWaySelection)
        {
            ewMainSelection = Array.find(selection.Purchase.Selections, function (item)
            {
                return item.LineID == selection.RelatedToID;
            });
        }

        return ewMainSelection;
    };

    return api;
}(UserInfo));

window.Bets = Bets;