﻿
/* exported Dom */
/* globals setDomText */
var Dom = (function ()
{
    'use strict';

    var api = {};

    api.setText = setDomText;
    api.safeSetText = function (element, text)
    {
        if (element)
        {
            api.setText(element, text);
        }
    };

    api.hideElement = function (element)
    {
        if (element)
        {
            element.style.display = 'none';
        }

    };

    api.showElement = function (element)
    {
        if (element)
        {
            element.style.display = 'block';
        }
    };
       
    api.loadExternalScript = function (url)
    {
        if (url === '')
        {
            return false;
        }

        var head = document.getElementsByTagName('head')[0],
            script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;

        if (head.contains(script))
        {
            return true;
        }

        head.appendChild(script);
        return head.contains(script);
    };

    api.getFirstSubTreeHref = function (element)
    {
        var href = null;

        if (!element)
        {
            return href;
        }

        var tagA = 'A';
        var elementA = element.tagName == tagA ? element : element.getElementsByTagName(tagA)[0];
        if (elementA) 
        {
            href = elementA.href;
        }

        return href;
    };

    api.toggleElementClasses = function(element, firstClassName, secondClassName)
    {
        if (element.classList.contains(firstClassName)) {
            element.classList.remove(firstClassName);
            element.classList.add(secondClassName);
            
            return secondClassName;
        }
        else {
            element.classList.add(firstClassName);
            element.classList.remove(secondClassName);
            
            return firstClassName;
        }
    };

    api.toggleExpanderLabel = function(el, isExpanded)
    {
        var text = isExpanded ? $string('UserInfo').ReadLess : $string('UserInfo').ReadMore;
        el && (el.innerText = text);
    };

    api.isTruncated = function(el)
    {
        return el.scrollWidth > el.offsetWidth;
    };

    return api;
})();

window.Dom = Dom;