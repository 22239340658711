export var GainUtil = (function () {
    var api = {};

    function getDecimalValueToRound(value, precision) {
        if (!value) {
            return NaN;
        }

        const parsed = parseInt(value);
        if (value.length <= precision) {
            return parsed;
        }

        return parseInt(value.substring(0, 3)) / 10;
    }

    function rounding(gain, roundFunction) {
        var x = parseFloat(gain).toFixed(12).split(".");
        var wholePart = x[0] * 1;
        var fractionalPart = roundFunction(getDecimalValueToRound(x[1], 2))
        if (fractionalPart === 100) {
            wholePart += 1;
            fractionalPart = 0;
        }

        return parseFloat(wholePart + "." + getFractionalPart(x[1], fractionalPart));
    }
    
    function getFractionalPart(fractionalStr, fractionalPart){

        // because JS can't round decimals and rounding .05 gives .5
        const zero = (fractionalStr &&
            fractionalStr[0] === "0"
            && fractionalPart < 10) ? "0" : "";

        return `${zero}${fractionalPart}`;
    }

    api.format = function (gain, mode) {
        var tempGain = api.round(gain, mode);
        return MoneyFormat.format(tempGain);
    };

    api.round = function (gain, mode, toFixed) {
        var tempGain = 0;
        switch (mode) {
            case GainRoundModes.ROUND:
                {
                    tempGain = rounding(gain, Math.round);
                    break;
                }
            case GainRoundModes.CEILING:
                {
                    tempGain = rounding(gain, Math.ceil);
                    break;
                }
            case GainRoundModes.FLOOR:
            default:
                {
                    // floor and legacy logic are the same
                    tempGain = rounding(gain, Math.floor);
                    break;
                }
        }

        return tempGain;
    }
    return api;
}());

window["GainUtil"] = GainUtil;