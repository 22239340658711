﻿/* globals UITooltip */
/* exported UITooltipSideMenu */
var UITooltipBetSearchSideMenu = (function (Super)
{
    'use strict';
    
    function TooltipSideMenu(options)
    {
        Super.call(this, options);
    }

    var proto = Object.create(Super.prototype);
    proto.constructor = TooltipSideMenu;
    proto.uber = Super.prototype;
    TooltipSideMenu.prototype = proto;

    TooltipSideMenu.prototype.prepare = function (e, target)
    {
        var time = target.querySelector('.side-menu-card-game-time');
        var sport = target.querySelector('.side-menu-card-sport');
        var name = target.querySelector('.side-menu-card-name');
            
        if (time || sport.scrollWidth > sport.clientWidth || name.scrollWidth > name.clientWidth)
        {
            this.uber.prepare.apply(this, arguments);
        }
    };

    return TooltipSideMenu;

}(UITooltip));

window.UITooltipBetSearchSideMenu = UITooltipBetSearchSideMenu;
