﻿var Odds = (function ()
{
    'use strict';
    var api = {};

    api.getChangeOddsClass = function (update)
    {
        if (update > 0) { return 'change change--up'; }
        if (update < 0) { return 'change change--down'; }

        return '';
    };

    api.getUpDownArrowClass = function(update)
    {
        //show arrows animation if any bet cell odd is updated from push server
        if (update > 0) { return 'oddArrowDown'; }
        else if (update < 0) { return 'oddArrowUp'; }
        return '';
    };


    /* jshint maxcomplexity: 15 */ // TODO: Refactor to reduce complexity!    
    /* jshint maxparams: 7 */ // TODO: Refactor to reduce number of params!
    api.format = function (odds, oddsStyle, eventType, linetype, betType, branch, fractionalOdds)
    {
        if (oddsStyle == OddStyle.FRACTIONAL || ((typeof (oddsStyle) == 'undefined' || oddsStyle == null) && currentOddStyle == OddStyle.FRACTIONAL))
        {
            return (fractionalOdds ? fractionalOdds : odds);
        }

        if (oddsStyle == OddStyle.EUROPEAN || ((typeof (oddsStyle) == 'undefined' || oddsStyle == null) && currentOddStyle == OddStyle.EUROPEAN))
        {
            // We need to explicitly truncate decimal odds before we call String.format().
            // That function calls the native Number.toFixed() function, which rounds odds instead of truncating them.
            // This causes rounding errors, i.e. decimal odd 1.005997 appears as 1.006 instead of 1.005
            odds = Math.floor((odds * 1000).toPrecision(10)) / 1000;
            if (odds < 1.001)
            {
                odds = 1.001;
            }

            //Applied as hotfix of SB-95691. Should be re-implemented in master based on BaseOddsRoundingMode & ForceTwoDigitsForOddsRounding setting
            if (window.CurrentSiteID == CONSTANTS.SiteIDs.NetBetItaly && odds < 1.01)
            {
                odds = 1.00;
            }
        }

        // currentBetFormat is actually current bet format. Here check is we need to show decimal format instead of Asian (if Asian is current)
        var betFormat = api.showDecimalInsteadOfAsian(betType, eventType, linetype, branch) ? BetFormat.European : currentBetFormat;

        // If an odds-style is explicitly defined as an argument, use its format instead of the current bet format
        if (typeof (oddsStyle) != 'undefined')
        {
            switch (oddsStyle)
            {
                case OddStyle.AMERICAN:
                    {
                        betFormat = '{0:' + constBetFormatAmerican + '}';
                        break;
                    }
                case OddStyle.EUROPEAN:
                    {
                        betFormat = BetFormat.European;
                        break;
                    }
                case OddStyle.MALAY:
                    {
                        betFormat = BetFormat.Malay;
                        break;
                    }
                case OddStyle.INDO:
                    {
                        betFormat = BetFormat.Indo;
                        break;
                    }
                case OddStyle.HONGKONG:
                    {
                        betFormat = BetFormat.Hongkong;
                        break;
                    }
            }
        }

        return betFormat.format(odds);
    };
    /* jshint maxcomplexity: 6 */
    /* jshint maxparams: 3 */

    /* jshint maxdepth:5 */
    /* jshint maxcomplexity: 15 */ // TODO: Refactor to reduce complexity!
    /* jshint maxparams: 5 */ // TODO: Refactor to reduce number of params!
    api.formatPoints = function (pts, isOver, isUnder, showOUAbbreviation, disableAsianStyleForBetSlip)
    {
        if (typeof pts == 'undefined')
        {
            return '';
        }

        pts *= 1;

        // Asians don't understand quarter points so, display quarters like this: +1.25 -> +1,+1.5 or O2.75 -> O2.5,3
        var overUnderAbbreviation;

        if (typeof (isAsianStyleForPointsEnabled) != 'undefined' && isAsianStyleForPointsEnabled && !disableAsianStyleForBetSlip)
        {
            if (Math.abs(pts - pts.toFixed(0)) == 0.25) //Quarter
            {

                if (!isOver && !isUnder) // Handicap
                {
                    var isPositiveHC = (pts + 0.25 > 0);
                    var ptsAsianHCFormat = isAsianView ? constPtsAsianHCFormat : constPtsAsianHCInEVFormat;
                    if (enableAsiansHCSpeacialSignsFormat)
                    {
                        return isPositiveHC ? '+' + ptsAsianHCFormat.format(pts - 0.25, pts + 0.25) : '-' + ptsAsianHCFormat.format(Math.abs(pts + 0.25), Math.abs(pts - 0.25));
                    }
                    return isPositiveHC ? ptsAsianHCFormat.format(pts - 0.25, pts + 0.25) : ptsAsianHCFormat.format(pts + 0.25, pts - 0.25);
                }

                // Over/Under
                var ptsAsianOUFormat = isAsianView ? constPtsAsianOUFormat : constPtsAsianOUInEVFormat;
                if (showOUAbbreviation)
                {
                    overUnderAbbreviation = isOver ? $string('General').OverShort : $string('General').UnderShort;
                    return ptsAsianOUFormat.format(overUnderAbbreviation + ' ', pts - 0.25, pts + 0.25);
                }
                else
                {
                    return ptsAsianOUFormat.format('', pts - 0.25, pts + 0.25);
                }
            }
        }

        if (!isOver && !isUnder) // Handicap
        {
            return pts > 0 ? "+" + PointsFormat.format(pts) : PointsFormat.format(pts);
        }

        // Over/Under
        if (showOUAbbreviation)
        {
            overUnderAbbreviation = isOver ? $string('General').OverShort : $string('General').UnderShort;
            return overUnderAbbreviation + ' ' + PointsFormat.format(pts);
        }
        else
        {
            return PointsFormat.format(pts);
        }
    };
    /* jshint maxcomplexity: 5 */
    /* jshint maxparams: 3 */

    /* jshint maxcomplexity: 12 */ // TODO: Refactor to reduce complexity!
    /* jshint maxparams: 9 */ // TODO: Refactor to reduce number of params!
    api.convert = function (odds, eventType, linetype, clear, oddsStyle, betType, branch, fractionalOdds, skipRounding)
    {
        if (!odds)
        {
            return 0;
        }

        if (!clear && api.shouldRound(eventType, linetype, branch))
        {
            odds = api.round(odds);
        }

        var oddsStyleToConvertTo = api.showDecimalInsteadOfAsian(betType, eventType, linetype, branch)
            ? OddStyle.EUROPEAN
            : currentOddStyle;

        // If an odds-style is explicitly defined as an argument, use it instead of the current odds-style
        oddsStyleToConvertTo = oddsStyle != undefined ? oddsStyle : oddsStyleToConvertTo;

        switch (oddsStyleToConvertTo)
        {
            case OddStyle.AMERICAN: { return odds; }
            case OddStyle.EUROPEAN: { return api.convertFromAmericanToDecimal(odds, skipRounding); }
            case OddStyle.FRACTIONAL: {
                if (fractionalOdds) {
                    return fractionalOdds;
                }
                else {
                    return FractionalOddsConverter.convert(odds);
                }

                break;
            }
            case OddStyle.MALAY: { return api.convertFromDecimalToMalaysian(api.convertFromAmericanToDecimal(odds, skipRounding)); }
            case OddStyle.INDO: { return api.convertFromAmericanToIndonesian(odds); }
            case OddStyle.HONGKONG: { return api.convertFromDecimalToHongKong(api.convertFromAmericanToDecimal(odds, skipRounding)); }
        }
    };
    /* jshint maxparams: 3 */
    /* jshint maxcomplexity: 5 */

    api.round = function (odds)
    {
        return odds;
    };

    /* jshint maxcomplexity: 8 */ // TODO: Refactor to reduce number of params!
    api.shouldRound = function (eventType, linetype, branchid)
    {
        if (VirtualSports.isBranchVirtual(branchid) || branchid == constHorseRacingBranchID || branchid == constGreyHoundRacingBranchID)
        {
            return false;
        }

        // Do not round for baseball line types, except for main bet types
        if (branchid && branchid === 7 && eventType !== 0)
        {
            return false;
        }

        return !(linetype && (eventType === 0 || eventType == 39 || eventType == 1) && (linetype == 2 || linetype == 3));
    };
    /* jshint maxcomplexity: 5 */

    /* jshint maxparams: 9 */ // TODO: Refactor to reduce number of params!
    api.convertFormat = function (odds, eventType, linetype, clear, oddsStyle, betType, branch, fractionalOdds, skipRounding)
    {
        var oddsToFormat = api.convert(odds, eventType, linetype, clear, oddsStyle, betType, branch, fractionalOdds, skipRounding);
        return api.format(oddsToFormat, oddsStyle, eventType, linetype, betType, branch);
    };
    /* jshint maxparams: 3 */

    api.decimalToAmerican = function (odd)
    {
        if (odd >= 2)
        {
            return Math.floor((odd * 100 - 100).toPrecision(12));
        }
        else
        {
            return Math.floor(((-1 / (odd - 1)) * 100).toPrecision(12));
        }
    };

    /* jshint maxcomplexity: 9 */ // TODO: Refactor to reduce complexity!
    api.convertFromDecimal = function (odds, oddsStyle)
    {
        if (odds === 0)
        {
            return 0;
        }

        // If an odds-style is explicitly defined as an argument, use it instead of the current odds-style
        var oddsStyleToConvertTo = typeof oddsStyle != 'undefined' ? oddsStyle : currentOddStyle;

        switch (oddsStyleToConvertTo)
        {
            case OddStyle.AMERICAN: return api.decimalToAmerican(odds);
            case OddStyle.FRACTIONAL: return FractionalOddsConverter.convert(api.decimalToAmerican(odds));
            case OddStyle.EUROPEAN: return odds;
            case OddStyle.HONGKONG: return api.convertFromDecimalToHongKong(odds);
            case OddStyle.INDO: return api.convertFromDecimalToIndonesian(odds);
            case OddStyle.MALAY: return api.convertFromDecimalToMalaysian(odds);
        }
    };
    /* jshint maxcomplexity: 5 */

    /* jshint maxcomplexity: 9 */ // TODO: Refactor to reduce complexity!
    api.convertFromAmerican = function (odds, oddsStyle)
    {
        if (odds === 0)
        {
            return 0;
        }

        // If an odds-style is explicitly defined as an argument, use it instead of the current odds-style
        var oddsStyleToConvertTo = typeof oddsStyle != 'undefined' ? oddsStyle : currentOddStyle;

        switch (oddsStyleToConvertTo)
        {
            case OddStyle.AMERICAN:
                {
                    return odds;
                }
            case OddStyle.FRACTIONAL:
                {
                    return FractionalOddsConverter.convert(odds);
                }
            case OddStyle.EUROPEAN:
                {
                    return api.convertFromAmericanToDecimal(odds);
                }
            case OddStyle.MALAY:
                {
                    return api.convertFromDecimalToMalaysian(api.convertFromAmericanToDecimal(odds));
                }
            case OddStyle.INDO:
                {
                    return api.convertFromAmericanToIndonesian(odds);
                }
            case OddStyle.HONGKONG:
                {
                    return api.convertFromDecimalToHongKong(api.convertFromAmericanToDecimal(odds));
                }
        }
    };
    /* jshint maxcomplexity: 5 */

    var getCalculatedOdds = function (odds)
    {
        var resultOdds;

        if (odds > 0)
        {
            resultOdds = 1 + odds / 100;
        }
        else
        {
            resultOdds = 1 - 100 / odds;
        }

        return resultOdds;
    };

    // private
    api._getCalculatedOdds = getCalculatedOdds;

    function formatCurrentOdds(odds)
    {
        var formatedOdds = Math.floor((odds * 100).toPrecision(10)) / 100;

        return formatedOdds;
    }

    // private
    api._formatCurrentOdds = formatCurrentOdds;

    api.convertFromAmericanToDecimal = function (odds, skipRounding)
    {
        if (odds === 0)
        {
            return 0;
        }

        var calculatedOdds = getCalculatedOdds(odds);

        if (odds > -10000 && (typeof (skipRounding) === 'undefined' || !skipRounding))
        {
            calculatedOdds = formatCurrentOdds(calculatedOdds);
        }

        return calculatedOdds;
    };

    api.convertFromAmericanToIndonesian = function (odd)
    {
        // Called by Odds.convert. Special conversion for Indo odds because for example -141 in American
        // should be -1.41 Indonesian. This is valid for both positive and negative American odds
        return (+odd).divide(100);
    };

    api.convertFromDecimalToMalaysian = function (odd)
    {
        if (odd.lte(2))
        {
            return odd.substract(1);
        }
        else
        {
            // Round the odd to the second digit after the decimal point if the current format expects this.
            // Why 101? Cause when decimal odd is greater than 101, malay odds get bigger than -0.01, for
            // example, -0.005 and then we need to display this third digits after the decimal point
            if (typeof constBetFormatMalay != 'undefined' && constBetFormatMalay == '0.00#' && odd <= 101)
            {
                var notRoundedOdd = (1).divide((1).substract(odd));
                return Math.ceil(notRoundedOdd.multiply(100)).divide(100);
            }
            else
            {
                return (1).divide((1).substract(odd));
            }
        }
    };

    api.convertFromDecimalToIndonesian = function (odd)
    {
        if (odd.lt(2))
        {
            var notRoundedOdd = (1).divide((1).substract(odd));
            return Math.floor(notRoundedOdd.multiply(1000)).divide(1000);
        }
        else
        {
            return odd.substract(1);
        }
    };

    api.convertFromDecimalToHongKong = function (odd)
    {
        return odd.substract(1);
    };

    /* jshint maxparams: 5 */ // TODO: Refactor to reduce number of params!
    api.showDecimalInsteadOfAsian = function (betType, eventType, lineType, branch, doNotUseCurrentOddStyle)
    {
        // Checks whether to show odds in decimal even though the current odds style may be Asian (Malay, indo, HongKong).
        // This method may be overridden in the Global.ext.js version for an Asian WHL
        // Here decimal will only be shown for the lines/selections of the below types:
        //  - All QA (BetType=7) except 'Odd/Even' (EventTypeID=38), for all branches
        //  - Money Line (LineType=1), only for soccer (BranchID=1)
        if (UseHKOddsStyleForAllMarkets && currentOddStyle == OddStyle.HONGKONG) {
            return false;
        }

        if ((!doNotUseCurrentOddStyle) && currentOddStyle != OddStyle.MALAY && currentOddStyle != OddStyle.INDO && currentOddStyle != OddStyle.HONGKONG)
        {
            return false;
        }

        if ((betType == 7 && eventType !== undefined && eventType != 38) || (lineType == 1 && (branch == 1 || branch == 62)))
        {
            return true;
        }
        return false;
    };

    /* jshint maxparams: 3 */
    /* jshint maxcomplexity: 7 */ // TODO: Refactor to reduce complexity!
    api.getAsianViewClassList = function (odds, update, isLive)
    {
        if (typeof currentBettingViewID != 'undefined' && currentBettingViewID != 2) { return ''; }

        var classList = '';

        if (update !== null && typeof update !== 'undefined' && isLive)
        {
            if (update > 0)
            {
                classList += ' oddWentDown';
            }

            if (update < 0)
            {
                classList += ' oddWentUp';
            }
        }
        if (typeof odds !== 'undefined')
        {
            var isNegative = typeof odds === 'string' ? odds[0] === '-' : odds < 0;
            if (isNegative)
            {
                classList += negativeOddsClass;
            }
        }

        return classList;
    };
    /* jshint maxcomplexity: 5 */

    /* jshint maxcomplexity: 14 */ // TODO: Refactor to reduce complexity!
    /* jshint maxparams: 6 */ // TODO: Refactor to reduce number of params!
    api.getStyleAbbrev = function (oddStyleID, betType, eventType, linetype, branch, isForAsian)
    {
        var abbreviation = ' ';

        if (typeof (oddStyleID) == 'undefined')
        {
            oddStyleID = api.showDecimalInsteadOfAsian(betType, eventType, linetype, branch) ? OddStyle.EUROPEAN : currentOddStyle;
        }

        if (hasAsianOdds)
        {
            switch (oddStyleID)
            {
                case OddStyle.AMERICAN:
                    abbreviation += !isForAsian ? '' : $string('General').AmericanAbbreviationForAsian;
                    break;
                case OddStyle.FRACTIONAL:
                    abbreviation += !isForAsian ? $string('General').FractionalAbbreviation : $string('General').FractionalAbbreviationForAsian;
                    break;
                case OddStyle.EUROPEAN:
                    abbreviation += !isForAsian ? $string('General').EuropeanAbbreviation : $string('General').EuropeanAbbreviationForAsian;
                    break;
                case OddStyle.MALAY:
                    abbreviation += !isForAsian ? $string('General').MalayAbbreviation : $string('General').MalayAbbreviationForAsian;
                    break;
                case OddStyle.INDO:
                    abbreviation += !isForAsian ? $string('General').IndoAbbreviation : $string('General').IndoAbbreviationForAsian;
                    break;
                case OddStyle.HONGKONG:
                    abbreviation += !isForAsian ? $string('General').HongkongAbbreviation : $string('General').HongkongAbbreviationForAsian;
                    break;
            }
        }

        return abbreviation;
    };
    /* jshint maxcomplexity: 5 */
    /* jshint maxparams: 3 */

    api.isValidOddStyle = function (oddStyle)
    {
        var result = Array.find(OddStyle, function (o)
        {
            return o == oddStyle;
        });

        return !!result;
    };

    api.hongKongToDecimal = function (odd)
    {
        return odd.add(1);
    };

    api.malaysianToDecimal = function (odd)
    {
        if (odd.gte(0) && odd.lte(1))
        {
            return odd.add(1);
        }
        else
        {
            return (1).substract((1).divide(odd));
        }
    };

    api.indonesianToDecimal = function (odd)
    {
        if (odd.gte(1))
        {
            return odd.add(1);
        }
        else
        {
            var notRoundedOdd = (1).substract((1).divide(odd));
            return Math.floor(notRoundedOdd.multiply(100)).divide(100);
        }
    };

    /* jshint maxcomplexity: 19 */ // TODO: Refactor to reduce complexity!
    /* jshint maxparams: 5 */ // TODO: Refactor to reduce number of params!
    api.getComboOdds = function (odds, rate, eventType, linetype, clear)
    {
        if (odds === 0)
        {
            return 0;
        }

        if (rate === undefined)
        {
            rate = 1;
        }

        if (!clear && api.shouldRound(eventType, linetype))
        {
            odds = api.round(odds);
        }

        if (rate == 1)
        {
            switch (currentOddStyle)
            {
                case OddStyle.AMERICAN:
                    {
                        return odds;
                    }
                case OddStyle.EUROPEAN:
                    {
                        return api.convertFromAmericanToDecimal(odds);
                    }
                case OddStyle.FRACTIONAL:
                    {
                        return FractionalOddsConverter.convert(odds);
                    }
                case OddStyle.MALAY:
                    {
                        return api.convertFromDecimalToMalaysian(api.convertFromAmericanToDecimal(odds));
                    }
                case OddStyle.INDO:
                    {
                        return api.convertFromAmericanToIndonesian(odds);
                    }
                case OddStyle.HONGKONG:
                    {
                        return api.convertFromDecimalToHongKong(api.convertFromAmericanToDecimal(odds));
                    }
            }
        }
        else
        {
            var dOdd = ((odds > 0) ? (1 + odds / 100) : (1 - 100 / odds)); // convert to european
            dOdd = dOdd * rate; // apply combo rate
            dOdd = (odds > -10000) ? (Math.floor((dOdd * 100).toPrecision(12)) / 100) : dOdd; // round

            switch (currentOddStyle)
            {
                case OddStyle.AMERICAN:
                    return api.decimalToAmerican(dOdd);
                case OddStyle.EUROPEAN:
                    return dOdd;
                case OddStyle.FRACTIONAL:
                    return FractionalOddsConverter.convert(api.decimalToAmerican(dOdd));
                case OddStyle.MALAY:
                    return api.convertFromDecimalToMalaysian(dOdd);
                case OddStyle.INDO:
                    return api.convertFromDecimalToIndonesian(dOdd);
                case OddStyle.HONGKONG:
                    return api.convertFromDecimalToHongKong(dOdd);
            }
        }
    };
    /* jshint maxcomplexity: 5 */
    /* jshint maxparams: 5 */

    return api;
}());

window.Odds = Odds;