function QAEventTeamSwap(eventTypeID) {
    this.EventTypeID = eventTypeID;
    this.Swapped = false;
}

var QAEventType = {
    OtherQA: 0,
    HalfTimeFulltime: 1,
    ExactScore: 2
}

QAEventTeamSwap.prototype =
{
    swapDrawGrid: function (drawGrid)
    {

        var eventType = this.__getEventType(this.EventTypeID);

        switch (eventType)
        {
            case QAEventType.HalfTimeFulltime:
                this.__doHalfTimeFullTimeGridSwap(drawGrid);
                break;
            case QAEventType.ExactScore:
                this.__doExactScoreGridSwap(drawGrid);
                break;
            default:
                this.__doStandartGridSwap(drawGrid);
        }
      
        //for backward compatability swapp the previous QA swapped bet types in the way they were swapped before if for them Team details is still not set from bettypes API.
        if (!this.Swapped &&
            Array.indexOf([38, 75, 81, 82, 89, 93, 98, 110, 119, 154, 155, 158, 165, 270, 272, 273, 274, 275, 276, 277, 278, 305, 307, 310, 319, 322, 361, 376, 377], this.EventTypeID) != -1)
        {
            if (this.EventTypeID != 377) drawGrid.sort(function (a1, a2) { return a2[0].LineTypeID - a1[0].LineTypeID; });
            this.Swapped = true;
        }
    },

    swapLineName: function (line)
    {
        var eventType = this.__getEventType(this.EventTypeID);

        switch (eventType)
        {
            case QAEventType.HalfTimeFulltime:
                this.__doHalfTimeFullTimeLineSwap(line);
                break;
            case QAEventType.ExactScore:
                this.__doExactScoreLineSwap(line);
                break;
        }
    },

    __doHalfTimeFullTimeGridSwap: function (drawGrid)
    {
        var firstLine = drawGrid[0][0];
        if (firstLine.Swapped !== undefined && firstLine.Swapped !== false)
        {
            this.Swapped = true;
        }

        if (!this.Swapped)
        {
            for (var gridColumn = 0; gridColumn < drawGrid.length; gridColumn++)
            {
                for (var gridRow = 0; gridRow < drawGrid[gridColumn].length; gridRow++)
                {
                    var line = drawGrid[gridColumn][gridRow];
                    this.__doHalfTimeFullTimeLineSwap(line);

                    if (line.LineName.indexOf('1') === 0)
                    {
                        line.Column = 1;
                    }
                    else if (line.LineName.indexOf('2') === 0)
                    {
                        line.Column = 2;
                    } if (line.LineName.indexOf($string("General").X) === 0)
                    {
                        line.Column = 3;
                    }

                    if (line.LineName.lastIndexOf('1') === 2)
                    {
                        line.Row = 1;
                    }
                    else if (line.LineName.lastIndexOf('2') === 2)
                    {
                        line.Row = 2;
                    }
                    else
                    {
                        line.Row = 0;
                    }
                }
            }
        }
       
        drawGrid.sort(function (a1, a2) { return a1[0].Column - a2[0].Column; });
        for (var gridColumn = 0; gridColumn < drawGrid.length; gridColumn++)
        {
            drawGrid[gridColumn].sort(function (a1, a2) { return a1.Row - a2.Row; });
        }
      
        this.Swapped = true;
    },

    __doExactScoreGridSwap: function (drawGrid)
    {
        var firstLine = drawGrid[0][0];
        if (firstLine.Swapped !== undefined && firstLine.Swapped !== false)
        {
            this.Swapped = true;
        }

        if (!this.Swapped)
        {
            for (var gridColumn = 0; gridColumn < drawGrid.length; gridColumn++)
            {
                for (var gridRow = 0; gridRow < drawGrid[gridColumn].length; gridRow++)
                {
                    var line = drawGrid[gridColumn][gridRow];
                    this.__doExactScoreLineSwap(line);
                }
            }
        }

        drawGrid.sort(function (a1, a2) { return a1[0].Column - a2[0].Column; });
        for (var gridColumn = 0; gridColumn < drawGrid.length; gridColumn++)
        {
            drawGrid[gridColumn].sort(function (a1, a2) { return a1.Row - a2.Row; });
        }

        this.Swapped = true;
    },

    __doStandartGridSwap: function (drawGrid)
    {

        var gridColumns = Array.getLength(drawGrid);

        if (gridColumns == 1)
        {
            //all lines are from same line type  => so search for Home and Away team line in the rows of the column and swap them
            var homeTeamRow = null;
            var awayTeamRow = null;
            for (var row = 0; row < drawGrid[0].length; row++)
            {
                var team = drawGrid[0][row].Team;
                if (team == 1)
                {
                    homeTeamRow = row;
                }
                else if (team == 2)
                {
                    awayTeamRow = row;
                }
            }
            if (homeTeamRow != null && awayTeamRow != null)
            {
                var homeLine = drawGrid[0][homeTeamRow];
                drawGrid[0][homeTeamRow] = drawGrid[0][awayTeamRow];
                drawGrid[0][awayTeamRow] = homeLine;

                this.Swapped = true;
            }
        }
        else if (gridColumns > 1)
        {
            //lines are from different line types => so check in which column are placed the Home and Away team lines(enough to check only lines in first row)
            var homeTeamColumn = null;
            var awayTeamColumn = null;
            for (var column = 0; column < gridColumns; column++)
            {
                var team = drawGrid[column][0].Team;
                if (team == 1)
                {
                    homeTeamColumn = column;
                }
                else if (team == 2)
                {
                    awayTeamColumn = column;
                }
            }

            if (homeTeamColumn != null && awayTeamColumn != null)
            {
                var homelines = drawGrid[homeTeamColumn];
                drawGrid[homeTeamColumn] = drawGrid[awayTeamColumn];
                drawGrid[awayTeamColumn] = homelines;

                this.Swapped = true;
            }
        }
    },

    __getEventType: function (eventTypeID)
    {
        if (Array.indexOf([627, 659, 545, 499, 307], eventTypeID) != -1) return QAEventType.HalfTimeFulltime;
        if (Array.indexOf([60, 310, 144], eventTypeID) != -1) return QAEventType.ExactScore;

        return QAEventType.OtherQA;
    },

    __doHalfTimeFullTimeLineSwap: function (line)
    {
        if (line.Swapped === undefined) line.Swapped = false;
        if (!line.Swapped)
        {
            if (line.LineName !== undefined)
                line.LineName = line.LineName.replace(/1/g, '@').replace(/2/g, '1').replace(/@/g, '2');

            if (line.AsianLineName !== undefined)
                line.AsianLineName = line.AsianLineName.replace(/1/g, '@').replace(/2/g, '1').replace(/@/g, '2');

            line.Swapped = true;
        }
    },

    __doExactScoreLineSwap: function (line)
    {
        if (line.Swapped === undefined) line.Swapped = false;
        if (!line.Swapped)
        {
            if (line.LineName !== undefined)
                line.LineName = line.LineName.split(":").reverse().join(":");

            if (line.AsianLineName !== undefined)
                line.AsianLineName = line.AsianLineName.split(":").reverse().join(":");

            line.Swapped = true;
        }
    },
}

window.QAEventTeamSwap = QAEventTeamSwap;
window.QAEventType = QAEventType;
