﻿function QAColumn(initArray)
{
    'use strict';

    this.ID = initArray[0];
    this.Name = initArray[1];
    this.Order = initArray[2];

    this.Count = 0;

    this.compareTo = function (c2)
    {
        if (this.ID > c2.ID)
        {
            return 1;
        }

        if (this.ID < c2.ID)
        {
            return -1;
        }

        return 0;
    };
}

window.QAColumn = QAColumn;