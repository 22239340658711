function QAEventInfo(initArray)
{
    this.IsSortedAsc = true;
    this.ID = parseInt(initArray[0]);
    this.Type = parseInt(initArray[1]);
    this.HasLiveStream = initArray[9] == 1;
    this.BranchID = parseInt(initArray[14]) || null;
    this.LeagueName = initArray[13];
    this.LeagueID = initArray[15];
    this.Link = initArray[16] || null;
    this.MinBetNumber = initArray[18];

    if (initArray[2])
    {
        var lines = typeof initArray[2][0] == "number" ? initArray[2][2] : initArray[2];
        this.Lines = ArrayOf(lines, QALine);

        this.Name = initArray[3];
        this.Columns = HashtableOf(initArray[4], QAColumn);
        this.Date = Date.fromISO(initArray[5]);

        this.TypeName = initArray[6];
        //***********Separating dependency between cient and event types***********
        var spt = oneTitleQA[this.Type];

        if (spt != undefined)
            this.SplitType = spt;
        else
            this.SplitType = initArray[7];
        //***********END***********
        this.OrderID = initArray[8];
        this.BetRadarEventId = initArray[10];
        this.BetRadarStatisticsEventId = initArray[11];

        this.setLineColumnInfos();
        this.filterColumns();

        this.DrawGrid = null;
        this.LastUpdate = false;
    }

    if (initArray[12])
    {   
        this.RacecardInfo = HashtableOf(initArray[12], RacecardLineInfo);
	}
	
	if (initArray[25])
	{
		this.SettlementDate = Date.fromISO(initArray[25]);
	}

    this.isQA = true;
    this.Live = false;
    this.IsOutright = true;
}

QAEventInfo.prototype =
{
    isExpired: function (lastupdate, multiplier) {
        if (this.LastUpdate) {
            var to = this.Type == 0 || this.Type == 1 ? LinesUpdateBaseInterval : SpecialEventUpdateTimeout;
            to *= multiplier;

            return ((new Date()).getTime() - this.LastUpdate.getTime()) > to;
        }
        else return true;
    },

    getUpdateString: function () {
        var lls = [this.ID];

        for (var key in this.Lines)
            lls.push(this.Lines[key].getUpdateString());

        return lls.join("#");
    },

    getSortedLines: function ()
    {
        //Primary sort by Odds, secondary sort by horse number
        var ref = this;
        var res = Array.getValues(this.Lines);

        res.sort(chainSort(
            function (l1, l2) { return Data.compareByLineTypeID(l1, l2); },
            function (l1, l2) { return Data.compareByLineStateID(l1, l2); },
            function (l1, l2)
            {
                //Sort by SP or not
                var l1sp = (l1.LineTypeID == 24 && l1.Odds == 0 ? 1 : 0);
                var l2sp = (l2.LineTypeID == 24 && l2.Odds == 0 ? 1 : 0);

                return Data.compare(l1sp, l2sp);
            },
            function (l1, l2) { return Data.compare(l1.Odds, l2.Odds); },     //Sort by odds
            function (l1, l2)
            {
                //Sort by horse nbr (if available)
                var rc1 = ref.RacecardInfo[l1.ID];
                var rc2 = ref.RacecardInfo[l2.ID];
                if (!rc1 || !rc2) return 0;
                return ref.IsSortedAsc ? Data.compare(rc1.HorseNumber, rc2.HorseNumber) : -Data.compare(rc1.HorseNumber, rc2.HorseNumber);
            }
        ));

        return res;
    },

    updateFromServer: function (initArray)
    {
        this.LastUpdate = new Date();
        if (initArray[12])
        {
            this.RacecardInfo = HashtableOf(initArray[12], RacecardLineInfo);
        }

        if (initArray[19]) { this.HorseRacingStateID = initArray[19] != -1 ? initArray[19] : this.HorseRacingStateID }
        if ((initArray[2]) && (initArray[2] != -1)) {

            var lines = typeof initArray[2][0] == "number" ? initArray[2][2] : initArray[2];
            this.updateLines(HashtableOf(lines, QALine));

            this.DrawGrid = false;
            this.DrawGridSize = 0;
            if(!this.Columns && initArray[4])
            {
                this.Columns = HashtableOf(initArray[4], QAColumn);
            }
        }
        else if (initArray[2] == -1)
        {
            this.Deleted = true;
            return;
        }
        else
        {
            this.__resetUpdated();
        }

        //***********EACH WAY***********
        if (initArray[9] && initArray[9] == 1) {
            this.IsEachWayEnabled = initArray[9];
            this.EWPlaceTerms = initArray[10];
            this.EWOddsTerms = initArray[11];
        }
        //***********EACH WAY***********

        if (initArray[5] == undefined) return;

        this.EventName = initArray[3];
        this.TypeName = initArray[5];

        this.getColumnNames(initArray[4]);
        //***********Separating dependency between cient and event types***********
        var spt = oneTitleQA[this.Type];
        this.SplitType = (spt != undefined) ? spt : initArray[8];
        //***********END***********
        this.OrderID = initArray[7];
        this.Date = Date.fromISO(initArray[6]);

        this.HasCombo = false;
        this.Updated = true;

    },

    getColumnNames: function (array) {
        this.ColumnNames = [];

        for (var i in array) {
            var item = array[i];
            this.ColumnNames[item[0]] = { ID: item[0], Name: item[1], MappedLineTypeID: item[2] };
        }

    },

    setLineColumnInfos: function ()    {
        var lineTypeIDFavourite = 26;
        var lineTypeIDSecondFavourite = 28;
        var infos = [undefined, undefined, undefined];
        for (var i = 0; i < this.Lines.length; i++) {
            var line = this.Lines[i];
            if ((infos[i % 3]) || (!line.Odds)) continue;
            infos[i % 3] = { TypeID: line.LineTypeID };
        }

        for (var i = 0; i < this.Lines.length; i++) {
            var line = this.Lines[i];
            if ((!infos[i % 3]) || (line.Odds)) continue;          
            var lineTypeID = line.LineTypeID;
            if (lineTypeID != lineTypeIDFavourite && lineTypeID != lineTypeIDSecondFavourite)
            {
                line.LineTypeID = infos[i % 3].TypeID;
            }
        }
    },

    filterColumns: function () {
        for (var key in this.Lines) {
            var line = this.Lines[key];
            if ((!line) || (!line.Odds)) continue;

            var column = this.Columns[line.LineTypeID];
            if (column) column.Count++;
        }

        for (var key in this.Columns) {
            if (this.Columns[key].Count == 0)
                delete this.Columns[key];
        }
    },
    
    buildDrawMatrix: function () {
        var grid = [];

        for (var key in this.Lines) {
            var line = this.Lines[key];
            if (!line.Odds) continue;

            if (BetSlipRegulations.IsItalian && (line.LineTypeID !== 15 || line.LineName === "No Goalscorer")) continue;

            var row = grid[line.LineName];
            if (!row) grid[line.LineName] = row = [];

            row[line.LineTypeID] = line;
        }

        var sortGridGoalscorer = function (gridItem1, gridItem2) {
            var gridItem1 = gridItem1[1];
            var gridItem2 = gridItem2[1];
            var line1, line2;

            var firstToScoreLineCondition = function (subItem) {
                return subItem.Odds && subItem.LineTypeID === 13;
            };
            line1 = Array.find(gridItem1, firstToScoreLineCondition);
            line2 = Array.find(gridItem2, firstToScoreLineCondition);

            if (!line1 || !line2) {
                return 0;
            }

            if (line1.QATypeParameter1 == 218) {
                return -1;
            } else if (line2.QATypeParameter1 == 218) {
                return 1;
            }

            var result = line1.Odds - line2.Odds;

            if (result > 0) {
                return 1;
            } else if (result < 0) {
                return -1;
            } else {
                return 0;
            }
        };

        var tuples = [];
        for (var key in grid) {
            tuples.push([key, grid[key]]);
        }
        tuples.sort(sortGridGoalscorer);

        var gridTemp = [];
        for (var i = 0; i < tuples.length; i++) {
            gridTemp[tuples[i][0]] = tuples[i][1];
        }

        grid = gridTemp;
        return grid;
    },

    buildDrawGrid: function (doTeamLinesSwap) {

        if (this.DrawGrid) return this.DrawGrid;

        var grid = [];
        var ggrid = Array.getValues(this.Columns);
        ggrid.sort(function (a1, a2) { return Data.compare(a1.ID, a2.ID); });
        for (var i in ggrid) grid[ggrid[i].ID] = [];

        for (var key in this.Lines) {
            var line = this.Lines[key];
            if (!line.Odds) continue;
            // The italian regulator is not accepting bets for exact score with goals more than 4
            if (BetSlipRegulations.IsItalian && this.Type == 60 && (line.QATypeParameter1 > 4 || line.QATypeParameter2 > 4)) continue;

            var column = grid[line.LineTypeID];
            if (!column) column = grid[line.LineTypeID] = [];
            column.push(line);
        }

        for (var key in grid)
            if (grid[key].length == 0)
                delete grid[key];

        var msx = 0;
        for (var key in grid)
            msx = Math.max(msx, grid[key].length);
        this.DrawGridSize = msx;

        this.DrawGrid = [];
        this.DrawGrid = Array.getFlatValues(grid);

        if (doTeamLinesSwap !== undefined && doTeamLinesSwap)
        {
            var swapManager = new QAEventTeamSwap(this.Type);
            swapManager.swapDrawGrid(this.DrawGrid);
        }

        return this.DrawGrid;
    },

    buildDrawGridFor3WHC: function(doTeamLinesSwap) {
        const qaLineTypeHome = 13;
        const qaLineTypeDraw = 14;
        const qaLineTypeAway = 15;

        if (this.DrawGrid) return this.DrawGrid;

        var grid = [
            [],
            [],
            []
        ];

        var lines = Array.getFlatValues(this.Lines);

        var hasScoreOverTen = lines
            .filter(function (item) {
                return item.LineTypeID === qaLineTypeDraw && (item.QATypeParameter2 > 99 || item.QATypeParameter2 < -99);
            })
            .length > 0;

        var scores = lines
            .filter(function(l) { return l.LineTypeID === qaLineTypeHome })
            .map(function(l) { return l.QATypeParameter2 });

        for (var scoreIndex in scores) {
            var score = scores[scoreIndex];

            var homeLine = lines.find(function(l) {
                return l.LineTypeID == qaLineTypeHome && l.QATypeParameter2 == score;
            });
            if (!homeLine || homeLine.Odds == 0) {
                continue;
            }

            var scoreForDraw = hasScoreOverTen
                ? score * 100
                : score > 0
                    ? score - 1
                    : score + 1;
            var drawLine = lines.find(function(l) {
                return l.LineTypeID == qaLineTypeDraw && l.QATypeParameter2 == scoreForDraw
            });
            if (!drawLine || drawLine.Odds == 0) {
                continue;
            }

            var scoreForAway = hasScoreOverTen
                ? score * -1
                : (score > 0 ? score + 1 : score - 1) * -1;
            var awayLine = lines.find(function(l) {
                return l.LineTypeID == qaLineTypeAway && l.QATypeParameter2 == scoreForAway;
            });
            if (!awayLine || awayLine.Odds == 0) {
                continue;
            }

            grid[0].push(homeLine);
            grid[1].push(drawLine);
            grid[2].push(awayLine);
        }

        var msx = 0;
        for (var key in grid) {
            msx = Math.max(msx, grid[key].length);
        }
        this.DrawGridSize = msx;

        this.DrawGrid = [];
        this.DrawGrid = Array.getFlatValues(grid);

        if (doTeamLinesSwap !== undefined && doTeamLinesSwap) {
            var swapManager = new QAEventTeamSwap(this.Type);
            swapManager.swapDrawGrid(this.DrawGrid);
        }

        return this.DrawGrid;
    },

    __resetUpdated: function () {
        this.Updated = false;

        if (!this.Lines) return;

        for (var key in this.Lines) {
            if (this.Lines[key])
                this.Lines[key].__resetUpdated();
        }
    },

    updateLines: function (lines) {
        if (!this.Lines) {
            this.createLines(lines);
            return;
        }

        for (var key in lines) {
            if (this.Lines[key])
                this.Lines[key].updateFromServer(lines[key], this.Type);
            else
                this.Lines[key] = lines[key];
        }

        for (var key in this.Lines) {
            if (this.Lines[key].Deleted) {
                delete this.Lines[key];
                continue;
            }

            this.HasCombo = this.HasCombo || this.Lines[key].HasCombo;
        }
    },

    createLines: function (lines)
    {
        this.Lines = lines;
        for (var key in this.Lines)
        {
            this.HasCombo = this.HasCombo || this.Lines[key].HasCombo;
        }
    },

    getLinesCount: function () {
        var cnt = 0;

        for (var i in this.Lines) {
            if (this.Lines[i] && this.Lines[i].Odds) cnt++;
        }

        return cnt;
    },

    getMoreCount: function () {
        "use strict";
        return this.getLinesCount();
    },

    hasOdds: function () {
        for (var key in this.Lines) {
            if (this.Lines[key].hasOdds()) return true;
        }

        return false;
    }
}

window.QAEventInfo = QAEventInfo;
