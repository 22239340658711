﻿/**
 * @author Martin
 * last updated: 09.06.2014
 *
 * This class handles QuickNavigation # of elements per page, pages generation
 * events + animations for swiping between pages. EDIT WITH CAUTION!!!
 *
 * This class uses minimum amount of jQuery Library for selectors purposes only
 * Compatible with all versions above 1.2
 *
 * THIS CODE IS STRONGLY RELATED WITH THE CSS THAT BUILDS THE BLOCKS!!!
 *
 */

var BETAPP = BETAPP || {};

BETAPP.slider = {};

BETAPP.util = {};

BETAPP.util.extendProps = function ()
{
    var res = {},
        len = arguments.length,
        stuff,
        j;
    for (j = 0; j < len; j++)
    {
        stuff = arguments[j];
        for (var i in stuff)
        {
            if (stuff.hasOwnProperty(i))
            {
                res[i] = stuff[i];
            }
        }
    }
    return res;
}

BETAPP.util.detectVendor = function ()
{
    var rootStyles = window.getComputedStyle(document.documentElement),
        testProp;

    for (var i = 0, len = arguments.length; i < len; i++)
    {
        testProp = arguments[i];
        if (testProp in rootStyles)
        {
            return testProp;
        }
    }

    return arguments[0];
}

function QuickNavManagement(options)
{
    var defaults = {
        wrapperId: 'quickNavigationWrap',
        itemsPerPage: 0,
        pxDeviation: 4,         //multiplied returns deviations from which we decide what and if to scroll
        minPadding: 8,           //minimum padding (added for left + right) to ensure minimum space between icons
        minItemsPerPage: 0,
        isFeaturedFirstItem: false,
        featuredItemWidthPadding: 26 // we have hardcoded width in css, so we need to adjust slider for featured item
    };

    this.data = BETAPP.util.extendProps(defaults, options);

    this.wrapper = document.getElementById(this.data.wrapperId);
}

QuickNavManagement.prototype.init = function (disableMenuPagination)
{
    var parent;
    var items;
    var pagination;
    var selectedPage;
    var itemsCount;

    var pages;                  //html element -> pages items
    var windowWidth;            //this one is set when pages are about to be generated and used from then on
    var safeguardTimeout;       //this timeout will fire if trainsitionEnd event does not occur

    var data;                   //options are merged to this one
    var qNav;                   //main object

    var stopped;                //bool, determines if we are to start animation on touchEnd

    var X = 0;                  //starting point X
    var Y = 0;                  //starting point Y
    var dX = 0;                  //distance traveled X
    var dY = 0;                  //distance traveled Y

    data = this.data;

    parent = this.wrapper.querySelector('.slider');
    items = this.wrapper.querySelector('.slider-items');
    pagination = this.wrapper.querySelector('.slider-pagination');
    selectedPage = this.wrapper.querySelector('.slider-selectedPage');
    itemsCount = items.children.length;
    selectedPage.value = getPageNumber() || 1;

    

    if (disableMenuPagination)
    {
        document.querySelector("#quickNavigationWrap").classList.add("quickNavWithoutPagination");
    }

    //set starting points
    function touchStart(e)
    {
        stopped = true; //no animation on touchEnd
        X = parseInt(e.changedTouches[0].clientX);
        Y = parseInt(e.changedTouches[0].clientY);
    }

    //track movement
    function touchMove(e)
    {
        stopped = true; //no animation on touchEnd
        dX = parseInt(e.changedTouches[0].clientX) - X;
        dY = parseInt(e.changedTouches[0].clientY) - Y;
        //if scroll occurs in vertical direction
        if (Math.abs(dY) > (data.pxDeviation * 4))
        {
            //is this diagonal scroll? -> more X-ish or Y-ish the user tries to scroll?
            if (Math.abs(dX) > Math.abs(dY)) //if X-ish -> it's a swipe
            {
                if (!disableMenuPagination)
                {
                    e.preventDefault(); //no vertical scroll
                    callScroll(data);
                }

            }
        }
            //if user inits horizontal scroll
        else if (Math.abs(dX) > data.pxDeviation) //although this one looks like the upper - IT IS NOT, DO NOT REMOVE!!!
        {
            if (!disableMenuPagination)
            {
                e.preventDefault();

                //if user scrolls a long range in horizontal direction -> bypass touchEnd
                if (Math.abs(dX) > (data.pxDeviation * 25)) {
                    stopped = true;
                    callScroll(data);
                }
            }

            
        }
        //always return false!!!
        return false;
    }

    //mark selected pages in pagination
    function selectPage(page)
    {
        var pageItem = '#' + data.wrapperId + '-page-' + page;
        pages.removeClass();
        $(pageItem).addClass('selected');
    }

    //translate3d forces GPU to render animations
    function translate(x)
    {
        var vendorProp = BETAPP.util.detectVendor('transform', 'webkitTransform', 'MozTransform', 'OTransform', 'msTransform'),
            x = x || 0;

        items.style[vendorProp] = 'translate3d(' + x + 'px, 0, 0)';
    }

    /**
     * Disable or enable CSS transition animation
     * by applying className 'noTransition'
     * @param {Boolean} addClass - add 'true' or remove 'false' the class
     */
    function disableTransition(addClass)
    {
        var cls = 'noTransition'; // defined in the styles.css
        if (addClass)
        {
            items.classList.add(cls);
        }
        else
        {
            setTimeout(function ()
            {
                items.classList.remove(cls);
            }, 600);
        }
    }

    /**
     * Get the current page number
     * @return {Integer} current page number
     */
    function getPageNumber()
    {
        var pageNumber = parseInt(selectedPage.value, 10);

        return pageNumber;
    }

    //determine swipe direction
    function callScroll(data)
    {
        (dX > 0) ? slideRight(data) : slideLeft(data);
    }

    /**
     * Slide to the left
     * @param {Boolean} instantMove - get the stored page number and slide to it
     * @return false
     */
    function slideLeft()
    {
        var currentPage = getPageNumber(),
            newPage = currentPage + 1,
            width,
            tranform;
        data.beforeSlideLeftAction && data.beforeSlideLeftAction(data, newPage);
        //existing page
        if (newPage <= pages.length)
        {
            qNav.DetachEvents();

            //last page
            if (newPage == pages.length)
            {
                var firstChildItems = $(':first-child', items);
                width = (firstChildItems.outerWidth() * itemsCount) - windowWidth;
                width = width + adjustWidth(firstChildItems);
                width *= (-1);
            }
            else //not last page :)
            {
                width = windowWidth * currentPage;
                width *= (-1);
            }

            selectedPage.value = newPage;
            selectPage(newPage);

            translate(width);
        }
        return false;
    }

    function slideRight()
    {
        var currentPage = getPageNumber(),
            newPage = currentPage - 1,
            width,
            tranform;

        //first page
        if (currentPage > 1)
        {
            qNav.DetachEvents();
            if (currentPage == pages.length)
            {
                var firstChildItems = $(':first-child', items);
                width = (firstChildItems.outerWidth() * itemsCount) - windowWidth * 2;
                width = width + adjustWidth(firstChildItems);
            }
            else
            {
                width = windowWidth * newPage - windowWidth;
            }

            //it has to be negative always
            width *= (-1);

            if (width > 0) //value is positive ... reset slider position
            {
                width = 0;
                selectedPage.value = 1;
                selectPage(1);
                qNav.AttachEvents();
            }
            selectedPage.value = newPage;
            selectPage(newPage);

            translate(width);
        }
        return false;
    }

    function adjustWidth(firstChildItems) 
    {
        var adjustWidthValue = 0;
        if (data.isFeaturedFirstItem) 
        {
            adjustWidthValue = getAdjustWidthForLastItem(firstChildItems); 
        }
        return adjustWidthValue;
    }

    function getAdjustWidthForLastItem(firstChildItems) 
    {
        var firstItemMargin = window.getComputedStyle(firstChildItems[0].querySelector(".c-casino-lobby-slider__icon")).marginLeft;
        var marginValue = parseInt(firstItemMargin, 10);
        var adjustWidth = data.featuredItemWidthPadding;
        if (!isNaN(marginValue)) 
        {
            adjustWidth = adjustWidth - marginValue;
        }
        adjustWidth = adjustWidth / 2;
        return adjustWidth;
    }

    qNav = {
        AttachEvents: function (e)   //Attach events listeners
        {
            if (e) //do we have transitionEnd?
            {
                clearTimeout(safeguardTimeout);
            }
            parent.addEventListener('touchstart', touchStart, false);
            parent.addEventListener('touchmove', touchMove, false);
            //parent.addEventListener('touchend', touchEnd, false);
        },
        DetachEvents: function ()    //Detach events listeners
        {
            qNav.SafeGuard();
            parent.removeEventListener('touchstart', touchStart, false);
            parent.removeEventListener('touchmove', touchMove, false);
            //parent.removeEventListener('touchend', touchEnd, false);

            items.removeEventListener('webkitTransitionEnd', qNav.AttachEvents, false);
            items.removeEventListener('oTransitionEnd', qNav.AttachEvents, false);
            items.removeEventListener('transitionend', qNav.AttachEvents, false);
            items.removeEventListener('msTransitionEnd', qNav.AttachEvents, false);
        },
        calculateItemWidthAndCount: function(data) 
        {
            if (data.itemsPerPage)
            {
                childW = Math.floor(windowWidth / data.itemsPerPage) - 2 * data.minPadding;
            }
            else
            {
                childW = $(items.firstElementChild).width();
            }
            var childMinW = childW + data.minPadding;
            var itemsPerPage = parseInt(windowWidth / childMinW);            
            if (!data.itemsPerPage && data.minItemsPerPage && itemsPerPage < data.minItemsPerPage) 
            {
                data.itemsPerPage = data.minItemsPerPage;
                return this.calculateItemWidthAndCount(data);
            }
            return [childW, itemsPerPage];
        },
        GeneratePages: function ()  //manage pages, elements per page and if to attach events
        {
            var childW;

            windowWidth = parent.clientWidth || $(window).outerWidth();
            var widthAndCount = this.calculateItemWidthAndCount(data);
            childW = widthAndCount[0];
            var itemsPerPage = widthAndCount[1];
            data.calculatedItemsPerPage = itemsPerPage;
            var childExpectedPadding = Math.floor(((windowWidth / itemsPerPage) - childW) / 2);
            data.childExpectedPadding = childExpectedPadding;
            //update paddings to set maximum visible elements per page
            var buttons = items.children;
            for (var i = 0; i < buttons.length; i++)
            {
                buttons[i].style.paddingLeft = childExpectedPadding + "px";
                buttons[i].style.paddingRight = childExpectedPadding + "px";
            }
            var iterations = Math.ceil(itemsCount / itemsPerPage) + 1;

            if (iterations > 2)
            {
                var HTML = '',   //you cannot use += with undefined variable
                    pageNum = getPageNumber();

                for (var i = 1; i < iterations; i++)
                {
                    var itemClass = "";
                    (i === pageNum) && (itemClass = 'class="selected"');
                    HTML += '<span id="' + data.wrapperId + '-page-' + i + '" ' + itemClass + '></span>';
                }
                pagination.innerHTML = HTML;
                pages = $(pagination).children();
                qNav.AttachEvents();
            }
            else
            {
                clearTimeout(safeguardTimeout);
                pagination.innerHTML = '';
                return false;
            }
        },
        SafeGuard: function ()
        {
            clearTimeout(safeguardTimeout);
            safeguardTimeout = setTimeout(function ()
            {
                qNav.DetachEvents();
                qNav.AttachEvents();
            }, 650);
        },
        Start: function ()
        {
            var selectedPageNum = getPageNumber();

            qNav.GeneratePages();
            disableTransition(false);

            //we don't need switch statement
            items.addEventListener('webkitTransitionEnd', qNav.AttachEvents, false);
            items.addEventListener('oTransitionEnd', qNav.AttachEvents, false);
            items.addEventListener('transitionend', qNav.AttachEvents, false);
            items.addEventListener('msTransitionEnd', qNav.AttachEvents, false);
            data.startFinished && data.startFinished(data);

        },
        Kill: function ()
        {
            var vendorProp = BETAPP.util.detectVendor('transition', 'webkitTransition', 'MozTransition', 'OTransition');

            disableTransition(true);
            selectedPage.value = 1;
            items.removeAttribute('style');

            qNav.DetachEvents();
        },
        Reset: function ()
        {
            qNav.Kill();
            qNav.Start();
        },
    };

    return {
        start: qNav.Start,
        reset: qNav.Reset,
        kill: qNav.Kill
    };
};

//document.addEventListener('DOMContentLoaded', function ()
//{
//    if(BETAPP.slider.quickNav)
//    BETAPP.slider.quickNav.start();
//}, false);


//handle resize/orientation change
var QNAVWINDOWRESIZETIMEOUT;

window.BETAPP = BETAPP;
window.QuickNavManagement = QuickNavManagement;
window.QNAVWINDOWRESIZETIMEOUT = QNAVWINDOWRESIZETIMEOUT;

window.addEventListener("resize", function ()
{
    clearTimeout(QNAVWINDOWRESIZETIMEOUT);
    QNAVWINDOWRESIZETIMEOUT = setTimeout(function ()
    {
        if(BETAPP.slider.quickNav)
        BETAPP.slider.quickNav.reset();

        if (BETAPP.slider.groupNav)
        {
            BETAPP.slider.groupNav.reset();
        }
    }, 100); //this may have to be reworked
}, false);

