﻿function IceHockeyScore(score1, score2, game)
{
    this.scores =
    {
        firstPeriodScore1: 0,
        firstPeriodScore2: 0,
        secondPeriodScore1: 0,
        secondPeriodScore2: 0,
        thirdPeriodScore1: 0,
        thirdPeriodScore2: 0,
        totalScore1: 0,
        totalScore2: 0
    }

    this.period = IceHockeyScore.getPeriodByGameStatus(game.GameData.Status);

    var scoreAdditionalData = IceHockeyScore.filterScoreAdditionalData(game.AdditionalData);

    if (Array.getLength(scoreAdditionalData) > 0)
    {
        this.updateScoreFromAdditionalData(scoreAdditionalData);
    }
    else
    {
        this.updateTotalScoreOnly(score1, score2);
    }

    if (game.IsTeamSwapEnabled)
    {
        this.swapScores();
    }


    return this.scores;
}

IceHockeyScore.emptyScoreValue = "";

IceHockeyScore.AdditionalDataKeys =
{
    FirstPeriodScore1: 122,
    FirstPeriodScore2: 123,
    SecondPeriodScore1: 124,
    SecondPeriodScore2: 125,
    ThirdPeriodScore1: 126,
    ThirdPeriodScore2: 127,
    TotalScore1: 128,
    TotalScore2: 129
};

IceHockeyScore.PeriodStatuses =
{
    FirstPeriod: 11,
    SecondPeriod: 12,
    ThirdPeriod: 13
};

IceHockeyScore.Period =
{
    First: 1,
    Second: 2,
    Third: 3
};

IceHockeyScore.filterScoreAdditionalData = function (additionalData)
{
    return Array.findAllValues(additionalData, function (row)
    {
        return Array.indexOf(IceHockeyScore.AdditionalDataKeys, row[0]) != -1 ? true : false;
    });
};

IceHockeyScore.getPeriodByGameStatus = function (status)
{
    switch (Math.abs(status))
    {
        case IceHockeyScore.PeriodStatuses.FirstPeriod:
            return IceHockeyScore.Period.First;
        case IceHockeyScore.PeriodStatuses.SecondPeriod:
            return IceHockeyScore.Period.Second;
        case IceHockeyScore.PeriodStatuses.ThirdPeriod:
            return IceHockeyScore.Period.Third;
    }
    return -1;
};

IceHockeyScore.isStarted = function (status)
{
    if (IceHockeyScore.getPeriodByGameStatus(status) != -1)
    {
        return true;
    }

    return false;
};

IceHockeyScore.isPaused = function (status)
{
    if (status < 0 && IceHockeyScore.getPeriodByGameStatus(status) != -1)
    {
        return true;
    }

    return false;
};

IceHockeyScore.prototype =
{
    updateScoreFromAdditionalData: function (scoreAdditionalData)
    {
        for (var k in scoreAdditionalData)
        {
            var key = scoreAdditionalData[k][0] * 1;
            var score = scoreAdditionalData[k][1] * 1;
            switch (key)
            {
                case IceHockeyScore.AdditionalDataKeys.FirstPeriodScore1:
                    this.scores.firstPeriodScore1 = this.formatPeriodScore(score, IceHockeyScore.Period.First);
                    break;
                case IceHockeyScore.AdditionalDataKeys.FirstPeriodScore2:
                    this.scores.firstPeriodScore2 = this.formatPeriodScore(score, IceHockeyScore.Period.First);
                    break;
                case IceHockeyScore.AdditionalDataKeys.SecondPeriodScore1:
                    this.scores.secondPeriodScore1 = this.formatPeriodScore(score, IceHockeyScore.Period.Second);
                    break;
                case IceHockeyScore.AdditionalDataKeys.SecondPeriodScore2:
                    this.scores.secondPeriodScore2 = this.formatPeriodScore(score, IceHockeyScore.Period.Second);
                    break;               
                case IceHockeyScore.AdditionalDataKeys.ThirdPeriodScore1:
                    this.scores.thirdPeriodScore1 = this.formatPeriodScore(score, IceHockeyScore.Period.Third);
                    break;
                case IceHockeyScore.AdditionalDataKeys.ThirdPeriodScore2:
                    this.scores.thirdPeriodScore2 = this.formatPeriodScore(score, IceHockeyScore.Period.Third);
                    break;                
                case IceHockeyScore.AdditionalDataKeys.TotalScore1:
                    this.scores.totalScore1 = score;
                    break;
                case IceHockeyScore.AdditionalDataKeys.TotalScore2:
                    this.scores.totalScore2 = score;
                    break;
            }
        }
    },

    formatPeriodScore: function (score, scorePeriod)
    {
        if (this.period !== -1 && this.period < scorePeriod)
        {
            return IceHockeyScore.emptyScoreValue;
        }
        return score;
    },

    updateTotalScoreOnly: function (totalScore1, totalScore2)
    {
        this.scores.firstPeriodScore1 = IceHockeyScore.emptyScoreValue;
        this.scores.firstPeriodScore2 = IceHockeyScore.emptyScoreValue;
        this.scores.secondPeriodScore1 = IceHockeyScore.emptyScoreValue;
        this.scores.secondPeriodScore2 = IceHockeyScore.emptyScoreValue;
        this.scores.thirdPeriodScore1 = IceHockeyScore.emptyScoreValue;
        this.scores.thirdPeriodScore2 = IceHockeyScore.emptyScoreValue;
        this.scores.totalScore1 = totalScore1 * 1;
        this.scores.totalScore2 = totalScore2 * 1;
    },

    swapScores: function ()
    {
        var temp = this.scores.firstPeriodScore1;
        this.scores.firstPeriodScore1 = this.scores.firstPeriodScore2;
        this.scores.firstPeriodScore2 = temp;

        temp = this.scores.secondPeriodScore1;
        this.scores.secondPeriodScore1 = this.scores.secondPeriodScore2;
        this.scores.secondPeriodScore2 = temp;
                
        temp = this.scores.thirdPeriodScore1;
        this.scores.thirdPeriodScore1 = this.scores.thirdPeriodScore2;
        this.scores.thirdPeriodScore2 = temp;

        temp = this.scores.totalScore1;
        this.scores.totalScore1 = this.scores.totalScore2;
        this.scores.totalScore2 = temp;
    }
}

window.IceHockeyScore = IceHockeyScore;