﻿/* exported MobileLeftNavigationPanel */
function MobileLeftNavigationPanel(config) {
    'use strict';
    MobilePanel.call(this, config);
    this.Position = PanelPosition.LEFT_NAV;
}

$.extend(MobileLeftNavigationPanel.prototype, MobilePanel.prototype,
{
    init: function ()
    {
        MobilePanel.prototype.init.call(this);
        
        // Left menu navigation panel(hamburger menu blocks) is always active for now, most of its data is static
        this.activate();
    }
});

window.MobileLeftNavigationPanel = MobileLeftNavigationPanel;