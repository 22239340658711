function MasterEvent(initArray)
{
    this.ID = parseInt(initArray[0], 10);
    this.Team1Name = initArray[1];
    this.Team2Name = initArray[2];
    this.LeagueID = parseInt(initArray[3]);
    this.LeagueName = initArray[20];
    this.MasterEventDate = Date.fromISO(initArray[4]);
    this.Live = false;
    this.ScoreUpdated = false;
    this.DangerUpdated = false;
    this.Danger = false;
    this.UpdateTimeoutMultiplier = 1;
    this.BranchID = parseInt(initArray[10]);
    this.BranchName = initArray[19];
    this.Events = HashtableOf(initArray[5], EventInfo);
    this.homeEnetplusID = parseInt(initArray[11]);
    this.awayEnetplusID = parseInt(initArray[12]);
    this.IsAmericanOddsDisplayEnabled = initArray[15] ? initArray[15] : false;
    this.HomeTeamRotationNumber = parseInt(initArray[13]);//13 - hometeam rotation number
    this.AwayTeamRotationNumber = parseInt(initArray[14]);//14 - away team rotation number
    this.IsBuyPointsEnabled = initArray[16] ? initArray[16] : false;
    this.IsSameEventInComboAllowed = initArray[17] ? initArray[17] : false;
    this.IsTeamSwapEnabled = initArray[18] ? initArray[18] : false;
    this.Specials = parseInt(initArray[22]);
    this.hasLiveStream = initArray[23];
    this.BetRadarStatisticsEventId = initArray[25];
    this.IsAwayKitUsed = initArray[26];
    this.HomeTeamName = initArray[27];
    this.AwayTeamName = initArray[28];
    this.IsTopBet = initArray[29];
    this.Link = initArray[30];
    this.MultiViewRegularEvents = initArray[31] ? HashtableOf(initArray[31], EventInfo) : [];
    this.MultiViewQAEvents = initArray[32] ? HashtableOf(initArray[32], QAEventInfo) : [];
    this.NthGoalEvents = initArray[32] ? HashtableOf(initArray[32], QAEventInfo) : [];

    /*override BranchID property - it is on 21 position in FormatMasterEventForFilter
    (this method is used in PageMethods.GetLeagueContentForMobile() - League Block)*/
    this.BranchID = initArray[21] ? parseInt(initArray[21]) : this.BranchID;

    if (initArray[8] == 1)  // Live!
    {
        this.Live = true;
        this.Score1 = initArray[6]
        this.Score2 = initArray[7]
        this.UpdateTimeout = LiveEventTimeOut;        
    }
    else
    {
        var toStart = -((new Date()).getTime() - this.MasterEventDate.getTime()) / 60000;
        var e1 = HashtableOf(initArray[6], QAEventInfo);

        for (var k in e1) this.Events[k] = e1[k];

        if (toStart < 180)
        {
            this.UpdateTimeoutMultiplier = 1;
        }
        else if (toStart < 360)
        {
            this.UpdateTimeoutMultiplier = 2;
        }
        else if (toStart < 1440)
        {
            this.UpdateTimeoutMultiplier = 3;
        }
        else
        {
            this.UpdateTimeoutMultiplier = 4;
        }
    }
    for (var k in this.Events)
    {
        this.Events[k].MasterEventID = this.ID;
        this.Events[k].IsOutright = false; // override IsOutright, so qa event is not outright
    };
    for (var eventID in this.MultiViewRegularEvents) {
        this.MultiViewRegularEvents[eventID].MasterEventID = this.ID;
    };
    for (var eventID in this.MultiViewQAEvents) {
        this.MultiViewQAEvents[eventID].MasterEventID = this.ID;
    };
    for (var eventID in this.NthGoalEvents) {
        this.NthGoalEvents[eventID].MasterEventID = this.ID;
    };
    this.LastUpdate = new Date(0);
    this.HTMLCache = null;
}

MasterEvent.prototype =
{
    isExpired: function ()
    {
        return ((new Date()).getTime() - this.LastUpdate.getTime()) > this.UpdateTimeout;
    },

    getHtmlCache: function (odd)
    {
        if (!this.HTMLCache) return null;

        var cc = this.HTMLCache;
        if (cc.odd != odd) return null;
        if (cc.oddstyle != currentOddStyle) return null;
        if (cc.filter != EventFilterMode) return null;

        return cc.html;
    },

    setHtmlCache: function (_odd, _html)
    {
        this.HTMLCache =
      {
          odd: _odd,
          oddstyle: currentOddStyle,
          filter: EventFilterMode,
          html: _html
      };
    },

    updateFromServer: function (ime)
    {
        this.LeagueID = ime.LeagueID;

        //this.LastUpdate = new Date();
        // updating events for masterevent
        for (var evtkey in ime.Events)
        {
            var sevt = ime.Events[evtkey];

            var evt = this.Events[evtkey];
            if (!evt)
            {
                evt = this.Events[evtkey] = sevt;
            }
            else
            {
                if (!evt.Lines || Array.isEmpty(evt.Lines))
                {
                    this.Events[evtkey] = evt = sevt;
                }
                else
                {
                    if (sevt.LinesCount) evt.LinesCount = sevt.LinesCount;
                }
            }
            evt.MasterEventID = this.ID;
        }

        // deleting surplus events
        for (var evtkey in this.Events)
        {
            if (!ime.Events[evtkey])
            {
                delete this.Events[evtkey];
            }
        }
    },

    isAlive: function ()
    {
        if (this.Live)
        {
            for (var key in this.Events)
            {
                var evt = this.Events[key];
                if (evt.Type == 39) return 1;
            }

            return false;
        }
        else
        {
            for (var key in this.Events)
            {
                var evt = this.Events[key];
                if (evt.Type == 0) return 1;
            }

            return false;
        }
    },

    setSpecials: function (events)
    {
        var hash = [];

        for (var key in events)
            hash[events[key].ID] = events[key];

        for (var key in this.Events)
        {
            var evt = this.Events[key];
            if ((evt.Type == 0) || (evt.Type == 1) || (evt.Type == 39)) continue;

            if (hash[key])
            {
                var evt = hash[key]
                this.Events[key] = evt;
                evt.MasterEventID = this.ID;
            }
            else
                delete this.Events[key];
        }
    },

    // returns number of events with types specified
    getActiveEventsCount: function ()
    {
        var hash = [];
        for (var i = 0; i < arguments.length; i++)
        {
            hash[arguments[i]] = true;
        }

        var res = 0;

        for (var key in this.Events)
        {
            var evt = this.Events[key];
            if (hash[evt.Type]) res++;
        }

        return res;
    },

    getMoreCount: function ()
    {
        return this.Specials;
    },

    hasCombo: function ()
    {
        for (var key in this.Events)
        {
            if (this.Events[key].HasCombo) return true;
        }
        return false;
    },

    getEvent: function ()
    {
        for (var key in this.Events)
        {
            return this.Events[key];
        }
    },

    getGropedEventTypes: function (group, events)
    {
        var evts = [];       
        for (var i in group.EventTypes)
        {
            var evType = group.EventTypes[i];
            var evt = [];
            for(var evKey in events)
            {
                evt = events[evKey];
                //use only events for current group
                if (evType.ID == evt.Type)
                    evts.push(evt);
            }
        }
        return evts;
    },

    hasAnyMarkets: function ()
    {
        return this.Specials && this.Specials > 0;
    },

    getMainEventID: function () {
        'use strict';

        if (!this.Events) {
            return 0;
        }

        var main = Array.find(this.Events, function (i) {
            return parseInt(i.Type) === 0;
        });

        if (!main) {
            return 0;
        }

        return main.ID;
    }
}

MasterEvent.compare = function (m1, m2)
{
    var tt;
    if ((tt = Data.compare(m1.MasterEventDate, m2.MasterEventDate)) != 0) return tt;
    if ((tt = Data.compare(m1.ID, m2.ID)) != 0) return tt;

    return 0;
}

window.MasterEvent = MasterEvent;