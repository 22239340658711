﻿/* exported MobileLeftPanel */
function MobileLeftPanel(config)
{
    'use strict';
    MobileSidePanel.call(this, config);
    this.Position = PanelPosition.LEFT;
}

$.extend(MobileLeftPanel.prototype, MobileSidePanel.prototype);

MobileLeftPanel.EmptyPanel = new MobileLeftPanel({id: 'panel-left-empty-panel'});
window.MobileLeftPanel = MobileLeftPanel;
