﻿Date.prototype.addHours = function (h)
{
    this.setTime(this.getTime() + (h * 60 * 60 * 1000));
    return this;
};

Date.prototype.getDateSuffix = function ()
{
    var d = this.getUTCDate();
    var s = $string('General').ShortFourth;
    if (d === 1 || d == 21 || d == 31) s = $string('General').ShortFirst;
    if (d === 2 || d == 22) s = $string('General').ShortSecond;
    if (d === 3 || d == 23) s = $string('General').ShortThird;

    return s;
};

Date.prototype.isToday = function ()
{
    // This method takes into consideration the client's settings' timezone
    var currentDate = Data.getLocalTime(timeControl.getServerTime());
    var thisDate = Data.getLocalTime(this);

    return currentDate.getFullYear() === thisDate.getFullYear()
        && currentDate.getDate() === thisDate.getDate()
        && currentDate.getMonth() === thisDate.getMonth();
};

Date.prototype.isTomorrow = function ()
{
    var tomorrowDate = Data.getLocalTime(timeControl.getServerTime());
    tomorrowDate.setDate(tomorrowDate.getDate() + 1);
    var thisDate = Data.getLocalTime(this);

    return tomorrowDate.getFullYear() === thisDate.getFullYear()
        && tomorrowDate.getDate() === thisDate.getDate()
        && tomorrowDate.getMonth() === thisDate.getMonth();
};