﻿// Base object for all responive blocks' templates. Every template is for a specific device.

var ResponsiveHTMLTemplate = (function () {
    return {
        AllHTMLTemplates: {},
        registerHTMLTemplate: function (template)
        {
            // every template is unique by name, device and version
            var devices = Object.keys(template.forDevice).where(function (d) { return template.forDevice[d]; });

            if (devices.length === 0)
            {
                alert('Not properly specified device for template ' + template.name + ', version ' + template.version);
            }

            for (var i = 0; i < devices.length; i += 1)
            {
                this.AllHTMLTemplates[template.prefixes[devices[i]] + template.name + '_' + template.version] = template;
            }
        },
        base: {
            version: 'v1.0',
            forDevice: {
                IsDefault: false,
                IsDesktop: false,
                IsMobile: false,
                IsTablet: false
            },
            prefixes: {
                IsDefault: 'TDefault',
                IsDesktop: 'TDesktop',
                IsMobile: 'TMobile',
                IsTablet: 'TTablet'
            },
            getTemplate: function (name, deviceType, version)
            {
                version = version || this.version;

                var template,
                    currentDeviceType = Application.deviceType.get(),
                    defaultTemplate = ResponsiveHTMLTemplate.AllHTMLTemplates[this.prefixes['IsDefault'] + name + '_' + version],
                    currentDeviceTemplate = ResponsiveHTMLTemplate.AllHTMLTemplates[this.prefixes[currentDeviceType] + name + '_' + version];
                
                // If there is a specified device type in the function's parameters we try to find it, otherwise we get the current device template
                if (deviceType)
                {
                    template = ResponsiveHTMLTemplate.AllHTMLTemplates[this.prefixes[deviceType] + name + '_' + version];
                }
                else
                {
                    template = currentDeviceTemplate || defaultTemplate;
                }

                if (!template && version > 'v1.0')
                {
                    var prevVersion = 'v' + (version.substring(1) - 1) + '.0';
                    template = this.getTemplate(name, deviceType, prevVersion);
                }

                return template;
            }
        }
    };
}());

window.ResponsiveHTMLTemplate = ResponsiveHTMLTemplate;