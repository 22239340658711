/*********************************************************************************************************************************************\
/*
/* Event
/*
/**/

function LiveEventInfo(initArray)
{
    BaseEventInfo.call(this, initArray);         
    this.Live = true;
    this.Special = false;
    this.SplitType = 0;
    this.OrderID = 0;
    this.MainLine = null;
    this.Options = [];
    this.Visible = { ML: true, Spread: true, OU: true };
    this.Score1 = 0;
    this.Score2 = 0;
    this.CornerScore1 = "";
    this.CornerScore2 = "";
    this.AdditionalData = [];
    this.GameData = [];

    if (initArray.length > 2 && initArray[2])
        this.updateFromServer(initArray[2]);
}
$.extend(LiveEventInfo.prototype, BaseEventInfo.prototype);

$.extend(LiveEventInfo.prototype,
{
    setVisible: function ()
    {
        this.Visible = { ML: true, Spread: true, OU: true };
    },

    getVisible: function ()
    {
        var has = { ML: false, Spread: false, OU: false };
        for (var key in this.Lines)
        {
            var ln = this.Lines[key];
            has.ML = has.ML || ln.ML;
            has.OU = has.OU || ln.OU;
            has.Spread = has.Spread || ln.Spread;
        }

        return (has.ML && this.Visible.ML) || (has.OU && this.Visible.OU) || (has.Spread && this.Visible.Spread);
    },

    updateFromServer: function (initArray)
    {
        this.LastUpdate = new Date();
        this.Danger = initArray[3] == 1;
        if (initArray[4] != undefined)
        {
            this.Score1 = initArray[4];
            this.Score2 = initArray[5];
        }

        if (typeof initArray[6] !== "undefined" && initArray[6] !== "")
        {
            this.CornerScore1 = initArray[6];
            this.CornerScore2 = initArray[7];
        }

        if (initArray[8] != undefined)
        {
            this.LiveGroupID = initArray[8];
        }

        this.AdditionalData = (typeof initArray[9] !== "undefined") && initArray[9] !== "" ? initArray[9] : [];

        this.HasCombo = false;
        this.Updated = true;

        this.GameData = (typeof initArray[10] !== "undefined") && initArray[10] !== "" ? initArray[10] : [];        

        if ((initArray[2]) && (initArray[2] != -1))
        {
            this.updateLines(HashtableOf(initArray[2], LineGroup));

            this.MainLine = null;
            this.Options = [];

            for (var key in this.Lines)
            {
                var line = this.Lines[key];

                if (line.IsOptional)
                    this.Options.push(line);
                else
                    this.MainLine = line;
            }
        }
        else if (initArray[2] == -1)
        {
            this.Deleted = true;
            return;
        }
        else
        {
            this.__resetUpdated();
        }
    },

    getAllLinesCount: function ()
    {
        var tot = 0;

        for (var key in this.Lines)
        {
            var ln = this.Lines[key];
            if (!ln.hasOdds()) continue;

            tot += ln.hasDraw() ? 3 : 2;
        }

        return tot;
    },

    hasDraw: function ()
    {
        var mainline = Array.find(this.Lines, function (i) { return !i.IsOptional; });
        if (mainline == null) return false;

        return mainline.hasDraw();
    },

    getLinesCount: function (opt)
    {
        var res = 0;

        for (var key in this.Lines)
        {
            var ll = this.Lines[key];
            if (opt && ll.IsOptional)
            {
                res += ll.hasOdds() ? 2 : 0;
                continue;
            }

            if (!opt && !ll.IsOptional)
            {
                return ll.hasDraw() ? 3 : 2;
            }
        }

        return res;
    }

});

window.LiveEventInfo = LiveEventInfo;