﻿var SPBetStatus =
{
    Open: 0,
    Lost: 1,
    Won: 2,
    Draw: 3,
    Cancelled: 4,
    Waiting: 5,
    Withdrawn: 6,
    FirstPlace: 7,
    SecondPlace: 8,
    ThirdPlace: 9,
    FourthPlace: 10,
    FifthPlace: 11,
    SixthPlace: 12,
    Closed: 13,
    PurchasePrepare: 14,
    Declined: 15,
    HalfLost: 16,
    HalfWon: 17,
    CashedOut: 32
};

//******************************************************************************************************************************
function SPOpenBetsSelection(initData, purchase)
{
    this.SelectionID = initData.SelectionID;
    this.StatusID = initData.StatusID;
    this.Odds = initData.Odds;
    this.Points = initData.Points;
    this.Result = initData.Result;
    this.HalfTimeScore = initData.HalfTimeScore;
    this.Link = initData.Link;
    this.Purchase = purchase;
    this.OddStyleID = this.Purchase.OddStyleID;
    this.WinToteID = initData.WinToteID;
    this.PlaceToteID = initData.PlaceToteID;
    this.ExternalEventScheduleID = initData.ExternalEventScheduleID;
    this.ExternalEventID = initData.ExternalEventID;
    this.CombinatorGroup = initData.CombinatorGroup;
}

$.extend(SPOpenBetsSelection.prototype,
{
    updateFromStoredObject: function (selObj)
    {
        $.extend(this, selObj);
        /*globals specialsID */// SplitTypes.js global
        this.SplitType = specialsID[this.EventTypeID];

        if (this.SplitType == undefined)
        {
            this.SplitType = this.SplitTypeID;
        }
        var date = Date.fromISO(selObj.EventDate);
		this.EventDate = Data.getLocalTime(Date.fromISO(selObj.EventDate));

		if (selObj.SettlementDate)
		{
			this.SettlementDate = Data.getLocalTime(Date.fromISO(selObj.SettlementDate));
		}

        if (this.IsLive && this.BranchID == 6)
        {
            var score = new TennisScore(this.Score1.toString(), this.Score2.toString());
            this.Score1 = score.ScoreSetsPlayer1;
            this.Score2 = score.ScoreSetsPlayer2;
        }

        // for compatibility with betslip selections for edit my bet feature
        this.BetType = this.IsRegular ? 1 : 7;
    },

    getEventName: function ()
    {
        if (this.IsRegular)
            return this.getRegularEventName();
        else
            return this.getQAEventName();

    },

    getRegularEventName: function ()
    {
        if (this.IsTeamSwapEnabled)
            return [this.Team2Name, this.IsTeamSwapEnabled ? $dict.ob("swapAt") : $string("General").VS, this.Team1Name].join(" ");
        else
            return [this.Team1Name, $string("General").VS, this.Team2Name].join(" ");
    },

    getQAEventName: function ()
    {
        if ((this.BranchID == constHorseRacingBranchID || this.BranchID == constGreyHoundRacingBranchID) && (this.LineTypeID != 18 && this.LineTypeID != 19))
        {
            var date = this.EventDate;
            date = (this.BranchID == constHorseRacingBranchID && UseAustralianHorseRacingView) ? Data.getLocalTime(date) : Data.getDateForHR(date);
            return this.LeagueName + ": " + date.toStringEx("HH:mm");
        }

        if (this.BranchID == constHorseRacingBranchID && (this.LineTypeID == 22 || this.LineTypeID == 24 || this.LineTypeID == 26 || this.LineTypeID == 28))
            return this.LineTypeName;

        if (this.Team1Name && this.Team2Name)
            return this.getRegularEventName();

        if (this.EventName)
            return this.EventName;

        return this.EventTypeName;
    },

    getOdds: function (noAbbrev, betTypeID, altOddStyle)
    {
        if (Odds.showDecimalInsteadOfAsian(betTypeID, this.EventTypeID, this.LineTypeID, this.BranchID, true))
        {
            if (altOddStyle != OddStyle.AMERICAN && altOddStyle != OddStyle.EUROPEAN && altOddStyle != OddStyle.FRACTIONAL && 
                !(altOddStyle == OddStyle.HONGKONG && UseHKOddsStyleForAllMarkets))
            {
                altOddStyle = OddStyle.EUROPEAN;
            }
        }

        var oddStyle = altOddStyle;
        if (oddStyle == undefined)
        {
            oddStyle = this.OddStyleID;
        }

        // Abbreviation is shown for the asian sites since there may be different oddstyles shown despite the user's current one
        var formattedOdds = Odds.convertFormat(this.Odds, this.EventTypeID, this.LineTypeID, true, oddStyle, betTypeID, this.BranchID, this.FractionalOdds);

        if (!noAbbrev)
        {
            var abbrev = Odds.getStyleAbbrev(oddStyle, betTypeID, this.EventTypeID, this.LineTypeID, this.BranchID, isAsianView);
            formattedOdds += abbrev;
        }

        if (Lines.isTypeSP(this.LineTypeID) && isRacingBranch(this.BranchID))
        {
            formattedOdds = $dict.ob("SP");
        }

        return formattedOdds;
    },

    getScore: function ()
    {
        if (!this.IsLive) return "";

        return this.getFormatedScore("{0}:{1}");
    },

    getFormatedScore: function(format)
    {
        var scoreFormat = format || "{0}:{1}";

        var score1Pos = !this.IsTeamSwapEnabled ? this.Score1 : this.Score2;
        var score2Pos = !this.IsTeamSwapEnabled ? this.Score2 : this.Score1;

        return scoreFormat.format(score1Pos, score2Pos);
    },

    getGameScores: function ()
    {
        return { FullTimeScore: this.Result, HalfTimeScore: this.HalfTimeScore };
    },

    getStatus: function ()
    {   //Statuses are the same for selections & bets
        if (this.StatusID == SPBetStatus.Lost && this.EachWayIncluded)
        {   //If main selection is Lost, but E/W Selection is Won, then we must show Placed
            var ref = this;
            var ewLine = this.Purchase.Selections.find(function (sel) { return sel && sel.RelatedToID && sel.RelatedToID == ref.LineID && sel.EventID == ref.EventID && sel.IsEachWaySelection; });
            if (ewLine && ewLine.StatusID == SPBetStatus.Won)
            {
                return $dict.ob("Placed");
            }
        }
        return SPOpenBetsBet.prototype.getStatus.call(this);
    },

    getPick: function ()
    {
        if (!this.IsRegular)
        {   //QA
            return this.LineName;
        }

        if (this.LineTypeID == 1)
        {   //ML
            switch (this.RowTypeID)
            {
                case 1:
                    return this.Team1Name;
                case 2:
                    return $dict.ob("Tie");
                case 3:
                    return this.Team2Name;
            }
        }
        else if (this.LineTypeID == 2)
        {   //HC
            switch (this.RowTypeID)
            {
                case 1:
                    return this.Team1Name;
                case 3:
                    return this.Team2Name;
            }
        }
        else
        {   //OU
            var ouText = (this.RowTypeID == 1 ? $dict.ob("Over") : $dict.ob("Under"));

            switch (this.RowTypeID)
            {
                case 1:
                    return $dict.ob("Over");
                case 3:
                    return $dict.ob("Under");
            }
        }
    },

    getQAPickName: function ()
    {
        return this.LineName;
    },

    getEventTypeName: function (betTypeID)
    {
        if (isFastMarket(this.SplitTypeID) || isTennisFastMarket(this.SplitTypeID))
        {
            var fastMarket = SplitTypeFactory.Create(this.SplitTypeID);
            var eventTypeName = fastMarket.buildEventNameForBet(this.LineTypeName, this.EventTypeName, this);

            return eventTypeName;
        }


        if (this.EventTypeID == RacingEventTypes.RaceCard)
        {
            var bettype = "";

            if (this.LineTypeID == 19 || this.LineTypeID == 18)
            {
                bettype = $string("General").Antepost + " ";
            }

            bettype += $string("BettingHistory").Win;
            return bettype;
        }

        if (this.EventTypeID === RacingEventTypes.AUHRTote)
            return "Win";
        if (this.EventTypeID === RacingEventTypes.AUHRTotePlaceOnly)
            return "Place";

        var isOutright = !this.MasterEventID;
        var toReturn = (this.UseEventName && !isOutright) ? this.EventName : this.EventTypeName;

        if (this.SplitType != 3 && this.SplitType != 4 && this.SplitType != 9
            && this.LineTypeName != null && this.LineTypeName.toLowerCase() != this.EventTypeName.toLowerCase() && this.LineTypeName.indexOf($string("shoppingCart").LineType) == -1)
            toReturn = '{0} {1}'.format(this.LineTypeName, this.EventTypeName);
        
        if (this.EventTypeID == 158)
        {
            toReturn = this.EventTypeName;
        }

        if ((this.EventTypeID == 0 || this.EventTypeID == 1 || this.EventTypeID == 39) && this.LineTypeName != this.EventTypeName)
            toReturn = '{0} {1}'.format(this.LineTypeName, this.EventTypeName);

        if (this.IsLive && (this.EventTypeID != 39))
        {
            toReturn = toReturn + " " + $dict.ob("LiveBetting");
        }

        if (VirtualSports.isBranchVirtual(this.BranchID) && !this.IsRegular)
        {
            if (Lines.isTypeEW(this.LineTypeID))
            {
                toReturn = "{0} ({1})".format(Bets.getPlaceString(this.PlaceTermsID), $dict.ob("Virtual"));
            }
            else if (VirtualSports.isVirtualSportsRacing(this.BranchID, this.EventTypeID))
            {
                toReturn = "{0} ({1})".format($dict.ob("Winner"), $dict.ob("Virtual"));
            }
        }

        if (this.EventTypeID == RacingEventTypes.BettingWithoutFav || this.EventTypeID == RacingEventTypes.BettingWithoutTwoFav ||
            this.EventTypeID == RacingEventTypes.PlaceOnly || this.EventTypeID == RacingEventTypes.MatchBetting)
        {
            toReturn = "{0} - {1}".format(this.EventTypeName, this.LineTypeName);
        }

        return toReturn;
    },

    getEvent: function (betTypeId)
    {
        var toReturn;

        if (this.isAggregatedEvent())
        {
            return this.getAggregatedEventName();
        }

        if ((betTypeId == 7 && this.RowTypeID == 0 && this.LineTypeID == 4) ||
             (this.Team1Name == undefined || this.Team2Name == undefined)) // to avoid underfined vs undefined
        {
            toReturn = typeof this.EventName !== 'undefined' ?
                this.EventName : this.LeagueName + " - " + this.LineTypeName;
        }
        else
        {
            var team1Pos = !this.IsTeamSwapEnabled ? this.Team1Name : this.Team2Name;
            var team2Pos = !this.IsTeamSwapEnabled ? this.Team2Name : this.Team1Name;
            var teamVSText = this.IsTeamSwapEnabled ? $dict.ob("swapAt") : $string("General").VS;
            toReturn = [team1Pos, teamVSText, team2Pos].join(" ");
        }

        if (this.IsLive)
        {
            var score1Pos = !this.IsTeamSwapEnabled ? this.Score1 : this.Score2;
            var score2Pos = !this.IsTeamSwapEnabled ? this.Score2 : this.Score1;
            toReturn = toReturn + ' [' + score1Pos + ':' + score2Pos + ']';
        }

        if (!this.IsRegular)
        {
            //QA
            if (this.QAEventName != null && this.QAEventName.length > 0)
            {
                toReturn = '{0} - {1}'.format(toReturn, this.QAEventName);
            }

            if ((this.BranchID == constHorseRacingBranchID || this.BranchID == constGreyHoundRacingBranchID) && (this.LineTypeID != 18 && this.LineTypeID != 19))
            {
                var date = this.EventDate;
                date = (this.BranchID == constHorseRacingBranchID && UseAustralianHorseRacingView) ? date : Data.getDateForHR(date);
                return this.LeagueName + ": " + date.toStringEx("HH:mm");
            }
            if (toReturn.indexOf('undefined', 0) != -1)
            {
                toReturn = this.LeagueName;
            }

            if (BetTypes.IsForeCast(betTypeId) || BetTypes.IsTriCast(betTypeId) || BetTypes.IsFirstFour(betTypeId) || VirtualSports.isVirtualSportsRacing(this.BranchID, this.EventTypeID))
            {
                toReturn = this.EventName;
            }

            if (VirtualSports.isVirtualSportsRacing(this.BranchID, this.EventTypeID))
            {
                toReturn = '{0} - {1}'.format(this.LeagueName, this.EventName.trim());
            }
        }

        return toReturn;
    },

    isAggregatedEvent: function()
    {
        return typeof eBranches.SoccerAggregates !== "undefined" && this.BranchID === eBranches.SoccerAggregates;
    },

    getAggregatedEventName: function()
    {
        var eventName = typeof this.EventName !== 'undefined' ?
            this.EventName : 
            this.LeagueName + " - " + this.LineTypeName;

        if (this.IsLive)
        { 
            eventName = eventName + this.getFormatedScore(' [{0}:{1}]');
        }

        return eventName;
    },

    getEventDescription: function ()
    {
        if (VirtualSports.isBranchVirtual(this.BranchID))
        {
            return "{0}: {1}".format($dict.ob("MatchId"), this.EventID);
        }

        return "";
    },

    getYourBet: function ()
    {
        if (this.YourBetOverride)
        {
            return this.YourBetOverride;
        }

        /* global isTennisFastMarket */
        if (isTennisFastMarket(this.SplitTypeID))
        {
            /* global SplitTypeFactory */
            var fastMarket = SplitTypeFactory.Create(this.SplitTypeID);
            var betPickedLine = fastMarket.buildPickLine(this);
            return betPickedLine;
        }

        if (!this.IsEachWaySelection && this.BranchID == constHorseRacingBranchID)
        {
            return this.LineName;
        }

        if (this.Points)
        {
            var isOver = this.IsRegular && this.LineTypeID == 3 && this.RowTypeID == 1;
            var isUnder = this.IsRegular && this.LineTypeID == 3 && this.RowTypeID == 3;
            var teamNameToInclude = "";
            if (this.LineTypeID == 3 && this.SplitTypeID == 6 && this.TeamMappingID)
            {
                teamNameToInclude = ([, this.Team1Name, this.Team2Name])[this.TeamMappingID] + " ";
            }
            return teamNameToInclude + this.getPick() + " " + Odds.formatPoints(this.Points, isOver, isUnder, undefined, disableAsianStyleForBetSlip);
        }

        var sel = $.extend({}, this);
        sel.BetTypeID = this.LineTypeID;
        sel.BetSide = this.RowTypeID;
        sel.QAParameter2 = this.QPar2;
        sel.SplitType = this.SplitTypeID;

        var helper = SelectionHelper.create(sel);
        return helper.getPickLine(false, 0);
    },

    getYourPulseBet: function (betTypeID)
    {
        if (this.YourBetOverride)
        {
            return this.YourBetOverride;
        }

        var sel = $.extend({}, this);
        sel.BetTypeID = betTypeID;
        sel.SplitType = this.SplitTypeID;
        sel.QAParameter1 = this.QPar1;
        sel.QAParameter2 = this.QPar2;

        var helper = SelectionHelper.create(sel);
        return helper.getPulseBetPickLine();
    },

    getPoints: function ()
    {
        var pts = "";

        if (this.LineTypeID == 2 || this.LineTypeID == 3 || this.Points)
        {
            pts = this.Points;
        }

        return pts;
    },


    getID: function ()
    {
        return this.SelectionID + 1;
    },

    isToWin: function (betTypeID)
    {   // Is the bet placed as a "to win" bet, i.e. the customer had entered how much they wanted to win
        // instead of stake. This is done when the bet was placed with a negative Malay or Indo odd.
        var currentStyleOdds = Odds.convertFormat(this.Odds, this.EventTypeID, this.LineTypeID, true, this.OddStyleID, betTypeID, this.BranchID, this.FractionalOdds)
        isCurrentOddAsian = (currentOddStyle == OddStyle.MALAY || currentOddStyle == OddStyle.INDO || (IsNegativeAmericanLikeAsian && currentOddStyle == OddStyle.AMERICAN)),
        isBetOddAsian = (this.OddStyleID == OddStyle.MALAY || this.OddStyleID == OddStyle.INDO || (IsNegativeAmericanLikeAsian && this.OddStyleID == OddStyle.AMERICAN));

        return (isBetOddAsian && this.ClientOdds < 0) || (!this.ClientOdds && isCurrentOddAsian && currentStyleOdds < 0);
    },

    getResult: function ()
    {
        var result = this.Result;
        if (this.IsTeamSwapEnabled)
        {
            result = result.split(":").reverse().join(":");
        }

        return result;
    },

    serialize: function ()
    {
        // This function is empty but is used from the serialization of the SPCashoutPurchase
    },

    compareCombinatorGroup: function (secondSelection, isDescending)
    {
        return Data.compareByCombinatorGroup(this.CombinatorGroup, secondSelection.CombinatorGroup, isDescending);
    }
});

//******************************************************************************************************************************
function SPOpenBetsBet(initData, purchase)
{
    this.BetID = initData.BetID;
    this.GeneratedID = initData.GeneratedID;
    this.StatusID = initData.StatusID;
    this.CurrentBalance = initData.CurrentBalance;
    this.DeadHeatDeposit = initData.DeadHeatDeposit;
    this.Commission = initData.Commission || 0;
    this.ProfitLoss = initData.ProfitLoss;
    this.PercentDeduction = initData.PercentDeduction;

    //Cashout start
    this.UIElementId = "mybets-b-{0}-{1}".format(purchase.PurchaseID, this.BetID);
    this.CashOutButtonAmountUIElementId = this.UIElementId + "-co-amount";
    this.CashOutTaxInfoUIElementId = this.UIElementId + "-co-taxinfo";
    this.CashOutButtonPercentageUIElementId = this.UIElementId + "-co-percentage";
    this.HasCashOutDangerUIElementId = this.UIElementId + "-co-danger";
    this.ErrorUIElementId = this.UIElementId + "-err";

    if (initData.CashOutDate)
    {
        this.CashOutDate = Data.getLocalTime(Date.fromISO(initData.CashOutDate));
    }

    this.IsPartialCashOut = initData.IsPartialCashOut || 0;

    this.CashOutData = null;
    this.CashOutStatus = null;
    this.NewOfferCashOutAmount = null;
    this.CashOutConfirmationState = false;
    this.CashOutConfirmationTimeoutID = null;
    this.CashOutConfirmationTimeOut = 0;
    this.CashOutConfirmationErrorTimeOutId = null;
    this.LastPartialCashoutAmount = 0;
    this.CurrentCashoutAmount = 0;
    this.CashoutSlider = null;

    this.CashoutStatusChanged = [];
    this.CashoutError = [];
    this.CashOutAvailabilityChanged = [];
    this.CashOutAmountChanged = [];
    this.CashOutDangerStatusChanged = [];
    this.PartialCashOutAvailabilityChanged = [];
    this.CashoutConfirmationStateChanged = [];

    //Cashout end

    this.Purchase = purchase;
    this.OddStyleID = purchase.OddStyleID;
    this.Selections = [];
    this.ComboPromotion = null;
    this.TaxPercent = initData.TaxPercent;
    this.TaxAmount = initData.TaxAmount;
}

$.extend(SPOpenBetsBet.prototype,
{
    updateFromStoredObject: function (betObj)
    {
        this.Mappings = betObj.Mappings;
        this.ClientOdds = betObj.OddsFrCl;
        this.NumberOfBets = betObj.NumOfBets;
        this.BetSubType = betObj.BSubType;
        this.BetTypeID = betObj.BType;
        this.ComboSize = betObj.Comb;
        this.BetName = betObj.BName;
        this.IsFreeBet = (betObj.FrBetBonusID !== undefined);
        this.FrBetBonusID = betObj.FrBetBonusID;
        this.RelatedToID = betObj.Rel2ID;
        this.IsMultiLine = (BetTypes.IsForeCast(this.BetTypeID) || BetTypes.IsTriCast(this.BetTypeID) || BetTypes.IsFirstFour(this.BetTypeID));
        this.Deposit = betObj.Stake;
        this.Gain = betObj.Gain;
        this.Odds = betObj.Odds;
        this.NativeClientOdds = betObj.NativeOddsFrCl;
        if (betObj.Dividend && betObj.Divisor)
        {
            this.Dividend = betObj.Dividend;
            this.Divisor = betObj.Divisor;
            this.FractionalOdds = "{0}/{1}".format(this.Dividend, this.Divisor);
        }
        else
        {
            this.FractionalOdds = "";
        }

        this.IsRiskFreeBet = (typeof betObj.IsRiskFreeBet != "undefined") ? (!!betObj.IsRiskFreeBet) : false;      
    },

    getIsRiskFreeBet: function ()
    {
        if (this.IsRiskFreeBet) return true;
        if (typeof this.FreeBetRef != "undefined" && this.FreeBetRef && this.FreeBetRef.IsRiskFreeBet) return true;
        return false;
    },
	
    getRiskFreeBetTooltip: function (bet)
    {
        if (!bet) return;

        if (bet.StatusID == SPBetStatus.Lost || bet.StatusID == SPBetStatus.HalfLost)
        {
            return $dict.ob("RiskFreeBetAmount").format(bet.getRiskFreeBetDeduction());
        }
        else if (bet.StatusID == SPBetStatus.Open)
        {
            return $dict.ob("RiskFreeBetAmountLost").format(bet.getRiskFreeBetDeduction());
        }
        return "";
    },

    getRiskFreeBetDeduction: function ()
    {
        if (!this.getIsRiskFreeBet()) return 0;
        if (typeof this.FreeBetRef != "undefined" && this.FreeBetRef && this.FreeBetRef.IsRiskFreeBet)
        {
            return this.FreeBetRef.Deposit;
        }
        return this.Deposit;
    },

    containsSP: function ()
    {
        for (var idx in this.Selections)
        {
            var selection = this.Selections[idx];
            if ((Lines.isTypeSP(selection.LineTypeID)) && (isRacingBranch(selection.BranchID)))
                return true;
        }
        return false;
    },

    getAlternativeOddStyle: function ()
    {
        return this.getOddStyleID();
    },

    getOddStyleID: function (prefferedOddstyle)
    {
        if (this.isComboBet() || Odds.showDecimalInsteadOfAsian(this.BetTypeID, this.Selections[0].EventTypeID, this.Selections[0].LineTypeID, this.Selections[0].BranchID, true))
        {
            if (currentOddStyle == OddStyle.AMERICAN || currentOddStyle == OddStyle.EUROPEAN || currentOddStyle == OddStyle.FRACTIONAL ||
                (currentOddStyle == OddStyle.HONGKONG && UseHKOddsStyleForAllMarkets))
            {
                return currentOddStyle;
            }
            else
            {
                return OddStyle.EUROPEAN;
            }
        }
        return prefferedOddstyle != undefined ? prefferedOddstyle : currentOddStyle;
    },

    getOddsAbbrev: function (outputOddStyle)
    {
        var isCombo = this.isComboBet();

        var branchID = isCombo ? undefined : this.Selections[0].BranchID;
        var lineTypeID = isCombo ? undefined : this.Selections[0].LineTypeID;
        var eventTypeID = isCombo ? undefined : this.Selections[0].EventTypeID;
        var oddStyle = this.getOddStyleID(outputOddStyle);

        return Odds.getStyleAbbrev(oddStyle, this.BetTypeID, eventTypeID, lineTypeID, branchID, isAsianView);
    },

    checkForSPSelection: function()
    {
        return  ((this.StatusID == SPBetStatus.Open) && this.Selections.any(function(sel) {
            return (Lines.isTypeSP(sel.LineTypeID)) && (isRacingBranch(sel.BranchID)) && sel.StatusID != SPBetStatus.Draw && sel.StatusID != SPBetStatus.Cancelled;
        }) && !((BetTypes.IsForeCast(this.BetTypeID) || BetTypes.IsTriCast(this.BetTypeID) || BetTypes.IsFirstFour(this.BetTypeID))));
    },

    getOdds: function (noAbbrev, oddsToShow, outputOddStyle)
    {
        var isCombo = this.isComboBet();
        var isSystem = this.isSystemBet();
        var isCombinator = this.isCombinatorBet();

        if (BetTypes.IsYourBet(this.BetTypeID))
        {
            var yourbetSelectionOdds = this.Selections[0] && this.Selections[0].Odds * 1;
            return Odds.convertFormat(yourbetSelectionOdds, null, null, true, this.getOddStyleID(outputOddStyle), this.BetTypeID, null, null);
        }

        // SB-23874 - do not show total permutation odds
        if ((isCombo && this.NumberOfBets > 1 && !this.IsEachWay) || isSystem || isCombinator) return "";

        var odds = oddsToShow ? oddsToShow : this.Odds;

        if (this.IsMultiLine && odds == 0)
        {
            return $dict.ob("NotAvailable");
        }

        var firstSelection = this.Selections[0];
        var eventTypeID = isCombo ? undefined : firstSelection.EventTypeID;

        if (this.containsSP())
        {
            var result = $dict.ob("SP");
            if (UseAustralianHorseRacingView)
            {
                var toteTypeId;
                if (firstSelection.EventTypeID === RacingEventTypes.AUHRTote)
                    toteTypeId = firstSelection.WinToteID;
                else if (firstSelection.EventTypeID === RacingEventTypes.AUHRTotePlaceOnly)
                    toteTypeId = firstSelection.PlaceToteID;
                switch (toteTypeId)
                {
                    case RacingToteTypes.BestTote:
                        result = $dict.betgui("BestTote");
                        break;
                    case RacingToteTypes.MidTote:
                        result = $dict.betgui("MidTote");
                        break;
                    case RacingToteTypes.BestToteOrSP:
                        result = $dict.betgui("BestToteOrSP");
                        break;
                }
            }
            return result;
        }

        var branchID = isCombo ? undefined : firstSelection.BranchID;
        var lineTypeID = isCombo ? undefined : firstSelection.LineTypeID;
        var oddStyle = this.getOddStyleID(outputOddStyle);

        var formattedOdds = Odds.convertFormat(odds, eventTypeID, lineTypeID, true, oddStyle, this.BetTypeID, branchID, this.FractionalOdds);
        if (!noAbbrev)
        {
            formattedOdds += this.getOddsAbbrev(outputOddStyle);
        }
        return formattedOdds;
    },

    getYourBet: function ()
    {
        if (this.EWBetRef && typeof this.EWBetRef.getYourBet == "function")
        {
            return this.EWBetRef.getYourBet();
        }

        if (this.BetTypeID == BetTypes.PulseBet)
        {
            return this.Selections[0].getYourPulseBet(this.BetTypeID);
        }

        if (!this.IsMultiLine) return this.Selections[0].getYourBet();

        var isStraight = (this.BetSubType == BetSubTypes.Straight);

        var sortedMaps = Array.getValues(this.Mappings);
        sortedMaps.sort(chainSort(
            function (m1, m2)
            {
                if (m1.OrderID > m2.OrderID) return 1;
                if (m1.OrderID < m2.OrderID) return -1;
                return 0;
            }));

        var dictKeys = ["", "FirstPlace", "SecondPlace", "ThirdPlace", "FourthPlace"];
        var yourBet = "";

        for (var idx in sortedMaps)
        {
            var map = sortedMaps[idx];
            var place = $dict.ob(dictKeys[map.OrderID]);

            var selection = Array.find(this.Selections, function (sel) { return sel.SelectionID == map.SelectionID; });
            if (!selection) return "";

            if (yourBet != "") yourBet += ", ";

            yourBet += selection.getPick();

            if (place != "")
            {
                yourBet += " " + place;
            }
        }

        return yourBet;
    },

    getEvent: function ()
    {
        if (this.Selections[0]) return this.Selections[0].getEvent(this.BetTypeID);

        return "";
    },

    getEventTypeName: function ()
    {
        if (!this.IsMultiLine) return this.Selections[0].getEventTypeName(this.BetTypeID);

        var dictKey = "";

        switch (this.BetSubType)
        {
            case BetSubTypes.Straight:
                dictKey = "Straight";
                break;
            case BetSubTypes.Reverse:
                dictKey = "Reverse";
                break;
            case BetSubTypes.Combination:
                dictKey = "Combination";
                break;
        }

        dictKey += (BetTypes.IsFirstFour(this.BetTypeID) ? "FirstFour" : (BetTypes.IsForeCast(this.BetTypeID) ? "Forecast" : "Tricast"));

        var castName = $dict.ob(dictKey);
        if (BetTypes.IsVirtual(this.BetTypeID)) castName += " " + $dict.ob("VirtualBrackets");

        return castName;
    },

    getGain: function (isPossibleReturn, skipTaxing)
    {
        var gain = 0;

        if (this.Selections.any(function (sel) { return sel.EventTypeID === RacingEventTypes.AUHRTote || sel.EventTypeID === RacingEventTypes.AUHRTotePlaceOnly; }))
        {
            return $dict.ob("NotAvailable");
        }

        if (this.StatusID == SPBetStatus.CashedOut)
        {
            var currentBalance = this.CurrentBalance;
            var taxesOnReturn = UserInfo.TaxProvider.getTaxAmountAfterPlacing(this, currentBalance);

            if (!skipTaxing && taxesOnReturn)
            {
                currentBalance = currentBalance - taxesOnReturn;
            }

            return this.formatGain(currentBalance);
        }

        if (this.checkForSPSelection())
        {
            return $dict.ob("NotAvailable");
        }

        if (this.StatusID == SPBetStatus.Open || isPossibleReturn)
        {
            var gaintemp = this.Gain;
          
            // If we are taxed with Polish tax on stake ( Turnover tax )
            if (UserInfo.TaxProvider.isTurnoverTaxEnabledAfterPlacing(this.TaxInfo))
            {
                gaintemp = this.applyTaxLogicForTurnOverAndReturnTaxProvider();
            }           

            if (!this.IsEachWay)
            {
                if (this.HasFreeBetContribution)
                {
                    gaintemp += this.FreeBetGain;
                }

                if (this.ComboPromotion && !this.IsFreeBet)
                {
                    var extraWinning = this.getExtraWinningFromComboBonus();
                    gaintemp = gaintemp + extraWinning;
                }

                if (this.IsFreeBet && !this.IsRiskFreeBet)
                {
                    gaintemp -= this.Deposit;
                }

                if (typeof this.FreeBetRef != "undefined" && this.FreeBetRef && this.FreeBetRef.IsRiskFreeBet)
                {
                    gaintemp += this.FreeBetRef.Deposit;
                }
            }
            else if (this.NormalBetRef && this.NormalBetRef.FreeBetContribution)
            {
              	gaintemp -= this.NormalBetRef.FreeBetContribution;
            }

            var taxesOnReturn = UserInfo.TaxProvider.getTaxAmountAfterPlacing(this, gaintemp);
            if (!skipTaxing && taxesOnReturn)
            {
                gaintemp = gaintemp - taxesOnReturn;
            }

            // Round the Gain as it is done on the betslip
            gain = this.isToWin() ? Math.round((gaintemp * 100).toFixed(4)) / 100 : GainUtil.round(gaintemp, this.getRoundingMode());
        }
        else
        {
            // When status is Betting History
            var currentBalance = this.StatusID === SPBetStatus.Draw || this.StatusID === SPBetStatus.Cancelled ? this.getCurrentBalanceForDraw() : this.CurrentBalance;
            if (this.getIsRiskFreeBet())
            {
                currentBalance += this.getRiskFreeBetDeduction();
            }

            if (this.ComboPromotion)
            {
                var extraWinning = this.getExtraWinningFromComboBonus();
                currentBalance = currentBalance + extraWinning;
            }

            var taxesOnReturn = currentBalance && this.StatusID != SPBetStatus.Cancelled ?
                UserInfo.TaxProvider.getTaxAmountAfterPlacing(this, currentBalance) : 0.00;

            if (!skipTaxing && taxesOnReturn)
            {
                currentBalance = currentBalance - taxesOnReturn;
            }

            return this.formatGain(currentBalance);
        }

        if (gain <= 0)
            return $dict.ob("NotAvailable");

        //return gain.toStringEx(constTotalMoneyFormat);
        return this.formatGain(gain);
    },

    applyTaxLogicForTurnOverAndReturnTaxProvider: function()
    {
        var deposit = UserInfo.TaxProvider.applyTaxToDeposit(this.Deposit);
        var ewOdds = this.EWOdds ? this.EWOdds : 0;
        ewOdds = this.EWBetRef ? this.EWBetRef.Odds : ewOdds; // when bet is of type SPMyBetsBet
        var ewGain = Odds.convertFromAmericanToDecimal(ewOdds, true) * deposit;
        var freeBetAmount = this.EWOdds && this.NormalBetRef && this.NormalBetRef.FreeBetContribution ? this.NormalBetRef.FreeBetContribution : 0;
        var gain = (Odds.convertFromAmericanToDecimal(this.Odds, true).toFixed(2) * deposit) + ewGain + freeBetAmount;
        return gain;
    },

    getTotalStake: function (addCurrencyCode)
    {
        addCurrencyCode = typeof addCurrencyCode === "undefined" || addCurrencyCode;
        var tStake = this.getTotalStakeAmount(); 

        //return tStake.toStringEx(constTotalMoneyFormat);
        var result = MoneyFormat.format(tStake);
        if (addCurrencyCode)
        {
            var currencyCode = this.Purchase.CurrencyCode;
            result = AppendCurrencyCodeWhenEnabled(currencyCode, result);
        }        

        return result;
    },

    getTotalStakeAmount: function ()
    {
        var tStake = 0;
        var isEw = typeof this.NormalBetRef != 'undefined';
        var freeBetContribution = 0;
        if (!this.IsFreeBet)
        {
            if (this.HasFreeBetContribution)
            {
                freeBetContribution = this.FreeBetContribution;
            }
            else if (this.NormalBetRef && this.NormalBetRef.HasFreeBetContribution)
            {
                freeBetContribution = this.NormalBetRef.FreeBetContribution;
            }
        }
        if (freeBetContribution)
        {
            if (isEw)
            {
                tStake = this.NormalBetRef.Deposit + freeBetContribution + this.Deposit;
            }
            else
            {
                tStake = this.Deposit + freeBetContribution;
            }
        }
        else
        {
            tStake = this.Deposit * this.NumberOfBets;
        }

        if (this.isToWin())
        {
            tStake = Math.round((tStake * 100).toFixed(4)) / 100;
        }

        return tStake;
    },

    getFormatedTotalStakeAfterTax: function ()
    {
        return UserInfo.current.toUserFormatedCurrencyString(UserInfo.TaxProvider.applyTaxToDeposit(this.getTotalStakeAmount()));
    },

    getFormatedTaxAmount: function (taxAmount)
    {
        return UserInfo.current.toUserFormatedCurrencyString(taxAmount);
    },

    getFreeBetContributionAmount: function ()
    {
        var freeBetContribution = 0;
        if (!this.IsFreeBet)
        {
            if (this.HasFreeBetContribution)
            {
                var freeBetContribution = this.FreeBetContribution;
            }
            else if (this.NormalBetRef && this.NormalBetRef.HasFreeBetContribution)
            {
                var freeBetContribution = this.NormalBetRef.FreeBetContribution;
            }
        }
        if (freeBetContribution)
        {
            tStake = freeBetContribution;
        }
        else
        {
            tStake = this.Deposit;
        }

        if (this.isToWin())
            tStake = Math.round((tStake * 100).toFixed(4)) / 100;

        return tStake;
    },

    getFreeBetContribution: function ()
    {
        return MoneyFormat.format(this.getFreeBetContributionAmount());
    },

    getStakeBreakdown: function (currencyFormat)
    {
        var betsText = this.NumberOfBets > 1 ? $dict.ob("ComboNamesBets") : $dict.ob("ComboNameBet");
        var currencyCode = this.Purchase.CurrencyCode;
        var stakePerBet = this.Deposit;
        currencyFormat = currencyFormat || stakeBreakdownCurrencyFormat;
        var value = AppendCurrencyCodeWhenEnabled(currencyCode, MoneyFormat.format(stakePerBet));

        return ("{0} {1} " + $string("General").x + " {2}").format(this.NumberOfBets, betsText, value);
    },

    isComboBet: function ()
    {
        return (this.BetTypeID == BetTypes.Combo ||
                this.BetTypeID == BetTypes.System ||
                this.BetTypeID == BetTypes.Teaser ||
                this.BetTypeID == BetTypes.VirtualCombo ||
                this.BetTypeID == BetTypes.VirtualSystem ||
                this.BetTypeID == BetTypes.Combinator ||
                this.BetTypeID == BetTypes.VirtualTeaser ||
                this.BetTypeID == BetTypes.YourBet);
    },

    isSystemBet: function()
    {
        this.BetTypeID == BetTypes.System || this.BetTypeID == BetTypes.VirtualSystem;
    },

    isCombinatorBet: function ()
    {
        return this.BetTypeID == BetTypes.Combinator;
    },

    getTicketHeader: function ()
    {
        var result;
        switch (this.BetTypeID)
        {
            case BetTypes.Combo:
            case BetTypes.VirtualCombo:
                result = $dict.ob("ComboNames_" + this.ComboSize);
                break;
            case BetTypes.Teaser:
            case BetTypes.VirtualTeaser:
                result = $dict.ob("Teaser");
                break;
            case BetTypes.System:
            case BetTypes.VirtualSystem:
                result = this.getSystemName();
                break;
            default:
                result = "";
        }

        if (this.BetName)
        {
            result = this.BetName;
        }

        if (this.IsEachWay)
        {
            result += " " + $dict.ob("EachWayTag");
        }

        return result;
    },

    getComboBetTypeName: function ()
    {
        var virtualBetText = BetTypes.IsVirtual(this.BetTypeID) ? " " + $dict.ob("VirtualBrackets") : "";

        switch (this.BetTypeID)
        {
            case BetTypes.Combo:
            case BetTypes.VirtualCombo:
                return (this.NumberOfBets > 1 ? $dict.ob("System") : $dict.ob("Combo")) + virtualBetText;
            case BetTypes.System:
            case BetTypes.VirtualSystem:
                if (UseNewBetsVisualization && this.BetName)
                {
                    return "{0}{1}: {2}".format($dict.ob("System"), virtualBetText, this.BetName);
                }
                else
                {
                    return $dict.ob("System") + virtualBetText;
                }
            case BetTypes.Teaser:
            case BetTypes.VirtualTeaser:
                return $dict.ob("Teaser") + virtualBetText;
            case BetTypes.Combinator:
                return $dict.ob("Combinator");
            case BetTypes.YourBet:
                var yourBetText = this.Purchase.Selections.length - 1;
                return "{0} : {1} {2}".format($dict.ob("YourBet"), yourBetText, $dict.ob("selections") );
            default:
                return "";
        }
    },

    getSystemName: function ()
    {
        if (this.BetName) return this.BetName;
        if (this.WinMastersHistoryBet) return $dict.ob("System");

        var sysKey = this.ComboSize;
        var comboGroups = this.getSelectionGroupKeys();
        var nbrSel = Array.getLength(comboGroups);
        var system = $dict.ob("System");


        if (sysKey < nbrSel)
            return "{0}/{1}".format(sysKey, nbrSel);

        var dictKey = "SystemNames_" + nbrSel;

        return $dict.ob(dictKey + "_" + sysKey);
    },

    getSelectionGroupKeys: function ()
    {
        var selectionGroups = [];
        for (var i in this.Selections)
        {
            var selection = this.Selections[i];
            var groupKey = selection.ComboGroupKey;

            // old purchases does not have ComboGroupKey saved in the db
            if (!groupKey)
            {
                groupKey = selection.SelectionID.toString();
            }

            selectionGroups[groupKey] = groupKey;
        }

        return selectionGroups;
    },

    getGroupedSelections: function ()
    {
        var groupedSelections = [];
        for (var i in this.Selections)
        {
            var selection = this.Selections[i];
            var groupName = selection.CombinatorGroup || "";

            if (!Array.getKeys(groupedSelections).contains(groupName))
            {
                groupedSelections[groupName] = [];
            }

            groupedSelections[groupName].push(selection);
        }

        return groupedSelections;
    },

    getSortedGroupKeys: function (groupedSelections)
    {
        var keys = Object.keys(groupedSelections);
        return keys.sort();
    },

    getStatus: function ()
    {
        //If winner (normal) bet lost but E/W bet won, return status "placed"
        if (this.NormalBetRef && this.NormalBetRef.StatusID == SPBetStatus.Lost && this.StatusID == SPBetStatus.Won)
        {
            return $dict.ob("Placed");
        }

        if (this.EWBetRef && this.EWBetRef.StatusID == SPBetStatus.Won && this.StatusID == SPBetStatus.Lost)
        {
            return $dict.ob("Placed");
        }

        switch (this.StatusID)
        {
            case SPBetStatus.Open:
                return $dict.ob("Open");
            case SPBetStatus.Won:
                return $dict.ob("Won");
            case SPBetStatus.Draw:
                return $dict.ob("Draw");
            case SPBetStatus.Lost:
                return $dict.ob("Lost");
            case SPBetStatus.Closed:
                return $dict.ob("Closed");
            case SPBetStatus.Cancelled:
                return $dict.ob("Cancelled");
            case SPBetStatus.Withdrawn:
                return $dict.ob("Withdrawn");
            case SPBetStatus.HalfLost:
                return $dict.ob("HalfLost");
            case SPBetStatus.HalfWon:
                return $dict.ob("HalfWon");
            case SPBetStatus.Declined:
                return $dict.ob("Rejected");
            case SPBetStatus.CashedOut:
                return this.IsPartialCashOut ? $dict.ob("CashedOutPartially") : $dict.ob("CashedOut");
            default:
                return "Invalid Status";
        }
    },

    isToWin: function ()
    {   // Is the bet placed as a "to win" bet, i.e. the customer had entered how much they wanted to win
        // instead of stake. This is done when the bet was placed with a negative Malay or Indo odd.
        // In HK you are inserting only the stake - and not how much the customer wants to win
        // In MY and INDO
        // You are inserting how much the customer wants to win - only when they are negative
        // For positive-  you are inserting the stake
        var currentStyleOdds = Odds.convertFormat(this.Odds, this.EventTypeID, this.LineTypeID, true, this.OddStyleID, this.BetTypeID, this.BranchID, this.FractionalOdds),
            isCurrentOddAsian = (currentOddStyle == OddStyle.MALAY || currentOddStyle == OddStyle.INDO || (IsNegativeAmericanLikeAsian && currentOddStyle == OddStyle.AMERICAN)),
            isBetOddAsian = (this.OddStyleID == OddStyle.MALAY || this.OddStyleID == OddStyle.INDO || (IsNegativeAmericanLikeAsian && this.OddStyleID == OddStyle.AMERICAN));

        return (isBetOddAsian && this.ClientOdds < 0) || (!this.ClientOdds && isCurrentOddAsian && currentStyleOdds < 0);
    },

    isToWinPlaced: function () {   // Is the bet placed as a "to win" bet, i.e. the customer had entered how much they wanted to win
        // instead of stake. This is done when the bet was placed with a negative Malay or Indo odd.
        // In HK you are inserting only the stake - and not how much the customer wants to win
        // In MY and INDO
        // You are inserting how much the customer wants to win - only when they are negative
        // For positive-  you are inserting the stake
        var currentStyleOdds = Odds.convertFormat(this.Odds, this.EventTypeID, this.LineTypeID, true, this.OddStyleID, this.BetTypeID, this.BranchID, this.FractionalOdds),
            isBetOddAsian = (this.OddStyleID == OddStyle.MALAY || this.OddStyleID == OddStyle.INDO || (IsNegativeAmericanLikeAsian && this.OddStyleID == OddStyle.AMERICAN));

        return (isBetOddAsian && currentStyleOdds < 0);
    },
    getToWinValue: function ()
    {   // Round the "To Win Value" as it is done on the betslip
        var toWin = Math.round(((this.Gain - this.Deposit) * 100).toFixed(4)) / 100;
        //return toWin.toStringEx(constTotalMoneyFormat);
        return MoneyFormat.format(toWin);
    },

    formatGain: function (gain)
    {
        var roundingMode = this.getRoundingMode();
        return GainUtil.format(gain, roundingMode);
    },

    getRoundingMode: function (){
        var roundingMode = this.Purchase 
            && this.Purchase.JSONProperties
            && this.Purchase.JSONProperties.AdditionalData
            && this.Purchase.JSONProperties.AdditionalData.GainRoundingMode
            || 0;
        return roundingMode;
    },

    getGainDirectionClass: function ()
    {
        var gainClass = "";

        if (this.getGain() > 0)
        {
            gainClass = positiveGainClass;
        }
        else if (this.getGain() <= 0)
        {
            gainClass = negativeGainClass;
        }

        return gainClass;
    },

    needApplyRejectedFormatting: function (isRejectedBet)
    {
        return ShowRejectedBets && isRejectedBet;
    },  

    getExtraWinningFromComboBonus: function ()
    {
        var extraWinnings = this.ComboPromotion.AdditionalOdds * UserInfo.TaxProvider.applyTaxToDeposit(this.Deposit);

        return GainUtil.round(extraWinnings, 1); // We always use floor for gainRounding of ComboBonus
    },

    getGainWithComboBonus: function ()
    {
        return this.ComboPromotion.ComboBonusOdds * UserInfo.TaxProvider.applyTaxToDeposit(this.Deposit);
    },

    getFreeBetDepositText: function ()
    {
        var phrase = this.IsRiskFreeBet || (this.FreeBetRef && this.FreeBetRef.IsRiskFreeBet) ? "RiskFreeBetDeposit" : "FreeBetDeposit";

        return '{0}: {1}'.format($dict.ob(phrase), this.FreeBetContribution);
    },
  
	getCurrentBalanceForDraw: function()
    {
      var ref = this.EWBetRef || this.NormalBetRef;
      if(ref)
      {
        return ref.Deposit + this.Deposit;
      }
      
      return this.Deposit;   
    },
  
    //Cashout start
	
    updateCashOutData: function (data)
    {
        var eventArgs;

        var wasAllowed = this.isCashOutAllowed();
        //var previousAmount = this.getCashOutAmount();
        var hadDanger = this.hasCashOutDanger();

        var refreshCashoutSlider = false;
        if ((!!this.CashOutData) != (!!data))
        {
            refreshCashoutSlider = true;
        }
        else if (this.CashOutData && data)
        {
            if (this.CashOutData.MinimalPartialCashoutAmount != data.MinimalPartialCashoutAmount
                || this.CashOutData.Amount != data.Amount)
            {
                refreshCashoutSlider = true;
            }
        }

        this.CashOutData = data;

        // don't update bet's UI if it is in cashed out state,
        // it will be removed in a while
        if (this.CashOutStatus == SPPurchaseStatus.Accepted ||
            this.CashOutStatus == SPPurchaseStatus.Waiting)
        {
            if (this.CashOutData)
            {
                this.CashOutData.IsPartialCashOutAllowed = false;
            }
            if (refreshCashoutSlider)
            {
                executeEvents(this.PartialCashOutAvailabilityChanged, this);
            }
            return false;
        }
        else if (this.CashOutStatus == SPPurchaseStatus.Declined)
        {
            refreshCashoutSlider = true;
            this.lastCashoutAmount = null;

            if (this.CashoutSlider)
            {
                this.CashoutSlider.percent = 100;
            }
        }
        else
        {
            this.lastCashoutAmount = null;
        }

        var isNowAllowed = this.isCashOutAllowed();
        if (this.CashOutConfirmationState)
        {
            eventArgs = { IsConfirmed: true, IsAllowed: isNowAllowed }
            executeEvents(this.CashoutConfirmationStateChanged, this, eventArgs);
        }
        else
        {
            if (wasAllowed != isNowAllowed)
            {
                executeEvents(this.CashOutAvailabilityChanged, this, isNowAllowed);
            }
        }



        //if (previousAmount != this.getCashOutAmount())
        //{
        if (this.isCashOutAllowed())
        {
            eventArgs = {
                Amount: this.getCashOutAmountStr((UserInfo.current ? UserInfo.current.currencyStringFormat : null)),
                Percentage: this.getCashOutPercentageStr()
            }

            executeEvents(this.CashOutAmountChanged, this, eventArgs);
        }
        //}

        var hasNowDanger = this.hasCashOutDanger();
        if (hadDanger != hasNowDanger)
        {
            executeEvents(this.CashOutDangerStatusChanged, this, hasNowDanger);
        }
        if (refreshCashoutSlider)
        {
            executeEvents(this.PartialCashOutAvailabilityChanged, this);
        }
        if (this.CashOutData && this.CashoutSlider)
        {
            if (!this.CashoutSlider.hasText() && this.CashoutSlider.percent >= 99.99 && !this.CashoutSlider.bankMyStakeIsEnabled)
            {
                this.CashoutSlider.setSliderValueText($dict.bs("PartialCashoutMaximum"));
            }
            if (refreshCashoutSlider)
            {
                this.CashoutSlider.setMinMax(this.CashOutData.MinimalPartialCashoutAmount, this.CashOutData.Amount);
                this.updateCashoutSliderText();
            }
        }

        return wasAllowed != isNowAllowed;
    },

    isCashOutAllowed: function ()
    {
        var amount = 0;
        if (this.CashOutData && !!this.CashOutData.IsAllowed)
        {
            amount = (this.CashOutStatus && this.NewOfferCashOutAmount) ?
               this.NewOfferCashOutAmount :
               this.CashOutData.Amount;
        }
        if (amount < 0.01)
        {
            return false;
        }
        return !(this.CashOutData == null) && !!this.CashOutData.IsAllowed;
    },

    isPartialCashoutAllowed: function ()
    {
        return this.CashOutData ? this.CashOutData.IsPartialCashOutAllowed : false;
    },

    getCashOutAmount: function ()
    {
        if (!this.isCashOutAllowed())
        {
            return 0;
        }

        return this.CashOutData.Amount;
    },

    getPartialCashOutAmount: function ()
    {
        if (!this.isPartialCashoutAllowed())
        {
            return 0;
        }

        if (!this.CashoutSlider) return 0;

        var amount = this.CashoutSlider.getRealValue();
        var maxAmount = this.CashOutData.Amount;
        var stakeLeft = maxAmount - amount;

        if (stakeLeft < 0.01)
        {
            return maxAmount;
        }

        if (stakeLeft < this.CashOutData.MinBet)
        {
            var roundingMode = this.getRoundingMode();
            return GainUtil.round(maxAmount - this.CashOutData.MinBet, roundingMode);
        }

        return amount;
    },

    getCashOutAmountStr: function (formatString)
    {

        var amount = 0;
        if (this.lastCashoutAmount && this.CashOutStatus && !this.NewOfferCashOutAmount)
        {
            amount = this.lastCashoutAmount;
        }
        else if (this.LastPartialCashoutAmount && !this.CashOutStatus)
        {
            amount = this.LastPartialCashoutAmount;
        }
        else
        {
            amount = (this.CashOutStatus && this.NewOfferCashOutAmount) ?
            this.NewOfferCashOutAmount :
            this.getCashOutAmount();
        }

        var roundingMode = this.getRoundingMode();
        amount = GainUtil.round(amount, roundingMode);

        if (formatString && formatString !== "")
        {
            return formatString.format(MoneyFormat.format(amount), this.Purchase.CurrencyCode);
        }

        return (IsShowCurrencyCode? this.Purchase.CurrencyCode: '') + MoneyFormat.format(amount);
    },

    getCashOutPercentage: function ()
    {
        if (!this.isCashOutAllowed())
        {
            return 0;
        }

        var percent = 0;
        var stake = this.Deposit;
        var gain = this.Gain;

        if (this.EWBetRef)
        {
            stake += this.EWBetRef.Deposit;
            gain += this.EWBetRef.Gain;
        }

        var cashOutAmount = Math.round((this.CashOutData.Amount * 100).toFixed(4)) / 100;
        if (cashOutAmount > stake)
        {
            percent = 50 + ((cashOutAmount - stake) / (gain - stake) * 50)
        }
        else
        {
            percent = (cashOutAmount / stake) * 50;
        }

        return Math.floor(percent);
    },

    getCashOutPercentageStr: function ()
    {
        var percentage = this.getCashOutPercentage();
        return percentage + "%";
    },

    hasCashOutDanger: function ()
    {
        return this.isCashOutAllowed() && !!this.CashOutData.IsDanger;
    },

    changeStatus: function (status)
    {
        if (this.CashOutStatus == status)
        {
            return false;
        }

        var previousStatus = this.CashOutStatus;
        this.CashOutStatus = status;
        var newStatus = this.CashOutStatus;

        var eventArgs = { PreviousStatus: previousStatus, NewStatus: newStatus };
        executeEvents(this.CashoutStatusChanged, this, eventArgs);

        // if cash out request was declined
        // remove the message and bring back the button after 10 secs.
        if (this.CashOutStatus === SPPurchaseStatus.Declined)
        {
            setTimeout(function ()
            {
                this.cleanErrorInformation();
                this.changeStatus(null);
            }.bind(this), 10000);
        }

        // if cash out request was accepted,
        // the bet should be removed after 10 secs,
        if (this.CashOutStatus === SPPurchaseStatus.Accepted)
        {
            setTimeout(function ()
            {
                this.changeStatus(null);
                SPMyBetsCache.getInstance().forceContentUpdate();
            }.bind(this), 10000);
        }

        return true;
    },

    getHasCashOutClassName: function ()
    {
        return "hasCashout";
    },

    getCashOutStatusText: function ()
    {
        if (SPPurchaseStatus.IsWaiting(this.CashOutStatus))
        {
            return $dict.bs("CashingOut");
        }

        if (this.CashOutStatus == SPPurchaseStatus.Accepted)
        {
            return $dict.bs("CashedOut");
        }

        if (this.CashOutStatus == SPPurchaseStatus.Declined)
        {
            return $dict.bs("CashOutDeclined");
        }

        return "";
    },

    getCashOutStatusType: function ()
    {
        if (SPPurchaseStatus.IsWaiting(this.CashOutStatus))
        {
            return "waiting";
        }

        if (this.CashOutStatus == SPPurchaseStatus.Accepted)
        {
            return "success";
        }

        if (this.CashOutStatus == SPPurchaseStatus.Declined)
        {
            return "error";
        }

        return "";
    },

    canShowCashOutAmountOnButton: function ()
    {
        return this.CashOutStatus == null || this.CashOutStatus == SPPurchaseStatus.Accepted;
    },

    onCashoutSliderChange: function ()
    {
        this.LastPartialCashoutAmount = this.getPartialCashOutAmount();

        var eventArgs = {
            Amount: this.getCashOutAmountStr((UserInfo.current ? UserInfo.current.currencyStringFormat : null)),
            Percentage: this.getCashOutPercentageStr()
        }

        executeEvents(this.CashOutAmountChanged, this, eventArgs);

        this.updateCashoutSliderText();
        if (this.CashoutSlider && this.CashoutSlider.bankMyStakeBtn)
        {
            this.CashoutSlider.bankMyStakeBtn.classList.remove('disabled');
        }
    },

    updateCashoutSliderText: function ()
    {
        if (!this.isPartialCashoutAllowed())
        {
            this.CashoutSlider.setSliderValueText("");
            return;
        }

        var currentAmount = this.getPartialCashOutAmount();
        var maxAmount = this.CashOutData.Amount;

        if (currentAmount == maxAmount)
        {
            this.CashoutSlider.setSliderValueText($dict.bs("PartialCashoutMaximum"));
        }
        else
        {
            var oddsArr = this.CashOutData.OddsData;
            var oddsProduct = 1.0;

            for (var i = oddsArr.length - 1; i >= 0; i--)
            {
                oddsProduct *= Odds.convertFromAmericanToDecimal(oddsArr[i], true);
            }
            var moneyStr = this.getFormattedCurrencyStr((maxAmount - currentAmount) * oddsProduct);
            this.CashoutSlider.setSliderValueText($dict.bs("PartialCashoutLessThanMaximum").format(moneyStr))
        }
    },

    setError: function (errorMessage)
    {
        this.Error = errorMessage;
        executeEvents(this.Purchase.CashoutError, this, this.hasError());
    },

    hasError: function ()
    {
        return this.Error && this.Error.length > 0;
    },

    cleanErrorInformation: function ()
    {
        if (this.Error && this.Error.length > 0)
        {
            this.Error = "";
        }
    },

    getCashOutOnClickMethod: function ()
    {
        return "MyBets.CashOut('{0}', '{1}')".format(this.Purchase.PurchaseID, this.BetID);
    },

    getTurnOverMessageShort: function ()
    {
        //(Subject to 12% tax)
        return UserInfo.TaxProvider.getTurnOverMessage(this.TaxInfo.TurnOverTaxPercent, $dict.ob, "TurnOverMessageShort");        
    },

    getTaxedMessageShort: function ()
    {
        //(12% tax applied)
        return $dict.ob("TaxedPercentMessageShort").format(this.TaxInfo.TaxPercent);
    },

    getTurnOverMessageLong: function ()
    {
        return UserInfo.TaxProvider.getTurnOverMessage(this.TaxInfo.TurnOverTaxPercent, $dict.ob, "TurnOverMessageLong");       
    },

    //Cashout end

    getLoyaltyPointName: function (status, potentialPoint, awardedPoint)
    {
        if (status === SPBetStatus.Open)
        {
            return potentialPoint;
        }

        return awardedPoint;
    }
});

//******************************************************************************************************************************
function SPOpenBetsPurchase(initData)
{
    this.PurchaseID = initData.PurchaseID;
    this.PurchaseDate = Data.getLocalTime(Date.fromISO(initData.PurchaseDate));
    this.CustomerID = initData.CustomerID;
    this.CurrencyCode = initData.CurrencyCode;
    this.CurrentBalance = initData.CurrentBalance;
    this.OddStyleID = initData.OddStyleID;
    this.PurchaseStatus = initData.PurchaseStatus;
    this.WebProviderID = initData.WebProviderID;
    this.TotalGain = 0;
    this.TotalStake = 0;
    this.TotalCommission = 0;
    this.TotalProfitLoss = 0;
    this.ExternalTicketID = initData.ExternalTicketID;
    this.ExternalTimestamp = Data.getLocalTime(Date.fromISO(initData.ExternalTimestamp));
    this.UIElementId = "mybets-p-{0}".format(initData.PurchaseID);

    this.createSelections(initData.Selections);
    this.createBets(initData.Bets);

    // updateFromStoredObject need to be executed first in order to work others functions properly
    this.updateFromStoredObject(initData.JSONProperties);
    // updateFromStoredObject need to be executed first in order to work others functions properly

    this.updateBetsWithTaxes();
    this.updateBetsWithComboPromotion(initData.ComboBonuses);
    this.LoyaltyPointsPromotions = initData.LoyaltyPointsPromotions;
    this.initTotalGainAndStake();
}

$.extend(SPOpenBetsPurchase.prototype,
{
    updateBetsWithTaxes: function ()
    {
        var betsObject = this.JSONProperties.Bets;
        var taxes = this.JSONProperties.Taxes;

        if (!taxes || taxes.length == 0)
        {
            return;
        }

        var providerName = UserInfo.TaxProvider.TaxInfo ? UserInfo.TaxProvider.TaxInfo.ProviderName : "";
        var tresholdAmount = UserInfo.TaxProvider.TaxInfo && UserInfo.TaxProvider.TaxInfo.TresholdAmount ?
            UserInfo.TaxProvider.TaxInfo.TresholdAmount : 0;

        for (var bIdx in this.Bets)
        {
            var ref = this;
            var purchaseBet = Array.find(betsObject, function (b) { return b.bID == ref.Bets[bIdx].GeneratedID });
            var turnOverTaxPercent = Array.find(taxes, function (e) { return e.TypeID === 6 && e.GeneratedID == purchaseBet.bID && e.Rate != 0 });
            var turnOverTaxAmount = Array.find(taxes, function (e) { return e.TypeID === 6 && e.GeneratedID == purchaseBet.bID && e.Amount != 0 });
            var treshHoldTaxAmount = Array.find(taxes, function (e) { return e.TypeID === 5 && e.GeneratedID == purchaseBet.bID && e.Amount != 0 });
            var taxInfo =
            {
                TaxPercent: this.Bets[bIdx].TaxPercent,
                TaxAmount: this.Bets[bIdx].TaxAmount,
                TurnOverTaxPercent: turnOverTaxPercent ? turnOverTaxPercent.Rate : 0,
                TurnOverTaxAmount: turnOverTaxAmount ? turnOverTaxAmount.Amount : 0,
                TreshHoldTaxAmount: treshHoldTaxAmount ? treshHoldTaxAmount.Amount : 0,
                ProviderName: providerName,
                TresholdAmount: tresholdAmount
            }

            this.Bets[bIdx].TaxInfo = taxInfo;
            this.Bets[bIdx].Taxes = Array.filter(taxes, function (e) { return e.GeneratedID == purchaseBet.bID });

            // need to apply tax and for EW because it is deep copy object
            if (this.Bets[bIdx].CombinedEWBetRef)
            {
                this.Bets[bIdx].CombinedEWBetRef.TaxInfo = taxInfo;
                if (this.Bets[bIdx].Taxes)
                {
                    var normalBetRef = this.Bets[bIdx].CombinedEWBetRef.NormalBetRef;
                    var normalBetGeneratedID = normalBetRef ? normalBetRef.GeneratedID : -1;
                    var normalBetTaxes = [];
                    if (normalBetGeneratedID > -1)
                    {
                        normalBetTaxes = Array.filter(taxes, function (e) { return e.GeneratedID == normalBetGeneratedID });	
                    }
                    var ewTaxes = this.Bets[bIdx].Taxes.slice();
                    for (var i = 0; i < ewTaxes.length; i++)
                    {
                        var normalTax = Array.filter(normalBetTaxes, function (e) { return e.TypeID == ewTaxes[i].TypeID });
                        if (normalTax && normalTax.length && normalTax[0].Amount)
                        {
                           ewTaxes[i].Amount = ewTaxes[i].Amount + normalTax[0].Amount;
                        }
                    }
                    
                    this.Bets[bIdx].CombinedEWBetRef.Taxes = ewTaxes;
                }
                
            }
        }

    },

    updateBetsWithComboPromotion: function (comboBonuses)
    {
        if (comboBonuses.Folds)
        {
            var purchObj = this.JSONProperties;
            var variants = this.getComboVariants(purchObj.Selections)

            for (var bIdx in purchObj.Bets)
            {
                var betObj = purchObj.Bets[bIdx];

                var spBet = Array.find(this.Bets, function (b) { return b.GeneratedID == betObj.bID });
                if (!spBet) continue;

                if (comboBonuses.Folds[betObj.Comb] && variants[betObj.Comb] == betObj.NumOfBets)
                {
                    spBet.ComboPromotion = comboBonuses.Folds[betObj.Comb];
                }
            }
        }
    },

    getComboVariants: function (selections)
    {
        // populate selection groups
        var selectionGroups = [];
        for (var j in selections)
        {
            var selection = selections[j];
            var groupKey = selection.ComboGroupKey;
            if (typeof selectionGroups[groupKey] == 'undefined')
            {
                selectionGroups[groupKey] = [];
            }
            selectionGroups[groupKey].push(selection);
        }
        var countArray = selectionGroups.select(function (grp) { return Array.getLength(grp); });
        var variants = BetMath.sumOfProducts(countArray);

        return variants;
    },

    createSelections: function (selections)
    {
        this.Selections = [];

        for (var sIdx in selections)
        {
            var selection = selections[sIdx];
            this.Selections[selection.SelectionID] = new SPOpenBetsSelection(selection, this);
        }
    },

    createBets: function (bets)
    {
        this.Bets = [];
        for (var bIdx in bets)
        {
            var bet = bets[bIdx];
            this.Bets[bet.BetID] = new SPOpenBetsBet(bet, this);
        }
    },


    // It seems only way to fix for TB-1535 (VirginBet)
    copyNativeClientOddsIfPossible: function(purchObj) {
        if (purchObj.Bets.length === 1 && purchObj.Selections.length === 1) {
            purchObj.Bets[0].NativeOddsFrCl = purchObj.Selections[0].ClientOdds;
        }
    },

    updateFromStoredObject: function (purchObj)
    {
        if (!purchObj) return;
        if (typeof purchObj == "string")
        {
            this.JSONStringData = purchObj;
            try
            {
                purchObj = JSON.parse(purchObj);
            }
            catch (ex)
            {
                return;
            }
        }

        this.JSONProperties = purchObj;
        this.copyNativeClientOddsIfPossible(purchObj);

        this.OddStyleID = purchObj.OddStyleID;

        for (var sIdx in purchObj.Selections)
        {
            var selObj = purchObj.Selections[sIdx];

            var selection = Array.find(this.Selections, function (sel) { return sel.SelectionID == selObj.ID; });
            if (!selection) continue;
            selection.updateFromStoredObject(selObj);
        }

        for (var bIdx in purchObj.Bets)
        {
            var betObj = purchObj.Bets[bIdx];

            var spBet = Array.find(this.Bets, function (b) { return b.GeneratedID == betObj.bID });
            if (!spBet) continue;

            spBet.updateFromStoredObject(betObj);

            var mappedSelections = this.getMappedSelections(spBet.Mappings);

            if (mappedSelections) spBet.Selections = mappedSelections;

        }

        for (var bIdx in this.Bets)
        {
            var spBet = this.Bets[bIdx];
            var relatedBet = Array.find(this.Bets, function (b) { return b.GeneratedID == spBet.RelatedToID });
            spBet.HasParentBet = (relatedBet !== spBet) && !(relatedBet == null);

            if (spBet.IsFreeBet && spBet.HasParentBet)
            {
              	var oddsRoundingMode = 0;
                var gainRoundingMode = 0;
                var oddsToCalculateGain = Odds.convertFromAmericanToDecimal(spBet.Odds, true);

                if (purchObj.AdditionalData)
                {
                    gainRoundingMode = purchObj.AdditionalData.GainRoundingMode || 0;
                    if (BetTypes.IsSingle(spBet.BetTypeID))
                    {
                        oddsRoundingMode = purchObj.AdditionalData.BaseOddsRoundingMode;
                    }

                    if (BetTypes.IsCombo(spBet.BetTypeID) || BetTypes.IsSystem(spBet.BetTypeID) || BetTypes.IsCombinator(spBet.BetTypeID))
                    {
                        oddsRoundingMode = purchObj.AdditionalData.ComboOddsRoundingMode;
                    }

                    if (oddsRoundingMode != 0)
                    {
                        oddsToCalculateGain = BetMath.roundDecimalOdds(oddsToCalculateGain, oddsRoundingMode);
                    }
                }
              
                //applying country tax on stake
                spBet.Gain = GainUtil.round((UserInfo.TaxProvider.applyTaxToDeposit(spBet.Deposit) * oddsToCalculateGain).toFixed(12), gainRoundingMode);
                relatedBet.HasFreeBetContribution = true;
                relatedBet.FreeBetContribution = spBet.Deposit;
                relatedBet.FreeBetGain = spBet.Gain - spBet.Deposit;
                relatedBet.FreeBetRef = spBet;
                relatedBet.CurrentBalance = spBet.CurrentBalance + relatedBet.CurrentBalance;
            }
            else
            {
                if (spBet.IsFreeBet)
                {
                    spBet.HasFreeBetContribution = false;
                    spBet.FreeBetContribution = spBet.Deposit;
                }
            }

        }

        if (purchObj.WinMastersHistoryBet)
        {
            var bet = new SPOpenBetsBet(purchObj, this);
            bet.BetTypeID = purchObj.BetTypeID;
            bet.BetTypeName = purchObj.BetTypeName;
            bet.ComboSize = purchObj.ComboSize;
            if (bet.BetTypeName.indexOf("SYSTEM") == 0)
            {
                bet.BetName = bet.BetTypeName.replace("_", " ").replace("_", "/");
            }
            bet.Odds = purchObj.Odds;
            bet.StatusID = purchObj.StatusID;
            bet.Deposit = purchObj.Stake;
            bet.NumberOfBets = 1;
            bet.BetStatus = purchObj.BetStatus;
            bet.Selections = this.Selections;
            bet.WinMastersHistoryBet = true;
            this.Bets[bet.BetID] = bet;
        }

        this.eachWayBetsInitalization();
    },

    eachWayBetsInitalization: function ()
    {
        for (var bid in this.Bets)
        {
            var bet = this.Bets[bid];

            if (typeof (bet.RelatedToID) == "undefined") continue;

            if (!this.checkIfEachWaySelection(bet.Selections)) continue;

            var otherBet;
            for (var curBid in this.Bets)
            {
                if (this.Bets[curBid].GeneratedID == bet.RelatedToID)
                {
                    otherBet = this.Bets[curBid];
                    break;
                }
            }

            if (typeof (otherBet) == "undefined") continue;

            bet.NormalBetRef = otherBet;
            otherBet.EWBetRef = bet;
        }

        for (var bid in this.Bets)
        {
            var bet = this.Bets[bid];
            var isCombo = bet.isComboBet();

            //TODO: investigate the purpose of this
            //var rightToLeft = (langTemplate == 33);

            if ((bet.IsFreeBet && bet.HasParentBet) || bet.EWBetRef) continue;

            if (bet.NormalBetRef)
            {
                bet.IsEachWay = true;

                //Deep copy object, we do not want a reference to the original
                var oldBetRefference = bet;
                bet = {};
                $.extend(bet, oldBetRefference);

                var mainBet = bet.NormalBetRef;
                if (!isCombo)
                {
                    bet.EWOdds = bet.Odds;
                    bet.Odds = mainBet.Odds;
                }
                else
                {
                    var EWBetRefEUOdds = Odds.convertFromAmericanToDecimal(bet.Odds, true);
                    var betEUOdds = Odds.convertFromAmericanToDecimal(mainBet.Odds, true);
                    bet.EWOdds = bet.Odds;
                    bet.Odds = Odds.decimalToAmerican(EWBetRefEUOdds + betEUOdds);
                }

                bet.Gain += mainBet.Gain;
                bet.CurrentBalance = bet.CurrentBalance + mainBet.CurrentBalance;
                bet.NumberOfBets += mainBet.NumberOfBets;
                bet.MainBetID = mainBet.BetID;
                bet.FractionalOdds = mainBet.FractionalOdds;
                this.Bets[bid].CombinedEWBetRef = bet;
                mainBet.EWBetRef = this.Bets[bid];

                if (mainBet.FreeBetRef)
                {
                    var freeBetPart = mainBet.FreeBetRef;
                    bet.HasFreeBetContribution = true;
                    bet.FreeBetContribution = freeBetPart.Deposit;
                    bet.FreeBetGain = freeBetPart.Gain - freeBetPart.Deposit;
                    bet.FreeBetRef = freeBetPart;
                }
                else if (mainBet.IsFreeBet)
                {
                    bet.HasFreeBetContribution = true;
                    bet.FreeBetContribution = mainBet.Deposit;
                    bet.FreeBetGain = mainBet.Gain - mainBet.Deposit;
                    bet.FreeBetRef = mainBet;
                    bet.IsFreeBet = true;
                }
            }
        }
    },

    getMappedSelections: function (mappings)
    {
        var selections = [];
        for (var idx in mappings)
        {               //Compatibility patch for some in-dev records. Should be unnecessary in final version.
            var selID = (typeof mappings[idx] == "number" ? mappings[idx] : mappings[idx].SelectionID);
            var selection = Array.find(this.Selections, function (sel) { return sel.SelectionID == selID; });
            if (selection) selections.push(selection);
        }

        var sortedSelections = selections.sort(function (s1, s2) { return s1.SelectionID - s2.SelectionID; });
        return sortedSelections;
    },

    getNumberOfBetsToShow: function ()
    {
        var betsToShow = Array.getValues(this.Bets);
        //Remove split free bets
        betsToShow = betsToShow.where(function (b) { return !(b.IsFreeBet && b.HasParentBet); });
        //Remove E/W bets
        betsToShow = betsToShow.where(function (b) { return !b.IsEachWay; });
        return Array.getLength(betsToShow);
    },

    getBetIdToShow: function (bet, shouldDisplayExternalTicketID)
    {
        var MAX_INT = 4294967295;

        if (shouldDisplayExternalTicketID && typeof (BetSlipRegulations) !== "undefined" && BetSlipRegulations.IsItalian && this.ExternalTicketID !== null)
        {
            return this.ExternalTicketID;
        }

        if (bet.StatusID === SPBetStatus.Declined)
        {
            //Show purchase ID for rejected bets
            return this.PurchaseID;
        }

        if (bet.IsPartialCashOut == 1 && bet.BetID > MAX_INT)
        {
            //Show purchase ID for partially cashed out bets
            return bet.BetID;
        }

        if (this.getNumberOfBetsToShow() == 1)
        {   //Only one bet in purchase - show purchase ID
            return this.PurchaseID;
        }

        if (bet.NormalBetRef && bet.IsEachWay)
        {   //Winner bet + E/W Bet - show the Winner bet ID
            return bet.NormalBetRef.BetID;
        }

        return bet.BetID;
    },

    checkIfEachWaySelection: function (selections)
    {
        for (var sel in selections)
        {
            var selection = selections[sel];
            if (selection.IsEachWaySelection)
            {
                return true;
            }
        }

        return false;
    },

    initTotalGainAndStake: function ()
    {
        this.TotalProfitLoss = 0;
        for (var bet in this.Bets)
        {
            var spBet = this.Bets[bet];
            this.TotalCommission += (spBet.Commission * 1);
            this.TotalGain += !spBet.Selections.any(function (sel) { return (Lines.isTypeSP(sel.LineTypeID) && isRacingBranch(sel.BranchID)) }) ? (spBet.getGain() * 1) : 0;
            this.TotalStake += (spBet.getTotalStake(false) * 1);
            this.TotalProfitLoss += (spBet.ProfitLoss * 1);
        }
    },

    shouldBetBeDrawn: function (bet)
    {
        //Do not show open bets in bet history, or closed bets in open bets
        //Can be a problem when only part of a purchase is settled
        if (this.IsOpenBet && bet.StatusID != SPBetStatus.Open) return false;
        if (!this.IsOpenBet && bet.StatusID == SPBetStatus.Open) return false;
        if (this.IsRejectedBet && bet.StatusID != SPBetStatus.Declined) return false;
        if (!UseNewBetsVisualization && this.IsRejectedBet && bet.StatusID == SPBetStatus.Declined) return false;
        if ((bet.IsFreeBet && bet.HasParentBet) || !!bet.IsEachWay) return false;
        return true;
    },

    //Cashout start
    updateCashOutData: function (data)
    {
        var hasChanges = false;

        if (data == null || data.length == 0)
        {
            for (var bid in this.Bets)
            {
                var changed = this.Bets[bid].updateCashOutData(null);
                hasChanges = hasChanges || changed;
            }
        }
        else
        {
            for (var i in data)
            {
                var betData = data[i];
                var bet = this.Bets[betData.BetID];
                if (bet && this.shouldBuildUIForBet(bet))
                {
                    var changed = bet.updateCashOutData(betData);
                    hasChanges = hasChanges || changed;
                }
            }
        }

        return hasChanges;
    },

    shouldBuildUIForBet: function (bet)
    {
        return !((bet.IsFreeBet && bet.HasParentBet) || !!bet.IsEachWay || bet.IsHiddenFromMyBets || bet.StatusID != SPBetStatus.Open);
    },

    changeBetCashOutStatus: function (betId, status, amount)
    {
        var bet = this.Bets[betId];
        if (bet)
        {
            bet.changeStatus(status);
            bet.NewOfferCashOutAmount = amount;
        }
    },

    changeLastRequestedCashoutAmount: function (betId, amount)
    {
        var bet = this.Bets[betId];
        if (bet)
        {
            bet.lastCashoutAmount = amount;
        }
    },

    setBetError: function (betId, errorMessage)
    {
        var bet = this.Bets[betId];
        if (bet)
        {
            bet.setError(errorMessage);
        }
    }

    //Cashout end 

});

includeExtension('/JSComponents/Pages/SPOpenBets.ext.js');

//******************************************************************************************************************************
function SPOpenBetsCache()
{
    this.Content = null;
    this.Purchases = [];
}

$.extend(SPOpenBetsCache.prototype,
{
    loadContent: function ()
    {
        //aquire the open bets from the server
        var ref = this;
        BettingHistoryPageMethods.GetUserOpenSPBets(
            function (result)
            {
                ref.processContent(result);
            },
            function (result)
            {
            }
        );
    },

    processContent: function (result)
    {
        if (result == null) return;

        //evaluate the result
        this.Content = eval(result);
        this.Purchases = SPOpenBets.createPurchases(this.Content);
    },
});

SPOpenBetsCache.createPurchases = function (historyObjects, mode)
{
    mode = mode || (typeof BetsList !== "undefined" ? BetsList.Mode.Open : mode);

    var result = [];

    //iterate through the purchases
    for (var pIdx in historyObjects)
    {   //iterate through the bets
        var historyObj = historyObjects[pIdx];

        if (historyObj.IsPurchase)
        {   //New Structure row
            var resPurchase = new SPOpenBetsPurchase(historyObj);
            result.push(resPurchase);
        }
        else
        {   //Old Structure rows
            for (var i in historyObj)
            {
                historyObj[i] = eval(historyObj[i]);
            }

            var betsList = new BetsList(historyObj, mode);
            for (var idx in betsList.Items)
            {
                result.push(betsList.Items[idx]);
            }
        }
    }

    return result;
}

window.SPBetStatus = SPBetStatus;
window.SPOpenBetsSelection = SPOpenBetsSelection;
window.SPOpenBetsBet = SPOpenBetsBet;
window.SPOpenBetsPurchase = SPOpenBetsPurchase;
window.SPOpenBetsCache = SPOpenBetsCache;
