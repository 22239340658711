﻿function ESInfoLogger(logName) {
    var _logname = logName;

    var sapi = {};

    sapi.sessionStorageAvailable = function () {
        if (typeof (sessionStorage) == "undefined") { return false; }

        try {
            sessionStorage.setItem("sessionStorageAvailable_test", "sessionStorageAvailable");
            var result = sessionStorage.length > 0;
            sessionStorage.removeItem("sessionStorageAvailable_test");
            return result;
        }
        catch (e) {
            return false;
        }
    };

    sapi.saveToStorage = function (key, value) {
        try {
            sessionStorage.setItem(key, value);
        }
        catch (e) { }
    };

    sapi.keyExsists = function (key) {
        return sessionStorage.getItem(key) != null;
    };

    sapi.getFromStorage = function (key) {
        var result;

        result = sessionStorage.getItem(key);

        return (typeof result != "undefined") ? result : null;
    };

    sapi.removeFromStorage = function (key) {
        sessionStorage.removeItem(key);
    };

    function newLogJson() {
        return { 'logname': _logname, 'middle': [], 'end': '' }
    }

    function newInfo() {
        return {
            Browser: "",
            Sid: "",
            Url: "",
            Hostname: "",
            LogName: "",
            Info: "",
            End: ""
        };
    }

    function isOK() {
        return sapi.sessionStorageAvailable() && sapi.keyExsists(_logname);
    }

    function clear() {
        sapi.removeFromStorage(_logname);
    }

    function data() {
        return JSON.parse(sapi.getFromStorage(_logname));
    }

    function current() {
        return isOK() ? data() : newLogJson();
    }

    function save(obj) {
        try {
            if (sapi.sessionStorageAvailable()) {
                sapi.saveToStorage(_logname, JSON.stringify(obj));
            } else {
                return false;
            }
            return true;
        } catch (e) { return false; }
    }

    function newLog() {
        if (sapi.sessionStorageAvailable() && !sapi.keyExsists(_logname)) {
            save(current());
        }
    }

    function addInfo(pname) {
        if (!isOK) return;

        var d = current();
        d.middle.push({ info: pname });
        save(d);
    }

    function end() {
        if (!isOK) return;

        var d = current();
        if (d.end !== "undefined" && d.end != "") { return; }

        save(d);

        logToES();
        clear();
    }

    function logToES() {
        if (!isOK()) { return false; }

        try {
            var apiUrl = jsErrHandler_Constants.ajax_url;
            var obj = current();
            var info = newInfo();
            info.Browser = navigator.userAgent;
            info.Sid = /SESS\w*ID=([^;]+)/i.test(document.cookie) ? RegExp.$1 : "";
            info.Url = encodeURIComponent(location.href.substr(0, location.href.indexOf('?') == -1 ? location.href.length : location.href.indexOf('?'))).replace("&", '%26');
            info.Hostname = location.hostname;
            info.LogName = _logname;
            info.Info = JSON.stringify(obj.middle);
            info.End = obj.end;

            var url = "";
            if ('http:' == document.location.protocol) {
                url = apiUrl + "?info=" + JSON.stringify([info]);
            } else {
                url = apiUrl.replace("http:", "https:") + "?info=" + JSON.stringify([info]);
            }
            setTimeout(function () {
                new Image().src = url;
            }, 20);

            return true;
        }
        catch (e) { return false; }
    }

    newLog();

    return {
        getLogName: function () {
            return _logname;
        },
        addInfo: function (pname) {
            addInfo(pname);
        },
        end: function () {
            end();
        },
    }
}

window.ESInfoLogger = ESInfoLogger;