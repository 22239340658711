﻿/* exported PushSogeiMessageHandler */
/* globals PushBaseMessageHandler */

var PushSogeiMessageHandler = (function (_super)
{
    var instance;

    function getInstance()
    {
        if (!instance)
        {
            instance = new PushSogeiMessageHandler();
        }

        return instance;
    }

    function PushSogeiMessageHandler()
    {
        _super.apply(this, arguments);
    }

    $.extend(PushSogeiMessageHandler.prototype, _super.prototype);

    PushSogeiMessageHandler.prototype.processMessage = function (message)
    {
        var subscribersKeys = Object.keys(this.Subscribers),
            subscriberIndex,
            currentSubscriber = null;

        for (var key = 0; key < subscribersKeys.length; key += 1)
        {
            subscriberIndex = subscribersKeys[key];
            currentSubscriber = this.Subscribers[subscriberIndex] || null;
            if (currentSubscriber !== null)
            {
                currentSubscriber.subscriberHandleFunction();
            }
        }
    };

    return getInstance();
}(PushBaseMessageHandler));

window.PushSogeiMessageHandler = PushSogeiMessageHandler;