﻿/* exported CenterPanelHolder */
function CenterPanelHolder(elementID)
{
    'use strict';
    this.element = document.getElementById(elementID);
}

$.extend(CenterPanelHolder.prototype,
{
    init: function ()
    {
        'use strict';
        return this.element;
    }
});

window.CenterPanelHolder = CenterPanelHolder;