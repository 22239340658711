﻿/*globals UserInfo, sogeiRun, BetSlipRegulations, PushOddsMessageHandler, PushSogeiMessageHandler, PushUserBetsMessageHandler, PushMessageTypes, userBetsPluginRun */
/* jshint maxdepth: 7, maxcomplexity: 18, maxparams: 4 ,maxstatements:33*/

var Facade = (function ()
{
    'use strict';
    var onDisconnectSubscribers = [];
    var isConnected = false;
    var PushHandlerTypes = [];

    function initHandlers()
    {
        PushHandlerTypes[PushMessageTypes.Odds] = PushOddsMessageHandler;
        PushHandlerTypes[PushMessageTypes.Sogei] = PushSogeiMessageHandler;
        PushHandlerTypes[PushMessageTypes.UserBets] = PushUserBetsMessageHandler;
    }

    function updateHandler(sender, data)
    {
        var message = JSON.parse(data);

        if (PushHandlerTypes.hasOwnProperty(message.MessageType))
        {
            PushHandlerTypes[message.MessageType].processMessage(message);
        }
    }
    /* jshint validthis: true */

    function connectionEstablished()
    {
        isConnected = true;

        if (UserInfo.current)
        {
            userBetsPluginRun();
        }

        UserInfo.onLogin['StartPushUserBets'] = function ()
        {
            userBetsPluginRun();
        };

        if (typeof (BetSlipRegulations) != 'undefined' && BetSlipRegulations.IsItalian)
        {
            if (UserInfo.current)
            {
                sogeiRun();
            }

            UserInfo.onLogin['StartPushSogei'] = function ()
            {
                sogeiRun();
            };
        }

        window.Communicator.Events.OnMessageReceived.ProcessMessage = Facade.updateHandler;
        window.Communicator.Events.OnDisconnect['Disconnected'] = Facade.disconnectHandler;
    }

    function addSubscriber(execFunc, subscribedItemKey, handlerKey, customHandlerParameters)
    {
        if (PushHandlerTypes.hasOwnProperty(handlerKey))
        {
            PushHandlerTypes[handlerKey].addSubscriber(subscribedItemKey, execFunc, customHandlerParameters);
        }
    }

    function removeSubscriber(subscribedItemKey, handlerKey)
    {
        if (PushHandlerTypes.hasOwnProperty(handlerKey))
        {
            PushHandlerTypes[handlerKey].removeSubscriber(subscribedItemKey);
        }
    }

    function clearDebounceEvents(subscribedItemKey)
    {
        if (PushHandlerTypes.hasOwnProperty(PushMessageTypes.Odds))
        {
            PushHandlerTypes[PushMessageTypes.Odds].clearDebounceEvents(subscribedItemKey);
        }
    }

    function disconnectHandler(data, sender)
    {
        isConnected = false;
        executeEvents(onDisconnectSubscribers, sender, data);
    }

    initHandlers();

    return {
        addSubscriber: addSubscriber,
        removeSubscriber: removeSubscriber,
        updateHandler: updateHandler,
        disconnectHandler: disconnectHandler,
        onConnectionEstablished: connectionEstablished,
        onDisconnectSubscribers: onDisconnectSubscribers,
        isConnected: isConnected,
        clearDebounceEvents: clearDebounceEvents
    };
})();

window.Facade = Facade;

/* jshint maxdepth: 3, maxcomplexity: 5, maxparams: 3 */
