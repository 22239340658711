﻿// jsRequire("/JSComponents/custom/LZString.js");
// jsRequire("/JSComponents/custom/jsErrorsLog_Constants.js");

var VisualLogger =
    {
        init: function ()
        {
            VisualLogger.ajax_url = jsErrHandler_Constants.ajax_url;
            VisualLogger.ajax_contentType = jsErrHandler_Constants.ajax_contentType;

            if (typeof BetSlip != "undefined" && BetSlip.EnableVisualLogger)
            {
                BetSlip.OnBetSend.VisualLogger = VisualLogger.saveBetSlipOnSend;
                BetSlip.OnPurchaseAdded.VisualLogger = VisualLogger.saveBetSlipOnPurchaseAdd;
                BetSlip.OnPurchaseUpdated.VisualLogger = VisualLogger.saveBetSlipOnPurchaseUpdate;
            }
        },

        saveBetSlipOnSend: function ()
        {
            VisualLogger.saveBetSlip("Bet placed");
        },

        saveBetSlipOnPurchaseAdd: function ()
        {
            VisualLogger.saveBetSlip("Purchase added to bet slip");
        },

        saveBetSlipOnPurchaseUpdate: function ()
        {
            VisualLogger.saveBetSlip("Purchase updated in bet slip");
        },
        
        saveBetSlipOnCashOut: function ()
        {
            VisualLogger.saveBetSlip("Cash out request");
        },

        saveBetSlip: function (description)
        {
            try
            {
                if (typeof UserInfo == "undefined" || UserInfo.current == null) return;

                var bsObj = document.getElementById("betting_slip");

                if (bsObj == null) return;

                var dummy = [];
                dummy.push(bsObj);

                VisualLogger.logHTML(dummy, UserInfo.current.userID, UserInfo.current.userName, description);
            }
            catch (e)
            {
                console.log(e.message);
                console.log(e.stack);
            }
        },

        logHTML: function (documentObjects, userID, userName, description)
        {
            try
            {
                var requestArr = [];
                for (var idx in documentObjects)
                {
                    var docObj = documentObjects[idx];
                    var compressedHtml = LZString.compressToEncodedURIComponent(docObj.innerHTML);

                    var dataObj = new Object();
                    dataObj.EncodedHTML = compressedHtml;
                    dataObj.UserID = userID;
                    dataObj.UserName = userName;
                    dataObj.ElementID = docObj.id;
                    dataObj.ElementClass = docObj.className;
                    dataObj.Url = encodeURIComponent(window.location.href);
                    dataObj.Description = description;
                    dataObj.InputFields = [];
					
					if (description != "Bet placed")
					{
						var purchase = BetSlip.Purchases[BetSlip.Purchases.length-1];

							if (purchase != undefined && purchase.PurchaseID != "0")
							{
								dataObj.PurchaseBetID = purchase.PurchaseID;
								dataObj.Description = description + " " + dataObj.PurchaseBetID;
								switch (true)
								{
									case BetSlip.isAsianMode == 1:
									dataObj.Viewtype = 1;
									break;
									
									case BetSlip.IsBetslipUK == 1:
									dataObj.Viewtype = 2;
									break;
																		
									default:
									dataObj.Viewtype = 0;
								}
							}
					}

                    //Get text inputs since their current value may be different from the HTML
                    var docObjInputs = docObj.getElementsByTagName("input");
                    for (var i in docObjInputs)
                    {
                        var elem = docObjInputs[i];
                        var elemType = elem.type ? elem.type.toLowerCase() : "";
                        if (elemType == "text" || elemType == "number")
                        {
                            var inputObj = new Object();
                            inputObj.Value = elem.value;
                            inputObj.Id = elem.id;
                            dataObj.InputFields.push(inputObj);
                        }
                    }

                    requestArr.push(dataObj);
                }

                if (Array.getLength(requestArr) == 0) return;

                var url = VisualLogger.ajax_url;

                if ('http:' != document.location.protocol)
                {
                    url = VisualLogger.ajax_url.replace("http:", "https:");
                }

                var req = new XMLHttpRequest();
                var params = "visLog=" + JSON.stringify(requestArr);

                req.open("POST", url, true);
                req.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
                req.send(params);

            }
            catch (e)
            {
                console.log(e.message);
                console.log(e.stack);
            }
        },

    };

VisualLogger.init();

window.VisualLogger = VisualLogger;