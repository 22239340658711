/**
 * UI Utils
 * useful methods and functions
 * @author Lyubomir Todorov
 */

/**
 * @namespace
 */
var UI = window.UI || {};

(function (UI, window, undefined)
{
    'use strict';

    var document = window.document;    

    // Utility object
    UI.utils = {
        /**
         * Extend object with muliple properties
         * @param {Object} Multiple objects
         * @example
         * var ext = extendProp(obj1, obj2, ... { prop1: 'value1' });
         * @returns {Object} The extended object
         */
        extendProp: function ()
        {
            var res = {},
                len = arguments.length,
                stuff,
                j,
                i;

            for (j = 0; j < len; j+=1)
            {
                stuff = arguments[j];
                for (i in stuff)
                {
                    if (stuff.hasOwnProperty(i))
                    {
                        res[i] = stuff[i];
                    }
                }
            }
            return res;
        },

        /**
         * Detect browser vendor prefixes
         * @param {String} CSS/JavaScript property
         * @returns {String | Boolean} false if the property is not supported
         */
        detectVendor: function ()
        {
            var rootStyles = window.getComputedStyle(document.documentElement),
                len = arguments.length,
                testProp,
                i;

            if (typeof rootStyles === 'object' && rootStyles !== null) {
                for (i = 0; i < len; i+=1) {
                    testProp = arguments[i];
                    if (testProp in rootStyles) {
                        return testProp;
                    }
                }
            }
            return false;
        },


        /**
         * Detect supported transition end event name
         * @returns transition end event name or false if it is not supported
         */
        detectTransitionEnd: function ()
        {
            var props = [],
                transitions = {
                    'transition': 'transitionend',
                    'webkitTransition': 'webkitTransitionEnd',
                    'oTransition': 'oTransitionEnd'
                },
                t;

            for (t in transitions)
            {
                if (transitions.hasOwnProperty(t))
                {
                    props.push(t);
                }
            }

            t = this.detectVendor.apply(null, props);
            t = t && transitions[t];

            return t;
        },

        /**
         * Returns a new debounced version of the passed function
         * which will postpone its execution until after wait milliseconds
         * have elapsed since the last time it was invoked.
         */
        debounce: function (fn, delay)
        {
            var timer = null;

            return function ()
            {
                var context = this,
                    args = arguments;

                clearTimeout(timer);
                timer = setTimeout(function ()
                {
                    fn.apply(context, args);
                }, delay);
            };
        }

        // addListener(el, type, fn)
        // removeListener(el, type, fn)
    };

    /**
     * Attaching and removing event listeners
     * @param {DOM Element} el
     * @param {String} Type of the event
     * @param {Function} fn
     */
    if (typeof document.attachEvent === 'object')
    {
        // Internet Explorer 10-
        UI.utils.addListener = function (el, type, fn)
        {
            el.attachEvent('on' + type, fn);
        };
    }
    else if (typeof window.addEventListener === 'function')
    {
        UI.utils.addListener = function (el, type, fn)
        {
            el.addEventListener(type, fn, false);
        };
    }
    else
    {
        // Older browsers
        UI.utils.addListener = function (el, type, fn)
        {
            el['on' + type] = fn;
        };
    }

    if (typeof document.detachEvent === 'object')
    {
        // Internet Explorer 10-
        UI.utils.removeListener = function (el, type, fn)
        {
            el.detachEvent('on' + type, fn);
        };
    }
    else if (typeof window.removeEventListener === 'function')
    {
        UI.utils.removeListener = function (el, type, fn)
        {
            el.removeEventListener(type, fn, false);
        };
    }
    else
    {
        // Older browsers
        UI.utils.removeListener = function (el, type, fn)
        {
            el['on' + type] = null;
        };
    }

}(UI, window));

window.UI = UI;