/*********************************************************************************************************************************************\
/*
/* Event
/*
/**/

function EventInfo(initArray)
{
    this.ID = parseInt(initArray[0]);
    this.Deleted = false;
    this.Live = false;
    this.Special = false;
    this.HasCombo = false;
    this.MainLine = null;
    this.Options = [];
    this.MinBetNumber = initArray[9];

    if (initArray[1] != undefined)
    {
        this.Type = parseInt(initArray[1]);
        this.Live = this.Type == 39;
    }

    //call updateFromServer
    if (initArray[2] && initArray[2][0] === 1)
    {
        initArray[2][1] && this.updateFromServer(initArray[2][1]);
        return this;
    }

    if ((initArray[2]) && (initArray[2] != -1))
    {
        var lines = typeof initArray[2][0] == "number" ? initArray[2][2] : initArray[2];
        this.Lines = HashtableOf(lines, LineGroup);

        for (var key in this.Lines) this.HasCombo = this.HasCombo || this.Lines[key].HasCombo;
    }
    else
    {
        this.Lines = null;
    }

    if (initArray[2] == -1)
    {
        this.Deleted = true;
        this.Lines = null;
    }

    if (initArray[3] != undefined)
    {
        // live event
        this.Live = true;
        this.Danger = initArray[3] == 1;
        this.Score1 = initArray[4];
        this.Score2 = initArray[5];
    }
    else
    {
        this.Live = false;
        this.TeamMapping = initArray[4];
    }

    this.OrderID = this.Type;

    var spt = specialsID[this.Type];

    if (spt != undefined)
        this.SplitType = spt;
    else
        this.SplitType = 0;

    this.Special = this.Type != 0 && this.Type != 1;

    if (initArray[4] != undefined)
    {
        this.Team1 = initArray[4];
        this.Team2 = initArray[5];
        this.TypeName = initArray[6];
        this.OrderID = initArray[8];
        this.LastUpdate = false;
    }
    else
    {
        this.LinesCount = initArray[5];
    }
}

EventInfo.prototype =
{
    isExpired: function (lastupdate, multiplier)
    {
        if (this.LastUpdate)
        {
            var to = this.Type == 0 || this.Type == 1 ? LinesUpdateBaseInterval : SpecialEventUpdateTimeout;
            to *= multiplier;

            return ((new Date()).getTime() - this.LastUpdate.getTime()) > to;
        }
        else return true;
    },

    getUpdateString: function ()
    {
        if ((!this.Lines) && (!this.Live)) return this.ID;

        var lls = [this.ID];

        for (var key in this.Lines)
            lls.push(this.Lines[key].getUpdateString());

        return lls.join("#");
    },

    updateFromServer: function (initArray)
    {
        this.LastUpdate = new Date();

        //***********Separating dependency between cient and event types***********
        var spt = specialsID[this.Type];

        if (spt != undefined)
            this.SplitType = spt;
        else
            this.SplitType = initArray[3];
        //***********END*********** 

        if (!initArray[2])
        {
            this.__resetUpdated();
            return;
        }

        if (initArray[2] == -1)
        {
            this.Deleted = true;
            return;
        }

        this.Updated = true;
        this.HasCombo = false;

        var lines = typeof initArray[2][0] == "number" ? initArray[2][2] : initArray[2];
        var newLines = HashtableOf(lines, LineGroup);

        this.updateEventLines(newLines);
    },

    updateEventLines: function (newLines)
    {
        if (!this.Lines)
        {

            this.createLines(newLines);

            return;
        }

        for (var key in newLines)
        {
            if (this.Lines[key])
                this.Lines[key].updateFromServer(newLines[key]);
            else
                this.Lines[key] = newLines[key];
        }

        this.MainLine = null;
        this.Options = [];

        for (var key in this.Lines)
        {
            var line = this.Lines[key];

            if (line.Deleted)
            {
                delete this.Lines[key];
                continue;
            }

            this.updateEventProperties(line);
        }
    },

    createLines: function (newLines)
    {
        this.Lines = newLines;
        for (var key in this.Lines)
        {
            var line = this.Lines[key];
            this.updateEventProperties(line);
        }
    },

    updateEventProperties: function (line)
    {
        this.HasCombo = this.HasCombo || line.HasCombo;

        if (line.IsOptional)
            this.Options.push(line);
        else
            this.MainLine = line;
    },

    __resetUpdated: function ()
    {
        this.Updated = false;

        if (!this.Lines) return;

        for (var key in this.Lines)
        {
            if (this.Lines[key])
                this.Lines[key].__resetUpdated();
        }
    },

    getLinesCount: function (opt)
    {
        var res = 0;

        for (var key in this.Lines)
        {
            var ll = this.Lines[key];
            if (opt && ll.IsOptional)
            {
                res += ll.hasOdds() ? 2 : 0;
                continue;
            }

            if (!opt && !ll.IsOptional)
            {
                return ll.hasDraw() ? 3 : 2;
            }
        }

        return res;
    },

    hasOdds: function (prop)
    {
        for (var key in this.Lines)
        {
            if (this.Lines[key].hasOdds(prop)) return true;
        }

        return false;
    }
}

window.EventInfo = EventInfo;