﻿var AcceptChangingOddsModes =
{
    AcceptChangedOddsAlways: 1,
    AcceptChangedOddsBetter: 2,
    AcceptChangedOddsNever : 3
};

var IDs =
    {
    placeButton: "place",
    acceptOddsButton: "acceptOdds",
    acceptOddsMessage: "accept-odds-message",
    acceptOddsLoader: "betslip-accept-changing-odds-loader",
    configItemAlways: "betslip-accept-changing-odds-item-always",
    configItemBetter: "betslip-accept-changing-odds-item-better",
    configItemNever: "betslip-accept-changing-odds-item-never"
};

var AcceptChangingOdds = (function ()
{
    function init(selectionsCount)
    {
        this.active = false;
        var state = StorageUtils.getFromStorage("AcceptChangingOdds", localStorage);
        if (state && state === "true" && selectionsCount > 0)
        {
            this.activate();
        }
    }

    function clear()
    {
        this.acceptOdds();        
    }

    function processChangeOdds(enableAcceptChangingOdds, isAsianMode, isBetInProcess, selections, selectionsInCombo)
    {
        if (this.active || !enableAcceptChangingOdds || isAsianMode || isBetInProcess ||
            !UserInfo.current || UserInfo.current.AcceptChangingOdds === AcceptChangingOddsModes.AcceptChangedOddsAlways)
        {
            return;
        }

        var changedOddsCount = 0,
            worseOddsCount = 0;        
        
        for (var key in selections)
        {
            var sel = selectionsInCombo[key];

            // selection unchecked in 'combo'/'system' tabs
            if (!sel || sel.Odds === sel.AcceptingOdds)
            {
                continue;
            }

            changedOddsCount = changedOddsCount + 1;
            if (sel.Odds < sel.AcceptingOdds)
            {
                worseOddsCount = worseOddsCount + 1;
            }
        }

        if (changedOddsCount === 0 || (worseOddsCount === 0 && UserInfo.current.AcceptChangingOdds === AcceptChangingOddsModes.AcceptChangedOddsBetter))
        {
            return;
        }

        this.activate();
    }

    function isActive()
    {
        return this.active;
    }

    function activate()
    {
        this.active = true;        
        toggleAcceptOddsButton(true);
        StorageUtils.saveToStorage("AcceptChangingOdds", true, localStorage);
    }

    function acceptOdds()
    {
        this.active = false;
        StorageUtils.saveToStorage("AcceptChangingOdds", false, localStorage);
    }

    function toggleAcceptOddsButton(isShow)
    {
        var placeButton = document.getElementById(IDs.placeButton);
        if (placeButton)
        {
            placeButton.classList[isShow ? "add" : "remove"]("isHidden");
        }

        var acceptButton = document.getElementById(IDs.acceptOddsButton);
        if (acceptButton)
        {
            acceptButton.classList[isShow ? "remove" : "add"]("isHidden");
        }

        var acceptMessage = document.getElementById(IDs.acceptOddsMessage);
        if (acceptMessage)
        {
            acceptMessage.classList[isShow ? "remove" : "add"]("isHidden");
        }
    }

    return {
        init: init,
        isActive: isActive,
        activate: activate,
        processChangeOdds: processChangeOdds,
        clear: clear,
        acceptOdds: acceptOdds,
        toggleAcceptOddsButton: toggleAcceptOddsButton
    };
})();

window.AcceptChangingOddsModes = AcceptChangingOddsModes;
window.IDs = IDs;
window.AcceptChangingOdds = AcceptChangingOdds;