﻿/* exported SidePanelsHolder, LeftPanelsHolder, RightPanelsHolder */
/* globals EventsManager */
function SidePanelsHolder(elementID)
{
    'use strict';
    this.element = document.getElementById(elementID);
    this.headingElem = this.element.getElementsByClassName('panel-heading')[0];
    this.headingTextElem = this.element.getElementsByClassName('panel-heading-text')[0];
    this.headingIconElem = this.element.getElementsByClassName('panel-heading-icon')[0];
    this.pageMainHeader = this.getPageMainHeader();
    this.winResizeHandler = {};
    this.expanded = false;
    this.isShown = true;
    //override:
    this.Position = '';
    this.panelOpenClass = '';
    this.headingIconOpenClass = '';
    this.headingIconCloseClass = '';
    this.OnPanelEnter = {};
    this.OnPanelLeave = {};
    this.viewScrollValue = 0;
}

SidePanelsHolder.eventsManager = new EventsManager();

$.extend(SidePanelsHolder.prototype,
{

    init: function ()
    {                                   
        'use strict';

        var contentOverlay = document.getElementById('panel-overlay');
        var leaveRef = this.leave.bind(this);
        contentOverlay.addEventListener('click', leaveRef, false);

        //this.winResizeHandler = debounce(this.adjustTopPosition, 50);

        return this.element;
    },

    show: function()
    {
        this.element.classList.remove('panel-side-hidden');

        this.isShown = true;

        SidePanelsHolder.eventsManager.dispatchEvent('onSideVisibilityChange', null, true);

        return this.isShown;
    },

    hide: function()
    {
        this.element.classList.add('panel-side-hidden');

        this.isShown = false;

        SidePanelsHolder.eventsManager.dispatchEvent('onSideVisibilityChange', null, true);

        return this.isShown;
    },

    scrollView: function(value)
    {
        if (document.body.scrollTop > 0)
        {
            document.body.scrollTop = value;
        } else
        {
            window.scrollTo(0,value);
        }
    },

    getViewScroll: function()
    {
        return document.body.scrollTop > 0 ? document.body.scrollTop : window.pageYOffset;
    },

    enter: function (transitionSettings)
    {
        'use strict';

        if (this.expanded === true)
        {
            return false;
        }

        this.expanded = true;
        this.viewScrollValue = this.getViewScroll();

        document.body.classList.add(this.panelOpenClass); //'panel-left-open'

        this.headingIconElem.classList.remove(this.headingIconOpenClass); // 'panel-heading-icon-r'
        this.headingIconElem.classList.add(this.headingIconCloseClass); //'panel-heading-icon-l'

        //for mobile and tablet
        if (!Application.deviceType.isDesktop() && !Application.isInIframe())
        {
            document.documentElement.classList.add('prevent-win-scroll-for-mobile');
            document.body.scrollTop = this.viewScrollValue;
            //this.adjustTopPosition();
            Application.preventBodyScroll.activate(this.element);
        }

        executeEvents(this.OnPanelEnter);
        return 'expanded: ' + this.element.expanded;
    },

    leave: function ()
    {
        'use strict';

        if (this.expanded === false)
        {
            return false;
        }

        this.expanded = false;
        document.body.classList.remove(this.panelOpenClass); //'panel-left-open'

        this.headingIconElem.classList.add(this.headingIconOpenClass); // 'panel-heading-icon-r'
        this.headingIconElem.classList.remove(this.headingIconCloseClass); //'panel-heading-icon-l'

        //for mobile and tablet
        if (!Application.deviceType.isDesktop() && !Application.isInIframe())
        {
            document.documentElement.classList.remove('prevent-win-scroll-for-mobile');
            this.scrollView(this.viewScrollValue);
            this.resetTopPosition();
            Application.preventBodyScroll.deactivate(this.element);
        }

        executeEvents(this.OnPanelLeave);
        return 'expanded: ' + this.element.expanded;
    },

    setPanelHeading: function (headingText)
    {
        'use strict';
        var _headingText = headingText || '';

        this.element.classList[_headingText.length > 0 ? 'remove' : 'add']('panel-side-heading-none');
        this.headingElem.classList[_headingText.length > 0 ? 'remove' : 'add']('panel-heading-none');
        this.headingTextElem.textContent = _headingText;

        return this.headingElem.classList;
    },

    adjustTopPosition: function ()
    {
        'use strict';

        if (Application.deviceType.isMobile() && document.body.classList.contains('unstickyHeader'))
        {
            if (this.pageMainHeader !== null && document.body.scrollTop <= this.pageMainHeader.clientHeight)
            {
                this.element.style.top = (this.pageMainHeader.clientHeight - document.body.scrollTop).toString() + 'px';
            }
            else
            {
                this.element.style.top = '0px';
            }
        }

        return this.element.style.top || false;
    },

    resetTopPosition: function ()
    {
        'use strict';

        this.element.removeAttribute('style');

        return 'removed style attr';
    },

    getPageMainHeader: function () {

        var mobileHeader = document.getElementById('headerNav');
        var respHeader = document.getElementById('panels-top-holder');

        return mobileHeader !== null && !mobileHeader.classList.contains('hidden') ? mobileHeader : respHeader;
    }
});


function LeftPanelsHolder(elementID)
{
    'use strict';
    SidePanelsHolder.call(this, elementID);

    this.Position = PanelPosition.LEFT;
    this.panelOpenClass = 'panel-left-open';
    this.headingIconOpenClass = 'panel-heading-icon-r';
    this.headingIconCloseClass = 'panel-heading-icon-l';
}
$.extend(LeftPanelsHolder.prototype, SidePanelsHolder.prototype);

function RightPanelsHolder(elementID)
{
    'use strict';
    SidePanelsHolder.call(this, elementID);

    this.Position = PanelPosition.RIGHT;
    this.panelOpenClass = 'panel-right-open';
    this.headingIconOpenClass = 'panel-heading-icon-l';
    this.headingIconCloseClass = 'panel-heading-icon-r';
}
$.extend(RightPanelsHolder.prototype, SidePanelsHolder.prototype);

window.SidePanelsHolder = SidePanelsHolder;
window.LeftPanelsHolder = LeftPanelsHolder;
window.RightPanelsHolder = RightPanelsHolder;