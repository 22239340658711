﻿/* exported MobileSidePanel */
/* globals MobilePeripheralPanel */
function MobileSidePanel(config)
{
    'use strict';
    MobilePeripheralPanel.call(this, config);
    
    this.panelHolder = null;
}

$.extend(MobileSidePanel.prototype, MobilePeripheralPanel.prototype,
{
    init: function ()
    {
        MobilePeripheralPanel.prototype.init.call(this);
        this.panelHolder = (this.Position == PanelPosition.LEFT && Application.leftPanelsHolder) ||
                            (this.Position == PanelPosition.RIGHT && Application.rightPanelsHolder);
    },

    activate: function ()
    {
        MobilePeripheralPanel.prototype.activate.call(this);

        this.Blocks.length > 0 ? this.show() : this.hide();
        this.panelHolder.setPanelHeading(this.HeadingText);
        this.panelHolder.OnPanelEnter['AfterPanelHolderEnter'] = this.executeMethodOnEveryBlock.bind(this, 'onPanelEnter');
        this.panelHolder.OnPanelLeave['AfterPanelHolderLeave'] = this.executeMethodOnEveryBlock.bind(this, 'onPanelLeave');
    },

    deactivate: function ()
    {
        MobilePeripheralPanel.prototype.deactivate.call(this);

        delete this.panelHolder.OnPanelEnter['AfterPanelHolderEnter'];
        delete this.panelHolder.OnPanelLeave['AfterPanelHolderLeave'];
    },

    show: function()
    {
        this.panelHolder.show();
    }, 

    hide: function()
    {
        this.panelHolder.hide();
    },

    toggle: function ()
    {
        'use strict';
        if (this.panelHolder.expanded)
        {
            this.leave();
        }
        else
        {
            this.enter();
        }

        return 'expanded: ' + this.panelHolder.element.expanded;
    },

    enter: function (transitionSettings)
    {
        this.panelHolder.enter();
    },

    leave: function ()
    {
        this.panelHolder.leave();
    },

    switchToPanel: function (toPanel, transitionSettings)
    {
        MobilePeripheralPanel.prototype.switchToPanel.call(this, toPanel, transitionSettings);
        this.leave();
    }
});

window.MobileSidePanel = MobileSidePanel;