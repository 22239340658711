/* globals MobileDetect, DocumentTouch */

/**
 * @namespace
 */
var UI = window.UI || {};
var MobileDetect = require("mobile-detect");
/**
 * Detect device and browser specific features
 */

UI.detects = (function ()
{
    /* jshint maxstatements:35 */

    'use strict';

    var DeviceChangeOnResize = false; //use to change HTML templates on window resize

    var api = {},
        version = '1.0.0',
        classes = ['js'],
        classPrefix = '',
        tests = [],
        detects = {},
        vendors = {},
        rootElement = document.documentElement,
        rootStyles = window.getComputedStyle(rootElement),
        mobileDetect = new MobileDetect(navigator.userAgent);

    /**
     * Create a feature test.
     * @private
     * @param {string} name - A string name of the test.
     * @param {function|boolean} fn - A boolean or a function that will return
     *                              a boolean result.
     * @param {boolean} addClass - Whether or not to add the name as a class to
     *                             the root element.
     * @returns {object} the defined test
     */
    function addTest(name, fn, addClass)
    {
        var test = {
            name: name.toLowerCase(),
            fn: fn,
            addClass: addClass || false
        };

        tests.push(test);

        return test;
    }

    /**
     * Run a test and detect its support.
     * @private
     * @param {object} test - An object that has been added using `addTest()`.
     * @param {string} test.name - A string name of the test.
     * @param {function|boolean} test.fn - A boolean or a function that will
     *                                   return a boolean result.
     * @param {boolean} test.addClass - Whether or not to add the name as a
     *                                class to the root element.
     * @returns {boolean} A boolean result of the test.
     */
    function runTest(test)
    {
        var cl = 'no-' + test.name,
            result = typeof test.fn === 'function' ? test.fn() : test.fn;

        if (result)
        {
            cl = test.name;
        }

        if (test.addClass)
        {
            classes.push(cl);
        }

        detects[test.name] = result;

        return result;
    }

    /**
     * Run through all tests.
     * @private
     * @returns {object} Object with all detects and their results.
     */
    function runTests()
    {
        var len = tests.length;

        while (len)
        {
            len -= 1;
            runTest(tests[len]);
        }

        tests = null;

        return detects;
    }

    /**
     * Determine wheather a property is supported.
     * @private
     * @param {object} obj - An object to check the properties.
     * @param {string[]} list - An array of the vendor prefixed properties.
     *                         The standard property must be the last item.
     * @returns {boolean} A boolean result of the property support.
     */
    function propTest(obj, list)
    {
        var len = list.length,
            name = list[len - 1],
            prop = null;

        while (len)
        {
            len -= 1;
            prop = list[len];

            if (prop in obj)
            {
                vendors[name] = prop;
                return true;
            }
        }

        return false;
    }

    /**
     * Get a list with the names of all detections.
     * @private
     * @returns {string[]} An array with all detection names.
     */
    function getTestNames()
    {
        return Object.keys(detects);
    }

    /**
     * Get a stored result from a specific detection.
     * @private
     * @param {string} name - A string name of the test.
     * @returns {boolean} A boolean result of the test.
     */
    function getTestResult(name)
    {
        return detects[name.toLowerCase()];
    }

    /**
     * Get a prefixed or non-prefixed property name variant of your input.
     * @private
     * @param {string} name - The standard property name.
     * @returns {string|undefined} The supported property name.
     */
    function getVendor(name)
    {
        return vendors[name];
    }

    /**
     * Add a prefix to each item of an array.
     * @private
     * @param {string[]} origin - An array of class names.
     * @param {string} prefix - An optional prefix.
     * @returns {string[]} An array with the prefixed class names.
     */
    function addPrefix(origin, prefix)
    {
        var result = origin;

        if (typeof prefix !== undefined)
        {
            result = origin.map(function (val)
            {
                return prefix + val;
            });
        }

        return result;
    }

    /**
     * Add class names to the root element.
     * @private
     * @param {string[]} classes - An array of class names.
     * @param {[type]} prefix - An optional prefix.
     * @returns {HTMLHtmlElement} The root element.
     */
    function setClasses(classes, prefix)
    {
        var list = addPrefix(classes, prefix);

        rootElement.className = list.join(' ');

        return rootElement;
    }

    /**
     * Add extra class names to easily indetify the client device
     * @returns {string[]} An array with extra class names.
     */
    function mobileDetectClasses()
    {
        var os = mobileDetect.os();

        if (mobileDetect.mobile())
        {
            classes.push('mobile');

            if (mobileDetect.phone())
            {
                classes.push('phone');
            }
            if (mobileDetect.tablet())
            {
                classes.push('tablet');
            }
            if (os)
            {
                classes.push(os.toLowerCase());
            }
        }
        else
        {
            classes.push('desktop');
        }

        return classes;
    }

    /**
     *
     */
    function setVendorTransitionEnd()
    {
        var transitionMap = {
            'transition': 'transitionend',
            'webkitTransition': 'webkitTransitionEnd'
        };

        vendors.transitionend = transitionMap[getVendor('transition')];
    }

    /**
     * Get the device pixel density
     * @returns {number} A value of the device pixel density
     */
    function getDevicePixelDensity()
    {
        return window.devicePixelRatio;
    }

    /**
     * Return default event type supported by device
     * @returns {string} `touchstart` or `click`
     */
    function defaultEventType()
    {
        return detects.touchevents ? 'touchstart' : 'click';
    }

    function updateMobileDetect()
    {
        mobileDetect = new MobileDetect(navigator.userAgent);
    }

    var deviceChangeOnResize = DeviceChangeOnResize || false;

    function getDeviceType()
    {
        if (deviceChangeOnResize === true && !getQuerystring('ua', null)) //?ua=1
        {
            return useWindowWith();
        }
        else
        {
            return useMobileDetect();       
        }
    }

    function useWindowWith()
    {
        if (window.innerWidth < deviceWidths.MOBILE)
        {
            return deviceTypes.MOBILE;
        }
        else if (window.innerWidth <= deviceWidths.TABLET)
        {
            return deviceTypes.TABLET;
        }
        else
        {
            return deviceTypes.DESKTOP;
        }
    }

    function useMobileDetect()
    {
        if (isTablet())
        {
            return deviceTypes.TABLET;
        }
        else if (isMobile())
        {
            return deviceTypes.MOBILE;
        }
        else
        {
            return deviceTypes.DESKTOP;
        }
    }

    function isMobile() 
    {
        return /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/im.test(navigator.userAgent);
    }

    function isTablet() 
    {
        return /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/im.test(navigator.userAgent);
    }

    function getDeviceOrientation()
    {
        var isDesktop = getDeviceType() === deviceTypes.DESKTOP;

        if (!isDesktop)
        {
            if (window.innerWidth < window.innerHeight)
            {
                return deviceOrientations.PORTRAIT; //portrait
            }
            else
            {
                return deviceOrientations.LANDSCAPE; //landscape
            }
        }
        else
        {
            return deviceOrientations.DESKTOP;
        }
    }

    function detectIPhoneX()
    {
        var os = mobileDetect.os();
        var ratio = window.devicePixelRatio || 1;
        var screen = {
            width : window.screen.width * ratio,
            height : window.screen.height * ratio
        };
        return os && (
            (screen.width === 1125 && screen.height === 2436) || // iPhone X and iPhone XS
            (screen.width === 828 && screen.height === 1792) || // iPhone XR
            (screen.width === 1242 && screen.height === 2688) // iPhone XS Max
        );
    }

    var deviceWidths =
    {
        MOBILE: 768,
        TABLET: 1024,
        DESKTOP: 1920
    };

    var deviceTypes =
    {
        MOBILE: 'IsMobile',
        TABLET: 'IsTablet',
        DESKTOP: 'IsDesktop'
    };

    var deviceOrientations =
    {
        PORTRAIT: 'portrait-orientation',
        LANDSCAPE: 'landscape-orientation',
        DESKTOP: 'desktop-orientation'
    };

    // Feature detections ------------------------------------------------------

    addTest('localStorage', function ()
    {
        var bool = true,
            val = 'ltodo';

        try
        {
            localStorage.setItem(val, val);
            localStorage.removeItem(val);
        } catch (e)
        {
            bool = false;
        }

        return bool;
    });

    addTest('sessionStorage', function ()
    {
        var bool = true,
            val = 'ltodo';

        try
        {
            sessionStorage.setItem(val, val);
            sessionStorage.removeItem(val);
        } catch (e)
        {
            bool = false;
        }

        return bool;
    });

    addTest('requestAnimationFrame', function ()
    {
        var list = [
            'webkitRequestAnimationFrame',
            'requestAnimationFrame'
        ];

        return propTest(window, list);
    });

    addTest('cancelAnimationFrame', function ()
    {
        var list = [
            'webkitCancelAnimationFrame',
            'cancelAnimationFrame'
        ];

        return propTest(window, list);
    });

    addTest('cssAnimations', function ()
    {
        var list = [
            'webkitAnimation',
            'animation'
        ];

        return propTest(rootStyles, list);
    });

    addTest('cssTransitions', function ()
    {
        var list = [
            'webkitTransition',
            'transition'
        ];

        return propTest(rootStyles, list);
    });

    addTest('cssTransforms', function ()
    {
        var list = [
            'webkitTransform',
            'msTransform',
            'transform'
        ];

        return propTest(rootStyles, list);
    });

    addTest('standalone', function ()
    {
        return !!('standalone' in navigator && navigator.standalone);
    }, true);

    addTest('touchevents', function ()
    {
        return !!('ontouchstart' in window || window.DocumentTouch &&
            document instanceof DocumentTouch);
    }, true);

    // End of feature detections -----------------------------------------------

    //runTests();
    //mobileDetectClasses();
    //setClasses(classes, classPrefix);
    //setVendorTransitionEnd();

    // Public API
    api.list = getTestNames;
    api.prefixed = getVendor;
    api.support = getTestResult;
    api.version = version;
    api.mobileDetect = mobileDetect;
    api.detectIPhoneX = detectIPhoneX;
    api.dpi = getDevicePixelDensity();
    api.defaultEvent = defaultEventType();
    api.updateMobileDetect = updateMobileDetect;
    api.getDeviceType = getDeviceType;
    api.getDeviceOrientation = getDeviceOrientation;
    api.deviceTypes = deviceTypes;
    api.deviceWidths = deviceWidths;
    api.deviceOrientations = deviceOrientations;
    api.deviceChangeOnResize = deviceChangeOnResize;

    return api;
}());

window.UI = UI;