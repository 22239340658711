﻿// Allow definition of Cookies global
var Cookies = (function ()
{
    "use strict";
    var api = {};

    var constGlobalCookieExpDays = 360;

    api.set = function (name, value, expireDays)
    {
        var exdate = new Date();        
        exdate.setTime(exdate.getTime() + (expireDays || constGlobalCookieExpDays) * 24 * 60 * 60 * 1000);

        document.cookie = name + "=" + encodeURI(value) +
            ((!expireDays) ? "" : ";expires=" + exdate.toGMTString()) + ";" +
            getDomainNameForCookie() + " path=/" +
            getSecureFlag();
    };

    api.setCookie = function(name, value, expireDays) {
        var date = new Date();
        date.setTime(date.getTime() + (expireDays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + date.toUTCString();
        document.cookie = name + "=" + value + ";" + expires + ";path=/";
    };

    api.get = function (cname)
    {
        var name = cname + "=";
        var ca = document.cookie.split(";");
        for (var i = 0; i < ca.length; i++)
        {
            var c = ca[i];
            while (c.charAt(0) == " ")
            {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0)
            {
                return decodeURI(c.substring(name.length, c.length));
            }
        }
      
        return undefined;
    };

    api.remove = function (name)
    {
        if (api.get(name))
        {
            document.cookie = name + "=" + ";expires=Thu, 01-Jan-1970 00:00:01 GMT;" + getDomainNameForCookie() + " path=/";
        }
    };

    var cookieDomain = initCookieDomain();
    var getDomainNameForCookie = () => cookieDomain;

    // Gets the base domain name form the window.location.hostname property. Used to set the domain for cookies.
    // For "whl.staging.sbtech.com" for instance it will return "domain=.sbtech.com;". The prefixed dot is needed by HTTP cookie standard
    function getSecondLevelDomains() {
        var domains = ["co.at", "*.ar", "*.au", "*.bd", "*.bn", "*.bt", "*.ck", "*.cy", "*.do", "*.eg", "*.er", "*.et", "*.fj", "*.fk", "*.gt", "*.gu", "*.id", "*.il", "*.jm", "*.ke", "*.kh",
        "*.kw", "*.mm", "*.mt", "*.mz", "*.ni", "*.np", "*.nz", "*.om", "*.pg", "*.py", "*.qa", "*.sv", "*.tr", "*.uk", "co.uk", "*.uy", "*.ve", "*.ye", "*.yu", "*.za", "*.zm", "*.zw"];

        if (window.CookiesBySubDomain) {
            return domains.concat(SecondLevelDomainExceptions.replace(/\s/g, "").split(","));
        }
        return domains;
    }

    function initCookieDomain()
    {
        var hostname = window.location.hostname;

        // Google Chrome ignores domain name for localhost but in this case explicitly defining a domain for cookies is unnecessary
        if (hostname == "localhost") { return ""; }

        var domainName = "",
            domainNameLevel = 0,
            domainParts, level,
            isStrict, sld, sldIndex;
        const secondLevelDomains = getSecondLevelDomains();

        for (var i = 0, len = secondLevelDomains.length; i < len; i += 1)
        {
            var domain = secondLevelDomains[i];
            if(domain === hostname) {
                return `domain=.${domain};`;
            }
            isStrict = domain.indexOf("*.") !== 0;
            sld = isStrict ? domain : domain.substring(2);
            sldIndex = hostname.indexOf("." + sld);

            if (sldIndex !== -1 && sldIndex === hostname.length - sld.length - 1)
            {
                domainParts = hostname.replace("." + sld, "").split(".");
                level = sld.split(".").length;

                if (isStrict && domainParts.length > 0 && level > domainNameLevel)
                {
                    domainName = "." + domainParts[domainParts.length - 1] + "." + sld;
                    domainNameLevel = domainParts.length;
                }
                else if (!isStrict && domainParts.length > 1 && level > domainNameLevel)
                {
                    domainName = "." + domainParts[domainParts.length - 2] + "." + domainParts[domainParts.length - 1] + "." + sld;
                    domainNameLevel = domainParts.length;
                }
            }
        }

        if (!domainName)
        {
            domainParts = hostname.split(".");
            if (domainParts.length > 1)
            {
                domainName = "." + domainParts[domainParts.length - 2] + "." + domainParts[domainParts.length - 1];
            }
        }

        return !domainName ? "" : "domain=" + domainName + ";";
    };

    var getSecureFlag = function ()
    {
        if (window.UseSecureCookies && location && location.protocol == "https:") {
            return ";secure";
        }

        return "";
    };

    api._getDomainNameForCookie = getDomainNameForCookie;

    return api;
}());

window.Cookies = Cookies;
