﻿var PushOdds = (function (Communicator)
{
    'use strict';

    var version = 1;

    function init(pushServerConnectionPointUrl)
    {
        Communicator.Start(pushServerConnectionPointUrl, 'PushOdds');
    }

    return {
        Version: version,
        Run: init
    }
})(Communicator);

window.PushOdds = PushOdds;