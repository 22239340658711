﻿var ScoreBoardParser = (function ScoreBoardParser()
{
    var mapping = {},
        prefix = "_";

    // Add new branches here:
    mapping[prefix + eBranches.Volleyball] = function (game) { return new VolleyballScore(game); }

    function ScoreBoardParser()
    {

    }

    ScoreBoardParser.prototype.parse = function (game)
    {
        var prop = prefix + game.BranchID;

        if (mapping.hasOwnProperty(prop))
        {
            return mapping[prop](game);
        }
    };

    return ScoreBoardParser;
})();

window.ScoreBoardParser = ScoreBoardParser;