﻿var GameData = (function () {
    function GameData(status, gameTime, updateTime, isET, redcards) {
        this.Status = status;
        this.GameTime = gameTime;
        this.LastTimeUpdate = Date.fromISO(updateTime);
        this.IsET = isET;
        this.RedCards = redcards;
    }
    return GameData;
})();

window.GameData = GameData;