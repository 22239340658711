﻿// Implemented only for backward compatibility with linked website scripts
// Use HTML5 localStorage and sessionStorage APIs directly in client scripts

/*globals StorageUtils */
var WebStorage = WebStorage || (function ()
{
    "use strict";
    var api = {};

    api.isSupported = function ()
    {
        return typeof Storage !== "undefined";
    };

    api.localAdd = function (item, data)
    {
        if (!api.isSupported())
        {
            return;
        }

        StorageUtils.saveToStorage(item, JSON.stringify(data), localStorage);
    };

    api.localContains = function (item)
    {
        return StorageUtils.keyExists(item, localStorage);
    };

    api.localRemove = function (item)
    {
        if (!api.isSupported())
        {
            return;
        }

        if (JSON.parse(StorageUtils.getFromStorage(item, localStorage)))
        {
            StorageUtils.removeFromStorage(item, localStorage);
        }
    };

    api.sessionRemove = function (item)
    {
        if (!api.isSupported())
        {
            return;
        }

        if (JSON.parse(StorageUtils.getFromStorage(item, sessionStorage)))
        {
            StorageUtils.removeFromStorage(item, sessionStorage);
        }
    };

    api.setItemInSession = function (key, value)
    {
        if (!api.isSupported())
        {
            return;
        }

        StorageUtils.saveToStorage(key, value, sessionStorage);
    };

    api.getItemArrayFromSession = function (item)
    {
        if (!api.isSupported())
        {
            return;
        }

        var itemArray = [];

        if (JSON.parse(StorageUtils.getFromStorage(item, localStorage)))
        {
            itemArray = JSON.parse(StorageUtils.getFromStorage(item, localStorage));
        }

        return itemArray;
    };

    api.getItemArrayFromLocal = function (item)
    {
        if (!api.isSupported())
        {
            return;
        }

        var itemArray = [];

        if (JSON.parse(StorageUtils.getFromStorage(item, localStorage)))
        {
            itemArray = JSON.parse(StorageUtils.getFromStorage(item, localStorage));
        }

        return itemArray;
    };


    return api;
}());

window.WebStorage = WebStorage;
